export class PCSKAccountClosureApprover {
    email: string;
    type: string;

    constructor(email: string, type: string) {
        this.email = email
        this.type = type
    }
}

export class PCSKAccountClosureItem {
    // API response states
    private _index: number | undefined;
    private readonly _account_uuid: string | undefined;
    private readonly _approval_expiry_timestamp: number | undefined;
    private readonly _approval_ids: string[] | undefined;
    private readonly _approved_by_emails: string[] | undefined;
    private readonly _aws_account_id: string | undefined;
    private readonly _can_approve: boolean | undefined;
    private readonly _closed_at: number | undefined;
    private readonly _closure_work_item_id: string | undefined;
    private readonly _email: string | undefined;
    private readonly _exceptions: object | undefined;
    private readonly _fd: string | undefined;
    private readonly _fi: string | undefined;
    private readonly _force_approval_reason: string | undefined;
    private readonly _force_approved_by_email: string | undefined;
    private readonly _has_crossed_waiting_period: boolean | undefined;
    private readonly _is_approved: boolean | undefined;
    private readonly _is_closed: boolean | undefined;
    private readonly _is_expired: boolean | undefined;
    private readonly _is_falcon: boolean | undefined;
    private readonly _last_notification_timestamp: number | undefined;
    private readonly _min_approvers: number | undefined;
    private readonly _name: string | undefined;
    private readonly _org_account_id: string | undefined;
    private readonly _request_timestamp: number | undefined;
    private readonly _requester_arn: string | undefined;
    private readonly _scheduled_closure_message: string | undefined;
    private readonly _sent_notifications_count: number | undefined;
    private readonly _services_examined: string[] | undefined;
    private readonly _state: string | undefined;
    private readonly _status: string | undefined;
    private readonly _team: string | undefined;
    private readonly _waiting_period: number | undefined;

    // Custom defined states
    private readonly _request_date: string | undefined;
    private readonly _approval_expiry_date: string | undefined;
    private readonly _last_notification_date: string | undefined;
    private readonly _display_account: string | undefined;
    private readonly _pending_approvals: number;

    // Static states
    public static activeClosureStatuses = ["REQUESTED", "CLEANED"]
    public static statusProcessing = "PROCESSING"
    public static skipKeys = ["activeClosureStatuses", "statusProcessing", "_display_account", "skipKeys", "_can_approve", "_index"]
    public static timestampToDate = (timestamp: number): string => {
        if (timestamp < 1) {
            return "unknown"
        }
        const date = new Date(timestamp * 1000);
        return date.toDateString();
    };

    constructor(data: {
        index?: number;
        account_uuid?: string;
        approval_expiry_timestamp?: number;
        approval_ids?: string[];
        approved_by_emails?: string[];
        aws_account_id?: string;
        can_approve?: boolean;
        closure_work_item_id?: string;
        closed_at?: number;
        email?: string;
        exceptions?: object;
        fd?: string;
        fi?: string;
        force_approval_reason?: string;
        force_approved_by_email?: string;
        has_crossed_waiting_period?: boolean;
        is_approved?: boolean;
        is_closed?: boolean;
        is_expired?: boolean;
        is_falcon?: boolean;
        last_notification_timestamp?: number;
        min_approvers?: number;
        name?: string;
        org_account_id?: string;
        request_timestamp?: number;
        requester_arn?: string;
        scheduled_closure_message?: string;
        sent_notifications_count?: number;
        services_examined?: string[];
        state?: string;
        status?: string;
        team?: string;
        waiting_period?: number;
    }) {
        this._account_uuid = data.account_uuid;
        this._approval_expiry_timestamp = data.approval_expiry_timestamp;
        this._approval_ids = data.approval_ids;
        this._approved_by_emails = data.approved_by_emails;
        this._aws_account_id = data.aws_account_id;
        this._can_approve = data.can_approve;
        this._closed_at = data.closed_at;
        this._closure_work_item_id = data.closure_work_item_id;
        this._email = data.email;
        this._exceptions = data.exceptions;
        this._fd = data.fd;
        this._fi = data.fi;
        this._force_approval_reason = data.force_approval_reason;
        this._force_approved_by_email = data.force_approved_by_email;
        this._has_crossed_waiting_period = data.has_crossed_waiting_period;
        this._index = data.index;
        this._is_approved = data.is_approved;
        this._is_closed = data.is_closed;
        this._is_expired = data.is_expired;
        this._is_falcon = data.is_falcon;
        this._last_notification_timestamp = data.last_notification_timestamp;
        this._min_approvers = data.min_approvers;
        this._name = data.name;
        this._org_account_id = data.org_account_id;
        this._request_timestamp = data.request_timestamp;
        this._requester_arn = data.requester_arn;
        this._scheduled_closure_message = data.scheduled_closure_message;
        this._sent_notifications_count = data.sent_notifications_count;
        this._services_examined = data.services_examined;
        this._state = data.state;
        this._status = data.status;
        this._team = data.team;
        this._waiting_period = data.waiting_period;

        // Custom defined states
        this._approval_expiry_date = data.approval_expiry_timestamp ? PCSKAccountClosureItem.timestampToDate(data.approval_expiry_timestamp) : ''
        this._request_date = data.request_timestamp ? PCSKAccountClosureItem.timestampToDate(data.request_timestamp) : ''
        this._last_notification_date = data.last_notification_timestamp ? PCSKAccountClosureItem.timestampToDate(data.last_notification_timestamp) : ''
        this._display_account = `${data.aws_account_id ?? ""}\n${data.name ?? ""}`
        this._pending_approvals = Math.max((data.min_approvers ? data.min_approvers : 0) - (data.approval_ids ? data.approval_ids.length : 0), 0);
    }

    get index(): number {
        return this._index ?? 0;
    }
    get email(): string {
        return this._email ?? "";
    }
    get exceptions(): object {
        return this._exceptions || {};
    }
    get org_account_id(): string {
        return this._org_account_id ?? "";
    }
    get name(): string {
        return this._name ?? "";
    }
    get account_uuid(): string {
        return this._account_uuid ?? "";
    }
    get aws_account_id(): string {
        return this._aws_account_id ?? "";
    }
    get request_timestamp(): number {
        return this._request_timestamp ?? 0;
    }
    get request_date(): string {
        return this._request_date ?? "";
    }
    get status(): string {
        return this._status ?? "";
    }
    get waiting_period(): number {
        return this._waiting_period ?? 0;
    }
    get closure_work_item_id(): string {
        return this._closure_work_item_id ?? "";
    }
    get approval_ids(): string[] {
        return this._approval_ids ?? [];
    }
    get last_notification_timestamp(): number {
        return this._last_notification_timestamp ?? 0;
    }
    get last_notification_date(): string {
        return this._last_notification_date ?? "";
    }
    get sent_notifications_count(): number {
        return this._sent_notifications_count ?? 0;
    }
    get min_approvers(): number {
        return this._min_approvers ?? 0;
    }
    get approval_expiry_timestamp(): number {
        return this._approval_expiry_timestamp ?? 0;
    }
    get approval_expiry_date(): string {
        return this._approval_expiry_date ?? "";
    }
    get requester_arn(): string {
        return this._requester_arn ?? "";
    }
    get force_approved_by_email(): string {
        return this._force_approved_by_email ?? "";
    }
    get force_approval_reason(): string {
        return this._force_approval_reason ?? "";
    }
    get services_examined(): string[] {
        return this._services_examined ?? [];
    }
    get closed_at(): number {
        return this._closed_at ?? 0;
    }
    get approved_by_emails(): string[] {
        return this._approved_by_emails ?? [];
    }
    get state(): string {
        return this._state ?? "";
    }
    get is_approved(): boolean {
        return this._is_approved ?? false;
    }
    get is_expired(): boolean {
        return this._is_expired ?? false;
    }
    get has_crossed_waiting_period(): boolean {
        return this._has_crossed_waiting_period ?? false;
    }
    get is_closed(): boolean {
        return this._is_closed ?? false;
    }
    get scheduled_closure_message(): string {
        return this._scheduled_closure_message ?? "";
    }
    get display_account(): string {
        return this._display_account ?? "";
    }
    get pending_approvals(): number {
        return this._pending_approvals ?? 0;
    }
    get is_falcon(): boolean {
        return this._is_falcon ?? false;
    }
    get fi(): string {
        return this._fi ?? ''
    }
    get fd(): string {
        return this._fd ?? ''
    }
    get team(): string {
        return this._team ?? ''
    }
    get can_approve() : boolean {
        return this._can_approve ?? false
    }
    // Setters
    set index(value: number | undefined) {
        this._index = value;
    }

    isReadyInPCSKClosureQueue(): boolean {
        return PCSKAccountClosureItem.activeClosureStatuses.includes(this.status)
    }

    isReadyForClosure(): boolean {
        return this.isReadyInPCSKClosureQueue() && this.is_approved;
    }

    isWaitingApprovals(): boolean {
        return this.isReadyInPCSKClosureQueue() && !this.is_approved;
    }

    isClosureInProcess(): boolean {
        return this.status === PCSKAccountClosureItem.statusProcessing;
    }

    isActiveInPCSKAccountClosure(): boolean {
        return this.isReadyInPCSKClosureQueue() || this.isClosureInProcess()
    }

    getCSVHeaders(): string {
        return Object.keys(this)
            .filter(key => !PCSKAccountClosureItem.skipKeys.includes(key))
            .sort()
            .join(',');
    }

    getCSVData(): string {
        return Object.keys(this)
            .filter(key => !PCSKAccountClosureItem.skipKeys.includes(key))
            .sort()
            .map(key => {
                const value = this[key as keyof PCSKAccountClosureItem];
                return value !== undefined ? `"${value}"` : '';
            })
            .join(',');
    }

    getCSV(): string {
        const headers = this.getCSVHeaders()
        const row = this.getCSVData()
        // Concatenate headers and rows with line breaks
        return [headers].concat(row).join('\n');
    }

    getStatusMessage(): string {
        const status = `${this.status}`.toLowerCase()
        if (status.includes("limit")) {
            return "has Exceeded Limit";
        }
        if (status === "closed") {
            return `is Successful`;
        }
        if (status.includes("failed")) {
            return "has Failed";
        }
        if (status.includes("processing")) {
            return "is being Processed";
        }
        if (status.includes("expired")) {
            return "has Expired";
        }
        if (this.is_approved) {
            if (this.has_crossed_waiting_period) {
                return "is Ready to be Processed";
            }
            return `is Approved and the Waiting Period ends ${this.scheduled_closure_message}`
        }
        if (status.includes("requested") || status.includes("cleaned")) {
            return "is Waiting Approvals";
        }
        return `has status ${this.status}`;
    }
}

export class PCSKAdminClosureSummaryItem {
    private _count: number | undefined;
    private _status: string | undefined;

    constructor(count?: number, status?: string) {
        this._count = count;
        this._status = status;
    }

    // Getter for count
    get count(): number | undefined {
        return this._count;
    }

    // Setter for count
    set count(value: number | undefined) {
        this._count = value;
    }

    // Getter for status
    get status(): string | undefined {
        return this._status;
    }

    // Setter for status
    set status(value: string | undefined) {
        this._status = value;
    }
}

// ServicesComplianceExaminationResponse represents a service with errors.
export class ServicesComplianceExaminationResponse {
    service: string;
    error: string;

    constructor(data: { service: string; error: string }) {
        this.service = data.service;
        this.error = data.error;
    }
}

// ErroredAccount represents an account with errors.
export class ErroredAccount {
    accountId: string;
    error: string;
    errorServices?: ServicesComplianceExaminationResponse[];

    constructor(data: { accountId: string; error: string; errorServices?: ServicesComplianceExaminationResponse[] }) {
        this.accountId = data.accountId;
        this.error = data.error;
        this.errorServices = data.errorServices?.map(service => new ServicesComplianceExaminationResponse(service));
    }
}

// InsertAccountClosureResponse represents the response of an account closure.
export class InsertAccountClosureResponse {
    clientRequestToken: string;
    processed: PCSKAccountClosureItem[];
    unProcessed: ErroredAccount[];
    errorMessage: string;

    constructor(data: {
        clientRequestToken: string;
        processed: PCSKAccountClosureItem[];
        unProcessed: ErroredAccount[];
        errorMessage: string;
    }) {
        this.clientRequestToken = data.clientRequestToken;
        this.processed = data.processed;
        this.unProcessed = data.unProcessed.map(account => new ErroredAccount(account));
        this.errorMessage = data.errorMessage;
    }
}

