export const mock_aws_accounts_response = [
    {
        "id": "564927313526",
        "arn": "",
        "email": "pcsk-aws-stage+16944602519384951694460251938463@salesforce.com",
        "name": "pcstest-kurt-shield-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "578377698994",
        "arn": "",
        "email": "pcsk-aws-stage+hr9mx5X1j9Y5ZqBAG2grr29lEQRoV0TD@salesforce.com",
        "name": "pcstest-85f0ad1b-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "185516396157",
        "arn": "",
        "email": "pcsk-aws-stage+sMjNbKbvcudkNrXVQpKXuYstsi0Ghol2@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "025629114568",
        "arn": "",
        "email": "pcsk-aws-stage+16899904001212421689990400121237@salesforce.com",
        "name": "test-XX-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "458694414896",
        "arn": "",
        "email": "pcsk-aws-stage+mIHePGdhpG3LFmfI6fofluoMZfuOqcRb@salesforce.com",
        "name": "dnssec-pol-test-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "834243724964",
        "arn": "",
        "email": "pcsk-aws-stage+16952459717217131695245971721686@salesforce.com",
        "name": "pcstest-jm-20230930-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "742131975695",
        "arn": "",
        "email": "pcsk-aws-stage+16944364006037001694436400603683@salesforce.com",
        "name": "pcstest-nouser-42376de5-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "632182263257",
        "arn": "",
        "email": "pcsk-aws-stage+16945603974487651694560397448748@salesforce.com",
        "name": "pcstest-20230915-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "068901050125",
        "arn": "",
        "email": "pcsk-aws-stage+16940265192094961694026519209378@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1694026511909072405",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "056375206402",
        "arn": "",
        "email": "pcsk-aws-stage+iCLMgtH7mwup5A7Yc47h29QU4MS6Ve8G@salesforce.com",
        "name": "pcstest-nouser-0b056137-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "272189513723",
        "arn": "",
        "email": "pcsk-aws-stage+16929270972778981692927097277871@salesforce.com",
        "name": "test-rnd-038731646608-pool-1692909005379558685",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "954091282203",
        "arn": "",
        "email": "pcsk-aws-stage+655lohUEImyufI61ioNwzfcUH3so7iKY@salesforce.com",
        "name": "pcstest-c3e305fe-fd2-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "835199419929",
        "arn": "",
        "email": "pcsk-aws-stage+16941167959319421694116795931818@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1694116788577920419",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "099664581673",
        "arn": "",
        "email": "pcsk-aws-stage+16944534167021661694453416702138@salesforce.com",
        "name": "pcstest-kurt-shield-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "164657601738",
        "arn": "",
        "email": "pcsk-aws-stage+16964603690625581696460369062540@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710687262",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "081412478747",
        "arn": "",
        "email": "pcsk-aws-stage+16945584583888761694558458376996@salesforce.com",
        "name": "pcstest-nouser-b90bc9af-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "528419681041",
        "arn": "",
        "email": "pcsk-aws-stage+eLz1uqZ64cMYEfx0rOEHj9HV9Rz5eNF2@salesforce.com",
        "name": "rcc-delete",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "154703667896",
        "arn": "",
        "email": "pcsk-aws-stage+synthetic-tester@salesforce.com",
        "name": "pcsk-synthetic-tester",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "740341996880",
        "arn": "",
        "email": "pcsk-aws-stage+16944273495562751694427349556208@salesforce.com",
        "name": "pcstest-nouser-274038a4-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "468676824113",
        "arn": "",
        "email": "pcsk-aws-stage+16962810798740111696281079873991@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "551937041342",
        "arn": "",
        "email": "pcsk-aws-stage+16945675125229061694567512514337@salesforce.com",
        "name": "pcstest-20230915-foundation-collection",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "297714730664",
        "arn": "",
        "email": "pcsk-aws-stage+16945548722522561694554872252233@salesforce.com",
        "name": "pcstest-nouser-b90bc9af-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "006284702128",
        "arn": "",
        "email": "pcsk-aws-stage+16902518535683071690251853568259@salesforce.com",
        "name": "pcstest-nouser-30cb218d-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "413846461250",
        "arn": "",
        "email": "pcsk-aws-stage+16891214490298291689121449029824@salesforce.com",
        "name": "endpoints-useast1-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "692407548234",
        "arn": "",
        "email": "pcsk-aws-stage+16944325214793381694432521479283@salesforce.com",
        "name": "pcstest-jack-fake-bom-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "900094696311",
        "arn": "",
        "email": "pcsk-aws-stage+16944292874623821694429287462360@salesforce.com",
        "name": "pcstest-jack-fake-bom-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "698263928899",
        "arn": "",
        "email": "pcsk-aws-stage+16923856502325171692385650232411@salesforce.com",
        "name": "pcstest-israel-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "221467375030",
        "arn": "",
        "email": "pcsk-aws-stage+FnRBUEGgE8oSbo7iCFxDI8xmW8EuZkC4@salesforce.com",
        "name": "herokucustaugtwoLate",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "564346361552",
        "arn": "",
        "email": "pcsk-aws-stage+16951502892865181695150289286347@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "480769999234",
        "arn": "",
        "email": "pcsk-aws-stage+test-marketplace-permissions@salesforce.com",
        "name": "pcsktest-marketplace-permissions",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "867798442864",
        "arn": "",
        "email": "pcsk-aws-stage+16951599745911511695159974591091@salesforce.com",
        "name": "pcstest-jmpreprod-9b2-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "245396707808",
        "arn": "",
        "email": "shrawan+closeAccountAPITest@salesforce.com",
        "name": "shrawan+closeAccountAPITest",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "933642081020",
        "arn": "",
        "email": "pcsk-aws-stage+16964564528557921696456452855780@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710543106",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "888796671447",
        "arn": "",
        "email": "pcsk-aws-stage+16964558035288191696455803528801@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710515843",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "150612951281",
        "arn": "",
        "email": "pcsk-aws-stage+16964616647213371696461664721319@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710729527",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "137191841826",
        "arn": "",
        "email": "pcsk-aws-stage+16940230684708011694023068470779@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1694023060854630019",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "765583699333",
        "arn": "",
        "email": "pcsk-aws-stage+16961245342020321696124534202013@salesforce.com",
        "name": "pcstest-local-FMT-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "659869176909",
        "arn": "",
        "email": "pcsk-aws-stage+16902608993016591690260899301654@salesforce.com",
        "name": "pcstest-nouser-76953fc3-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "049541089598",
        "arn": "",
        "email": "pcsk-aws-stage+16964681390522811696468139052244@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710959291",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "935539430726",
        "arn": "",
        "email": "pcsk-aws-stage+Nqds9294SxetguIQJJDKZvmXyvqACcbt@salesforce.com",
        "name": "herokucustomermanual",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "385834598848",
        "arn": "",
        "email": "pcsk-aws-stage+16891182155774641689118215577442@salesforce.com",
        "name": "endpoints-useast1-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "370073908640",
        "arn": "",
        "email": "pcsk-aws-stage+16902512065952601690251206595255@salesforce.com",
        "name": "pcstest-nouser-30cb218d-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "700159607492",
        "arn": "",
        "email": "pcsk-aws-stage+16934442786481111693444278648090@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616525154640",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "736155599651",
        "arn": "",
        "email": "pcsk-aws-stage+16934326238010291693432623801009@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524765147",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "174527893103",
        "arn": "",
        "email": "pcsk-aws-stage+7rjOjUYBfPXP8M0ccbVdwX7qeV7LoBKF@salesforce.com",
        "name": "pcstest-jm-ou-04-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "428107179646",
        "arn": "",
        "email": "pcsk-aws-stage+16952453243342321695245324327745@salesforce.com",
        "name": "pcstest-jm-20230930-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "097260735870",
        "arn": "",
        "email": "pcsk-aws-stage+16927309514589061692730951458888@salesforce.com",
        "name": "pcstest-targetisrael-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "649629195888",
        "arn": "",
        "email": "pcsk-aws-stage+SaJFlamLpwtrz0rfNzkmMVEuCx3BvS9C@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "315666832969",
        "arn": "",
        "email": "pcsk-aws-stage+16962756627472761696275662740173@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "607219917736",
        "arn": "",
        "email": "pcsk-aws-stage+vIKLPDsHln4WdaJgkJcPxZDQmiE29VwO@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-svc2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "302238812042",
        "arn": "",
        "email": "pcsk-aws-stage+iQ9gO2Ua9HrYqkrI0IAtmpRwAyR4xPgi@salesforce.com",
        "name": "pcstest-3e6a82fd-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "068933169654",
        "arn": "",
        "email": "pcsk-aws-stage+16962763113458181696276311345801@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-svc2",
        "team_name": "service2",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "529452185582",
        "arn": "",
        "email": "pcsk-aws-stage+16944576583394521694457658339398@salesforce.com",
        "name": "pcstest-kurt-shield-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "487926413630",
        "arn": "",
        "email": "pcsk-aws-stage+16902596076325311690259607632528@salesforce.com",
        "name": "pcstest-nouser-76953fc3-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "test3",
        "arn": "",
        "email": "test3@gmail.com",
        "name": "",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "521359148260",
        "arn": "",
        "email": "pcsk-aws-stage+fHZRVfcmoR6GgwlUjj1DWelx4zF45m4c@salesforce.com",
        "name": "herokucustaugtwoLate",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "248205202279",
        "arn": "",
        "email": "pcsk-aws-stage+RJ24iyqGj3z82dc10PuP92EoMyEuNtAS@salesforce.com",
        "name": "pcstest-backuptest5-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "023058032181",
        "arn": "",
        "email": "pcsk-aws-stage+16906009589046731690600958904669@salesforce.com",
        "name": "pcstest-pcstest-kurt-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "953994510696",
        "arn": "",
        "email": "pcsk-aws-stage+16927335353617051692733535361643@salesforce.com",
        "name": "pcstest-targetisrael2-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "132767303220",
        "arn": "",
        "email": "pcsk-aws-stage+uFT4PraeVjLdSRNAN1NapUMcYXJVI8pf@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "745908138271",
        "arn": "",
        "email": "pcsk-aws-stage+16932580183582951693258018358276@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523635439",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "394780774328",
        "arn": "",
        "email": "pcsk-aws-stage+16908353715304051690835371530398@salesforce.com",
        "name": "pcstest-pcstest-kurt-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "972232047070",
        "arn": "",
        "email": "pcsk-aws-stage+b1D2wibrkSgKUoy1FDdX7SGU1jQGGGkn@salesforce.com",
        "name": "pcstest-nouser-0b056137-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "075607432363",
        "arn": "",
        "email": "pcsk-aws-stage+HfAHp0Pfax91W1YfJjBsXSinupSXbBAv@salesforce.com",
        "name": "pcstest-backuptest5-fd2-svc3",
        "team_name": "service3",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "test2",
        "arn": "",
        "email": "test2@gmail.com",
        "name": "",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "550957100831",
        "arn": "",
        "email": "pcsk-aws-stage+16908334286587391690833428658719@salesforce.com",
        "name": "pcstest-pcstest-kurt-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "602401886447",
        "arn": "",
        "email": "pcsk-aws-stage+16891208021652081689120802165197@salesforce.com",
        "name": "endpoints-useast1-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "556067781797",
        "arn": "",
        "email": "pcsk-aws-stage+16961154622373751696115462237349@salesforce.com",
        "name": "pcstest-eric-il-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "621140335241",
        "arn": "",
        "email": "pcsk-aws-stage+16944428636640101694442863663982@salesforce.com",
        "name": "pcstest-kurt-shield-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "894657395887",
        "arn": "",
        "email": "pcsk-aws-stage+16951483517576501695148351757598@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-svc2",
        "team_name": "service2",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "613212347610",
        "arn": "",
        "email": "pcsk-aws-stage+yboFOwVFpKtSpnE1hSpkJnSDog2BHpvm@salesforce.com",
        "name": "rcc-delete",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "357444088963",
        "arn": "",
        "email": "pcsk-aws-stage+3hpQjJyk5fzbynvNzJtZ7vNpqeWIg18i@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "546146270804",
        "arn": "",
        "email": "pcsk-aws-stage+16898112141606681689811214160664@salesforce.com",
        "name": "pcstest-nouser-f94c3332-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "455737503728",
        "arn": "",
        "email": "pcsk-aws-stage+16952352656590001695235265658987@salesforce.com",
        "name": "pcstest-nouser-d2f100b7-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "560053961592",
        "arn": "",
        "email": "pcsk-aws-stage+16899878119642311689987811964220@salesforce.com",
        "name": "test-XX-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "601220099312",
        "arn": "",
        "email": "pcsk-aws-stage+16962862671085431696286267108528@salesforce.com",
        "name": "pcstest-nouser-494360ae-fd2-mjactivedir",
        "team_name": "mj-activedir",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "565650324478",
        "arn": "",
        "email": "pcsk-aws-stage+16902499149149131690249914914909@salesforce.com",
        "name": "pcstest-nouser-30cb218d-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "863176700272",
        "arn": "",
        "email": "pcsk-aws-stage+nIDZvquydoMCMHvQcib1qttq59mmXQMO@salesforce.com",
        "name": "eric-sfci-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "586667200930",
        "arn": "",
        "email": "pcsk-aws-stage+YKqJ5X5MEaQazsSFAeyocTScmiZt3nPv@salesforce.com",
        "name": "pcstest-backuptest5-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "030371978934",
        "arn": "",
        "email": "pcsk-aws-stage+16923824214946561692382421494605@salesforce.com",
        "name": "pcstest-pcs-test-israel-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "430015181035",
        "arn": "",
        "email": "pcsk-aws-stage+16934248569588301693424856956578@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616523782924",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "106324208841",
        "arn": "",
        "email": "pcsk-aws-stage+I1cijztN2ycnj5zkGTukByRX6QQVTa3U@salesforce.com",
        "name": "herokucustaugtwo",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "832556029312",
        "arn": "",
        "email": "pcsk-aws-stage+16959457561522181695945756132085@salesforce.com",
        "name": "pcstest-jm-231001-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "705702827784",
        "arn": "",
        "email": "pcsk-aws-stage+16940312366411441694031236641094@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1694031228558965013",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "282885972558",
        "arn": "",
        "email": "pcsk-aws-stage+16944376936618271694437693661802@salesforce.com",
        "name": "pcstest-nouser-42376de5-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "548088967055",
        "arn": "",
        "email": "pcsk-aws-stage+16905977239198181690597723919814@salesforce.com",
        "name": "pcstest-pcstest-kurt-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "497976545653",
        "arn": "",
        "email": "pcsk-aws-stage+16962823770523051696282377052182@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "869917115027",
        "arn": "",
        "email": "pcsk-aws-stage+2OhM5kPkO9hVJnlgMX8bF1JWZaiiBWSg@salesforce.com",
        "name": "herokucustaugthreeTwo",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "005012271582",
        "arn": "",
        "email": "pcsk-aws-stage+vVJNukLrE8BQ7DAzUwuHuwWeZY7TIN9g@salesforce.com",
        "name": "eric-sfci-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "379702703080",
        "arn": "",
        "email": "pcsk-aws-stage+wodVEazSQWwbakysF7bMQYUnUgn5ANnV@salesforce.com",
        "name": "pcstest-jm-ou-04-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "205989643681",
        "arn": "",
        "email": "pcsk-aws-stage+16908347240901561690834724090139@salesforce.com",
        "name": "pcstest-pcstest-kurt-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "699546770539",
        "arn": "",
        "email": "pcsk-aws-stage+16878597576096711687859757609663@salesforce.com",
        "name": "test-nonfalcon-shrawan-reset",
        "team_name": "pcsk-india-non-falcon",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "447266589673",
        "arn": "",
        "email": "pcsk-aws-stage+16902531460751031690253146075099@salesforce.com",
        "name": "pcstest-nouser-30cb218d-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "316590300603",
        "arn": "",
        "email": "pcsk-aws-stage+16962849708159051696284970815783@salesforce.com",
        "name": "pcstest-nouser-494360ae-fd2-svc3",
        "team_name": "service3",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "633257078197",
        "arn": "",
        "email": "pcsk-aws-stage+j9rQ6ek3U1jMNHRWPhZlU8k38Fy09qr1@salesforce.com",
        "name": "pcstest-3e6a82fd-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "269842993554",
        "arn": "",
        "email": "pcsk-aws-stage+K1SXp6sg4wIkH03qz2iLzC69HfaAGpR6@salesforce.com",
        "name": "pcstest-85f0ad1b-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "628054293183",
        "arn": "",
        "email": "pcsk-aws-stage+16959476994535451695947699453521@salesforce.com",
        "name": "pcstest-jm-231001-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "970214191190",
        "arn": "",
        "email": "pcsk-aws-stage+NQpXzCyXuaGAlPS23l7hRY6atEkEfsum@salesforce.com",
        "name": "pcstest-kurt-iris-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "239894203298",
        "arn": "",
        "email": "pcsk-aws-stage+UW0pPkUcFrwhXRQJnMC3cYw1A8Nmegxh@salesforce.com",
        "name": "pcstest-85f0ad1b-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "138649368844",
        "arn": "",
        "email": "pcsk-aws-stage+fqXN8jBFm2TG1FsYdP2GQmYQKBcDNdMx@salesforce.com",
        "name": "eric-sfci-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "111580651891",
        "arn": "",
        "email": "pcsk-aws-stage+16944435094288171694443509428803@salesforce.com",
        "name": "pcstest-kurt-shield-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "367595771470",
        "arn": "",
        "email": "pcsk-aws-stage+16951580387918381695158038791780@salesforce.com",
        "name": "pcstest-jmpreprod-9b2-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "723338238473",
        "arn": "",
        "email": "pcsk-aws-stage+pcsk-tests-migration-t1@salesforce.com",
        "name": "pcsk-tests-migration-t1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "615097203631",
        "arn": "",
        "email": "pcsk-aws-stage+16962733614252851696273361425229@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "575587562368",
        "arn": "",
        "email": "pcsk-aws-stage+hLp6OFBbE2QpI4LB2lz6rcxbhxxDRkHh@salesforce.com",
        "name": "pcstest-kurt-iris-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "264997558739",
        "arn": "",
        "email": "pcsk-aws-stage+16951541659697261695154165955448@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "370655815241",
        "arn": "",
        "email": "pcsk-aws-stage+16923888794644441692388879464422@salesforce.com",
        "name": "pcstest-israel-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "072650628459",
        "arn": "",
        "email": "pcsk-aws-stage+16932528492372931693252849237244@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523401544",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "292302444465",
        "arn": "",
        "email": "pcsk-aws-stage+kqTCaaNbxFy16qpqxCo8mdwqkdrz3AWJ@salesforce.com",
        "name": "pcstest-068fb9cb-fd2-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "130552488117",
        "arn": "",
        "email": "pcsk-aws-stage+16961232374513631696123237451341@salesforce.com",
        "name": "pcstest-local-FMT-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "259263277213",
        "arn": "",
        "email": "pcsk-aws-stage+16962927779490681696292777949047@salesforce.com",
        "name": "pcstest-kurt-soter-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "518223331747",
        "arn": "",
        "email": "pcsk-aws-stage+16944318745359531694431874535892@salesforce.com",
        "name": "pcstest-jack-fake-bom-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "978414841055",
        "arn": "",
        "email": "pcsk-aws-stage+zauYKtZDm9SXNI2Pay2LoNDnuFMBbukk@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-spinbakmngng",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "134562545040",
        "arn": "",
        "email": "pcsk-aws-stage+6YTb6qitN230qu9OPWJ0Mdr62fxKTKHW@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-skywalker",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "619173406029",
        "arn": "",
        "email": "pcsk-aws-stage+16934313290202371693431329020224@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524710265",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "419818792179",
        "arn": "",
        "email": "pcsk-aws-stage+16899834233152311689983423315224@salesforce.com",
        "name": "test-XX-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "929345736304",
        "arn": "",
        "email": "pcsk-aws-stage+16958847497030081695884749702991@salesforce.com",
        "name": "PCSK-SSMtestAccount",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "397782254468",
        "arn": "",
        "email": "pcsk-aws-stage+16944299345470621694429934546984@salesforce.com",
        "name": "pcstest-jack-fake-bom-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "793536927745",
        "arn": "",
        "email": "pcsk-aws-stage+16959522376678381695952237667820@salesforce.com",
        "name": "pcstest-nouser-1f542767-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "467405228188",
        "arn": "",
        "email": "pcsk-aws-stage+16959541821407541695954182121137@salesforce.com",
        "name": "pcstest-nouser-1f542767-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "628815322248",
        "arn": "",
        "email": "svaradarajan+pcsk+test+1@salesforce.com",
        "name": "sri-pcsk-dev",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "914688764352",
        "arn": "",
        "email": "pcsk-aws-stage+16934352150410351693435215039354@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524861682",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "080693991689",
        "arn": "",
        "email": "shivmitra.mishra+dev@salesforce.com",
        "name": "pcsk-shivmitra-dev",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "621984512652",
        "arn": "",
        "email": "pcsk-aws-stage+step-func@salesforce.com",
        "name": "pcsk-test-step-func-update",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "387259194917",
        "arn": "",
        "email": "pcsk-aws-stage+IyImIfMOdGbnE5rxupDNfJe7LL5RXaem@salesforce.com",
        "name": "pcsk-sri-falcon-stage-2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "577778726716",
        "arn": "",
        "email": "pcsk-aws-stage+9eMM84qVomIdqnjxfxNGmxvTDkv81wu2@salesforce.com",
        "name": "pcstest-nouser-0b056137-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "966813013778",
        "arn": "",
        "email": "pcsk-aws-stage+16886827961577551688682796157751@salesforce.com",
        "name": "endpoints-useast1-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "437949889626",
        "arn": "",
        "email": "pcsk-aws-stage+16962720644767891696272064476772@salesforce.com",
        "name": "pcstest-local-FMT-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "874956360921",
        "arn": "",
        "email": "pcsk-aws-stage+16947421172398791694742117239812@salesforce.com",
        "name": "pcstest-nouser-65906f9a-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "025894412379",
        "arn": "",
        "email": "pcsk-aws-stage+16961212940475241696121294047462@salesforce.com",
        "name": "pcstest-eric-il-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "512191872383",
        "arn": "",
        "email": "pcsk-aws-stage+16959483484698171695948348469786@salesforce.com",
        "name": "pcstest-jm-231001-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "498600781471",
        "arn": "",
        "email": "pcsk-aws-stage+16923780122039451692378012189672@salesforce.com",
        "name": "pcstest-pcs-test-israel-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "757448299176",
        "arn": "",
        "email": "pcsk-aws-stage+16945578113433211694557811343287@salesforce.com",
        "name": "pcstest-nouser-b90bc9af-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "776005330476",
        "arn": "",
        "email": "pcsk-aws-stage+4aPJ9GgXZCav5FTeis8isGWu4bBU1s1q@salesforce.com",
        "name": "pcstest-85f0ad1b-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "775286036940",
        "arn": "",
        "email": "pcsk-aws-stage+16945649272131371694564927213104@salesforce.com",
        "name": "pcstest-20230915-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "488266670883",
        "arn": "",
        "email": "pcsk-aws-stage+16905996662235411690599666223536@salesforce.com",
        "name": "pcstest-pcstest-kurt-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "006138487347",
        "arn": "",
        "email": "pcsk-aws-stage+16934455738681861693445573868166@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616525219675",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "996020965038",
        "arn": "",
        "email": "pcsk-aws-stage+rspkwkQuygRGtikfjn3alomlkWRuWHcz@salesforce.com",
        "name": "rcc-delete",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "726428300296",
        "arn": "",
        "email": "pcsk-aws-stage+16934410426769571693441042676937@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616525063730",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "980363684616",
        "arn": "",
        "email": "pcsk-aws-stage+16947356480749671694735648074946@salesforce.com",
        "name": "pcstest-netv2-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "516342130321",
        "arn": "",
        "email": "pcsk-aws-stage+16927303069282751692730306928251@salesforce.com",
        "name": "pcstest-targetisrael-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "941320155201",
        "arn": "",
        "email": "pcsk-aws-stage+16902557310177941690255731017790@salesforce.com",
        "name": "pcstest-nouser-30cb218d-foundation-skywalker",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "061935380044",
        "arn": "",
        "email": "pcsk-aws-stage+16902550858676121690255085867607@salesforce.com",
        "name": "pcstest-nouser-30cb218d-foundation-sitebridge",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "661263906832",
        "arn": "",
        "email": "pcsk-aws-stage+16962804212724481696280421272429@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "882015580597",
        "arn": "",
        "email": "pcsk-aws-stage+0HvaV1kanhI9g9Ma9LJFL39Gy8spbXnt@salesforce.com",
        "name": "pcstest-7e0059e4-fd2-mjactivedir",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "531166876673",
        "arn": "",
        "email": "pcsk-aws-stage+16952530852839431695253085283926@salesforce.com",
        "name": "pcstest-jm-20230930b-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "921407252649",
        "arn": "",
        "email": "pcsk-aws-stage+16962782627810471696278262780943@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "183595828280",
        "arn": "",
        "email": "pcsk-aws-stage+16945597512452691694559751245249@salesforce.com",
        "name": "pcstest-nouser-b90bc9af-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "058126126236",
        "arn": "",
        "email": "pcsk-aws-stage+dTz1a279pRU67St5WyExtqH73W7Qqn6B@salesforce.com",
        "name": "pcstest-backuptest5-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "049015348942",
        "arn": "",
        "email": "pcsk-aws-stage+ctJnHJsUbM4eVMxVF91WU5rEK1bV0HQE@salesforce.com",
        "name": "pcstest-backuptest5-foundation-resilienceBackup",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "384239814127",
        "arn": "",
        "email": "pcsk-aws-stage+16932496213747581693249621374635@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523299035",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "220096496352",
        "arn": "",
        "email": "pcsk-aws-stage+16934345672406561693434567240635@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524839326",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "426739051855",
        "arn": "",
        "email": "pcsk-aws-stage+16927459655127841692745965512691@salesforce.com",
        "name": "pcstest-targetisrael2-foundation-sitebridge",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "801328323915",
        "arn": "",
        "email": "pcsk-aws-stage+16782627693207061678262769320700@salesforce.com",
        "name": "pcsk-aws-pool-b916782627693043601678262769304340",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "118478547251",
        "arn": "",
        "email": "pcsk-aws-stage+onboard-demo-3@salesforce.com",
        "name": "onboarding-demo-3",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "692743596679",
        "arn": "",
        "email": "pcsk-aws-stage+16902589619309451690258961930940@salesforce.com",
        "name": "pcstest-nouser-76953fc3-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "967364931302",
        "arn": "",
        "email": "pcsk-aws-stage+16927296614513501692729661451335@salesforce.com",
        "name": "pcstest-targetisrael-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "214345212317",
        "arn": "",
        "email": "pcsk-aws-stage+16947459949085791694745994908548@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-fd2-mjactivedir",
        "team_name": "mj-activedir",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "551268849533",
        "arn": "",
        "email": "pcsk-aws-stage+16945610449990081694561044998854@salesforce.com",
        "name": "pcstest-20230915-foundation-resilienceBkp",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "626705709572",
        "arn": "",
        "email": "pcsk-aws-stage+16923901708169461692390170816929@salesforce.com",
        "name": "pcstest-israel-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "556695143450",
        "arn": "",
        "email": "pcsk-aws-stage+16945655738302661694565573824649@salesforce.com",
        "name": "pcstest-20230915-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "821138702523",
        "arn": "",
        "email": "pcsk-aws-stage+16962769597506081696276959750576@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "539044056999",
        "arn": "",
        "email": "pcsk-aws-stage+16932502663319041693250266331881@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523316017",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "894401142716",
        "arn": "",
        "email": "pcsk-aws-stage+16934287400127131693428740012658@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524632969",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "702235978634",
        "arn": "",
        "email": "pcsk-aws-stage+16945636330206631694563633020571@salesforce.com",
        "name": "pcstest-20230915-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "740424242407",
        "arn": "",
        "email": "pcsk-aws-stage+16932586643394211693258664339403@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523657867",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "411467451963",
        "arn": "",
        "email": "pcsk-aws-stage+zP0yZwj9nTf1CL2UgX9eohsToH4tteVw@salesforce.com",
        "name": "pcstest-kurt-iris-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "347972259143",
        "arn": "",
        "email": "pcsk-aws-stage+k95svD7G6003QhhsMJEtAIU7QTStMBRU@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "128411145353",
        "arn": "",
        "email": "pcsk-aws-stage+16934416889666041693441688966553@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616525198562",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "249445568985",
        "arn": "",
        "email": "pcsk-aws-stage+16964674915664601696467491566356@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710935659",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "246769583612",
        "arn": "",
        "email": "pcsk-aws-stage+16952446785497121695244678549683@salesforce.com",
        "name": "pcstest-jm-20230930-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "295965539232",
        "arn": "",
        "email": "pcsk-aws-stage+16932534953700571693253495370034@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523418263",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "880006536988",
        "arn": "",
        "email": "pcsk-aws-stage+spgnOkqqSFgdygZF0FuXYI8IUE6oaLUq@salesforce.com",
        "name": "aws-pcscanary3-uswest2-foundation-syssec",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "694844652845",
        "arn": "",
        "email": "pcsk-aws-stage+16927453196382431692745319638226@salesforce.com",
        "name": "pcstest-targetisrael2-foundation-skywalker",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "113560532688",
        "arn": "",
        "email": "pcsk-aws-stage+JZls5bAqM51AFLCvl1buCwis1w28HL2k@salesforce.com",
        "name": "pcstest-kurt-iris-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "299732620611",
        "arn": "",
        "email": "pcsk-aws-stage+16904178206233841690417820623379@salesforce.com",
        "name": "pcstest-nouser-76953fc3-foundation-skywalker",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "106414668815",
        "arn": "",
        "email": "pcsk-aws-stage+6XHmOxBYpEC9camdCyNVGeXefPRKSO8A@salesforce.com",
        "name": "aws-pcscanary3-uswest2-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "938175589299",
        "arn": "",
        "email": "pcsk-aws-stage+l3rk4GsVsI5ZDPyUcj6Phh9WpjjvYtzJ@salesforce.com",
        "name": "pcstest-jack-01-al-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "344678915973",
        "arn": "",
        "email": "pcsk-aws-stage+16945629857664881694562985766460@salesforce.com",
        "name": "pcstest-20230915-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "466765339546",
        "arn": "",
        "email": "pcsk-aws-stage+MSG2SRSWAOAt7gL7xFTAT68rLYKPeZOS@salesforce.com",
        "name": "aws-pcscanary3-uswest2-foundation-soter",
        "team_name": "soter",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "386576945502",
        "arn": "",
        "email": "pcsk-aws-stage+16927440294353921692744029435344@salesforce.com",
        "name": "pcstest-targetisrael2-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "175697145775",
        "arn": "",
        "email": "pcsk-aws-stage+16951561021475371695156102147519@salesforce.com",
        "name": "pcstest-jmpreprod-9b2-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "510188051365",
        "arn": "",
        "email": "pcsk-aws-stage+16905983710454761690598371045471@salesforce.com",
        "name": "pcstest-pcstest-kurt-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "863129636510",
        "arn": "",
        "email": "pcsk-aws-stage+16932573714569401693257371456925@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523615151",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "206366625121",
        "arn": "",
        "email": "pcsk-aws-stage+16944286417802181694428641780197@salesforce.com",
        "name": "pcstest-jack-fake-bom-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "318779437070",
        "arn": "",
        "email": "pcsk-aws-stage+2hSmDMdOnpwGNhGsyzpaAKH3AGSJVJBU@salesforce.com",
        "name": "eric-pool-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "842912197872",
        "arn": "",
        "email": "pcsk-aws-stage+N1jn0AdjJ8iaMkYFdH8BADy8N2bM0Fls@salesforce.com",
        "name": "pcstest-backuptest5-fd2-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "343606428766",
        "arn": "",
        "email": "pcsk-aws-stage+16898076172543611689807617254333@salesforce.com",
        "name": "pcstest-nouser-f94c3332-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "185566461478",
        "arn": "",
        "email": "pcsk-aws-stage+16935430454383721693543045436812@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693543037641844863",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "846532450974",
        "arn": "",
        "email": "pcsk-aws-stage+16947343543849341694734354384916@salesforce.com",
        "name": "pcstest-netv2-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "101005336683",
        "arn": "",
        "email": "pcsk-aws-stage+16944415703032731694441570303256@salesforce.com",
        "name": "pcstest-nouser-42376de5-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "386997047723",
        "arn": "",
        "email": "pcsk-aws-stage+KYoZA9p3A5RiQCu9vVEdQ4TNPnqFnOeJ@salesforce.com",
        "name": "pcstest-3e6a82fd-foundation-sitebridge",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "016962222995",
        "arn": "",
        "email": "pcsk-aws-stage+16964496121375431696449612137529@salesforce.com",
        "name": "pcstest-kurt-soter-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "505171913910",
        "arn": "",
        "email": "pcsk-aws-stage+vQH3rh4Hpq6vd2J6CRKTACGi3QoP4xrw@salesforce.com",
        "name": "pcstest-nouser-40c6d971-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "836183112396",
        "arn": "",
        "email": "pcsk-aws-stage+40v4ZAvm4Hrp2B9ri4lBrxby6xvVFQbk@salesforce.com",
        "name": "pcstest-85f0ad1b-foundation-skywalker",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "445277239409",
        "arn": "",
        "email": "pcsk-aws-stage+16964577787150151696457778714995@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710593446",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "248855667594",
        "arn": "",
        "email": "pcsk-aws-stage+16898184151298241689818415129786@salesforce.com",
        "name": "pcstest-nouser-f94c3332-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "092235241445",
        "arn": "",
        "email": "pcsk-aws-stage+16908340773658501690834077365829@salesforce.com",
        "name": "pcstest-pcstest-kurt-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "290758969345",
        "arn": "",
        "email": "pcsk-aws-stage+Z0YP4kegSaQ8Hwk5mNqsOZ61Xya56zLm@salesforce.com",
        "name": "herokucustaugthree",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "011732541587",
        "arn": "",
        "email": "pcsk-aws-stage+16934536971852441693453697185229@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693451102093268988",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "741548252956",
        "arn": "",
        "email": "pcsk-aws-stage+16962727124749571696272712474937@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696271405623129345",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "834330940156",
        "arn": "",
        "email": "pcsk-aws-stage+16952511461492001695251146149096@salesforce.com",
        "name": "pcstest-jm-20230930b-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "249863793700",
        "arn": "",
        "email": "pcsk-aws-stage+xjO4jMjN2D9bRMo7EZAfYXQ4uLSyGRhk@salesforce.com",
        "name": "pcsk-sri-falcon-stage-1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "521049731874",
        "arn": "",
        "email": "pcsk-aws-stage+16934517578988891693451757898869@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693451102093172543",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "795095518420",
        "arn": "",
        "email": "pcsk-aws-stage+16945688065628951694568806562824@salesforce.com",
        "name": "pcstest-netv2-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "276100269264",
        "arn": "",
        "email": "pcsk-aws-stage+16902576698778441690257669877840@salesforce.com",
        "name": "pcstest-nouser-76953fc3-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "769524902257",
        "arn": "",
        "email": "pcsk-aws-stage+16902524993216791690252499321639@salesforce.com",
        "name": "pcstest-nouser-30cb218d-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "403741003002",
        "arn": "",
        "email": "pcsk-aws-stage+16917907544345591691790754434543@salesforce.com",
        "name": "pcstest-pcs-test-israel-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "384374999305",
        "arn": "",
        "email": "pcsk-aws-stage+16904160195482621690416019548257@salesforce.com",
        "name": "pcstest-nouser-76953fc3-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "275991265855",
        "arn": "",
        "email": "pcsk-aws-stage+16944383391139581694438339113932@salesforce.com",
        "name": "pcstest-nouser-42376de5-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "283596803383",
        "arn": "",
        "email": "pcsk-aws-stage+zNn0q9nZdrNIpcBlfG25z1ZwOh2WmS9G@salesforce.com",
        "name": "pcstest-nouser-40c6d971-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "488784953592",
        "arn": "",
        "email": "pcsk-aws-stage+UnpUwceX3dln5UbX6jl4Pds59JqxShto@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "412401253175",
        "arn": "",
        "email": "pcsk-aws-stage+16935195556779511693519555677932@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693518898923782974",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "668454206164",
        "arn": "",
        "email": "pcsk-aws-stage+16951470589294021695147058928832@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-resilienceBkp",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "217887505356",
        "arn": "",
        "email": "pcsk-aws-stage+16932567265406241693256726540604@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523582351",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "936792889377",
        "arn": "",
        "email": "pcsk-aws-stage+16904191136324171690419113632413@salesforce.com",
        "name": "pcstest-nouser-76953fc3-foundation-collection",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "630633201645",
        "arn": "",
        "email": "pcsk-aws-stage+16927341807648811692734180764770@salesforce.com",
        "name": "pcstest-targetisrael2-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "603911874403",
        "arn": "",
        "email": "pcsk-aws-stage+16944662874857151694466287485694@salesforce.com",
        "name": "pcstest-20230913-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "033541009026",
        "arn": "",
        "email": "pcsk-aws-stage+43MkFekUseWcUobaOqvVGUoFjmb4akK4@salesforce.com",
        "name": "pcsk-dipanshu.nagpal+test",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "525316715916",
        "arn": "",
        "email": "pcsk-aws-stage+16891188630835641689118863083560@salesforce.com",
        "name": "endpoints-useast1-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "465888798614",
        "arn": "",
        "email": "pcsk-aws-stage+16932509121714321693250912153742@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523332704",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "023479134762",
        "arn": "",
        "email": "pcsk-aws-stage+JhRvX97oYq2wH7FB6SHhtqzDyGdcQqBa@salesforce.com",
        "name": "pcstest-kurt-iris-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "622541851185",
        "arn": "",
        "email": "pcsk-aws-stage+16961238857039531696123885703893@salesforce.com",
        "name": "pcstest-local-FMT-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "073037599814",
        "arn": "",
        "email": "pcsk-aws-stage+16952407995614271695240799561407@salesforce.com",
        "name": "pcstest-nouser-d2f100b7-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "684344391956",
        "arn": "",
        "email": "pcsk-aws-stage+16947395282639501694739528263932@salesforce.com",
        "name": "pcstest-nouser-65906f9a-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "143273510491",
        "arn": "",
        "email": "pcsk-aws-stage+16927348526368261692734852636776@salesforce.com",
        "name": "test-rnd-038731646608-pool-1692729005606210202",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "646116925933",
        "arn": "",
        "email": "pcsk-aws-stage+16951548121206001695154812120574@salesforce.com",
        "name": "pcstest-jmpreprod-9b2-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "236746722870",
        "arn": "",
        "email": "pcsk-aws-stage+16964623116115921696462311611497@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710753584",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "194135168975",
        "arn": "",
        "email": "pcsk-aws-stage+TfSI4a3q6V5xTrbBtJSgJS2fFWn6PEIf@salesforce.com",
        "name": "pcstest-nouser-0b056137-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "655541389632",
        "arn": "",
        "email": "pcsk-aws-stage+8Q7preSLPmx3uHHBr2Hv61SDXiykmYcQ@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "405545274327",
        "arn": "",
        "email": "pcsk-aws-stage+16962714150051041696271415005086@salesforce.com",
        "name": "pcstest-local-FMT-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "120376744600",
        "arn": "",
        "email": "pcsk-aws-stage+16934429839864261693442983986409@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616525110632",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "523068053829",
        "arn": "",
        "email": "pcsk-aws-stage+16947434097154151694743409715397@salesforce.com",
        "name": "pcstest-nouser-65906f9a-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "396334581226",
        "arn": "",
        "email": "pcsk-aws-stage+16962875617213631696287561721342@salesforce.com",
        "name": "pcstest-kurt-soter-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "808743713925",
        "arn": "",
        "email": "pcsk-aws-stage+cFgbYOq4UeAwIOxY9Nsthz0zOFME8SPD@salesforce.com",
        "name": "aws-pcscanary3-uswest2-foundation-terraform",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "645639747078",
        "arn": "",
        "email": "pcsk-aws-stage+16923817757623351692381775762270@salesforce.com",
        "name": "pcstest-pcs-test-israel-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "897300127802",
        "arn": "",
        "email": "pcsk-aws-stage+16962908020462581696290802046238@salesforce.com",
        "name": "pcstest-kurt-soter-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "702796375706",
        "arn": "",
        "email": "pcsk-aws-stage+16923862958287171692386295828652@salesforce.com",
        "name": "pcstest-israel-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "817774811065",
        "arn": "",
        "email": "pcsk-aws-stage+avaHe9JTSHNNnVvV2EnkCtK7oiwxdvrX@salesforce.com",
        "name": "pcstest-7e0059e4-fd2-svc3",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "487623080145",
        "arn": "",
        "email": "pcsk-aws-stage+16895341607013051689534160701302@salesforce.com",
        "name": "pcsk-aws-pool-fd16895341607012851689534160701271",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "362268762882",
        "arn": "",
        "email": "pcsk-aws-stage+16944305799448811694430579944815@salesforce.com",
        "name": "pcstest-jack-fake-bom-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "402408947273",
        "arn": "",
        "email": "pcsk-aws-stage+axqDpw8SldJHEB0rlvSuaslMteNKCSj3@salesforce.com",
        "name": "pcstest-38410fa1-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "530775019274",
        "arn": "",
        "email": "pcsk-aws-stage+ZaGmPSkFutatIhk7E7aMFl4tnKck1WLt@salesforce.com",
        "name": "pcstest-38410fa1-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "002906020355",
        "arn": "",
        "email": "pcsk-aws-stage+LIGtnSCJ4ZYM8lON6M0koMUXhN61GehI@salesforce.com",
        "name": "pcstest-85f0ad1b-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "471152890283",
        "arn": "",
        "email": "pcsk-aws-stage+16952427392686231695242739268603@salesforce.com",
        "name": "pcstest-nouser-d2f100b7-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "363864834261",
        "arn": "",
        "email": "pcsk-aws-stage+16964661970876631696466197087643@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710892897",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "333706859293",
        "arn": "",
        "email": "pcsk-aws-stage+16898148148269131689814814826906@salesforce.com",
        "name": "pcstest-nouser-f94c3332-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "825427802661",
        "arn": "",
        "email": "pcsk-aws-stage+16885860608124941688586060812489@salesforce.com",
        "name": "pcsk-aws-pool-8816885860608124011688586060812382",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "282247259436",
        "arn": "",
        "email": "pcsk-aws-stage+UqVLXTPIBlGlCsn4EmRhNBVGj51SdRj0@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "513638065982",
        "arn": "",
        "email": "pcsk-aws-stage+16923875884739971692387588473977@salesforce.com",
        "name": "pcstest-israel-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "168543614327",
        "arn": "",
        "email": "pcsk-aws-stage+61nKJgn3xyyaKAdzvQPDTinWZAkpjbZX@salesforce.com",
        "name": "pcstest-7e0059e4-fd2-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "129776594257",
        "arn": "",
        "email": "pcsk-aws-stage+16947330603847841694733060384765@salesforce.com",
        "name": "pcstest-netv2-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "572677948079",
        "arn": "",
        "email": "pcsk-aws-stage+16952440326389601695244032620743@salesforce.com",
        "name": "pcstest-jm-20230930-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "032706037614",
        "arn": "",
        "email": "pcsk-aws-stage+jH824L43rqefjkiFE96A8kTtaVK7NwRM@salesforce.com",
        "name": "pcsk-sri-falcon-stage-4",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "478427934069",
        "arn": "",
        "email": "pcsk-aws-stage+RYmjC1rZsbboQTxZuLFOBiDwyDj6oVwa@salesforce.com",
        "name": "herokucustaugtwo",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "492520652763",
        "arn": "",
        "email": "pcsk-aws-stage+16951509342806101695150934269819@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "701803704674",
        "arn": "",
        "email": "pcsk-aws-stage+16934436322515571693443632251528@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616525132728",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "189268918743",
        "arn": "",
        "email": "pcsk-aws-stage+16941967768983831694196776883569@salesforce.com",
        "name": "pcstest-nouser-274038a4-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "521057724906",
        "arn": "",
        "email": "pcsk-aws-stage+16902602529630411690260252963038@salesforce.com",
        "name": "pcstest-nouser-76953fc3-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "075856301260",
        "arn": "",
        "email": "pcsk-aws-stage+XLMiTMPWirOUo37cEmydVNI0BatWqfQc@salesforce.com",
        "name": "pcstest-85f0ad1b-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "715538271363",
        "arn": "",
        "email": "pcsk-aws-stage+16923811137972481692381113797230@salesforce.com",
        "name": "pcstest-pcs-test-israel-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "515627034381",
        "arn": "",
        "email": "pcsk-aws-stage+16923843603214311692384360321344@salesforce.com",
        "name": "pcstest-israel-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "910442721325",
        "arn": "",
        "email": "pcsk-aws-stage+16952346139525441695234613952468@salesforce.com",
        "name": "pcstest-nouser-d2f100b7-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "215292114106",
        "arn": "",
        "email": "pcsk-aws-stage+16959438121585241695943812158390@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1695943805505276949",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "038731646608",
        "arn": "",
        "email": "pc_master_reset+038731646608@salesforce.com",
        "name": "root-account-falcon-pcsk-stage",
        "team_name": "pcsk-pce-mpa-approvers",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "273617123944",
        "arn": "",
        "email": "cgosalia+pcsk+test@salesforce.com",
        "name": "pcsk-cgosalia",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "362615300707",
        "arn": "",
        "email": "pcsk-aws-stage+16951606200213081695160620006678@salesforce.com",
        "name": "pcstest-jmpreprod-9b2-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "946071172244",
        "arn": "",
        "email": "pcsk-aws-stage+Zrk8APXfUabDlkX7Dls6tL4LlIM4zpNZ@salesforce.com",
        "name": "pcstest-85f0ad1b-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "394193209252",
        "arn": "",
        "email": "pcsk-aws-stage+YKgooD75eNBG5LyJfrn9bPKYV5iyIQHc@salesforce.com",
        "name": "pcstest-1361d1f2-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "357320611548",
        "arn": "",
        "email": "pcsk-aws-stage+16962901535073391696290153507288@salesforce.com",
        "name": "pcstest-kurt-soter-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "964026271607",
        "arn": "",
        "email": "pcsk-aws-stage+16962869137273731696286913727347@salesforce.com",
        "name": "pcstest-kurt-soter-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "171827842961",
        "arn": "",
        "email": "pcsk-aws-stage+IS8fWCrZiyWtsbxS8xQFb0abXDLLhpFK@salesforce.com",
        "name": "pcstest-1361d1f2-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "664172617889",
        "arn": "",
        "email": "pcsk-aws-stage+GoQDBfnqMD0BViTzgoTGdbj5YiD2lPVQ@salesforce.com",
        "name": "pcstest-jm-ou-04-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "486917641656",
        "arn": "",
        "email": "pcsk-aws-stage+16944697294923601694469729408606@salesforce.com",
        "name": "pcstest-nouser-b90bc9af-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "128407326772",
        "arn": "",
        "email": "pcsk-aws-stage+9Ly0CzwzONPtNAk3JigIlrpzE2tnBU0H@salesforce.com",
        "name": "pcstest-jm-ou-04-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "583223387614",
        "arn": "",
        "email": "pcsk-aws-stage+16944675788789881694467578878970@salesforce.com",
        "name": "pcstest-20230913-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "771640547152",
        "arn": "",
        "email": "pcsk-aws-stage+16902583166114311690258316611427@salesforce.com",
        "name": "pcstest-nouser-76953fc3-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "270013923442",
        "arn": "",
        "email": "pcsk-aws-stage+16964642542421631696464254242140@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710825178",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "331742637898",
        "arn": "",
        "email": "pcsk-aws-stage+16934358623749931693435862374967@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524901874",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "283737026820",
        "arn": "",
        "email": "pcsk-aws-stage+16952414466485291695241446648512@salesforce.com",
        "name": "pcstest-nouser-d2f100b7-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "358858399420",
        "arn": "",
        "email": "pcsk-aws-stage+16959568057874371695956805787416@salesforce.com",
        "name": "pcstest-nouser-1f542767-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "860950963328",
        "arn": "",
        "email": "pcsk-aws-stage+fAbpB2uEXHMiNCJH9QjGWWWG4DDQ55uj@salesforce.com",
        "name": "eric-sfci-foundation-sitebridge",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "012619135825",
        "arn": "",
        "email": "pcsk-aws-stage+wHlE4IESleKz0SUeXkRSO18mr4dWXcTB@salesforce.com",
        "name": "eric-sfci-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "033146435691",
        "arn": "",
        "email": "pcsk-aws-stage+16934390998720751693439099872058@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616525009276",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "918780388267",
        "arn": "",
        "email": "pcsk-aws-stage+6XR3o5XOIhGVNZQwJpFs8n0t1UA9IHSE@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-skywalker",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "877899283465",
        "arn": "",
        "email": "pcsk-aws-stage+ZO3PJNme2fwQ5W6EaFgmuXRxUrs1XFg3@salesforce.com",
        "name": "pcstest-38410fa1-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "4fb54c21-854a-4d9c-984d-1144061070d1",
        "arn": "",
        "email": "",
        "name": "",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": "PENDINGCLOSE"
    },
    {
        "id": "437833859212",
        "arn": "",
        "email": "pcsk-aws-stage+pIQyg7LQsMVl1T4GVdzxK6Tc72TcmZEQ@salesforce.com",
        "name": "aws-pcscanary3-uswest2-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "453612467737",
        "arn": "",
        "email": "pcsk-aws-stage+16944351077210281694435107720934@salesforce.com",
        "name": "pcstest-jack-fake-bom-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "148100333646",
        "arn": "",
        "email": "pcsk-aws-stage+16891195091498141689119509149809@salesforce.com",
        "name": "endpoints-useast1-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "471212634688",
        "arn": "",
        "email": "pcsk-aws-stage+8KKVVKP28dzPFLnAqvxe8158mU6OyUnF@salesforce.com",
        "name": "PCSTEST_20190621.00",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "290172548756",
        "arn": "",
        "email": "pcsk-aws-stage+qWjF0h1pKZNuy6Ww5bFakJFt09pptsCq@salesforce.com",
        "name": "pcstest-nouser-0b056137-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "448776747599",
        "arn": "",
        "email": "pcsk-aws-stage+bdUnweZ9XA9NMIwAhikkLudk33eB8FLI@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-spindepmngng",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "182563374557",
        "arn": "",
        "email": "pcsk-aws-stage+16944596073355921694459607318620@salesforce.com",
        "name": "pcstest-kurt-shield-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "345328591054",
        "arn": "",
        "email": "pcsk-aws-stage@salesforce.com",
        "name": "PCSK Staging",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "580263588104",
        "arn": "",
        "email": "pcsk-aws-stage+Xxxgb2wtiOjMo0inVyrLBiSqQWQGKgfV@salesforce.com",
        "name": "Test epatrice 9 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "391893305908",
        "arn": "",
        "email": "pcsk-aws-stage+EkX6XMrJYdFOsKCKxOBQPKVJk4dOEEaF@salesforce.com",
        "name": "PCSKJitCommonIAM5",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "243504563613",
        "arn": "",
        "email": "pcsk-aws-stage+16934423366451181693442336645102@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616525086321",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "634028382818",
        "arn": "",
        "email": "pcsk-aws-stage+16964687867666301696468786766608@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710980227",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "423540747479",
        "arn": "",
        "email": "pcsk-aws-stage+16952420928992401695242092899222@salesforce.com",
        "name": "pcstest-nouser-d2f100b7-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "483516187683",
        "arn": "",
        "email": "pcsk-aws-stage+16964597213841231696459721371389@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710665217",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "993420231111",
        "arn": "",
        "email": "pcsk-aws-stage+nonFalconTest4@salesforce.com",
        "name": "nonFalconTest4",
        "team_name": "roberteam",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "502139894236",
        "arn": "",
        "email": "pcsk-aws-stage+P27RffZMj5OoZly6P27A0ATkyDFNsWGs@salesforce.com",
        "name": "pcstest-nouser-40c6d971-foundation-collection",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "014746192031",
        "arn": "",
        "email": "pcsk-aws-stage+16947447023642571694744702364230@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-fd2-svc3",
        "team_name": "service3",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "990821721458",
        "arn": "",
        "email": "pcsk-aws-stage+16886815039243201688681503924297@salesforce.com",
        "name": "endpoints-useast1-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "142470929699",
        "arn": "",
        "email": "pcsk-aws-stage+y0dBK9zCRxWk8RY4zqCnfuLWo4kZLblc@salesforce.com",
        "name": "pcstest-jack-al-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "125380424460",
        "arn": "",
        "email": "pcsk-aws-stage+16962895062965621696289506296544@salesforce.com",
        "name": "pcstest-kurt-soter-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "118531356037",
        "arn": "",
        "email": "pcsk-aws-stage+16944570116073821694457011607272@salesforce.com",
        "name": "pcstest-kurt-shield-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "095968124779",
        "arn": "",
        "email": "pcsk-aws-stage+NiR1jQLz3oS9v0PxpPQ4NYfCYquDNz4U@salesforce.com",
        "name": "pcstest-6b93c3a5-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "556966041849",
        "arn": "",
        "email": "pcsk-aws-stage+16961225893199481696122589319886@salesforce.com",
        "name": "pcstest-local-FMT-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "750733556689",
        "arn": "",
        "email": "pcsk-aws-stage+16932483292339531693248329233936@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523251205",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "266754782538",
        "arn": "",
        "email": "pcsk-aws-stage+16952395075491681695239507549011@salesforce.com",
        "name": "pcstest-nouser-d2f100b7-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "719100063749",
        "arn": "",
        "email": "pcsk-aws-stage+Fhrh1I5lsB6OaucWOLDLGevcHNQ8la3o@salesforce.com",
        "name": "aws-pcscanary3-uswest2-foundation-ipam-management",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "661670059616",
        "arn": "",
        "email": "pcsk-aws-stage+cErl33HKDiELBOLG8gW9nZ5NBUAmWxYs@salesforce.com",
        "name": "pcstest-85f0ad1b-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "005137199304",
        "arn": "",
        "email": "pcsk-aws-stage+16951535209873411695153520987322@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "943887195717",
        "arn": "",
        "email": "pcsk-aws-stage+c4DttDwpSF4vdP0rlqjc4jrq2Pc3xeyd@salesforce.com",
        "name": "dfroese333b-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "980840064582",
        "arn": "",
        "email": "pcsk-aws-stage+16964584255950041696458425594970@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710616680",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "355198137071",
        "arn": "",
        "email": "pcsk-aws-stage+07tjYWYljZfzEH9H0vuybhQeArDbGGnG@salesforce.com",
        "name": "PCSTEST_20190621.01",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "751485363984",
        "arn": "",
        "email": "pcsk-aws-stage+16947440558032921694744055803240@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-fd2-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "860647466160",
        "arn": "",
        "email": "pcsk-aws-stage+dOqM3u3lNOXGkDU7oVHssk3hGUPFeJXm@salesforce.com",
        "name": "pcstest-3e6a82fd-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "126343934955",
        "arn": "",
        "email": "pcsk-aws-stage+4OI1v4fBMXOIMLlyGwK45ybk7Erd1z5y@salesforce.com",
        "name": "pcsk-bhor.arora+stage_ctest3",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "377031589089",
        "arn": "",
        "email": "pcsk-aws-stage+16932457460286171693245746028563@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523134692",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "195059485307",
        "arn": "",
        "email": "pcsk-aws-stage+16945694528382671694569452838171@salesforce.com",
        "name": "pcstest-netv2-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "324422741460",
        "arn": "",
        "email": "pcsk-aws-stage+16964545097854121696454509785396@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710477543",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "674421302295",
        "arn": "",
        "email": "pcsk-aws-stage+9Y25Ermv3Fs6riAaKmJ17siyHASr0lsa@salesforce.com",
        "name": "pcstest-kurt-iris-new-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "287439623301",
        "arn": "",
        "email": "pcsk-aws-stage+2kmdbrP2khhs0VPoFs2jAIjTOLcEavFA@salesforce.com",
        "name": "pcstest-jm-ou-04-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "930689215347",
        "arn": "",
        "email": "pcsk-aws-stage+29EYh6HQR7Rp9WFajl2WAhBMERB33LDR@salesforce.com",
        "name": "pcstest-backuptest5-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "516294621183",
        "arn": "",
        "email": "pcsk-aws-stage+16886821503645341688682150364510@salesforce.com",
        "name": "endpoints-useast1-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "668995888066",
        "arn": "",
        "email": "pcsk-aws-stage+nMG68OlBCvXRvOxRT3PJY6b8nr194gHl@salesforce.com",
        "name": "nonFalconTest2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "302534177423",
        "arn": "",
        "email": "pcsk-aws-stage+16899910481139201689991048113914@salesforce.com",
        "name": "test-XX-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "157554770621",
        "arn": "",
        "email": "pcsk-aws-stage+16951464129123861695146412912368@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "705832445623",
        "arn": "",
        "email": "pcsk-aws-stage+Qkw2X3QulaKTnEKSsbr3Mwcvt9qo6vip@salesforce.com",
        "name": "PCSTEST_09172019.00",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "009855615523",
        "arn": "",
        "email": "pcsk-aws-stage+16962817293353751696281729335353@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "919651276540",
        "arn": "",
        "email": "pcsk-aws-stage+cix3TS1HsdqXetoqcc7W3CYnnII6LGce@salesforce.com",
        "name": "aws-pcscanary3-uswest2-crypto-syssec",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "809074162710",
        "arn": "",
        "email": "pcsk-aws-stage+2TCI1AT7ppAYfi732HmxL4JSV5K148an@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-spinbakmnged",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "147496671681",
        "arn": "",
        "email": "pcsk-aws-stage+16964532134478691696453213447847@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710387452",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "775196878040",
        "arn": "",
        "email": "pcsk-aws-stage+16934319767180251693431976717974@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524731679",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "462086171508",
        "arn": "",
        "email": "pcsk-aws-stage+16959444608936421695944460893614@salesforce.com",
        "name": "pcstest-jm-231001-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "447194923832",
        "arn": "",
        "email": "pcsk-aws-stage+16951515817070971695151581707079@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "598053027916",
        "arn": "",
        "email": "pcsk-aws-stage+ieUJ7qg87VNexak4sElqrXu2uXomj1Vq@salesforce.com",
        "name": "shiv-go1.15-1-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "398117480799",
        "arn": "",
        "email": "pcsk-aws-stage+UZ9DJ0GHMlXpgtuDu0IrfSoV8icybTjM@salesforce.com",
        "name": "pcstest-nouser-0b056137-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "059388651462",
        "arn": "",
        "email": "pcsk-aws-stage+16961161104148891696116110414857@salesforce.com",
        "name": "pcstest-eric-il-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "989099653458",
        "arn": "",
        "email": "pcsk-aws-stage+Z0NodPvH4xup2jdVB0jgZwWqQ7yHWfvd@salesforce.com",
        "name": "pcstest-jm-ou-04-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "590016831264",
        "arn": "",
        "email": "pcsk-aws-stage+590016831264@salesforce.com",
        "name": "onboarding-demo-8",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "705121053234",
        "arn": "",
        "email": "pcsk-aws-stage+sSvGfaX9u2A0SFY7xAlaOoVVpn94MG3n@salesforce.com",
        "name": "commonIAM20",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "449068853432",
        "arn": "",
        "email": "pcsk-aws-stage+16932599560541131693259956037368@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523706019",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "151174822101",
        "arn": "",
        "email": "pcsk-aws-stage+16945555197509581694555519750932@salesforce.com",
        "name": "pcstest-nouser-b90bc9af-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "957590972106",
        "arn": "",
        "email": "pcsk-aws-stage+ue0AkDOqPkx0EVWlyml8cawhcv6w8jva@salesforce.com",
        "name": "pcstest-0f15002c-foundation-svc2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "256579325064",
        "arn": "",
        "email": "pcsk-aws-stage+16944637028383361694463702838278@salesforce.com",
        "name": "pcstest-20230913-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "920698851936",
        "arn": "",
        "email": "pcsk-aws-stage+16944441577124081694444157712389@salesforce.com",
        "name": "pcstest-kurt-shield-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "868093030984",
        "arn": "",
        "email": "pcsk-aws-stage+16936118008020011693611800801985@salesforce.com",
        "name": "pcstest-nouser-477ccecf-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "908045211330",
        "arn": "",
        "email": "pcsk-aws-stage+dteBjS67VOQgj3kxnfzx4hEssNEsOfh2@salesforce.com",
        "name": "nathan-20191111-01-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "950403166454",
        "arn": "",
        "email": "pcsk-aws-stage+pcsk-falcon-cli@salesforce.com",
        "name": "pcsk-falcon-cli-test-collaboration",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "297134781137",
        "arn": "",
        "email": "pcsk-aws-stage+LR51T6UG7UUJ0p1cjJC4CkeUdXPlW1s0@salesforce.com",
        "name": "pcs-uswest2-20200129c-fd2-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "137840704365",
        "arn": "",
        "email": "pcsk-aws-stage+16959561576454751695956157645392@salesforce.com",
        "name": "pcstest-nouser-1f542767-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "092138163372",
        "arn": "",
        "email": "pcsk-aws-stage+16934543427805991693454342780582@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693451102093299669",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "354701132189",
        "arn": "",
        "email": "pcsk-aws-stage+16944260563206611694426056320604@salesforce.com",
        "name": "pcstest-nouser-274038a4-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "320902554872",
        "arn": "",
        "email": "pcsk-aws-stage+0UtYhlC5ECjrOAXiPzOjGl4mPrRwhdB3@salesforce.com",
        "name": "pcstest-kurt-iris-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "726164796306",
        "arn": "",
        "email": "pcsk-aws-stage+16940249346993951694024934699377@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1694024927819192756",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "222125948367",
        "arn": "",
        "email": "pcsk-aws-stage+yo6xH5alZEloz0FfvtnF12m11K3WMS4Z@salesforce.com",
        "name": "eric-sfci-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "435326149490",
        "arn": "",
        "email": "pcsk-aws-stage+Z0NHB6KQnO9wHpBF6eiSlPdCCPSy5Ew9@salesforce.com",
        "name": "aws-pcscanary3-uswest2-crypto-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "504050597048",
        "arn": "",
        "email": "pcsk-aws-stage+16961130181764921696113018176475@salesforce.com",
        "name": "pcstest-nouser-1f542767-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "913674567138",
        "arn": "",
        "email": "pcsk-aws-stage+PIhASRVUZKlzsSo06Jme4BywMu96btSp@salesforce.com",
        "name": "pcstest-uswest2-2020-01-14a-foundation-svc2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "510123102377",
        "arn": "",
        "email": "pcsk-aws-stage+16945542173954011694554217395263@salesforce.com",
        "name": "pcstest-nouser-b90bc9af-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "419170117713",
        "arn": "",
        "email": "pcsk-aws-stage+16927315968008451692731596800825@salesforce.com",
        "name": "pcstest-targetisrael2-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "747754208087",
        "arn": "",
        "email": "pcsk-aws-stage+jQw2SxfNZI4dXp0IYS4w3x4BaEcmNfNO@salesforce.com",
        "name": "Test SCP 1 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "134532437364",
        "arn": "",
        "email": "pcsk-aws-stage+16917888163598001691788816359743@salesforce.com",
        "name": "pcstest-pcs-test-israel-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "632461652278",
        "arn": "",
        "email": "pcsk-aws-stage+16944312265366791694431226536660@salesforce.com",
        "name": "pcstest-jack-fake-bom-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "234632048532",
        "arn": "",
        "email": "pcsk-aws-stage+lambda-migration-t2@salesforce.com",
        "name": "pcsk-run-through-t2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "354366989340",
        "arn": "",
        "email": "pcsk-aws-stage+nonFalconTest6@salesforce.com",
        "name": "nonFalconTest6",
        "team_name": "roberteam",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "729319420467",
        "arn": "",
        "email": "pcsk-aws-stage+GYfZokotkHwctyDApuFRbGcGK2Wk93k5@salesforce.com",
        "name": "aws-pcscanary3-uswest2-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "066703779862",
        "arn": "",
        "email": "pcsk-aws-stage+16932522038121071693252203812092@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523380470",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "461323942171",
        "arn": "",
        "email": "pcsk-aws-stage+16944396305540321694439630553981@salesforce.com",
        "name": "pcstest-nouser-42376de5-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "203014807577",
        "arn": "",
        "email": "pcsk-aws-stage+16947388799976951694738879997679@salesforce.com",
        "name": "pcstest-nouser-65906f9a-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "430170171422",
        "arn": "",
        "email": "pcsk-aws-stage+16962888586362501696288858636189@salesforce.com",
        "name": "pcstest-kurt-soter-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "750982190738",
        "arn": "",
        "email": "pcsk-aws-stage+b3YBwqlzXGrm4XIMwZkCMdzectxwYK3N@salesforce.com",
        "name": "pcs-1219-04-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "752945468937",
        "arn": "",
        "email": "pcsk-aws-stage+16932593093893111693259309389288@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523682927",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "697019534064",
        "arn": "",
        "email": "pcsk-aws-stage+2Cfu0cCbxDnXxpAWS8Pu4oAj5s9w9iSq@salesforce.com",
        "name": "pcstest-0108-03-foundation-spindepmngng",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "710358182260",
        "arn": "",
        "email": "pcsk-aws-stage+iDG8X8DlYqHjZNbF2oMNZJZjM40s0iGe@salesforce.com",
        "name": "pcstest-nouser-40c6d971-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "345041280481",
        "arn": "",
        "email": "pcsk-aws-stage+16951567464729891695156746472929@salesforce.com",
        "name": "pcstest-jmpreprod-9b2-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "920728844384",
        "arn": "",
        "email": "pcsk-aws-stage+DUrvrAmQuOE4nzmbE2WHMSuPffmGZspU@salesforce.com",
        "name": "rcc-delete",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "401459096566",
        "arn": "",
        "email": "pcsk-aws-stage+jc0ZSNAfkRzTyrWzMDOEJND52vwrCDDO@salesforce.com",
        "name": "pcstest-38410fa1-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "857203153958",
        "arn": "",
        "email": "pcsk-aws-stage+16964655486853501696465548685335@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710871383",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "366368525106",
        "arn": "",
        "email": "pcsk-aws-stage+onboard-demo-2@salesforce.com",
        "name": "onboarding-demo-2",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "170719215770",
        "arn": "",
        "email": "pcsk-aws-stage+16952479129386061695247912938582@salesforce.com",
        "name": "pcstest-jm-20230930-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "298111844466",
        "arn": "",
        "email": "pcsk-aws-stage+a4CRolsMozeYnSHEhuVmTosPqbYNPwC8@salesforce.com",
        "name": "pcstest-d7bc358a-fd2-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "803431186678",
        "arn": "",
        "email": "pcsk-aws-stage+azJUdsQDf83BzMVfqOF9H5kY7npZo96t@salesforce.com",
        "name": "pcs-uswest2-20200129c-foundation-sapentest",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "869361444457",
        "arn": "",
        "email": "pcsk-aws-stage+16944234697642531694423469764167@salesforce.com",
        "name": "pcstest-nouser-274038a4-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "370305458060",
        "arn": "",
        "email": "pcsk-aws-stage+1JucpVyvTwr9RVjsIESVCj7yExoSov5w@salesforce.com",
        "name": "pcstest-ed878bc4-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "296096644429",
        "arn": "",
        "email": "pcsk-aws-stage+16947375876519221694737587651904@salesforce.com",
        "name": "pcstest-nouser-65906f9a-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "783424388538",
        "arn": "",
        "email": "pcsk-aws-stage+16961187019987941696118701998774@salesforce.com",
        "name": "pcstest-eric-il-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "151344348667",
        "arn": "",
        "email": "pcsk-aws-stage+16964700829450731696470082945046@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205711030830",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "632283260248",
        "arn": "",
        "email": "pcsk-aws-stage+3eKkOJzua1VLRYVBOYYRWPyhv32cSaBH@salesforce.com",
        "name": "jonathan mason",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "059951234464",
        "arn": "",
        "email": "pcsk-aws-stage+16927328882838851692732888283866@salesforce.com",
        "name": "pcstest-targetisrael2-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "119148682277",
        "arn": "",
        "email": "pcsk-aws-stage+nonfalconDemo1@salesforce.com",
        "name": "nonfalconDemo1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "817068875694",
        "arn": "",
        "email": "pcsk-aws-stage+E4OV7RDEyt8BGLuXWXN7Shunp6NXFglG@salesforce.com",
        "name": "policyAddTest-mkI-fd2-svc3",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "994281275363",
        "arn": "",
        "email": "pcsk-aws-stage+NkUumVlRoMHLXb4ZFTLH2VlRTvh1wI66@salesforce.com",
        "name": "Test SCP 1 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "295991218201",
        "arn": "",
        "email": "pcsk-aws-stage+16934365102024571693436510202439@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524923310",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "879429740848",
        "arn": "",
        "email": "pcsk-aws-stage+GPfT0MtEeuTlWd9e8kWeCenMtQu7OIsL@salesforce.com",
        "name": "nathan-20191112-01-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "562466364435",
        "arn": "",
        "email": "pcsk-aws-stage+16944422172252631694442217225202@salesforce.com",
        "name": "pcstest-nouser-42376de5-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "590757772931",
        "arn": "",
        "email": "pcsk-aws-stage+16905990191551961690599019155172@salesforce.com",
        "name": "pcstest-pcstest-kurt-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "448635153061",
        "arn": "",
        "email": "pcsk-aws-stage+448635153061@salesforce.com",
        "name": "onboarding-demo-7",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "805671272422",
        "arn": "",
        "email": "pcsk-aws-stage+APtEno0Qku89SCRFasUmOVnZLdo0mXLy@salesforce.com",
        "name": "PCSKJitCommonIAM2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "904574773994",
        "arn": "",
        "email": "pcsk-aws-stage+OAHF3fZ6N9D5JagM2pxG4Japgnzkd0aX@salesforce.com",
        "name": "PCSTEST_05282019",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "900118989176",
        "arn": "",
        "email": "pcsk-aws-stage+16934449257709161693444925770897@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616525177142",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "472827145757",
        "arn": "",
        "email": "pcsk-aws-stage+16962920998071841696292099807127@salesforce.com",
        "name": "pcstest-kurt-soter-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "334571304536",
        "arn": "",
        "email": "pcsk-aws-stage+WxCF94bhoxnCCle4ysXS19H6nLTAaDk9@salesforce.com",
        "name": "pcstest-c3e305fe-fd2-mjactivedir",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "742640462214",
        "arn": "",
        "email": "pcsk-aws-stage+VAWy36eOx9mLYFrgi4XfBp6kDeBbSrlk@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "396086430400",
        "arn": "",
        "email": "pcsk-aws-stage+exd6AXX1JtzBdIFOOOYpqqPWiqFuG9MS@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "314343455410",
        "arn": "",
        "email": "pcsk-aws-stage+16927433820337121692743382033693@salesforce.com",
        "name": "pcstest-targetisrael2-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "061139367309",
        "arn": "",
        "email": "pcsk-aws-stage+16929251589236871692925158923635@salesforce.com",
        "name": "pcstest-backuptest3-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "847317606587",
        "arn": "",
        "email": "pcsk-aws-stage+wOYsr9UYnZTBPIbO0pHg2ltkPaL70kb9@salesforce.com",
        "name": "pcstest_ca_policy_20200507",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "017360939143",
        "arn": "",
        "email": "pcsk-aws-stage+16923798199365371692379819936515@salesforce.com",
        "name": "pcstest-pcs-test-israel-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "910614163161",
        "arn": "",
        "email": "pcsk-aws-stage+YbcEpBlZSZbxTvUYiLiMPSOmb7y8Rdqn@salesforce.com",
        "name": "Test epatrice 15 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "766422830058",
        "arn": "",
        "email": "pcsk-aws-stage+Xa8OhZdO9QsqMJI50X81JScadJl0BChL@salesforce.com",
        "name": "Test epatrice 12 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "741147212715",
        "arn": "",
        "email": "pcsk-aws-stage+16962830250611321696283025061076@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "019626450754",
        "arn": "",
        "email": "pcsk-aws-stage+16962914509948471696291450994828@salesforce.com",
        "name": "pcstest-kurt-soter-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "304432404069",
        "arn": "",
        "email": "pcsk-aws-stage+16944669335824301694466933582411@salesforce.com",
        "name": "pcstest-20230913-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "322100793237",
        "arn": "",
        "email": "pcsk-aws-stage+lR8MTJT6DDAhZ0qjjYO3uKuMdlPLQ7dN@salesforce.com",
        "name": "PCSK-test2-PR377",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "805986338486",
        "arn": "",
        "email": "pcsk-aws-stage+16923895256279001692389525627874@salesforce.com",
        "name": "pcstest-israel-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "226961099387",
        "arn": "",
        "email": "pcsk-aws-stage+16951489976897891695148997689760@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "745876528940",
        "arn": "",
        "email": "pcsk-aws-stage+5ViaUsQ2n1LFbdbugR2FwmYSDZJ303LT@salesforce.com",
        "name": "pcstest-6f8cc171-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "743380266502",
        "arn": "",
        "email": "pcsk-aws-stage+JJOe2bjBUsighYKWSnZsPvw5KQpJWibC@salesforce.com",
        "name": "pcs-staging05-foundation-spinbakmngng",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "779601177922",
        "arn": "",
        "email": "pcsk-aws-stage+16944615436552181694461543655064@salesforce.com",
        "name": "pcstest-20230913-foundation-resilienceBackup",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "599254606229",
        "arn": "",
        "email": "pcsk-aws-stage+Ao6ls0RCjhPPnXpqqrIgMTwgHpqMkNbT@salesforce.com",
        "name": "Test epatrice non-falcon 2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "905469561577",
        "arn": "",
        "email": "pcsk-aws-stage+fZeSH1pr2yj44LYA4q2H4DTiYfLNJsjm@salesforce.com",
        "name": "pcs-uswest2-20200128b-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "test",
        "arn": "",
        "email": "tfjhvj",
        "name": "",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "665829644506",
        "arn": "",
        "email": "pcsk-aws-stage+YWAvOQyQrgxevDnFM2CQ1gbrmRXZ1tMj@salesforce.com",
        "name": "pcstest-backuptest5-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "996384714930",
        "arn": "",
        "email": "pcsk-aws-stage+16959496448756311695949644875590@salesforce.com",
        "name": "pcstest-jm-231001-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "483515108899",
        "arn": "",
        "email": "pcsk-aws-stage+16932554335745951693255433574574@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523511689",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "539728102338",
        "arn": "",
        "email": "pcsk-aws-stage+XgUAI71EIJtBRRSUhLqx727hMjtAKEc4@salesforce.com",
        "name": "pcstest-backuptest5-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "615533568680",
        "arn": "",
        "email": "pcsk-aws-stage+Mf4hhnILQQiQgfRmtApAmn3YtIQiyFL6@salesforce.com",
        "name": "pcstest-3e6a82fd-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "916476672326",
        "arn": "",
        "email": "pcsk-aws-stage+csakZxfqSRRVhrpwYW4J50NpXbC9Zxb0@salesforce.com",
        "name": "Test epatrice 19 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "482874961337",
        "arn": "",
        "email": "pcsk-aws-stage+16947350012919071694735001291887@salesforce.com",
        "name": "pcstest-netv2-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "088639148088",
        "arn": "",
        "email": "pcsk-aws-stage+tNZtLhmxLEz6UU55cm4fPCJSWQ4gU2ns@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-spinbakmngng",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "921167350179",
        "arn": "",
        "email": "pcsk-aws-stage+16927322429283441692732242928324@salesforce.com",
        "name": "pcstest-targetisrael2-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "269144885335",
        "arn": "",
        "email": "pcsk-aws-stage+16952524387782301695252438778210@salesforce.com",
        "name": "pcstest-jm-20230930b-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "213181363936",
        "arn": "",
        "email": "pcsk-aws-stage+16947362951651491694736295165067@salesforce.com",
        "name": "pcstest-netv2-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "274388279563",
        "arn": "",
        "email": "pcsk-aws-stage+16959515894496091695951589449595@salesforce.com",
        "name": "pcstest-nouser-1f542767-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "964417970042",
        "arn": "",
        "email": "pcsk-aws-stage+16961206458518121696120645851787@salesforce.com",
        "name": "pcstest-eric-il-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "587994677173",
        "arn": "",
        "email": "pcsk-aws-stage+QmBVo95ZUjCMxsf9BhZHuZh7ibw3Ekcj@salesforce.com",
        "name": "pcstest-jm-ou-04-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "731132575475",
        "arn": "",
        "email": "pcsk-aws-stage+16959470521172471695947052117231@salesforce.com",
        "name": "pcstest-jm-231001-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "262726584391",
        "arn": "",
        "email": "pcsk-aws-stage+16908360186560701690836018656066@salesforce.com",
        "name": "pcstest-pcstest-kurt-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "540005750992",
        "arn": "",
        "email": "pcsk-aws-stage+16923804675700661692380467563515@salesforce.com",
        "name": "pcstest-pcs-test-israel-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "931966416896",
        "arn": "",
        "email": "pcsk-aws-stage+b1OOwRHbMXiHKBo3ElhtThFk46cCXp6X@salesforce.com",
        "name": "eric-sfci-foundation-collection",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "317384154617",
        "arn": "",
        "email": "pcsk-aws-stage+mNnWZfT9oK6rxtlKeMARLkbpQOh6WBnq@salesforce.com",
        "name": "pcstest-38410fa1-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "366567859891",
        "arn": "",
        "email": "pcsk-aws-stage+16932470374148731693247037414857@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523202815",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "800117819449",
        "arn": "",
        "email": "pcsk-aws-stage+16964590740642471696459074064229@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710642415",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "518147298772",
        "arn": "",
        "email": "pcsk-aws-stage+ozUOfEYVfyiz5y1fkj2FJ0tFwsiQqWt9@salesforce.com",
        "name": "PCSKJitCommonIAM8",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "175178487114",
        "arn": "",
        "email": "pcsk-aws-stage+6e70224@salesforce.com",
        "name": "pcsk-falcon-stage-test-1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "750907158245",
        "arn": "",
        "email": "pcsk-aws-stage+DRMHz6nePj3knl6uYdllucWuliyaeNfH@salesforce.com",
        "name": "Test epatrice 3 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "824313006453",
        "arn": "",
        "email": "pcsk-aws-stage+HMicQW2RgHwLNyUVBLXgesCvG96YJeLA@salesforce.com",
        "name": "pcstest-c3e305fe-fd2-svc3",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "721818859315",
        "arn": "",
        "email": "pcsk-aws-stage+16944402764375011694440276437445@salesforce.com",
        "name": "pcstest-nouser-42376de5-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "038543847350",
        "arn": "",
        "email": "pcsk-aws-stage+ylHrgdCpX3RQ07Itd3vcB15NTvkxiAkU@salesforce.com",
        "name": "pcstest-nouser-40c6d971-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "388556973561",
        "arn": "",
        "email": "pcsk-aws-stage+Swrd279q3vIREpkgscdgYUKexuS5i8Pw@salesforce.com",
        "name": "eric-sfci-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "447367634070",
        "arn": "",
        "email": "pcsk-aws-stage+K0KSWA9CmTiNpcXRDqpCJWts9WBLOQc7@salesforce.com",
        "name": "PCSKJitCommonIAM10",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "587644614683",
        "arn": "",
        "email": "pcsk-aws-stage+5opuBE5ryslVVBmJzRkv1mVMlEzYrO5Q@salesforce.com",
        "name": "pcstest-backuptest5-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "178465763524",
        "arn": "",
        "email": "pcsk-aws-stage+HKz6JoUXPrmf8k9L7KTY8XAQnzfae1m1@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "121135081172",
        "arn": "",
        "email": "pcsk-aws-stage+16952466196605181695246619660433@salesforce.com",
        "name": "pcstest-jm-20230930-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "082954448696",
        "arn": "",
        "email": "pcsk-aws-stage+16951522280967111695152228096694@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "502764431302",
        "arn": "",
        "email": "pcsk-aws-stage+16962750129745911696275012974533@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-resilienceBkp",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "082932943565",
        "arn": "",
        "email": "pcsk-aws-stage+16934280932616751693428093261581@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524607648",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "396866884925",
        "arn": "",
        "email": "pcsk-aws-stage+16935189059023031693518905902286@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693518898923039451",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "452469347921",
        "arn": "",
        "email": "pcsk-aws-stage+nonFalconTest15@salesforce.com",
        "name": "nonFalconTest15",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "506757521180",
        "arn": "",
        "email": "pcsk-aws-stage+KHr7UuKXvxFehWnhZrn3lMBdQlfJleQf@salesforce.com",
        "name": "Test epatrice 4 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "842774841257",
        "arn": "",
        "email": "pcsk-aws-stage+16964610173462981696461017346276@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710708354",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "087362216354",
        "arn": "",
        "email": "pcsk-aws-stage+7kl3ZmhbG85Qxy2lbwks8ixsRsg3wosE@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "890091446764",
        "arn": "",
        "email": "pcsk-aws-stage+16944279956595971694427995659578@salesforce.com",
        "name": "pcstest-nouser-274038a4-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "875080499808",
        "arn": "",
        "email": "pcsk-aws-stage+16951573921325841695157392132471@salesforce.com",
        "name": "pcstest-jmpreprod-9b2-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "984489081751",
        "arn": "",
        "email": "pcsk-aws-stage+16902563780493141690256378049247@salesforce.com",
        "name": "pcstest-nouser-30cb218d-foundation-collection",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "616834630279",
        "arn": "",
        "email": "pcsk-aws-stage+16932489751211421693248975121120@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523275974",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "939478896346",
        "arn": "",
        "email": "pcsk-aws-stage+16902505609819271690250560981922@salesforce.com",
        "name": "pcstest-nouser-30cb218d-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "746596592381",
        "arn": "",
        "email": "pcsk-aws-stage+16934549895784961693454989578480@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693451102093325113",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "234064312802",
        "arn": "",
        "email": "pcsk-aws-stage+kZmbUGBMIkh9ugQ0cVYgIhEMLfSnxOSy@salesforce.com",
        "name": "Test epatrice dnr provisioning 2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "339346766436",
        "arn": "",
        "email": "pcsk-aws-stage+16934530514041641693453051404137@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693451102093244157",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "211134190445",
        "arn": "",
        "email": "pcsk-aws-stage+16932476832620581693247683262000@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523224276",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "788963034471",
        "arn": "",
        "email": "pcsk-aws-stage+vbUWEOYhvncrcD30NXXPc31ukOmq84bs@salesforce.com",
        "name": "pcstest-nouser-0b056137-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "654000521564",
        "arn": "",
        "email": "pcsk-aws-stage+16917901085091511691790108509094@salesforce.com",
        "name": "pcstest-pcs-test-israel-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "593988804382",
        "arn": "",
        "email": "pcsk-aws-stage+PS5HvcxJtCJqKl214MkbqeNmHBId2oWC@salesforce.com",
        "name": "eric-sfci-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "403718605474",
        "arn": "",
        "email": "pcsk-aws-stage+tj9HIMNkYkmnPWUjeLsrBuWXGcLzmtIT@salesforce.com",
        "name": "pcstest-kurt-iris-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "524619325611",
        "arn": "",
        "email": "pcsk-aws-stage+16952433854531851695243385453166@salesforce.com",
        "name": "pcstest-jm-20230930-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "075000577625",
        "arn": "",
        "email": "pcsk-test@salesforce.com",
        "name": "pcsk+stage_test4",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "647079407316",
        "arn": "",
        "email": "pcsk-aws-stage+16944682237041171694468223704096@salesforce.com",
        "name": "pcstest-20230913-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "771744179885",
        "arn": "",
        "email": "pcsk-aws-gov-stage2+anjani.k@salesforce.com",
        "name": "test-anjani.k2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "351538912226",
        "arn": "",
        "email": "pcsk-aws-stage+16964502611509611696450261150895@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696449605512825280",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "888809528081",
        "arn": "",
        "email": "pcsk-aws-stage+LW4V79jAQz95eu91BocyWvUaQndH6691@salesforce.com",
        "name": "PCSTEST_06032019",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "779787153699",
        "arn": "",
        "email": "pcsk-aws-stage+16944630580028611694463057982801@salesforce.com",
        "name": "pcstest-20230913-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "671104000550",
        "arn": "",
        "email": "pcsk-aws-stage+16944547097523931694454709752377@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1694453405584480873",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "917401085543",
        "arn": "",
        "email": "pcsk-aws-stage+SPCulFqpBfEFbVGyi5ZoIlOF8fRk4NdL@salesforce.com",
        "name": "pcstest-jm-ou-04-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "294373604716",
        "arn": "",
        "email": "pcsk-aws-stage+MQlqSxQMgqEdcIMBGhYjwSm3w1tQDlkE@salesforce.com",
        "name": "pcstest-backuptest5-fd2-mjactivedir",
        "team_name": "mj-activedir",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "750492692772",
        "arn": "",
        "email": "pcsk-aws-stage+16944247640937721694424764093696@salesforce.com",
        "name": "pcstest-nouser-274038a4-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "203821112238",
        "arn": "",
        "email": "pcsk-aws-stage+16959574542642441695957454264225@salesforce.com",
        "name": "pcstest-nouser-1f542767-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "625886676428",
        "arn": "",
        "email": "pcsk-aws-stage+16902544385635431690254438563525@salesforce.com",
        "name": "pcstest-nouser-30cb218d-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "137874559451",
        "arn": "",
        "email": "pcsk-aws-stage+137874559451@salesforce.com",
        "name": "onboarding-demo-5",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "103291325546",
        "arn": "",
        "email": "pcsk-aws-stage+16952492048208421695249204820803@salesforce.com",
        "name": "pcstest-jm-20230930-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "809908043970",
        "arn": "",
        "email": "pcsk-aws-stage+51xOA9VEwR88mSXJoXVSBnqMKXo9HijH@salesforce.com",
        "name": "pcstest-backuptest5-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "825367334920",
        "arn": "",
        "email": "pcsk-aws-stage+TrHAUU7Fu78AAewgq8SoGzxTqKy3W2tO@salesforce.com",
        "name": "pcs-1219-04-foundation-spinbakmngng",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "715477511251",
        "arn": "",
        "email": "pcsk-aws-stage+16945681602890261694568160289005@salesforce.com",
        "name": "pcstest-netv2-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "329308568786",
        "arn": "",
        "email": "pcsk-aws-stage+16952537316264781695253731626423@salesforce.com",
        "name": "pcstest-jm-20230930b-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "903264822938",
        "arn": "",
        "email": "pcsk-aws-stage+16947337068937531694733706893709@salesforce.com",
        "name": "pcstest-netv2-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "050756592042",
        "arn": "",
        "email": "pcsk-aws-stage+16932515583186851693251558318667@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523362749",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "129938684919",
        "arn": "",
        "email": "pcsk-aws-stage+16945591057466081694559105746589@salesforce.com",
        "name": "pcstest-nouser-b90bc9af-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "491953059684",
        "arn": "",
        "email": "pcsk-aws-stage+T4IooxokqZf0cqYRAuDthNRhDijrSNkO@salesforce.com",
        "name": "Test epatrice 18 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "133282092280",
        "arn": "",
        "email": "pcsk-aws-stage+Vjc7fsVAuRYcBxJxsqYX52L9tuu5Qytc@salesforce.com",
        "name": "pcstest-727e2f81-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "961495109812",
        "arn": "",
        "email": "pcsk-aws-stage+16947324127326941694732412732675@salesforce.com",
        "name": "pcstest-netv2-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "964163811978",
        "arn": "",
        "email": "pcsk-aws-stage+16934403950448741693440395044809@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616525047789",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "287493113784",
        "arn": "",
        "email": "pcsk-aws-stage+16945623390758531694562339075823@salesforce.com",
        "name": "pcstest-20230915-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "061482124910",
        "arn": "",
        "email": "pcsk-aws-stage+16902537921362671690253792136263@salesforce.com",
        "name": "pcstest-nouser-30cb218d-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "710732325921",
        "arn": "",
        "email": "pcsk-aws-stage+16947369413559661694736941355946@salesforce.com",
        "name": "pcstest-nouser-65906f9a-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "850461571431",
        "arn": "",
        "email": "pcsk-aws-stage+5aHMVRlOy8yyg8mlxjV5dg2QG4vwRdZW@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "474452578625",
        "arn": "",
        "email": "pcsk-aws-stage+bACjxzIjfz5P3oO9TM3p7BGj6NktaONB@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-svc2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "092953560282",
        "arn": "",
        "email": "pcsk-aws-stage+16964694356375891696469435637572@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205711009803",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "783570045291",
        "arn": "",
        "email": "pcsk-aws-stage+nonFalconTest17@salesforce.com",
        "name": "nonFalconTest17",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "152543508723",
        "arn": "",
        "email": "pcsk-aws-stage+16961167580316441696116758031595@salesforce.com",
        "name": "pcstest-eric-il-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "544111064412",
        "arn": "",
        "email": "pcsk-aws-stage+16959555093983691695955509398278@salesforce.com",
        "name": "pcstest-nouser-1f542767-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "923540455135",
        "arn": "",
        "email": "pcsk-aws-stage+16944656406889541694465640688933@salesforce.com",
        "name": "pcstest-20230913-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "940018124346",
        "arn": "",
        "email": "pcsk-aws-stage+16959451089624951695945108962469@salesforce.com",
        "name": "pcstest-jm-231001-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "175044056525",
        "arn": "",
        "email": "pcsk-aws-stage+16944409238811341694440923881117@salesforce.com",
        "name": "pcstest-nouser-42376de5-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "743980818323",
        "arn": "",
        "email": "pcsk-aws-stage+17qmP1sioYZRJvifNeWoeNgkLok1IPcg@salesforce.com",
        "name": "pcstest-cf6e62df-fd2-svc3",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "056760766302",
        "arn": "",
        "email": "pcsk-aws-stage+FaIxuUaDodnSxSd40shj4FRBKKVt0U9Q@salesforce.com",
        "name": "Test epatrice 16 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "199046374430",
        "arn": "",
        "email": "pcsk-aws-stage+sgu4PcB4epLTVuPFLeOkO1xKrXpOoM6a@salesforce.com",
        "name": "brucetest10-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "131252523683",
        "arn": "",
        "email": "pcsk-aws-stage+16934371590196151693437159019596@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524945151",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "887176375689",
        "arn": "",
        "email": "pcsk-aws-stage+dZfxmfaHiUovK0JlwBPaOBsusPyPQpQ0@salesforce.com",
        "name": "PCSTEST_20191125_17",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "057358948627",
        "arn": "",
        "email": "pcsk-aws-stage+16964551571894721696455157189366@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710497945",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "946421292875",
        "arn": "",
        "email": "pcsk-aws-stage+EcaOJPEavu51dwJHWj2zlzb3p6ACls82@salesforce.com",
        "name": "pcstest-backuptest5-foundation-svc2",
        "team_name": "service2",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "105123424967",
        "arn": "",
        "email": "pcsk-aws-stage+16891201547441901689120154744148@salesforce.com",
        "name": "endpoints-useast1-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "968005050618",
        "arn": "",
        "email": "pcsk-aws-stage+16934274464695901693427446458927@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524577059",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "999034646788",
        "arn": "",
        "email": "pcsk-aws-stage+I35BkMdamPWwgnXPPN9vYNf3mdQfbvPA@salesforce.com",
        "name": "Test epatrice kinnarid 2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "287105317476",
        "arn": "",
        "email": "pcsk-aws-stage+l3s2kLRTsVR9DjLSJaAgATGzyQKmNOGo@salesforce.com",
        "name": "pcstest-nouser-0b056137-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "test_hang",
        "arn": "",
        "email": "hzhao@salesforce.com",
        "name": "test n",
        "team_name": "aketa",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "984433299946",
        "arn": "",
        "email": "pcsk-aws-stage+16961180537975531696118053797528@salesforce.com",
        "name": "pcstest-eric-il-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "033770154600",
        "arn": "",
        "email": "pcsk-aws-stage+16944608979039381694460897903923@salesforce.com",
        "name": "pcstest-20230913-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "435774901106",
        "arn": "",
        "email": "pcsk-aws-stage+hm11srWYwpkxMZHp81u6ylvLNvuCqyOR@salesforce.com",
        "name": "pcstest-3e6a82fd-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "741886094002",
        "arn": "",
        "email": "pcsk-aws-stage+XPZUzXf0oVEFEH5pDWb84nA6sFupolgp@salesforce.com",
        "name": "pcstest-nouser-0b056137-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "061860062943",
        "arn": "",
        "email": "pcsk-aws-stage+16932547879474171693254787947385@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523492776",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "941066262613",
        "arn": "",
        "email": "pcsk-aws-stage+9no2FkgpcLGUwKmCGJhRF124yxIGoGbk@salesforce.com",
        "name": "pcstest-1361d1f2-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "236941421163",
        "arn": "",
        "email": "pcsk-aws-stage+W66atfXTHQu3eUN8VSgtXhTzJ0RpO8hq@salesforce.com",
        "name": "pcsk-bhor.test+stage_ctest3",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "847500076686",
        "arn": "",
        "email": "pcsk-aws+guardduty-delegate-for-mpa-038731646608@salesforce.com",
        "name": "PCSK_Stage_GuardDuty_Administrator_038731646608",
        "team_name": "dnr-threatdetection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "890701408119",
        "arn": "",
        "email": "pcsk-aws-stage+16904184671306821690418467130678@salesforce.com",
        "name": "pcstest-nouser-76953fc3-foundation-sitebridge",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "178509040138",
        "arn": "",
        "email": "pcsk-aws-stage+16947414689605301694741468960511@salesforce.com",
        "name": "pcstest-nouser-65906f9a-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "562713996824",
        "arn": "",
        "email": "pcsk-aws-stage+w1UXrhiXe5pPUoKkFaTu2k5dm1qu2iRQ@salesforce.com",
        "name": "aws-pcscanary3-uswest2-foundation-tvm",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "568454463758",
        "arn": "",
        "email": "pcsk-aws-stage+test-scp-stage@salesforce.com",
        "name": "test-scp-stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "248801899070",
        "arn": "",
        "email": "pcsk-aws-stage+LGzrauJiZYTt4SQGGCgwltrPKYweUfDh@salesforce.com",
        "name": "Test epatrice non-falcon",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "961366494417",
        "arn": "",
        "email": "pcsk-aws-stage+pB9pOfaSie9NbKGKKPNwcQqDTNwFOYTG@salesforce.com",
        "name": "aws-pcscanary3-uswest2-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "380437203585",
        "arn": "",
        "email": "pcsk-aws-stage+YlhNKve6tIhpquefIZZyWCjZnkgQCDxZ@salesforce.com",
        "name": "pcstest-kurt-iris-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "537391309139",
        "arn": "",
        "email": "pcsk-aws-stage+lbYYJTdDpJbDqWc6RMGIIU4awJnOnudn@salesforce.com",
        "name": "PCSKJitCommonIAM14",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "166470950147",
        "arn": "",
        "email": "pcsk-aws-stage+16945668659711311694566865971111@salesforce.com",
        "name": "pcstest-20230915-foundation-sitebridge",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "504084843019",
        "arn": "",
        "email": "pcsk-aws-stage+16934332716808881693433271680870@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524795233",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "773058193506",
        "arn": "",
        "email": "pcsk-gov-sandbox-test-2@salesforce.com",
        "name": "pcsk-gov-sandbox-test-2",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "956220949052",
        "arn": "",
        "email": "pcsk-aws-stage+wMIQLYIobCY2qSdA7xYcGFtIvHkDUNJH@salesforce.com",
        "name": "PCSTEST_20191107.01",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "142068658888",
        "arn": "",
        "email": "pcsk-aws-stage+16952485590560681695248559056013@salesforce.com",
        "name": "pcstest-jm-20230930-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "500851414236",
        "arn": "",
        "email": "pcsk-aws-stage+step-func1@salesforce.com",
        "name": "pcsk-test-step-func-update-1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "468633559475",
        "arn": "",
        "email": "pcsk-aws-stage+16947306133456051694730613345586@salesforce.com",
        "name": "pcstest-netv2-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "464721225435",
        "arn": "",
        "email": "pcsk-aws-stage+16952498528670801695249852866969@salesforce.com",
        "name": "pcstest-jm-20230930-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "487784549399",
        "arn": "",
        "email": "pcsk-aws-stage+16944710204573171694471020457266@salesforce.com",
        "name": "pcstest-nouser-b90bc9af-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "255401550093",
        "arn": "",
        "email": "pcsk-aws-stage+prADrNmN5xLMMwa5iYV2fyRXCFvWQBgZ@salesforce.com",
        "name": "dfroese283-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "139313527116",
        "arn": "",
        "email": "pcsk-aws-stage+16944370475103481694437047510323@salesforce.com",
        "name": "pcstest-nouser-42376de5-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "414067181006",
        "arn": "",
        "email": "pcsk-aws-stage+16899858142896881689985814289683@salesforce.com",
        "name": "test-XX-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "513513215294",
        "arn": "",
        "email": "pcsk-aws-stage+16941974267071491694197426707088@salesforce.com",
        "name": "pcstest-nouser-274038a4-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "076132035276",
        "arn": "",
        "email": "pcsk-aws-stage+2JlCbzT38UgqjUzJFaMeZFHLn6O7mwjx@salesforce.com",
        "name": "pcstest-cf6e62df-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "850765619452",
        "arn": "",
        "email": "pcsk-aws-stage+i9wC9rV24HGn86dgf5JjnFpAc7YQeJH8@salesforce.com",
        "name": "pcstest-7577816f-fd2-svc3",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "002022043031",
        "arn": "",
        "email": "pcsk-aws-stage+rZIrriSAagJf8D7vEs0op1wOOiwn86cD@salesforce.com",
        "name": "pcstest-b7d55527-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "076707630669",
        "arn": "",
        "email": "pcsk-aws-stage+16944624117841181694462411784094@salesforce.com",
        "name": "pcstest-20230913-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "165809561359",
        "arn": "",
        "email": "pcsk-aws-stage+16934511087435281693451108743455@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693451102092282617",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "612796416289",
        "arn": "",
        "email": "pcsk-aws-stage+vtm3NpeOFOseUYVti1GCzDq8BxfgMvL9@salesforce.com",
        "name": "eric-sfci-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "555145178432",
        "arn": "",
        "email": "pcsk-aws-stage+16947408225596781694740822559620@salesforce.com",
        "name": "pcstest-nouser-65906f9a-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "921903257804",
        "arn": "",
        "email": "pcsk-aws-stage+gxj0yPEUbbNxqpBGFzKdKEsXPqqBo1IG@salesforce.com",
        "name": "Test epatrice non-falcon 4",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "643748006020",
        "arn": "",
        "email": "pcsk-aws-stage+6PRiSRiUmNaXqhK4fFCnbBC3YAj0vxcW@salesforce.com",
        "name": "pcstest-38410fa1-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "168472081966",
        "arn": "",
        "email": "pcsk-aws-stage+T2WaTUGMJzu0IQUdNnYGC8640DmS1Sj0@salesforce.com",
        "name": "pcstest-3e6a82fd-foundation-skywalker",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "453816024586",
        "arn": "",
        "email": "pcsk-aws-stage+16962882095755401696288209575519@salesforce.com",
        "name": "pcstest-kurt-soter-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "967995863024",
        "arn": "",
        "email": "pcsk-aws-stage+mgXFH9n5cWrt2wIdzdnagCcJa3f08zAX@salesforce.com",
        "name": "eric-sfci-foundation-skywalker",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "536885228490",
        "arn": "",
        "email": "pcsk-aws-stage+16944589508953691694458950895346@salesforce.com",
        "name": "pcstest-kurt-shield-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "566307405247",
        "arn": "",
        "email": "pcsk-aws-stage+16952382122574241695238212257192@salesforce.com",
        "name": "pcstest-nouser-d2f100b7-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "613215892002",
        "arn": "",
        "email": "pcsk-aws-stage+16934255055509551693425505550919@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524504465",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "235439467841",
        "arn": "",
        "email": "pcsk-aws-stage+16899891054723101689989105472305@salesforce.com",
        "name": "test-XX-foundation-spinbakmnged",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "683206498284",
        "arn": "",
        "email": "pcsk-aws-stage+Wd04N2nynbHSOCvWBNp1lX7wXTwMyw8y@salesforce.com",
        "name": "pcstest-8450344d-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "432700296498",
        "arn": "",
        "email": "pcsk-aws-stage+16944344600665081694434460066459@salesforce.com",
        "name": "pcstest-jack-fake-bom-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "127245967634",
        "arn": "",
        "email": "pcsk-aws-stage+16962776148507151696277614850692@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "109335591056",
        "arn": "",
        "email": "pcsk-aws-stage+Kj6PKJYfetP8UOp90OkBdfDuas2fndZ7@salesforce.com",
        "name": "pcs-1219-01-fd2-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "031929747331",
        "arn": "",
        "email": "pcsk-aws-stage+tkiG8wTOnTqrL8UQeyCFEX3HT7FFE8la@salesforce.com",
        "name": "herokucustaugthreeTwo",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "314891441153",
        "arn": "",
        "email": "pcsk-aws-stage+pzhtSGbSQ7iwmzS1QrQkraQoxJWQocKL@salesforce.com",
        "name": "pcstest-38410fa1-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "093196390547",
        "arn": "",
        "email": "bhorbhaskar.verma@salesforce.com",
        "name": "pcsk-bhor.verma-dev",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "811801675415",
        "arn": "",
        "email": "pcsk-aws-stage+16944267027475321694426702747474@salesforce.com",
        "name": "pcstest-nouser-274038a4-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "277138192924",
        "arn": "",
        "email": "pcsk-aws-stage+nonFalconTest13@salesforce.com",
        "name": "nonFalconTest13",
        "team_name": "roberteam",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "034077853072",
        "arn": "",
        "email": "pcsk-aws-stage+16899845614243311689984561424327@salesforce.com",
        "name": "test-XX-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "496822215026",
        "arn": "",
        "email": "heroku-secdev+aws-smU4EqstBDwsmVFqXl24NSYoXytGd@salesforce.com",
        "name": "PCSTEST_20190513.00",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "941134488908",
        "arn": "",
        "email": "pcsk-aws-stage+g3DlP5VTpfTNnxc7hPpsxqEaU4iyK0Ob@salesforce.com",
        "name": "pcstest-nouser-0b056137-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "663091864759",
        "arn": "",
        "email": "pcsk-aws-stage+V5lPrKxsnYK4gCK7Shmt3UaSmNlddxqD@salesforce.com",
        "name": "pcstest-1361d1f2-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "778867028122",
        "arn": "",
        "email": "pcsk-aws-stage+zVzAEDuChMzJvZkeKcrHXEdE9sq2Hu3h@salesforce.com",
        "name": "PCSKJitCommonIAM7",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "772686076610",
        "arn": "",
        "email": "pcsk-aws-stage+9mgHIrl346wmkInFutSCv0dNE1R3zNsQ@salesforce.com",
        "name": "pcs-uswest2-20200128c-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "8707352931136",
        "arn": "",
        "email": "riya.sinha@salesforce.com",
        "name": "CIP Prd",
        "team_name": "anjaniteam",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "336156663138",
        "arn": "",
        "email": "pcsk-aws-stage+eHyEZVGYDcbuFwG9sOVdANtPYnzGL3Kn@salesforce.com",
        "name": "PCSTEST_10292019.00",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "671384560735",
        "arn": "",
        "email": "pcsk-aws-stage+g90eHcDhf6ivJydLR5C2OBbzc6niqVeO@salesforce.com",
        "name": "pcstest-3e6a82fd-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "524529386292",
        "arn": "",
        "email": "pcsk-aws-stage+twTrqP0gZQnKadktBUuoV011nvPGWybu@salesforce.com",
        "name": "Test epatrice 17 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "986993199773",
        "arn": "",
        "email": "pcsk-aws-stage+DgdwYiJfmLrKkf2JrEFnxF29rKuFW95V@salesforce.com",
        "name": "pcstest-92bb9bc2-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "554193621769",
        "arn": "",
        "email": "pcsk-aws-stage+pcsk-run-test-t6@salesforce.com",
        "name": "pcsk-run-through-t6",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "503496792597",
        "arn": "",
        "email": "pcsk-aws-stage+16934267991016521693426799101635@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524555262",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "020183587906",
        "arn": "",
        "email": "pcsk-aws-stage+Zz85RKUmmuEpfUOrX5csvbGocYnn93g5@salesforce.com",
        "name": "commonIAM19",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "291454904322",
        "arn": "",
        "email": "pcsk-aws-stage+GCGtqWOuURTKPjf6VxIu1yFnf4uBafid@salesforce.com",
        "name": "eric-sfci-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "542203572191",
        "arn": "",
        "email": "pcsk-aws-stage+16934261522058911693426152205863@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524530870",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "373153871772",
        "arn": "",
        "email": "pcsk-aws-stage+Nfu6oYsGrv3RuYLJoWS3ZqZINMw5W4Kb@salesforce.com",
        "name": "aws-pcscanary3-uswest2-crypto-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "334447346834",
        "arn": "",
        "email": "pcsk-aws-stage+16935413275989771693541327598952@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693541320423754199",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "164459721003",
        "arn": "",
        "email": "pcsk-aws-stage+16961193497306531696119349730635@salesforce.com",
        "name": "pcstest-eric-il-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "440784058782",
        "arn": "",
        "email": "pcsk-aws-stage+UsPsoyKpATHLPtUtvbGZuotMj56bQJdK@salesforce.com",
        "name": "pcstest-1361d1f2-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "681585708256",
        "arn": "",
        "email": "pcsk-aws-stage+16952504991479021695250499144486@salesforce.com",
        "name": "pcstest-jm-20230930b-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "695203081093",
        "arn": "",
        "email": "pcsk-aws-stage+ekHItTTk7bnyNaPAHxh7tdR9Gx6gboUp@salesforce.com",
        "name": "pcstest-1361d1f2-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "644869992816",
        "arn": "",
        "email": "pcsk-aws-stage+16936111510940691693611151094037@salesforce.com",
        "name": "pcstest-nouser-477ccecf-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "787776030426",
        "arn": "",
        "email": "pcsk-aws-stage+JnmgLhsEkTRUXTd23r7MfMB32Iffhf7N@salesforce.com",
        "name": "pcstest-jm-ou-04-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "000135205372",
        "arn": "",
        "email": "pcsk-aws-stage+16947427634073451694742763407330@salesforce.com",
        "name": "pcstest-nouser-65906f9a-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "307341236759",
        "arn": "",
        "email": "pcsk-aws-stage+dVJU575GX7QAHY2j8Mrihnm33OmD3h5O@salesforce.com",
        "name": "pcstest-backuptest5-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "359908997465",
        "arn": "",
        "email": "pcsk-aws-stage+2RUOv18Z0An5H3QAyqItWqc9jNDGrOyq@salesforce.com",
        "name": "pcs-uswest2-20200128c-fd2-svc3",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "124786165941",
        "arn": "",
        "email": "pcsk-aws-stage+16944338137077111694433813707694@salesforce.com",
        "name": "pcstest-jack-fake-bom-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "314583789834",
        "arn": "",
        "email": "pcsk-aws-stage+16932560801063781693256080106342@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523539052",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "498646156093",
        "arn": "",
        "email": "pcsk-aws-stage+16964668440913611696466844091341@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710914766",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "210628248857",
        "arn": "",
        "email": "pcsk-aws-stage+B9CcD6jnHl4h21ssq6awJCadTK2ePbf9@salesforce.com",
        "name": "pcs-uswest2-20200128c-fd2-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "428344646612",
        "arn": "",
        "email": "pcsk-aws-stage+16945662199253311694566219925315@salesforce.com",
        "name": "pcstest-20230915-foundation-skywalker",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "540610963113",
        "arn": "",
        "email": "pcsk-aws-stage+16906003131006921690600313100688@salesforce.com",
        "name": "pcstest-pcstest-kurt-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "274574306163",
        "arn": "",
        "email": "pcsk-aws-stage+16947382343887531694738234388738@salesforce.com",
        "name": "pcstest-nouser-65906f9a-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "873985687728",
        "arn": "",
        "email": "pcsk-aws-stage+BtkO6JJYJKcm3oTc9uqQ668UOf6p8feB@salesforce.com",
        "name": "aws-pcscanary3-uswest2-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "147085200817",
        "arn": "",
        "email": "pcsk-aws-stage+16959509403068471695950940306831@salesforce.com",
        "name": "pcstest-jm-231001-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "908480221200",
        "arn": "",
        "email": "pcsk-aws-stage+79YaFWcXFa1erpLZFi1Xa32p4soF9bbD@salesforce.com",
        "name": "herokucustaugthreeThree",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "528077769648",
        "arn": "",
        "email": "pcsk-aws-stage+16962856186994131696285618699394@salesforce.com",
        "name": "pcstest-nouser-494360ae-fd2-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "670534444787",
        "arn": "",
        "email": "pcsk-aws-stage+3w1eKKQRHDWfw2Fd6AofGblk7j10J9Iv@salesforce.com",
        "name": "pcstest-3e6a82fd-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "684326751492",
        "arn": "",
        "email": "pcsk-aws-stage+RwqbHp1L6gvLuo0u1z7f3IkbhhoxyztV@salesforce.com",
        "name": "andy-f00z01-fd2-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "799696138513",
        "arn": "",
        "email": "pcsk-aws-stage+16899232883600281689923288360020@salesforce.com",
        "name": "test dipanshu",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "851284887978",
        "arn": "",
        "email": "pcsk-aws-stage+16952517929490161695251792931389@salesforce.com",
        "name": "pcstest-jm-20230930b-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "301841287785",
        "arn": "",
        "email": "pcsk-aws-stage+16962843218867651696284321886745@salesforce.com",
        "name": "pcstest-nouser-494360ae-fd2-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "687965925615",
        "arn": "",
        "email": "pcsk-aws-stage+9RN2RARVWwZyp662I5FGorVmSTuj7Ms5@salesforce.com",
        "name": "pcsk-sri-falcon-stage-5",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "777884371995",
        "arn": "",
        "email": "pcsk-aws-stage+JHzWmlH9Df346AvgIuVExOu893HXCbU6@salesforce.com",
        "name": "pcstest-5cf38656-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "248981870869",
        "arn": "",
        "email": "afucsdemiranda+jun25endtoend2@salesforce.com",
        "name": "awsconfig-pr377-endtoend-jun25",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "680299802421",
        "arn": "",
        "email": "pcsk-aws-stage+16951477051802641695147705180243@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "492762077399",
        "arn": "",
        "email": "pcsk-aws-stage+16961174058186051696117405818575@salesforce.com",
        "name": "pcstest-eric-il-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "279255874491",
        "arn": "",
        "email": "pcsk-aws-stage+t3j2R6ZMSnrlU9E8NdsI9E0aR2sLEvrX@salesforce.com",
        "name": "dfroese282-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "803925714746",
        "arn": "",
        "email": "pcsk-aws-stage+16902570231732301690257023173223@salesforce.com",
        "name": "pcstest-nouser-76953fc3-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "650591424227",
        "arn": "",
        "email": "pcsk-aws-stage+at19LmsmW1P7iuw1wV2QBeocfGkExzdB@salesforce.com",
        "name": "pcstest-jm-ou-04-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "247586454969",
        "arn": "",
        "email": "pcsk-aws-stage+16927446746531631692744674653145@salesforce.com",
        "name": "pcstest-targetisrael2-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "297210842712",
        "arn": "",
        "email": "pcsk-aws-stage+16927466105298261692746610529792@salesforce.com",
        "name": "pcstest-targetisrael2-foundation-collection",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "962002093233",
        "arn": "",
        "email": "pcsk-aws-stage+16899884579356641689988457935654@salesforce.com",
        "name": "test-XX-foundation-spinbakmngng",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "754737009494",
        "arn": "",
        "email": "pcsk-aws-stage+16944228212028911694422821202868@salesforce.com",
        "name": "pcstest-nouser-274038a4-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "368839661829",
        "arn": "",
        "email": "pcsk-aws-stage+F0W05jDgugBdgJM8AvVfxDj5axZeKiDd@salesforce.com",
        "name": "dnssec-pol-test-foundation-skywalker",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "605452231061",
        "arn": "",
        "email": "pcsk-aws-stage+16959489961440951695948996144044@salesforce.com",
        "name": "pcstest-jm-231001-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "673635289556",
        "arn": "",
        "email": "pcsk-aws-stage+XeP9B7PgplJazNTivZfLL7Z4yjbphNsL@salesforce.com",
        "name": "pcstest-backuptest5-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "532560005031",
        "arn": "",
        "email": "pcsk-aws-stage+IPCHaNdkOcvj99zQz3ObilkNIdznJldC@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-spinbakmnged",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "875096568545",
        "arn": "",
        "email": "pcsk-aws-stage+16944583043731771694458304373113@salesforce.com",
        "name": "pcstest-kurt-shield-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "071546227774",
        "arn": "",
        "email": "pcsk-aws-stage+ZbAC00DqiZeMPfUMmwwxXnQeREHpg57u@salesforce.com",
        "name": "pcstest-38410fa1-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "445338527146",
        "arn": "",
        "email": "pcsk-aws-stage+16899871630923921689987163092388@salesforce.com",
        "name": "test-XX-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "479240676384",
        "arn": "",
        "email": "pcsk-aws-stage+caBAgfmSySuy5zTcUajKnhJsT273ICks@salesforce.com",
        "name": "andy-f00z01-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "642419113581",
        "arn": "",
        "email": "pcsk-aws-stage+eZaL2fiwK8Ueq8TbYZl1FCnz2M6McEyM@salesforce.com",
        "name": "pcstest-jack-01-al-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "533757073664",
        "arn": "",
        "email": "pcsk-aws-stage+16941123605669041694112360566880@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1694112354076215677",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "589904814023",
        "arn": "",
        "email": "pcsk-aws-stage+Y6i0ZNTb8Q3CAd9On8Nzs5nxk1lmhIag@salesforce.com",
        "name": "pcstest-06fd64cb-fd2-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "291398388017",
        "arn": "",
        "email": "pcsk-aws-stage+16898100620411221689810062041105@salesforce.com",
        "name": "pcstest-nouser-f94c3332-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "122778658214",
        "arn": "",
        "email": "pcsk-aws-stage+TgdGhQuJmkngOW1Wip7gAAvyQgqUHChq@salesforce.com",
        "name": "eric-sfci-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "420958625471",
        "arn": "",
        "email": "pcsk-aws-stage+16964570999352091696457099935155@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710569132",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "821147368222",
        "arn": "",
        "email": "pcsk-aws-stage+16898082649597521689808264959748@salesforce.com",
        "name": "pcsk-aws-pool-7516898082649597291689808264959705",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "378254851860",
        "arn": "",
        "email": "pcsk-aws-stage+16891220948365331689122094836529@salesforce.com",
        "name": "endpoints-useast1-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "690962212393",
        "arn": "",
        "email": "pcsk-aws-stage+nonFalconTest7@salesforce.com",
        "name": "nonFalconTest7",
        "team_name": "roberteam",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "598774580798",
        "arn": "",
        "email": "pcsk-aws-stage+8YHAIUqldymSwkdRHYmYSXp2csxgqXSV@salesforce.com",
        "name": "PCSTEST_09102019.00",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "668177145188",
        "arn": "",
        "email": "pcsk-aws-stage+4mFq9G732WW8rpCAG9mtl93TPafoSU4N@salesforce.com",
        "name": "herokucustaugthree",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "622417474911",
        "arn": "",
        "email": "pcsk-aws-stage+16951496433073181695149643307246@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "175151394074",
        "arn": "",
        "email": "pcsk-aws-stage+16951586845854221695158684585405@salesforce.com",
        "name": "pcstest-jmpreprod-9b2-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "387930693767",
        "arn": "",
        "email": "pcsk-aws-stage+16945642798444111694564279844388@salesforce.com",
        "name": "pcstest-20230915-foundation-tentacle",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "664693409098",
        "arn": "",
        "email": "pcsk-aws-stage+nonFalconTest10@salesforce.com",
        "name": "nonFalconTest10",
        "team_name": "roberteam",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "825289227567",
        "arn": "",
        "email": "pcsk-aws-stage+onboard-demo-1@salesforce.com",
        "name": "onboarding-demo-1",
        "team_name": "public-cloud-security-kernel",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "047717245297",
        "arn": "",
        "email": "pcsk-aws-stage+o4APs6I47aPpuIA8gjZvjFACJlTZDFG2@salesforce.com",
        "name": "pcstest-backuptest5-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "058156546994",
        "arn": "",
        "email": "pcsk-aws-stage+NdcNDIXlsXs9fHLFZnG7zbbvlWytH43A@salesforce.com",
        "name": "pcstest-3e6a82fd-foundation-codedeploy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "990921847605",
        "arn": "",
        "email": "pcsk-aws-stage+wg5Nz0wnBbr4FvA2d3umoWsxehBaoeju@salesforce.com",
        "name": "pcstest-c3e305fe-fd2-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "897771810208",
        "arn": "",
        "email": "pcsk-aws-stage+16898094145467131689809414546709@salesforce.com",
        "name": "pcstest-nouser-f94c3332-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "991119690229",
        "arn": "",
        "email": "pcsk-aws-stage+En0xtLaTH4uNZySUfsnebV9QhKkCcob0@salesforce.com",
        "name": "pcstest-nouser-5b659791-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "085216222591",
        "arn": "",
        "email": "pcsk-aws-stage+16934339195375041693433919537482@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524818314",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "454135089447",
        "arn": "",
        "email": "pcsk-aws-stage+16964629593683651696462959368347@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710775840",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "101822085338",
        "arn": "",
        "email": "pcsk-aws-stage+16959464036934841695946403693465@salesforce.com",
        "name": "pcstest-jm-231001-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "524867122742",
        "arn": "",
        "email": "pcsk-aws-stage+cqypSK1T5G9OLrU95HlPuvrat2NkHHau@salesforce.com",
        "name": "pcs-uswest2-20200123c-foundation-spinbakmngng",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "848560915580",
        "arn": "",
        "email": "pcsk-aws-stage+UtAzRnKNLT9LrotP0ZLP5gXcuISdTRtV@salesforce.com",
        "name": "nathan-uswest2-20191108-03-foundation-sysseciam",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "740843066284",
        "arn": "",
        "email": "pcsk-aws-stage+sMwUs1lPA9DF6d45gGYyVSiYBhh5RmCL@salesforce.com",
        "name": "Test epatrice dnr provisioning",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "959161015900",
        "arn": "",
        "email": "pcsk-aws-stage+16944703753661031694470375366022@salesforce.com",
        "name": "pcstest-nouser-b90bc9af-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "758279744774",
        "arn": "",
        "email": "pcsk-aws-stage+deKBG4WSvlHfQfRTFduW0W3aHEmWrYJM@salesforce.com",
        "name": "pcstest-uswest2-2020-01-14a-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "502037769159",
        "arn": "",
        "email": "pcsk-aws-stage+nonFalconTest16@salesforce.com",
        "name": "nonFalconTest16",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "245892108482",
        "arn": "",
        "email": "pcsk-aws-stage+fFbSD5roYMV4Lr6TfBXK4a3te1KSdMNm@salesforce.com",
        "name": "pcstest-0f99690b-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "813133984441",
        "arn": "",
        "email": "pcsk-aws-stage+16944649958821361694464995882101@salesforce.com",
        "name": "pcstest-20230913-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "343218367464",
        "arn": "",
        "email": "pcsk-aws-stage+VXDQ1JPph1gtPCoVZpE367zpkKNTRlKM@salesforce.com",
        "name": "commonIAM21",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "390487422131",
        "arn": "",
        "email": "pcsk-aws-stage+8pOY2mUgGmI8kMMHE5fguoHpabtsVbhg@salesforce.com",
        "name": "pcstest-kurt-iris-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "057562144738",
        "arn": "",
        "email": "pcsk-aws-stage+IIfc7v9UshEmvrNWAqJHOBnienMG5jTp@salesforce.com",
        "name": "pcstest-nouser-93f359c8-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "861702666727",
        "arn": "",
        "email": "pcsk-aws-stage+16952359114077501695235911407733@salesforce.com",
        "name": "pcstest-nouser-d2f100b7-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "873707828456",
        "arn": "",
        "email": "pcsk-aws-stage+16951554570908971695155457090812@salesforce.com",
        "name": "pcstest-jmpreprod-9b2-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "197921628833",
        "arn": "",
        "email": "pcsk-aws-stage+VQr95aZ7VpBMRSRsJ7kok8h3fNA31YtF@salesforce.com",
        "name": "PCSKJitCommonIAM",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "738634055144",
        "arn": "",
        "email": "pcsk-aws-stage+Kj0uirnzUyYP0YSvV2KPAwahDEPZg37A@salesforce.com",
        "name": "brucetest8-foundation-spindepmngng",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "713754179142",
        "arn": "",
        "email": "pcsk-aws-stage+FbWVn2AyMz1Np68RxobzyTlWqLnTUKBh@salesforce.com",
        "name": "Test epatrice non-falcon 3",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "555610228147",
        "arn": "",
        "email": "pcsk-aws-stage+UctgxI1zFyT9nkyHyoUxsDRKl2bkCgIm@salesforce.com",
        "name": "pcs-uswest2-20200128b-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "794498047002",
        "arn": "",
        "email": "pcsk-aws-stage+16944643495801141694464349580096@salesforce.com",
        "name": "pcstest-20230913-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "052411634253",
        "arn": "",
        "email": "pcsk-aws-stage+Agh5f0bT1GbD5727zHHPpVsuLpaYOwTS@salesforce.com",
        "name": "commonIAM18",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "974936408611",
        "arn": "",
        "email": "pcsk-aws-stage+16934306812175721693430681217553@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524692646",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "771790452051",
        "arn": "",
        "email": "pcsk-aws-stage+HW1L3Cj0reDQLKbQtoNvYnbt7RsH0j2g@salesforce.com",
        "name": "pcstest-fe5f92b3-fd2-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "702172097851",
        "arn": "",
        "email": "pcsk-aws-stage+16934524044544581693452404454442@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693451102093216684",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "637970027290",
        "arn": "",
        "email": "pcsk-aws-stage+KDCLRceYqEdjBOat3NO4S1MuihVOnaTK@salesforce.com",
        "name": "eric-sfci-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "182378633142",
        "arn": "",
        "email": "pcsk-aws-stage+16947453480586731694745348058654@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-fd2-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "496297702502",
        "arn": "",
        "email": "pcsk-aws-stage+16961219412390731696121941239057@salesforce.com",
        "name": "pcstest-local-FMT-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "597612365476",
        "arn": "",
        "email": "pcsk-aws-stage+16945616924679401694561692467921@salesforce.com",
        "name": "pcstest-20230915-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "239026362890",
        "arn": "",
        "email": "pcsk-aws-stage+16944688691668611694468869166841@salesforce.com",
        "name": "pcstest-nouser-b90bc9af-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "614436545045",
        "arn": "",
        "email": "heroku-secdev+aws-0FN8EXcPpbwfTVC7Kzc5y6rbPHnFQ@salesforce.com",
        "name": "PCSTEST_05142019.00",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "920629536873",
        "arn": "",
        "email": "pcsk-aws-stage+16951593298029461695159329802929@salesforce.com",
        "name": "pcstest-jmpreprod-9b2-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "871090559221",
        "arn": "",
        "email": "pcsk-aws-stage+16934397470071611693439747007143@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616525030634",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "642994646193",
        "arn": "",
        "email": "pcsk-aws-stage+16944389851867541694438985186738@salesforce.com",
        "name": "pcstest-nouser-42376de5-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "807283315648",
        "arn": "",
        "email": "pcsk-aws-stage+dJnAkMzCAi8QvntcuYOX9ZamukW8K5OB@salesforce.com",
        "name": "PCSTEST_20191122.01",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "755858469446",
        "arn": "",
        "email": "pcsk-aws-stage+16964538617867961696453861786701@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710455235",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "290781817515",
        "arn": "",
        "email": "pcsk-aws-stage+0QMySfSdlQsKqt82RCKYllRm3qvEO5vM@salesforce.com",
        "name": "pcstest-7e0059e4-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "198692560860",
        "arn": "",
        "email": "pcsk-aws-stage+VTVbQH9XS3mdlIxQrJs4vxS7L0kFk5tb@salesforce.com",
        "name": "pcstest-1361d1f2-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "191826918676",
        "arn": "",
        "email": "pcsk-aws-stage+16932463923909631693246392390948@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523168899",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "328228566127",
        "arn": "",
        "email": "pcsk-aws-stage+16944357553183111694435755318285@salesforce.com",
        "name": "pcstest-nouser-42376de5-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "430777079485",
        "arn": "",
        "email": "pcsk-aws-stage+km2yAJpbkJZeYgF9cFWyKJ2j8SJHl7bu@salesforce.com",
        "name": "aws-pcscanary3-uswest2-crypto-soter",
        "team_name": "soter",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "613767594706",
        "arn": "",
        "email": "pcsk-aws-stage+16902241994124771690224199412451@salesforce.com",
        "name": "pcsk-aws-pool-2c16902241994123871690224199412292",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "766040754712",
        "arn": "",
        "email": "pcsk-aws-stage+LgfNaxnCc68Uy6Dwem6OhLXFwTDBuNXM@salesforce.com",
        "name": "PCSTEST_20191107.00",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "034938593210",
        "arn": "",
        "email": "pcsk-aws-stage+16961199977197431696119997719725@salesforce.com",
        "name": "pcstest-eric-il-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "042821120477",
        "arn": "",
        "email": "pcsk-aws-stage+uEYetLytpnB6eiSFpE7vpcTanOla1sDI@salesforce.com",
        "name": "pcstest-9c666355-foundation-publicproxy1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "737515375346",
        "arn": "",
        "email": "pcsk-aws-stage+16917894628625761691789462862541@salesforce.com",
        "name": "pcstest-pcs-test-israel-foundation-svc1",
        "team_name": "service1",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "823700735002",
        "arn": "",
        "email": "pcsk-aws-stage+4MFayV4rkzyVM0CZetfseYL6HglPfhlB@salesforce.com",
        "name": "pcs-staging-2019121301-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "391315785352",
        "arn": "",
        "email": "pcsk-aws-stage+16964649013164721696464901316453@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710849295",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "662031583640",
        "arn": "",
        "email": "pcsk-aws-stage+16964636058462401696463605846059@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696453205710797753",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "874240121310",
        "arn": "",
        "email": "pcsk-aws-stage+16959548308447971695954830844777@salesforce.com",
        "name": "pcstest-nouser-1f542767-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "721636990871",
        "arn": "",
        "email": "pcsk-aws-stage+16934378068524241693437806852406@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524966599",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "745251414097",
        "arn": "",
        "email": "pcsk-aws-stage+16923830674670521692383067467035@salesforce.com",
        "name": "pcstest-pcs-test-israel-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "737535028706",
        "arn": "",
        "email": "pcsk-aws-stage+16898190634680441689819063468033@salesforce.com",
        "name": "pcstest-nouser-f94c3332-foundation-skywalker",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "871361370046",
        "arn": "",
        "email": "pcsk-aws-stage+16898130146081851689813014608159@salesforce.com",
        "name": "pcstest-nouser-f94c3332-foundation-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "556592475674",
        "arn": "",
        "email": "pcsk-aws-stage+16898166142227781689816614222774@salesforce.com",
        "name": "pcstest-nouser-f94c3332-foundation-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "508653847323",
        "arn": "",
        "email": "pcsk-aws-stage+zi6QKDzGMVfHR1b7MSsxhU7orCZr10pW@salesforce.com",
        "name": "pcstest-7e0059e4-fd2-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "229962256636",
        "arn": "",
        "email": "pcsk-aws-stage+16886834415527791688683441552775@salesforce.com",
        "name": "endpoints-useast1-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "799852732244",
        "arn": "",
        "email": "pcsk-aws-stage+llHa4EwGmgM67sWKYFv4v8EzdbFcbB9B@salesforce.com",
        "name": "pcstest-21bf6a7a-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "856418058964",
        "arn": "",
        "email": "pcsk-aws-stage+16923882341079851692388234107919@salesforce.com",
        "name": "pcstest-israel-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "050680589811",
        "arn": "",
        "email": "pcsk-aws-stage+EoTyPJ58qLCeAYdhtHyh1Rkwk3cJE40z@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "778690451742",
        "arn": "",
        "email": "pcsk-aws-stage+IvNO2ZfqYvC4v2VhMAsZ6aPFFNhocrPB@salesforce.com",
        "name": "pcstest_ca_policy_20200505",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "677902491396",
        "arn": "",
        "email": "pcsk-aws-stage+16959528859898011695952885989745@salesforce.com",
        "name": "pcstest-nouser-1f542767-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "727356806137",
        "arn": "",
        "email": "pcsk-aws-stage+QX0iDE4r4cN72BAOVnMuLu2tQOBS4cvd@salesforce.com",
        "name": "pcstest-nouser-0b056137-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "214979000779",
        "arn": "",
        "email": "pcsk-aws-stage+FGDOwNe8bkLpYM6XamYqd50o915DLo6K@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-spindepmngng",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "334008171347",
        "arn": "",
        "email": "pcsk-aws-stage+16951528746153021695152874615284@salesforce.com",
        "name": "pcstest-nouser-a051ed7d-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "815299777281",
        "arn": "",
        "email": "pcsk-aws-stage+sPWBgXxEVJ2cEftliMbCxh5HM3KWRIcZ@salesforce.com",
        "name": "aws-pcscanary3-uswest2-crypto-publicproxy",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "890855193978",
        "arn": "",
        "email": "pcsk-aws-stage+16961148129722531696114812972234@salesforce.com",
        "name": "pcstest-eric-il-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "845855089730",
        "arn": "",
        "email": "pcsk-aws-stage+DZ2gin1CKUNUIENEhsE0O5hGJdXVzCWs@salesforce.com",
        "name": "PCSTEST_CAWPOLICY_20200212",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "052429241934",
        "arn": "",
        "email": "pcsk-aws-stage+16952472658867391695247265886719@salesforce.com",
        "name": "pcstest-jm-20230930-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "512232811851",
        "arn": "",
        "email": "pcsk-aws-stage+16959502922978241695950292297805@salesforce.com",
        "name": "pcstest-jm-231001-foundation-sitebridge",
        "team_name": "sitebridge",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "976453879308",
        "arn": "",
        "email": "pcsk-aws-stage+16951612661917941695161266191776@salesforce.com",
        "name": "pcstest-jmpreprod-9b2-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "293246247748",
        "arn": "",
        "email": "pcsk-aws-stage+CurrH1Wh7pp1BZNsmLbGCqWrTdnCsJaw@salesforce.com",
        "name": "commonIAM15",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "239999636068",
        "arn": "",
        "email": "pcsk-aws-stage+16910730130462241691073013046195@salesforce.com",
        "name": "pcsk-aws-pool-7b16910730130461241691073013046018",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "949960496376",
        "arn": "",
        "email": "pcsk-aws-stage+SmbxT9987rlsrY2dLTn9Iiw8PSjJxiyS@salesforce.com",
        "name": "Test epatrice 19 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "721055907674",
        "arn": "",
        "email": "pcsk-aws-stage+nonFalconTest3@salesforce.com",
        "name": "nonFalconTest3",
        "team_name": "roberteam",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "631294400705",
        "arn": "",
        "email": "pcsk-aws-stage+16964514140663811696451414066365@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1696451405333073915",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "436879654289",
        "arn": "",
        "email": "pcsk-aws-stage+16952401535462551695240153536923@salesforce.com",
        "name": "pcstest-nouser-d2f100b7-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "724228184210",
        "arn": "",
        "email": "pcsk-aws-stage+uATTblyquy32cXtbaN3Irm3lHZ8GYh3z@salesforce.com",
        "name": "Test SCP 1 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "273323952647",
        "arn": "",
        "email": "pcsk-aws-stage+16923837133161801692383713316158@salesforce.com",
        "name": "pcstest-israel-foundation-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "582525321781",
        "arn": "",
        "email": "pcsk-aws-stage+16923869419286551692386941928638@salesforce.com",
        "name": "pcstest-israel-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "317544409651",
        "arn": "",
        "email": "pcsk-aws-stage+16944540637411321694454063741111@salesforce.com",
        "name": "pcstest-kurt-shield-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "555269345183",
        "arn": "",
        "email": "pcsk-aws-stage+16932541420871571693254142087139@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523464809",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "419786501286",
        "arn": "",
        "email": "pcsk-aws-stage+Ru0WwtAFrng0cO7fNWQB2jlVVzYM2Ryt@salesforce.com",
        "name": "pcstest-kurt-iris-new-foundation-svc1",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "816282407629",
        "arn": "",
        "email": "pcsk-aws-stage+16944254100527811694425410052727@salesforce.com",
        "name": "pcstest-nouser-274038a4-foundation-publicproxy",
        "team_name": "publicproxy",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "808705545224",
        "arn": "",
        "email": "pcsk-aws-stage+uDFFsCHYZSjaahFI3TX7tyKNHusqTfrH@salesforce.com",
        "name": "pcstest-nouser-40c6d971-foundation-stride",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "228059654237",
        "arn": "",
        "email": "pcsk-aws-stage+16944331675944321694433167594413@salesforce.com",
        "name": "pcstest-jack-fake-bom-foundation-codedeploy",
        "team_name": "secure-agility",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "test1",
        "arn": "",
        "email": "test1@gmail",
        "name": "",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "023285665813",
        "arn": "",
        "email": "pcsk-aws-stage+16932451013536301693245101353611@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523101396",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "510637993912",
        "arn": "",
        "email": "pcsk-aws-stage+HqeQjaIeLNgkVb7JNaHdqUmbWCwFC7qh@salesforce.com",
        "name": "pcstest-jm-ou-04-foundation-skywalker",
        "team_name": "netsec-fps",
        "account_substrate": "aws",
        "account_status": "CLOSE_REQUESTED"
    },
    {
        "id": "738649091450",
        "arn": "",
        "email": "pcsk-aws-stage+ILXlcjujH9xnAeXqwVXS9QXbdyHJRWgH@salesforce.com",
        "name": "pcs-uswest2-20200128b-fd2-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "705035011015",
        "arn": "",
        "email": "pcsk-aws-stage+qx71kEgtxvep4gOuBavur5fymJz8DHGn@salesforce.com",
        "name": "brucetest6-foundation-spindepmngng",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "556066288209",
        "arn": "",
        "email": "pcsk-aws-stage+16944241161878341694424116187804@salesforce.com",
        "name": "pcstest-nouser-274038a4-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "258001589379",
        "arn": "",
        "email": "pcsk-aws-stage+16899897526374881689989752637467@salesforce.com",
        "name": "test-XX-foundation-spindepmngng",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "592524519022",
        "arn": "",
        "email": "pcsk-aws-stage+IAAdhXsURCROEUklypT109XeeQ2MlXqQ@salesforce.com",
        "name": "pcstest-9905ad5d-foundation-spinbakmnged",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "030044247979",
        "arn": "",
        "email": "pcsk-aws-stage+16878616326955331687861632695425@salesforce.com",
        "name": "pcsk-nonfalcon-test",
        "team_name": "pcsk-india-non-falcon",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "253921777148",
        "arn": "",
        "email": "pcsk-aws-stage+16952388608710411695238860870950@salesforce.com",
        "name": "pcstest-nouser-d2f100b7-foundation-ddi",
        "team_name": "ddi",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "755212184367",
        "arn": "",
        "email": "pcsk-aws-stage+16932444570401821693244457040162@salesforce.com",
        "name": "test-rnd-038731646608-pool-1693243805523075887",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "632575158310",
        "arn": "",
        "email": "pcsk-aws-stage+16947401750564691694740175056450@salesforce.com",
        "name": "pcstest-nouser-65906f9a-foundation-tentacle",
        "team_name": "tentacle",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "441061688562",
        "arn": "",
        "email": "pcsk-aws-stage+16QudlQNOVKl38j5Vlkm8bU8ItIeYRkX@salesforce.com",
        "name": "pcsk-sri-falcon-stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "198159115354",
        "arn": "",
        "email": "pcsk-aws-stage+lGTsLEzsQxt12McI9Quysf2pbAus1UuE@salesforce.com",
        "name": "pcstest-0f99690b-fd2-ddi",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "706671317255",
        "arn": "",
        "email": "pcsk-aws-stage+16934293865571871693429386557169@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524658775",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "633539965736",
        "arn": "",
        "email": "pcsk-aws-stage+16934384524848501693438452484835@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524988271",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "309102254490",
        "arn": "",
        "email": "pcsk-aws-stage+BPtR8sdvemyDLoDvKjNNsBJBw1Ct8oq1@salesforce.com",
        "name": "pcstest-c3e305fe-foundation-tft",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "647409855624",
        "arn": "",
        "email": "pcsk-aws-stage+nN61HGcjg91LZ45siI1hjPH33oig0PMg@salesforce.com",
        "name": "pcstest-jack-01-al-foundation-tgw",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "590401553278",
        "arn": "",
        "email": "pcsk-aws-stage+LRtBnGNY4Q6Ot5mtKmSvaEknAMERU1cH@salesforce.com",
        "name": "pcstest-kurt-iris-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "364178850949",
        "arn": "",
        "email": "pcsk-aws-stage+16934300341878351693430034187815@salesforce.com",
        "name": "pcstest-rnd-0387-pool-1693421616524671656",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "599777547188",
        "arn": "",
        "email": "pcsk-aws-stage+16923850049900161692385004989987@salesforce.com",
        "name": "pcstest-israel-foundation-stride",
        "team_name": "stride",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "036438566272",
        "arn": "",
        "email": "pcsk-aws-stage+16962836730943231696283673094220@salesforce.com",
        "name": "pcstest-nouser-494360ae-foundation-collection",
        "team_name": "collection",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "467832181964",
        "arn": "",
        "email": "pcsk-aws-stage+xvOWkAQTpUU1SVg0kZfhRg7qcvZx2ZPH@salesforce.com",
        "name": "Test epatrice 8 stage",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "075236371977",
        "arn": "",
        "email": "pcsk-aws-stage+16959535339375031695953533937485@salesforce.com",
        "name": "pcstest-nouser-1f542767-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "440341076294",
        "arn": "",
        "email": "pcsk-aws-stage+3HyfEpx5arnPxTVX9cvpHWjFPLzlgJ9r@salesforce.com",
        "name": "pcstest-kurt-iris-foundation-tft",
        "team_name": "terraform",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "699486851117",
        "arn": "",
        "email": "pcsk-aws-stage+G5tCZcbJH1I7SbEURfbTZoIcalY4H8y6@salesforce.com",
        "name": "pcstest-38410fa1-foundation-skywalker",
        "team_name": "",
        "account_substrate": "aws",
        "account_status": ""
    },
    {
        "id": "092386369936",
        "arn": "",
        "email": "pcsk-aws-stage+UWCrwjPged8N3Hct0nlrKmQZZXJ3aQRB@salesforce.com",
        "name": "pcstest-backuptest5-fd2-tgw",
        "team_name": "transit-gateway",
        "account_substrate": "aws",
        "account_status": ""
    }
]