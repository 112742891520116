import { AccessRequest } from '../utils/types/accessRequest'
import { IsMockEnabled } from "../constants/accessRequest";
import { mock_access_requests_response } from '../utils/mocks/accessRequestsResponse'
import {BASE_URL, HandleErrorResponse} from "./common";

interface GetAccessRequestsApiResponse {
    accessRequests: AccessRequest[];
    meta: {
        total: number;
    };
}

export const fetchAccessRequests = async (userEmail: string) => {
    const endpoint = `${BASE_URL}/access-requests?filter%5Buser%5D=${encodeURIComponent(userEmail)}`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching access requests:", error);
        throw error;
    }
};
export const canApproveAccessRequests = async (accessId: string): Promise<boolean> => {
    const endpoint = `${BASE_URL}/access-requests/${accessId}/canApprove`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (response.ok) {
            return true;
        }

        await HandleErrorResponse(response);

    } catch (error) {
        console.error("Error fetching access requests:", error);
    }
    return false;
};

export const getAccessRequestDetails = async (requestId: string) => {
    const endpoint = `${BASE_URL}/access-requests/${requestId}?include=aws_account.id`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching access requests:", error);
        throw error;
    }
};

export const fetchAccountAccessRequests = async (
    account_id: string,
    last_timestamp: number
): Promise<GetAccessRequestsApiResponse> => {
    const response: GetAccessRequestsApiResponse = {
        accessRequests: [],
        meta: {
            total: 0
        }
    };

    if (IsMockEnabled()) {
        const waitForTimeout = (ms: number): Promise<void> => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, ms);
            });
        }
        await waitForTimeout(1000);
        response.accessRequests = mock_access_requests_response;
        response.meta.total = response.accessRequests.length;
        return response;
    }

    const endpoint = `${BASE_URL}/aws-accounts/${account_id}/access-requests?last_timestamp=${last_timestamp}`;
    try {
        const apiResponse = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (!apiResponse.ok) {
            await HandleErrorResponse(response);
        }

        response.accessRequests =  await apiResponse.json();
        response.meta.total = response.accessRequests.length;

        return response;
    } catch (error) {
        console.error("Error fetching account access requests:", error);
        throw error;
    }
};

export const revokeAccessRequests = async (requestId: string) => {
    if (IsMockEnabled()) {
        return {};
    }
    const endpoint = `${BASE_URL}/access-requests/${requestId}/revoke`;

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return response;
    } catch (error) {
        console.error("Error fetching access requests:", error);
        throw error;
    }
};
export const createAccessRequest = async (requestData: any) => {
    const endpoint = `${BASE_URL}/access-requests`;

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            body: JSON.stringify(requestData)
        });
        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching access requests:", error);
        throw error;
    }
};