import { getLoggedInUserEmail } from "../lookup/session";
import { CONSTANT_ACCESS_REQUEST } from "../../constants/accessRequest";

export type AccessRequest = {
    id: string;
    uuid: string;
    sfdc_user: string;
    sfdc_email: string;
    role_name: string;
    reason_bucket: string;
    reason_bucket_label: string;
    reason: string;
    detailed_reason: string;
    aws_account: string;
    aws_account_name: string;
    timestamp: number;
    duration: number;
    approved_at: number;
    approved_by: string;
    approved_by_email: string;
    revoked_at: number;
    revoked_by: string;
    rejected_at: number;
    rejected_by: string;
    expired: boolean;
    expires_at: number;
    status: string;
    name: string;
    account_substrate: string;
    change_case: string;
    incident: string
};

export type ReviewAccessRequest = {
    id: string;
    uuid: string;
    sfdc_user: string;
    sfdc_email: string;
    role_name: string;
    reason_bucket: string;
    reason_bucket_label: string;
    reason: string;
    aws_account: string;
    timestamp: number;
    duration: number;
    approved_at: number;
    approved_by: string;
    approved_by_email: string;
    revoked_at: number;
    revoked_by: string;
    rejected_at: number;
    rejected_by: string;
    expired: boolean;
    expires_at: number;

    calculated_status: string;
    complete_reason: string;
    reviewer: string;
    created_at_date: string;
    expiry_date: string;
    actions: string[];
};


// Access Request Minor Reason Type
export type PCSKAccessRequestMinorReason = {
    status: string;
    message: string;
    data: {
        reason_id: string;
        label: string;
        value: string;
        description: string;
        type: "MINOR";
        parent_reason_id: string;
        action: string;
        note: string;
    };
};

// Access Request Major Reason Type
export type PCSKAccessRequestMajorReason = {
    status: string;
    message: string;
    data: {
        reason_id: string;
        label: string;
        value: string;
        description: string;
        type: "MAJOR";
        parent_reason_id: string | null;
        minor_reasons: PCSKAccessRequestMinorReason[];
    };
};


function getCalculatedStatusFromAccessRequest(accessRequest: AccessRequest): string {
    if (accessRequest.rejected_by) {
        return CONSTANT_ACCESS_REQUEST.CALCULATED_STATUS_REJECTED;
    } else if (accessRequest.expired) {
        return CONSTANT_ACCESS_REQUEST.CALCULATED_STATUS_EXPIRED;
    } else if (accessRequest.approved_by_email.length) {
        return CONSTANT_ACCESS_REQUEST.CALCULATED_STATUS_ACTIVE;
    }
    return CONSTANT_ACCESS_REQUEST.CALCULATED_STATUS_PENDING;
}

export function AccessRequestsToReviewAccessRequest(accessRequests: AccessRequest[]): ReviewAccessRequest[] {
    const reviewAccessRequests: ReviewAccessRequest[] = []

    for (const accessRequest of accessRequests) {
        const reviewAccessRequest: ReviewAccessRequest = {
            id: accessRequest.id,
            uuid: accessRequest.uuid,
            sfdc_user: accessRequest.sfdc_user,
            sfdc_email: accessRequest.sfdc_email,
            role_name: accessRequest.role_name,
            reason_bucket: accessRequest.reason_bucket,
            reason_bucket_label: accessRequest.reason_bucket_label,
            reason: accessRequest.reason,
            aws_account: accessRequest.aws_account,
            timestamp: accessRequest.timestamp,
            duration: accessRequest.duration,
            approved_at: accessRequest.approved_at,
            approved_by: accessRequest.approved_by,
            approved_by_email: accessRequest.approved_by_email,
            revoked_at: accessRequest.revoked_at,
            revoked_by: accessRequest.revoked_by,
            rejected_at: accessRequest.rejected_at,
            rejected_by: accessRequest.rejected_by,
            expired: accessRequest.expired,
            expires_at: accessRequest.expires_at,

            calculated_status: "",
            complete_reason: `${accessRequest.reason_bucket} ${accessRequest.reason}` ,
            created_at_date: new Date(accessRequest.timestamp * 1000).toString(),
            expiry_date: new Date(accessRequest.expires_at * 1000).toString(),
            reviewer: accessRequest.approved_by_email,
            actions: [],
        }

        reviewAccessRequest.calculated_status = getCalculatedStatusFromAccessRequest(accessRequest)

        if (reviewAccessRequest.calculated_status === CONSTANT_ACCESS_REQUEST.CALCULATED_STATUS_REJECTED) {
            reviewAccessRequest.reviewer = reviewAccessRequest.rejected_by
        } else if (reviewAccessRequest.calculated_status === CONSTANT_ACCESS_REQUEST.CALCULATED_STATUS_ACTIVE) {
            reviewAccessRequest.actions.push('revoke')
        } else if (reviewAccessRequest.calculated_status === CONSTANT_ACCESS_REQUEST.CALCULATED_STATUS_PENDING) {
            // self-approval or reject is not possible
            if (getLoggedInUserEmail() !== reviewAccessRequest.sfdc_user.trim()) {
                reviewAccessRequest.actions.push('approve', 'reject')
            } else {
                reviewAccessRequest.actions.push('cancel')
            }
        }
        reviewAccessRequests.push(reviewAccessRequest)
    }

    return reviewAccessRequests;
}