import React from 'react';
import { Card, Icon } from '@salesforce/design-system-react';

interface ComingSoonSummaryCardProps {
    topic: string;
}

const ComingSoonSummaryCard: React.FC<ComingSoonSummaryCardProps> = ({ topic }) => {
    return (
        <>
            <Card
                className="slds-card slds-card_boundary"
                heading={
                    <div className="slds-text-color--weak">
                        {topic}
                    </div>
                }
                icon={
                    <Icon
                        category="standard"
                        name="account"
                        size="small"
                    />
                }
                style={{ minHeight: '15vh' }}
            >
                <div
                    className="slds-text-align_center slds-text-color--inverse-weak">
                    <Icon category="utility" name="clock" size="medium"
                          className="slds-m-bottom_medium slds-m-top--large"/>
                    <p className="slds-text-heading_small">Coming Soon</p>
                </div>
            </Card>
        </>
    );
};

export default ComingSoonSummaryCard;
