import React, { useEffect, useState } from 'react';
import {
    BrandBand,
    IconSettings,
    GlobalNavigationBar,
    GlobalNavigationBarRegion,
    ToastContainer,
} from '@salesforce/design-system-react';
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom';
import standardSprite from '@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg';
import utilitySprite from '@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg';
import actionSprite from '@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg';
import doctypeSprite from '@salesforce-ux/design-system/assets/icons/doctype-sprite/svg/symbols.svg';
import customSprite from '@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg';
import MultiStackToast from "../utils/multiStackToast";
import { MultiStackToastMessage, ToastType } from "../../utils/types/toast";
import AdminAuthenticatingView from "../pcsk_admin/adminAuthenticatingView";
import NotAuthenticatedView from "../pcsk_admin/notAuthenticatedView";
import { useUser } from "../../context/users";
import { getLoggedInUserEmail } from "../../utils/lookup/session";
import PCSKAdminHomePage from "../pcsk_admin/home/Home";
import PCSKAdminGlobalNavBarLink from "../../utils/components/global_nav_bar_link/PCSKAdminGlobalNavBarLink";
import {GET_ROUTE_PATH, URL_PATHS} from "../../constants/pcsk_admin/paths";
import ProfileCircle from "../../utils/components/profile/profile";
import PCSKAdminStackSetDriftPage from "../pcsk_admin/violation/StacksetDrift";
import {UserApiServiceFactory} from "../../services/user/factory";
import PCSKAdminStackSetDeploymentsPage from "../pcsk_admin/deployment/stacksets/StackSetDeployments";
import PCSKAdminSCPDeploymentsPage from "../pcsk_admin/deployment/scp/SCPDeployments";
import PCSKAdminSAMDeploymentsPage from "../pcsk_admin/deployment/sam/SAMDeployments";


const PCSKAdmin: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [toasts, setToasts] = useState<MultiStackToastMessage[]>([]);
    const [isPCSKAdminAccessible, setIsPCSKAdminAccessible] = useState(false);
    const [authenticating, setAuthenticating] = useState(true);
    const {user, setUser } = useUser();
    const [localStorageUser] = useState<string>(getLoggedInUserEmail());

    const addToast = (heading: string, details: string, variant: ToastType) => {
        setToasts([...toasts, { heading: heading, details: details, variant: variant, id: new Date().getTime() }]);
    };
    const removeToast = (id: number) => {
        setToasts(toasts.filter(toast => toast.id !== id));
    };

    const userAPIService = UserApiServiceFactory.getService()

    useEffect(() => {
        (async () => {
            try {
                setAuthenticating(true);
                const userInfo = await userAPIService.fetchUserMetadata(localStorageUser);
                setUser(userInfo);
                setIsPCSKAdminAccessible(userInfo.allowed_jit_admin_access === "true")
            } catch (error) {
                console.error("Error fetching user data:", error);
                setIsPCSKAdminAccessible(false);
            } finally {
                setAuthenticating(false);
            }
        })();
    }, [localStorageUser, setUser]);

    const getAuthenticatedStatus = () => {
        const authStatus = localStorage.getItem("authenticated");
        return authStatus === "true";
    }

    // Navigate to login page when existing token expires
    useEffect(() => {
        if (!getAuthenticatedStatus()) {
            navigate("/login");
        }
    }, [getAuthenticatedStatus]);

    const violationsNavigationBar = (
        <GlobalNavigationBar>
            <GlobalNavigationBarRegion region="secondary">
                <PCSKAdminGlobalNavBarLink
                    label="Stacksets Drift"
                    active={() => {
                        return windowPathNameIncludes(URL_PATHS.PCSK_ADMIN_STACKSETS_DRIFTS_PAGE) ||
                            windowPathNameEquals(URL_PATHS.PCSK_ADMIN_VIOLATIONS_PAGE)
                    }}
                    onClick={() => navigate(URL_PATHS.PCSK_ADMIN_STACKSETS_DRIFTS_PAGE)}
                    activeIcon="chevrondown" nonActiveIcon="chevronright"
                />
            </GlobalNavigationBarRegion>
        </GlobalNavigationBar>
    )

    const deploymentsNavigationBar = (
        <GlobalNavigationBar>
            <GlobalNavigationBarRegion region="secondary">
                <PCSKAdminGlobalNavBarLink
                    label="Stacksets"
                    active={() => {
                        return windowPathNameIncludes(URL_PATHS.PCSK_ADMIN_STACKSETS_DEPLOYMENTS_PAGE) ||
                            windowPathNameEquals(URL_PATHS.PCSK_ADMIN_DEPLOYMENTS_PAGE)
                    }}
                    onClick={() => navigate(URL_PATHS.PCSK_ADMIN_STACKSETS_DEPLOYMENTS_PAGE)}
                />
                <PCSKAdminGlobalNavBarLink
                    label="SCP"
                    active={() => {
                        return windowPathNameIncludes(URL_PATHS.PCSK_ADMIN_SCP_DEPLOYMENTS_PAGE)
                    }}
                    onClick={() => navigate(URL_PATHS.PCSK_ADMIN_SCP_DEPLOYMENTS_PAGE)}
                />
                <PCSKAdminGlobalNavBarLink
                    label="SAM"
                    active={() => {
                        return windowPathNameIncludes(URL_PATHS.PCSK_ADMIN_SAM_DEPLOYMENTS_PAGE)
                    }}
                    onClick={() => navigate(URL_PATHS.PCSK_ADMIN_SAM_DEPLOYMENTS_PAGE)}
                />
            </GlobalNavigationBarRegion>
        </GlobalNavigationBar>
    )

    const windowPathNameEquals = (name: string) => {
        return location.pathname === name || location.pathname === `${name}/`
    }
    const windowPathNameIncludes = (name: string) => {
        return location.pathname.includes(name)
    }
    const authorisedPageView = (
        <IconSettings
            standardSprite={standardSprite}
            utilitySprite={utilitySprite}
            actionSprite={actionSprite}
            doctypeSprite={doctypeSprite}
            customSprite={customSprite}
        >
            <BrandBand className="custom-brand-band-class" id="brand-band-large" size="large">
                <GlobalNavigationBar>
                    <GlobalNavigationBarRegion region="primary" className='slds-text-title_bold'>
                        <a href={window.location.origin}>
                            <div className='slds-m-top--xxx-small'>
                                <img
                                    src={process.env.PUBLIC_URL + '/salesforce-logo.jpg'}
                                    alt="Salesforce Logo"
                                    style={{ width: '60px', height: '32px' }}
                                />
                            </div>
                        </a>
                        <div className='slds-text-color_success slds-m-top--x-small slds-m-right--medium'>
                            PCSK Admin
                        </div>
                    </GlobalNavigationBarRegion>
                    <GlobalNavigationBarRegion region="secondary">
                        <PCSKAdminGlobalNavBarLink label="Home"
                                                   active={() => { return windowPathNameEquals(URL_PATHS.PCSK_ADMIN_HOME_PAGE) }}
                                                   onClick={() => {navigate(URL_PATHS.PCSK_ADMIN_HOME_PAGE)}}
                        />
                        <PCSKAdminGlobalNavBarLink label="Deployments"
                                                   active={() => { return windowPathNameIncludes(URL_PATHS.PCSK_ADMIN_DEPLOYMENTS_PAGE) }}
                                                   onClick={() => navigate(URL_PATHS.PCSK_ADMIN_DEPLOYMENTS_PAGE)}
                        />
                        <PCSKAdminGlobalNavBarLink label="Violations"
                                                   active={() => { return windowPathNameIncludes(URL_PATHS.PCSK_ADMIN_VIOLATIONS_PAGE) }}
                                                   onClick={() => navigate(URL_PATHS.PCSK_ADMIN_VIOLATIONS_PAGE)}
                        />
                    </GlobalNavigationBarRegion>
                    <GlobalNavigationBarRegion region="tertiary">
                        <div className='slds-p-right--medium slds-p-top_xxx-small slds-grid'>
                            <a href={window.location.origin} className='slds-m-top--x-small slds-m-right--medium'>
                                <div className='slds-text-title_bold'>
                                    PCSK JIT
                                </div>
                            </a>
                            <ProfileCircle/>
                        </div>
                    </GlobalNavigationBarRegion>
                </GlobalNavigationBar>

                <Routes>
                    {/* Base Path */}
                    <Route path="/" element={<PCSKAdminHomePage addToast={addToast}/>}/>

                    {/* Violations Paths */}
                    <Route path={GET_ROUTE_PATH(URL_PATHS.PCSK_ADMIN_VIOLATIONS_PAGE)}
                           element={<PCSKAdminStackSetDriftPage addToast={addToast} navigationBar={violationsNavigationBar}/>}/>
                    <Route path={GET_ROUTE_PATH(URL_PATHS.PCSK_ADMIN_STACKSETS_DRIFTS_PAGE)}
                           element={<PCSKAdminStackSetDriftPage addToast={addToast} navigationBar={violationsNavigationBar}/>}/>

                    {/*Deployments Page*/}
                    <Route path={GET_ROUTE_PATH(URL_PATHS.PCSK_ADMIN_DEPLOYMENTS_PAGE)}
                           element={<PCSKAdminStackSetDeploymentsPage addToast={addToast} navigationBar={deploymentsNavigationBar}/>}/>
                    <Route path={GET_ROUTE_PATH(URL_PATHS.PCSK_ADMIN_STACKSETS_DEPLOYMENTS_PAGE)}
                           element={<PCSKAdminStackSetDeploymentsPage addToast={addToast} navigationBar={deploymentsNavigationBar}/>}/>
                    <Route path={GET_ROUTE_PATH(URL_PATHS.PCSK_ADMIN_SCP_DEPLOYMENTS_PAGE)}
                           element={<PCSKAdminSCPDeploymentsPage addToast={addToast} navigationBar={deploymentsNavigationBar}/>}/>
                    <Route path={GET_ROUTE_PATH(URL_PATHS.PCSK_ADMIN_SAM_DEPLOYMENTS_PAGE)}
                           element={<PCSKAdminSAMDeploymentsPage addToast={addToast} navigationBar={deploymentsNavigationBar}/>}/>

                </Routes>
            </BrandBand>
            <ToastContainer>
                <MultiStackToast toasts={toasts} removeToast={removeToast} />
            </ToastContainer>
        </IconSettings>
    );

    return authenticating ? <AdminAuthenticatingView/> : !isPCSKAdminAccessible ? <NotAuthenticatedView/> : authorisedPageView
};

export default PCSKAdmin;
