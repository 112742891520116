import React, { useState, useEffect } from "react";
import {Button, Card, Combobox, comboboxFilterAndLimit, Input} from "@salesforce/design-system-react";
import { CONSTANT_ACCESS_REQUEST } from "../../../../constants/accessRequest";
import { ToastState } from "../../../../utils/types/toast";
import {createNewAccountUsingVending} from "../../../../services/accountVending";
import {CONSTANT_ACCOUNT} from "../../../../constants/accountManagement";
import {useAccountCreationStatus} from "../../../../context/account_vending/AccountCreationStatus";
import {HandleError} from "../../../utils/common";
import {TOAST_MESSAGE} from "../../../../constants/toastMessages";

type AccountCreationFormProps = {
    isActive: boolean;
    toast: ToastState;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
};

type MPAInfo = {
    value: string;
    label: string;
    id: string;
    isFalcon: boolean;
};

const AccountCreation: React.FC<AccountCreationFormProps> = ({ isActive, toast, setToast }) => {
    const [accountName, setAccountName] = useState("");
    const [serviceTeamName, setServiceTeamName] = useState("");
    const [mpaID, setMpaID] = useState("");
    const [requesters, setRequesters] = useState("");
    const [gusProductTag, setGusProductTag] = useState("");
    const [ouArn] = useState("root");
    const [mpaAccountIDs, setMpaAccountIDs] = useState<MPAInfo[]>(getMPAAccounts());
    const [selectedMPA, setSelectedMPA] = useState<any>([]);
    const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);
    const [accountRequesterErrorMessage, setAccountRequesterErrorMessage] = useState("");
    const [gusProductTagErrorMessage, setGUSProductTagErrorMessage] = useState("");

    const {setAccountCreationStatus} = useAccountCreationStatus()

    const clearSelections = () => {
        setAccountName("");
        setServiceTeamName("");
        setMpaID("");
        setRequesters("");
        setGusProductTag("");
        setSelectedMPA([]);
        setMpaAccountIDs(getMPAAccounts());
    };

    useEffect(() => {
        if (isActive) {
            clearSelections();
        }
    }, [isActive]);

    useEffect(() => {
        setIsCreateButtonDisabled(!validateAccountInfo());
    }, [accountName, serviceTeamName, mpaID, requesters, gusProductTag, ouArn]);

    const validateEmails = (input: string) => {
        if (input.length > 0) {
            const emailsArray = input.split(",").map((email) => email.trim());
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            for (let i = 0; i < emailsArray.length; i++) {
                const email = emailsArray[i];

                if (!emailRegex.test(email)) {
                    setAccountRequesterErrorMessage("Please enter a valid email address");
                    return false;
                }
            }
        }
        setAccountRequesterErrorMessage("");
        return true;
    };

    const validateGUSProductTag = (input: string) => {
        if (input.length === 0 || input.length === 18) {
            setGUSProductTagErrorMessage("");
            return true;
        }
        setGUSProductTagErrorMessage("Please enter a valid GUS Product Tag");
        return false;
    };

    const validateAccountInfo = () => {
        const hasAccountName = accountName !== "";
        const hasServiceTeamName = serviceTeamName !== "";
        const hasMPAID = mpaID !== "";
        const hasAccountRequestors = requesters !== "" && validateEmails(requesters);
        const hasGUSProductTag = gusProductTag !== "" && validateGUSProductTag(gusProductTag);

        return !(!hasAccountName || !hasServiceTeamName || !hasMPAID || !hasAccountRequestors || !hasGUSProductTag);
    };

    const handleAccountNameUpdate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAccountName(event.target.value);
    };

    const handleServiceTeamNameUpdate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setServiceTeamName(event.target.value);
    };

    const handleAccountRequesterUpdate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = event.target.value;
        setRequesters(inputValue);
        validateEmails(inputValue);
    };

    const handleGusProductTagUpdate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = event.target.value;
        setGusProductTag(event.target.value);
        validateGUSProductTag(inputValue);
    };

    const handleCreateAccountRequest = async () => {
        setToast({
            isVisible: true,
            message: {
                heading: "Account Creation requested, please wait.",
            },
            variant: "warning",
        });
        const payload = {
            account_name: accountName,
            team_name: serviceTeamName,
            organization_account_id: mpaID,
            account_type: "default-non-falcon-profile",
            account_owners: requesters,
            gus_product_tag: gusProductTag,
            organization_unit_arn: ouArn,
        }
        createNewAccountUsingVending(payload).then(data => {
            clearSelections();
            setAccountCreationStatus({
                crt_id: data.client_request_token,
                status: CONSTANT_ACCOUNT.PROCESSING,
                account_id: "",
                error_status: ""
            });
            navigator.clipboard.writeText(data.client_request_token);
            setToast({
                isVisible: true,
                message: {
                    heading: "Account Creation in process, please wait.",
                    details: `Token id ${data.client_request_token} is copied to clipboard, please use it to check the status later`
                },
                variant: "success",
            });
        }).catch(error => {
            const toastSetter = {
                setToast: setToast
            };
            HandleError(toastSetter, error, TOAST_MESSAGE.ERROR_ACCOUNT_VENDING_CREATION);
        })

    };

    return (
        <Card heading={CONSTANT_ACCOUNT.NEW_ACCOUNT_REQUEST}>
            <div className="slds-p-around_small">
                <div className="slds-p-around--xx-small">
                    <Input
                        id="account_vending_account_name"
                        label={CONSTANT_ACCOUNT.ACCOUNT_NAME}
                        required
                        value={accountName}
                        onChange={handleAccountNameUpdate}
                        placeholder="Enter Account Name"
                    />
                </div>
                <div className="slds-p-around--xx-small">
                    <Input
                        id="account_vending_service_team"
                        label={CONSTANT_ACCOUNT.SERVICE_TEAM}
                        required
                        value={serviceTeamName}
                        onChange={handleServiceTeamNameUpdate}
                        placeholder="Enter Service Team Name"
                    />
                </div>
                <div className="slds-p-around--xx-small">
                    <Combobox
                        id="account_vending_mpa"
                        events={{
                            onSelect: (event: any, data: any) => {
                                setSelectedMPA(data.selection);
                                setMpaID(data.selection[0].value);
                            },
                        }}
                        labels={{
                            label: CONSTANT_ACCOUNT.MPA_NAME,
                            placeholder: CONSTANT_ACCOUNT.MPA_NAME_PLACEHOLDER,
                        }}
                        required
                        menuPosition="absolute"
                        options={comboboxFilterAndLimit({
                            options: mpaAccountIDs,
                            selection: selectedMPA,
                            limit: 10000
                        })}
                        selection={selectedMPA}
                        variant="readonly"
                    />
                </div>
                <div className="slds-p-around--xx-small">
                    <Input
                        id="account_vending_requestors"
                        label="Account Requestor(s)"
                        value={requesters}
                        onChange={handleAccountRequesterUpdate}
                        required
                        errorText={accountRequesterErrorMessage}
                        placeholder="List of account owners"
                    />
                </div>
                <div className="slds-p-around--xx-small">
                    <Input
                        id="account_vending_gus_p_tag"
                        label={CONSTANT_ACCOUNT.GUS_PRODUCT_TAG}
                        value={gusProductTag}
                        onChange={handleGusProductTagUpdate}
                        errorText={gusProductTagErrorMessage}
                        required
                        placeholder="Enter GUS Product Tag"
                    />
                </div>
                <div className="slds-p-around--xx-small">
                    <Input id="account_vending_ou_arn" label={CONSTANT_ACCOUNT.OU_ARN} required disabled placeholder={CONSTANT_ACCOUNT.OU_ARN_PLACEHOLDER} value={ouArn} />
                </div>
                <div className="slds-p-left--xx-small slds-p-top--medium">
                    <Button
                        key="createAccessRequestButton"
                        label={CONSTANT_ACCESS_REQUEST.CREATE_BUTTON}
                        variant="brand"
                        onClick={handleCreateAccountRequest}
                        disabled={isCreateButtonDisabled}
                    />
                </div>
            </div>
        </Card>
    );
};

export default AccountCreation;

function getMPAAccounts() {
    const ssmMPAMetadata = `${process.env.REACT_APP_PCSK_ONBOARDED_MPA_METADATA}`;
    const MPAList = JSON.parse(ssmMPAMetadata);
    const filteredAccounts = MPAList.filter((account: { isFalcon: any; }) => !account.isFalcon);

    return filteredAccounts.map((account: { id: any; label: any; value: any, isFalcon: any; }) => ({
        value: account.id,
        label: `${account.id} - ${account.label} - ${account.value}`,
        id: account.id,
        isFalcon: account.isFalcon,
    }));
}