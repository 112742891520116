export const URL_PATHS = {
    // Home
    PCSK_ADMIN_HOME_PAGE: '/pcsk-admin',
    // Violations
    PCSK_ADMIN_VIOLATIONS_PAGE: '/pcsk-admin/violations',
    PCSK_ADMIN_STACKSETS_DRIFTS_PAGE: '/pcsk-admin/violations/stacksets-drift',
    // Deployments
    PCSK_ADMIN_DEPLOYMENTS_PAGE: '/pcsk-admin/deployments',
    PCSK_ADMIN_STACKSETS_DEPLOYMENTS_PAGE: '/pcsk-admin/deployments/stacksets',
    PCSK_ADMIN_SCP_DEPLOYMENTS_PAGE: '/pcsk-admin/deployments/scp',
    PCSK_ADMIN_SAM_DEPLOYMENTS_PAGE: '/pcsk-admin/deployments/sam',
}

export function GET_ROUTE_PATH (path: string) {
    if (path.startsWith(URL_PATHS.PCSK_ADMIN_HOME_PAGE)) {
        return path.slice(URL_PATHS.PCSK_ADMIN_HOME_PAGE.length);
    }
    return path;
}