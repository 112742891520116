import {StackSetStagger} from "./stagger";
import {timestampToDate} from "./deployment";


export class StackSetOperation {
    id: string;
    created_at: number;
    stackset_status: string;
    operation_type: string;
    stackset_id: string;
    updated_at: number;
    staggers: StackSetStagger[];

    /**
     * Creates a new instance of StackSetOperation.
     * @param {string} operation_id - Unique identifier for the operation.
     * @param {number} created_at - Timestamp of creation.
     * @param {string} operation_status - Status of the operation.
     * @param {string} operation_type - Type of operation.
     * @param {string} stagger_group_name - Name of the stagger group.
     * @param {number} [updated_at] - Timestamp of the last update.
     * @param {StackSetStagger[]} [staggers] - Staggers for this operation
     */
    constructor(
        operation_id: string,
        created_at: number,
        operation_status: string,
        operation_type: string,
        stagger_group_name: string,
        updated_at: number,
        staggers: StackSetStagger[]
    ) {
        this.id = operation_id;
        this.created_at = created_at;
        this.stackset_status = operation_status;
        this.operation_type = operation_type;
        this.stackset_id = stagger_group_name;
        this.updated_at = updated_at;
        this.staggers = staggers;
    }

    /**
     * Updates the operation status and updated_at timestamp.
     * @param {string} newStatus - The new operation status.
     */
    updateStatus(newStatus: string): void {
        if (!newStatus) {
            throw new Error("A new status must be provided.");
        }
        this.stackset_status = newStatus;
        this.updated_at = Date.now();
    }

    /**
     * Converts the created_at timestamp to a human-readable date string.
     * @returns {string} - Human-readable date string for created_at.
     */
    getCreatedAtDate(): string {
        return timestampToDate(this.created_at);
    }

    /**
     * Converts the updated_at timestamp to a human-readable date string.
     * @returns {string} - Human-readable date string for updated_at.
     */
    getUpdatedAtDate(): string {
        return timestampToDate(this.updated_at);
    }

    /**
     * Creates an instance of StackSetOperation from a plain object.
     * @param {object} obj - The object containing StackSetOperation data.
     * @returns {StackSetOperation} - A new instance of StackSetOperation.
     */
    static fromObject(obj: Record<string, unknown>): StackSetOperation {
        if (!obj || typeof obj !== 'object') {
            throw new Error("Invalid object provided.");
        }

        const {
            operation_id,
            created_at,
            operation_status,
            operation_type,
            stagger_group_name,
            updated_at,
            staggers,
        } = obj as {
            operation_id: string;
            created_at: number;
            operation_status: string;
            operation_type: string;
            stagger_group_name: string;
            updated_at: number;
            staggers: StackSetStagger[];
        };

        return new StackSetOperation(
            operation_id,
            created_at,
            operation_status,
            operation_type,
            stagger_group_name,
            updated_at,
            staggers
        );
    }
}