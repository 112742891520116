import React, { useState, useEffect } from "react";
import {
    Button,
    Combobox,
    comboboxFilterAndLimit,
    Input,
} from "@salesforce/design-system-react";
import {fetchMyTeams, updateServiceTeam} from "../../../../services/teams";
import {ToastState} from "../../../../utils/types/toast";
import {Account} from "../../../../utils/types/account";
import {fetchOwnedAccounts} from "../../../../services/accounts";
import {CONSTANT_TEAMS} from "../../../../constants/teams";
import {CONSTANT_ACCESS_REQUEST} from "../../../../constants/accessRequest";
import {HandleError} from "../../../utils/common";
import {TOAST_MESSAGE} from "../../../../constants/toastMessages";

type UpdateServiceTeamProps = {
    isActive: boolean;
    isActiveSection: boolean;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
};

type ServiceTeam = {
    id: string;
    name: string;
    value: string;
    label: string;
    slack_channel: string;
    members: string[];
}

const UpdateServiceTeam: React.FC<UpdateServiceTeamProps> = ({ isActive, isActiveSection, setToast}) => {
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [inputAccountValue, setInputAccountValue] = useState<string>("");
    const [serviceTeams, setServiceTeams] = useState<ServiceTeam[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<Account[]>([]);
    const [selectedServiceTeam, setSelectedServiceTeam] = useState<ServiceTeam[]>([]);
    const [currentServiceTeam, setCurrentServiceTeam] = useState<any>("");
    const [isNewServiceTeamDisabled, setIsNewServiceTeamDisabled] = useState(true);
    const [isAccountListLoading, setIsAccountListLoading] = useState<boolean>(false);
    const [isTeamListLoading, setIsTeamListLoading] = useState<boolean>(false);
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);

    const clearSelections = () => {
        setSelectedAccount([]);
        setSelectedServiceTeam([]);
        setCurrentServiceTeam([]);
        setIsNewServiceTeamDisabled(true);
    };

    useEffect(() => {
        if (isActive && isActiveSection) {
            clearSelections();
            fetchMyAccounts();
            fetchServiceTeams();
        }
    }, [isActive, isActiveSection]);

    useEffect(() => {
        setIsUpdateButtonDisabled(!validateAccountInfo());
    }, [selectedAccount, selectedServiceTeam]);

    const fetchMyAccounts = () => {
        setIsAccountListLoading(true)
        fetchOwnedAccounts().then((data) => {
            const transformedData = data.map((account: Account) => ({
                id: account.id,
                label: account.id + "(" + account.name + ") ( " + account.email + " )",
                value: account.id,
                account_substrate: account.account_substrate,
                account_status: account.account_status,
                team_name: account.team_name,
            })).sort((a: any, b: any) => {
                if (isNaN(a.id) || isNaN(b.id)) {
                    return a.id.localeCompare(b.id);
                }
                return parseInt(a.id) - parseInt(b.id); // compare as numbers
            });
            setAccounts(transformedData);
        }).catch((error) => {
            const toastSetter = {
                setToast: setToast
            };
            HandleError(toastSetter, error, TOAST_MESSAGE.ERROR_FETCHING_MY_ACCOUNTS);
        }).finally(() => {
            setIsAccountListLoading(false)
        })
    }

    const fetchServiceTeams = () => {
        setIsTeamListLoading(true)
        fetchMyTeams().then((data) => {
            const transformedData = data.map((serviceTeam: ServiceTeam) => ({
                id: serviceTeam.name,
                label: serviceTeam.name,
                value: serviceTeam.name,
                slack_channel: serviceTeam.slack_channel,
                members: serviceTeam.members
            }));
            setServiceTeams(transformedData);
        }).catch((error) => {
            const toastSetter = {
                setToast: setToast
            };
            HandleError(toastSetter, error, TOAST_MESSAGE.ERROR_UPDATING_SERVICE_TEAM);
        }).finally(() => {
            setIsTeamListLoading(false)
        })
    }

    const validateAccountInfo = () => {
        const hasTeamName = selectedAccount.length !== 0;
        const hasNewServiceTeam = selectedServiceTeam.length !== 0;

        return !isNewServiceTeamDisabled && hasTeamName && hasNewServiceTeam;
    };

    const handleUpdateSlackChannelRequest = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setToast({
            isVisible: true,
            message: {
                details: "Updated Service Team requested",
                heading: "In Process",
            },
            variant: "warning"
        });
        const payload = {
            aws_account_id: selectedAccount[0].id,
            team_name: selectedServiceTeam[0].value,
        }
        updateServiceTeam(payload).then((response) => {
            setToast({
                isVisible: true,
                message: {
                    details: "Updated Service Team successfully",
                    heading: "Success",
                },
                variant: "success"
            });
            // Refresh team values
            fetchMyAccounts();
            fetchServiceTeams();
        }).catch((err) => {
            setToast({
                isVisible: true,
                message: {
                    details: "Error updating service team",
                    heading: "Error",
                },
                variant: "error"
            });
        })
    };
    return (
        <div className="slds-p-around_small">
            <div className="slds-p-around--xx-small">
                <Combobox
                    id="update_service_team_accounts"
                    events={{
                        onSelect: (event: any, data: any) => {
                            setSelectedAccount(data.selection);
                            setCurrentServiceTeam(data.selection[0].team_name);
                            setIsNewServiceTeamDisabled(false);
                            setSelectedServiceTeam([]);
                            setInputAccountValue("");
                        },
                        onChange: (event: any, data: any) => {
                            setInputAccountValue(data.value);
                        },
                        onRequestRemoveSelectedOption: (event: any, data: any) => {
                            setInputAccountValue("");
                            setSelectedAccount([]);
                        },
                    }}
                    labels={{
                        label: CONSTANT_TEAMS.ACCOUNT,
                        placeholder: CONSTANT_TEAMS.ACCOUNT_PLACEHOLDER,
                        noOptionsFound: isAccountListLoading ? "" : "No Matches Found."
                    }}
                    required
                    menuPosition="absolute"
                    options={comboboxFilterAndLimit({
                        inputValue: inputAccountValue,
                        options: accounts,
                        selection: selectedAccount,
                        limit: 10000,
                    })}
                    value={inputAccountValue}
                    selection={selectedAccount}
                    variant="inline-listbox"
                    menuItemVisibleLength={10}
                    hasMenuSpinner={isAccountListLoading}
                />


            </div>
            <div className="slds-p-around--xx-small">
                <Input
                    id="update_service_team_current_value"
                    label={CONSTANT_TEAMS.CURRENT_SERVICE_TEAM}
                    required
                    disabled={true}
                    value={currentServiceTeam}
                    placeholder={CONSTANT_TEAMS.CURRENT_SERVICE_TEAM_PALCEHOLDER}
                />
            </div>
            <div className="slds-p-around--xx-small">
                <Combobox
                    id="update_service_team_new_value"
                    events={{
                        onSelect: (event: any, data: any) => {
                            setSelectedServiceTeam(data.selection);
                        },
                    }}
                    labels={{
                        label: CONSTANT_TEAMS.NEW_SERVICE_TEAM,
                        placeholder: CONSTANT_TEAMS.NEW_SERVICE_TEAM_PLACEHOLDER,
                        noOptionsFound: isTeamListLoading ? "" : "No Matches Found."
                    }}
                    required
                    menuPosition="absolute"
                    options={comboboxFilterAndLimit({
                        inputValue: "",
                        options: serviceTeams,
                        selection: selectedServiceTeam,
                        limit: 10000,
                    })}
                    singleInputDisabled={isNewServiceTeamDisabled}
                    selection={selectedServiceTeam}
                    variant="readonly"
                    hasMenuSpinner={isTeamListLoading}
                />
            </div>
            <div className="slds-p-left--xx-small slds-p-top--medium">
                <Button
                    key="updateSlackChannelRequest"
                    label={CONSTANT_TEAMS.UPDATE_BUTTON}
                    variant="brand"
                    onClick={handleUpdateSlackChannelRequest}
                    disabled={isUpdateButtonDisabled}
                />
            </div>
            <div className="slds-p-left--xx-small slds-p-top--medium className='slds-var-m-around_xxx-small slds-color__text_gray-8">
                <b>{CONSTANT_ACCESS_REQUEST.NOTE_LABEL} </b>
                Account approvers/teams are managed in IAM portal. See <a href="https://confluence.internal.salesforce.com/display/FALCON/PCSK+FAQ" target="_blank" rel="noopener noreferrer">here</a>.
            </div>
        </div>
    );

};

export default UpdateServiceTeam;