import {BASE_URL, HandleErrorResponse} from "../common";
import {
    AccountStackSetDriftInfo,
    DriftDetails,
    DriftedAccountRequest,
    StackSetsDriftSummary
} from "../../utils/types/stackSetDrift";
import {DriftDetectionApiService} from "./service"
import StacksetDriftSummary from "../../components/pcsk_admin/home/StacksetDriftSummary";
import {SCPDriftSummary} from "../../utils/types/scpDrift";

export class MainDriftDetectionApiService implements DriftDetectionApiService {
    readonly basePath = `${BASE_URL}/stackset-drift`;

    async getStackSetDrift(filter: DriftedAccountRequest): Promise<AccountStackSetDriftInfo[]> {
        try {
            const finalURI = `${this.basePath}?account_id=${filter.account_id}&stackset_name=${filter.stackset_name}&from_time=${Math.round(filter.from_time.getTime() / 1000)}&to_time=${Math.round(filter.to_time.getTime() / 1000)}`;
            const response = await fetch(finalURI, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const jsonArray: any[] = await response.json();
            return jsonArray.map((item, index) => {
                return asPCSKDriftDetectionItem(item, index + 1);
            });

        } catch (error) {
            console.error("Error fetching stack set drift:", error);
            throw error;
        }
    }

    async getStackSetDriftSummary(): Promise<StackSetsDriftSummary[]> {
        try {
            const finalURI = `${this.basePath}/summary`;
            const response = await fetch(finalURI, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const jsonArray: any[] = await response.json();
            return jsonArray.map((item) => {
                return asStackSetsDriftSummary(item);
            });

        } catch (error) {
            console.error("Error fetching stack set drift:", error);
            throw error;
        }
    }
    async getSCPDriftSummary(): Promise<SCPDriftSummary[]> {
        try {
            const finalURI = `${this.basePath}/summary`;
            const response = await fetch(finalURI, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const jsonArray: any[] = await response.json();
            return jsonArray.map((item) => {
                return asSCPDriftSummary(item);
            });

        } catch (error) {
            console.error("Error fetching stack set drift:", error);
            throw error;
        }
    }
}

const asSCPDriftSummary = (response: any): StackSetsDriftSummary => {
    const count = response?.count ?? 0;
    const status = response?.status ?? `Unknown`;
    return new SCPDriftSummary(status, count)
}


const asStackSetsDriftSummary = (response: any): StackSetsDriftSummary => {
    const count = response?.count ?? 0;
    const status = response?.status ?? `Unknown`;
    return new StackSetsDriftSummary(status, count)
}

const asPCSKDriftDetectionItem = (response: any, index = 1): AccountStackSetDriftInfo => {
    let drift_details: DriftDetails[] = [];
    if (response.drift_details) {
        drift_details = JSON.parse(response.drift_details).map((detail: any) => {
            return {
                LogicalResourceId: detail.LogicalResourceId,
                ResourceType: detail.ResourceType,
                StackId: detail.StackId,
                PhysicalResourceId: detail.PhysicalResourceId,
                StackResourceDriftStatus: detail.StackResourceDriftStatus,
                PropertyDifferences: detail.PropertyDifferences.map((diff: any) => {
                    return {
                        PropertyPath: diff.PropertyPath,
                        ExpectedValue: diff.ExpectedValue,
                        ActualValue: diff.ActualValue,
                        DifferenceType: diff.DifferenceType
                    };
                })
            };
        });
    }

    return {
        index: index,
        account_id: response.account_id,
        stackset_name: response.stackset_name,
        drift_status: response.drift_status,
        false_positive: response.false_positive,
        status_reason: response.status_reason,
        instance_status: response.instance_status,
        drift_check_timestamp: response.drift_check_time,
        drift_details: drift_details
    }
};