import {BASE_URL, HandleErrorResponse} from "./common";

export const fetchMyAccounts = async () => {
    const endpoint = `${BASE_URL}/aws-accounts?filter%5Bowner_only%5D=true`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}
