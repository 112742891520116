import {BASE_URL, HandleErrorResponse} from "./common";

export const fetchReasons = async () => {
    const endpoint = `${BASE_URL}/reasons`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching reason data:", error);
        throw error;
    }
}