import {BASE_URL, HandleErrorResponse} from "./common";

export const fetchMyTeams = async () => {
    const endpoint = `${BASE_URL}/teams?owner_only=true`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}

export const updateSlackChannel = async (requestData: any) => {
    const endpoint = `${BASE_URL}/teams`;

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            body: JSON.stringify(requestData)
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return response.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}

export const updateServiceTeam = async (requestData: any) => {
    const endpoint = `${BASE_URL}/update-accounts-teams`;

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            body: JSON.stringify(requestData)
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return response.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}
