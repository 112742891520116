import React, { useState, useEffect } from "react";
import {Button, Combobox, comboboxFilterAndLimit, Input, InputIcon} from "@salesforce/design-system-react";
import {fetchMyTeams, updateSlackChannel} from "../../../../services/teams";
import {ToastState} from "../../../../utils/types/toast";
import {CONSTANT_TEAMS} from "../../../../constants/teams";
import {HandleError} from "../../../utils/common";
import {TOAST_MESSAGE} from "../../../../constants/toastMessages";

type UpdateSlackChannelProps = {
    isActive: boolean;
    isActiveSection: boolean;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
};

type ServiceTeam = {
    id: string;
    name: string;
    value: string;
    label: string;
    slack_channel: string;
    members: string[];
}

const UpdateSlackChannel: React.FC<UpdateSlackChannelProps> = ({ isActive, isActiveSection, setToast}) => {
    const [serviceTeams, setServiceTeams] = useState<ServiceTeam[]>([]);
    const [selectedTeamName, setSelectedTeamName] = useState<ServiceTeam[]>([]);
    const [selectedSlackChannelName, setSelectedSlackChannelName] = useState<any>([]);
    const [isSlackChannelDisabled, setIsSlackChannelDisabled] = useState(true);
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);
    const [isTeamListLoading, setIsTeamListLoading] = useState<boolean>(false);

    const clearSelections = () => {
        setSelectedTeamName([]);
        setSelectedSlackChannelName("");
        setIsSlackChannelDisabled(true);
    };

    useEffect(() => {
        if (isActive && isActiveSection) {
            clearSelections();
            fetchServiceTeams();
        }
    }, [isActive, isActiveSection]);

    useEffect(() => {
        setIsUpdateButtonDisabled(!validateAccountInfo());
    }, [selectedTeamName, selectedSlackChannelName, isSlackChannelDisabled]);

    const fetchServiceTeams = () => {
        setIsTeamListLoading(true)
        fetchMyTeams().then((data) => {
            const transformedData = data.map((serviceTeam: ServiceTeam) => ({
                id: serviceTeam.name,
                label: serviceTeam.name,
                value: serviceTeam.name,
                slack_channel: serviceTeam.slack_channel,
                members: serviceTeam.members
            }));
            setServiceTeams(transformedData);
        }).catch((error) => {
            const toastSetter = {
                setToast: setToast
            };
            HandleError(toastSetter, error, TOAST_MESSAGE.ERROR_UPDATING_SERVICE_TEAM);
        }).finally(() => {
            setIsTeamListLoading(false)
        })
    }

    const validateAccountInfo = () => {
        const hasTeamName = selectedTeamName.length !== 0;
        const hasSlackChannelName = selectedSlackChannelName !== "";

        return !isSlackChannelDisabled && hasTeamName && hasSlackChannelName;
    };

    const handleSlackChannelNameUpdate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSelectedSlackChannelName(event.target.value);
    };

    const handleUpdateSlackChannelRequest = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setToast({
            isVisible: true,
            message: {
                details: "Updated Slack Channel requested",
                heading: "In Process",
            },
            variant: "warning"
        });
        const payload = {
            name: selectedTeamName[0].value,
            slack_channel: selectedSlackChannelName,
        }
        updateSlackChannel(payload).then((response) => {
            setToast({
                isVisible: true,
                message: {
                    details: "Updated Slack Channel successfully",
                    heading: "Success",
                },
                variant: "success"
            });
            // Refresh team values
            fetchServiceTeams();
        }).catch((err) => {
            const toastSetter = {
                setToast: setToast
            };
            HandleError(toastSetter, err, TOAST_MESSAGE.ERROR_UPDATING_SLACK_CHANNEL);
        }).finally(() => {
        })
    };

    return (
        <div className="slds-p-around_small">
            <div className="slds-p-around--xx-small">
                <Combobox
                    id="update_slack_channel_teams"
                    events={{
                        onSelect: (event:any, data:any) => {
                            setSelectedTeamName(data.selection)
                            setSelectedSlackChannelName(data.selection[0].slack_channel);
                            setIsSlackChannelDisabled(true);
                        }
                    }}
                    labels={{
                        label: CONSTANT_TEAMS.SERVICE_TEAM,
                        placeholder: CONSTANT_TEAMS.SERVICE_TEAM_PLACEHOLDER,
                        noOptionsFound: isTeamListLoading ? "" : "No Matches Found."
                    }}
                    required
                    menuPosition="absolute"
                    options={comboboxFilterAndLimit({
                        inputValue: "",
                        options: serviceTeams,
                        selection: selectedTeamName,
                        limit: 10000
                    })}
                    selection={selectedTeamName}
                    variant="readonly"
                    hasMenuSpinner={isTeamListLoading}
                />
            </div>
            <div className="slds-p-around--xx-small">
                <Input
                    id="update_slack_channel_value"
                    label={CONSTANT_TEAMS.SLACK_CHANNEL}
                    required
                    disabled={isSlackChannelDisabled}
                    value={selectedSlackChannelName}
                    onChange={handleSlackChannelNameUpdate}
                    placeholder={CONSTANT_TEAMS.SLACK_CHANNEL_PLACEHOLDER}
                    iconRight={
                        <InputIcon
                            assistiveText={{
                                icon: 'Clear',
                            }}
                            name="edit"
                            category="utility"
                            onClick={() => {
                                if (selectedTeamName.length !== 0) {
                                    setIsSlackChannelDisabled(!isSlackChannelDisabled);
                                }
                            }}
                        />
                    }
                />
            </div>
            <div className="slds-p-left--xx-small slds-p-top--medium">
                <Button
                    key="updateSlackChannelRequest"
                    label={CONSTANT_TEAMS.UPDATE_BUTTON}
                    variant="brand"
                    onClick={handleUpdateSlackChannelRequest}
                    disabled={isUpdateButtonDisabled}
                />
            </div>
        </div>
    );
};

export default UpdateSlackChannel;