import {SCPDeploymentApiService} from "./service";
import {ListSCPOperationsResponse} from "../../../utils/types/deployments/scp/operations";
import {GenericDeploymentSubmissionResponse} from "../../../utils/types/deployments/genericDeploymentResponse";

// Helper function to generate mock data
function generateMockData(number: number) {
    const mockOperations = Array.from({ length: number }, (_, index) => ({
        operation_id: `operation-id-${index}`,
        mpa_id: `mpa-id-${Math.floor(Math.random() * 1000000000000)}`,
        auto_mode: Math.random() > 0.5,
        aws_path: `*/*/falcon_dev_only_${index}`,
        current_stagger_stage_completed_timestamp: `${Date.now() - index * 1000}`,
        is_falcon: Math.random() > 0.5 ? "true" : "false",
        operation_type: "UpdateStagger",
        scp_id: `scp-id-${Math.random().toString(36).substring(2, 10)}`,
        scp_name: `permission-boundary-scp-${index}`,
        stagger_level: `stagger-level-${index}`,
        stagger_stage: `ou_level-sg${index}-dev-*`,
        status: ["Completed", "Pending", "Failed"][Math.floor(Math.random() * 3)],
        timestamp_of_operation_creation: `${Date.now() - index * 5000}`,
        can_expand: Math.random() > 0.5,
        can_revert: true,
    }));

    return {
        operations: mockOperations
    };
}

// Updated MockSCPDeploymentApiService class
export class MockSCPDeploymentApiService implements SCPDeploymentApiService {
    async getOperations(): Promise<ListSCPOperationsResponse> {
        const mockData = generateMockData(100);
        return new Promise<ListSCPOperationsResponse>(resolve => {
            setTimeout(() => {
                resolve(ListSCPOperationsResponse.fromJSON(mockData));
            }, 500);
        });
    }

    async expandOperation(changeCase: string, operationID: string): Promise<GenericDeploymentSubmissionResponse> {
        return new Promise<GenericDeploymentSubmissionResponse>(resolve => {
            setTimeout(() => {
                resolve(new GenericDeploymentSubmissionResponse("Expand Request Accepted!", true));
            }, 500);
        });
    }

    async rollbackOperation(changeCase:string, operationID: string): Promise<GenericDeploymentSubmissionResponse> {
        return new Promise<GenericDeploymentSubmissionResponse>(resolve => {
            setTimeout(() => {
                resolve(new GenericDeploymentSubmissionResponse("Revert Request Accepted!", true));
            }, 500);
        });
    }
}
