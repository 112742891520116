import React, { useEffect } from 'react';
import { Toast } from "@salesforce/design-system-react";
import {MultiStackToastMessage, ToastType} from "../../utils/types/toast";

interface ToastProps {
    toasts: MultiStackToastMessage[];
    removeToast: (id: number) => void;
    duration?: number;
}

const MultiStackToast: React.FC<ToastProps> = ({ toasts, removeToast, duration = 5000 }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            toasts.forEach(toast => removeToast(toast.id));
        }, duration);

        return () => clearTimeout(timer);
    }, [toasts, removeToast, duration]);

    return (
        <div className="slds-notify_container slds-is-relative">
            {toasts.map((toast, index) => (
                <Toast
                    key={index}
                    labels={{
                        heading: toast.heading,
                        details: toast.details,
                    }}
                    variant={toast.variant}
                    onRequestClose={() => removeToast(toast.id)}
                    duration={duration}
                />
            ))}
        </div>
    );
};

export default MultiStackToast;
