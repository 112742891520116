import React, { createContext, ReactNode, useContext, useState } from 'react';

interface AccountCreationStatus {
    crt_id: string;
    status: string;
    account_id: string;
    error_status: string;
}

const AccountCreationContext = createContext<{
    accountCreationStatus: AccountCreationStatus;
    setAccountCreationStatus: React.Dispatch<React.SetStateAction<AccountCreationStatus>>;
} | undefined>(undefined);

const initialAccountCreationStatus: AccountCreationStatus = {
    crt_id: "",
    status: "",
    account_id: "",
    error_status: ""
};

export const useAccountCreationStatus = () => {
    const context = useContext(AccountCreationContext);
    if (!context) {
        throw new Error('useAccountCreationStatus must be used within a AccountCreationStatusProvider');
    }
    return context;
};

interface AccountCreationStatusProviderProps {
    children: ReactNode;
}

export const AccountCreationStatusProvider: React.FC<AccountCreationStatusProviderProps> = ({ children }) => {
    const [accountCreationStatus, setAccountCreationStatus] = useState<AccountCreationStatus>(initialAccountCreationStatus);

    return (
        <AccountCreationContext.Provider value={{ accountCreationStatus, setAccountCreationStatus }}>
            {children}
        </AccountCreationContext.Provider>
    );
};