export class ChangeCaseMetadata {
    generic_admin_actions_data: {
        allowed: boolean;
        errors: string[];
    };
    metadata: {
        subject: string;
    };

    // Constructor to initialize the class with provided data
    constructor() {
        this.generic_admin_actions_data = {allowed: false, errors: []}
        this.metadata = {subject: ""};
    }

    // Method to populate the class from a JSON object
    static fromJSON(json: any): ChangeCaseMetadata {
        const instance = new ChangeCaseMetadata();
        instance.generic_admin_actions_data = {
            allowed: json.generic_admin_actions_data.allowed,
            errors: json.generic_admin_actions_data.errors || []
        };
        instance.metadata = {
            subject: json.metadata.Subject || "",
        };
        return instance;
    }
}
