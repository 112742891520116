import React, { Component } from "react";
import {
    Button, DataTable, DataTableCell, DataTableColumn
} from "@salesforce/design-system-react";
import {fetchMyAccounts} from "../../../services/myAccounts";
import {ReviewAccessRequest} from "../../../utils/types/accessRequest";
import {selectedAccountInfo} from "../../../utils/types/account";
import {CONSTANT_ACCOUNT} from "../../../constants/accountManagement";
import {HandleError} from "../../utils/common";
import {ToastState} from "../../../utils/types/toast";
import {TOAST_MESSAGE} from "../../../constants/toastMessages";
import EmptyPageSpinner from "../../../utils/components/spinner/page_spinner_overlay";

type MyAccountsProps = {
    action?: (type: string) => void;
    isActive: boolean;
    setActiveTab: (tab: string) => void;
    setActiveSubTab: (tabIndex: number) => void;
    selectedAccount: selectedAccountInfo;
    setSelectedAccount: (accountInfo: selectedAccountInfo) => void;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
};

type MyAccountsState = {
    accounts: any[];
    selectedDuration: number;
    liveAccessRequests: ReviewAccessRequest[];
    allAccessRequests: ReviewAccessRequest[];
    loading: boolean;
    currentPage: number;
    pageSize: number;
    totalPages: number;
    startIndex: number;
    endIndex: number;
    displayedData: ReviewAccessRequest[];
    selectedAccount: string;
    selectedAccountIndex: number;
    filter: string;
    dataLoading: boolean;
    searchTerm: string;
};

type CustomStatusCellProps = {
    children?: React.ReactNode;
    item?: ReviewAccessRequest;
};

class MyAccounts extends Component<MyAccountsProps, MyAccountsState> {
    constructor(props: MyAccountsProps) {
        super(props);
        const initialSearchTerm = props.selectedAccount && props.selectedAccount.account_id ? props.selectedAccount.account_id : "";
        this.state = {
            selectedAccount: "",
            selectedAccountIndex: -1,
            selectedDuration: 24,
            liveAccessRequests: [],
            allAccessRequests: [],
            loading: false,
            currentPage: 1,
            pageSize: 15,
            totalPages: -1,
            startIndex: -1,
            endIndex: -1,
            accounts: [],
            displayedData: [],
            filter: "All",
            dataLoading: false,
            searchTerm: initialSearchTerm
        };
    }

    onCreateAccessRequestUsingAccountID(props: any) {
        this.props.setActiveTab('AccessRequestSection')
        this.props.setActiveSubTab(1);
        this.props.setSelectedAccount({
            account_id: props.item.id,
            account_substrate: props.item.account_substrate.toString().toLowerCase()
        })
    }

    reviewRequestUsingAccountID(props: any) {
        this.props.setActiveTab('AccessRequestSection');
        this.props.setActiveSubTab(2);
        this.props.setSelectedAccount({
            account_id: props.item.id
        });
    }

    componentDidUpdate(prevProps: MyAccountsProps) {
        if (prevProps.selectedAccount !== this.props.selectedAccount) {
            if (this.props.selectedAccount && this.props.selectedAccount.account_id) {
                this.setState({
                    searchTerm: this.props.selectedAccount.account_id
                }, this.setPaginationControls);
            }
        }

        // Check if the component just became active
        if (!prevProps.isActive && this.props.isActive) {
            this.updateItems();
        }
    }


    componentDidMount() {
        this.updateItems();
    }

    private setPaginationControls = () => {
        const filteredAccounts = this.state.searchTerm
            ? this.state.accounts.filter(account =>
                account.id.includes(this.state.searchTerm) ||
                account.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
            )
            : this.state.accounts;

        const totalPages = Math.ceil(filteredAccounts.length / this.state.pageSize);
        const startIndex = (this.state.currentPage - 1) * this.state.pageSize;
        const endIndex = Math.min(startIndex + this.state.pageSize, filteredAccounts.length);

        this.setState({
            totalPages: totalPages,
            startIndex: startIndex,
            endIndex: endIndex,
            displayedData: filteredAccounts.slice(startIndex, endIndex),
        });
    }

    private goToPreviousPage = () => {
        this.setState((prevState) => ({
            currentPage: Math.max(prevState.currentPage - 1, 1),
        }), () => this.setPaginationControls());
    }

    private goToNextPage = () => {
        this.setState((prevState) => ({
            currentPage: Math.min(prevState.currentPage + 1, this.state.totalPages),
        }), () => this.setPaginationControls());
    }

    updateItems() {
        this.setState({'dataLoading': true})
        fetchMyAccounts().then(data => {
            const transformedData = data.map((account: any) => ({
                id: account.id,
                status: account.account_status || "ACTIVE",
                account_substrate: account.account_substrate.toUpperCase(),
                email: account.email || "-",
                name: account.name || "-",
                team_name: account.team_name || "-",
                summary: account.name + ' (' + account.id + ')',
                details: account.name + ' (' + account.id + ')',
            })).sort((a: any, b: any) => {
                if (isNaN(a.id) || isNaN(b.id)) { // if one of them is not a number, compare as strings
                    return a.id.localeCompare(b.id);
                }
                return parseInt(a.id) - parseInt(b.id); // compare as numbers
            });
            this.setState({ accounts: transformedData }, () => this.setPaginationControls());
            this.setState({ dataLoading: false });
        }).catch(error => {
            console.error("Error fetching my accounts:", error);
            HandleError(this.props, error, TOAST_MESSAGE.ERROR_FETCHING_MY_ACCOUNTS);
            this.setState({ 'dataLoading': false });
        });
    }

    private CustomReviewRequestActionsCell: React.FC<CustomStatusCellProps> = ({ children, ...props }) => {
        // const { item } = props;
        return (
            <td className='slds-text-align_right'>
                <Button
                    label={CONSTANT_ACCOUNT.REVIEW_ACCESS}
                    variant='outline-brand'
                    onClick={() => this.reviewRequestUsingAccountID(props)}
                />
                <Button
                    label={CONSTANT_ACCOUNT.REQUEST_ACCESS}
                    variant='brand'
                    onClick={() => this.onCreateAccessRequestUsingAccountID(props)}
                />
            </td>
        );
    };

    private CustomReviewRequestCell: React.FC<CustomStatusCellProps> = ({ children, ...props }) => {
        const breakTextIntoChunks = (text: string, chunkSize: number) => {
            const regex = new RegExp(`.{1,${chunkSize}}`, "g");
            return text.match(regex);
        };

        const textChunks = breakTextIntoChunks(children as string, 25);

        return (
            <td className="slds-text-align_left">
                <p>
                    {textChunks &&
                        textChunks.map((chunk, index) => (
                            <React.Fragment key={index}>
                                {chunk}
                                {index < textChunks.length - 1 && <br />}
                            </React.Fragment>
                        ))}
                </p>
            </td>
        );
    };

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        this.setState({
            searchTerm: value,
            currentPage: 1,  // Reset to the first page when the search term changes
        }, this.setPaginationControls);  // Recalculate pagination after setting the state
    }

    render() {
        this.CustomReviewRequestActionsCell.displayName = DataTableCell.displayName;
        this.CustomReviewRequestCell.displayName = DataTableCell.displayName;

        if (this.state.dataLoading) {
            return (
                <div style={{minHeight: "80vh"}}>
                    <EmptyPageSpinner/>
                </div>
            );
        }
        return (
            <div style={{minHeight: "80vh"}}>
                {this.state.accounts.length > this.state.pageSize && (
                    <div className="slds-col slds-size_1-of-1 slds-text-align_right slds-m-bottom_medium">
                        <div className="slds-m-bottom_medium">
                            <input
                                type="text"
                                placeholder={CONSTANT_ACCOUNT.ACCOUNT_SEARCH_PLACEHOLDER}
                                value={this.state.searchTerm}
                                onChange={this.handleSearchChange}
                                className="slds-input"
                            />
                        </div>

                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <div className="slds-align-bottom">
                                <p> {`Showing (${this.state.startIndex + 1} - ${Math.min(this.state.endIndex, this.state.accounts.length)}) of ${this.state.accounts.length}`} </p>
                            </div>
                            <div>
                                <Button
                                    label="<"
                                    onClick={this.goToPreviousPage}
                                    disabled={this.state.currentPage === 1}
                                    className={`slds-button ${
                                        this.state.currentPage === 1
                                            ? 'slds-button_neutral'
                                            : 'slds-button_brand'
                                    }  slds-avatar--small slds-m-right--small`}
                                />
                                <span> Page {this.state.currentPage} of {this.state.totalPages} </span>
                                <Button
                                    label=">"
                                    onClick={this.goToNextPage}
                                    disabled={this.state.currentPage === this.state.totalPages}
                                    className={`slds-button ${
                                        this.state.currentPage === this.state.totalPages
                                            ? 'slds-button_neutral'
                                            : 'slds-button_brand'
                                    }  slds-avatar--small slds-m-left--small`}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {this.state.accounts.length > 0 ? (
                    <div>
                        <DataTable
                            resizeBehavior="auto"
                            striped={true}
                            columnBordered={true}
                            items={this.state.displayedData}
                            fixedHeader={true}
                            id="MyAccountsTable"
                        >
                            <DataTableColumn
                                label={CONSTANT_ACCOUNT.ACCOUNT_ID}
                                property='id'
                                width="6rem">
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCOUNT.NAME}
                                property='name'
                                width="12rem">
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCOUNT.EMAIL}
                                property='email'
                                width="8rem">
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCOUNT.SERVICE_TEAM}
                                property='team_name'
                                width="8rem">
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCOUNT.SUBSTRATE_TYPE}
                                property='account_substrate'
                                width="6rem">
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCOUNT.STATUS}
                                property='status'
                                width="8rem">
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCOUNT.ACTIONS}
                                width="16rem">
                                <this.CustomReviewRequestActionsCell />
                            </DataTableColumn>
                        </DataTable>
                    </div>
                ): (
                    <div className='slds-page-header'>{CONSTANT_ACCOUNT.NO_ACCOUNT_MSG}</div>
                )}
            </div>
        );
    }
}

export default MyAccounts;
