import {UserApiService} from "./service";
import {User} from "../../context/users";
import {BASE_URL} from "../common";

const mockUser: User = {
    id: "shrawan.sah@salesforce.com",
    email: "shrawan.sah@salesforce.com",
    user_id: "shrawan.sah@salesforce.com",
    allowed_account_creation: "true",
    allowed_jit_admin_access: "true",
    allowed_drift_view: "true",
    roles: [
        "offline_access",
        "uma_authorization",
        "default-roles-pcsk"
    ],
    cloudZones: [
        "ou=falcondev,o=clouds,dc=falcondev,dc=sfdc,dc=net"
    ]
};

export class MockUserApiService implements UserApiService {
    async fetchUserMetadata(email: string): Promise<User> {
        // Simulate a 1-second delay
        await new Promise(resolve => setTimeout(resolve, 1000));
        return mockUser;
    }
}
