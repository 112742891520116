import React, { useState, useEffect } from 'react';
import { Combobox, comboboxFilterAndLimit, Dropdown } from '@salesforce/design-system-react';
import { ArtifactsApiServiceFactory } from "../../../services/sam/factory";

interface CommitSelectorProps {
    setArtifact: React.Dispatch<React.SetStateAction<string>>;
    disable: boolean;
    prefix: string;
}

// Dropdown options for duration filter
const filterDropdownOptions = [
    { label: 'Last 7 days', value: 7 },
    { label: 'Last 14 days', value: 14 },
    { label: 'Last 30 days', value: 30 },
];

const ArtifactSelector: React.FC<CommitSelectorProps> = ({
                                                             setArtifact,
                                                             disable, prefix
                                                         }) => {
    const [selectedArtifact, setSelectedArtifact] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredCommits, setFilteredCommits] = useState<{ id: string; label: string }[]>([]);
    const [selectedDropDownFilter, setSelectedDropDownFilter] = useState(filterDropdownOptions[0]);
    const [isFetchingArtifacts, setIsFetchingArtifacts] = useState<boolean>(false);
    const [artifacts, setArtifacts] = useState<string[]>([]);
    const [error, setError] = useState<string>(''); // Error state

    const artifactApiService = ArtifactsApiServiceFactory.getService();

    useEffect(() => {
        fetchStackSetsArtifacts();
    }, []);

    useEffect(() => {
        fetchStackSetsArtifacts();
    }, [selectedDropDownFilter]);

    // Effect to filter commits whenever searchTerm or commits change
    useEffect(() => {
        const filtered = artifacts
            .filter((artifact) => artifact.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((commit) => ({ id: commit, label: commit }));
        setFilteredCommits(filtered);
    }, [searchTerm, artifacts]);

    const fetchStackSetsArtifacts = async () => {
        try {
            setError('');
            setArtifacts([]);
            setSearchTerm('');
            setArtifact('');
            setSelectedArtifact('');

            setIsFetchingArtifacts(true);
            const artifacts = await artifactApiService.getArtifacts(prefix, selectedDropDownFilter.value);
            setArtifacts(artifacts.artifactNames);
        } catch (err: any) {
            console.log('Failed to load artifacts.', err);
            let mssg = 'Failed to load artifacts. Please try again later.'
            if (err && err.hasOwnProperty("message")) {
                mssg = err.message
            }
            setError(mssg);
        } finally {
            setIsFetchingArtifacts(false);
        }
    }

    return (
        <>
            <div className="slds-grid">
                <div className="slds-size_8-of-8">
                    <Combobox
                        id="artifact_picker_combobox"
                        className={""}
                        events={{
                            onSelect: (event: any, data: any) => {
                                if (data.selection && data.selection.length > 0) {
                                    setArtifact(data.selection[0].id);
                                    setSelectedArtifact(data.selection[0].id);
                                    setSearchTerm(data.selection[0].label);
                                }
                            },
                            onChange: (event: any, data: any) => {
                                setSearchTerm(data.value);
                            },
                            onRequestRemoveSelectedOption: (event: any, data: any) => {
                                setArtifact('');
                                setSelectedArtifact('');
                                setSearchTerm('');
                            },
                        }}
                        labels={{
                            placeholder: 'Search by Commit ID or Artifact Name',
                            noOptionsFound: isFetchingArtifacts ? '' : 'No Matches Found.',
                        }}
                        required
                        menuPosition="absolute"
                        options={
                            isFetchingArtifacts
                                ? []
                                : comboboxFilterAndLimit({
                                    inputValue: searchTerm,
                                    options: filteredCommits,
                                    selection: selectedArtifact ? [{ id: selectedArtifact, label: selectedArtifact }] : [],
                                    limit: 20000,
                                })
                        }
                        value={searchTerm}
                        selection={selectedArtifact ? [{ id: selectedArtifact, label: selectedArtifact }] : []}
                        variant="inline-listbox"
                        menuItemVisibleLength={5}
                        hasMenuSpinner={isFetchingArtifacts}
                        disabled={disable}
                    />
                    {/* Error message below Combobox */}
                    {error && <div className="slds-text-color_error slds-m-top_xx-small">{error}</div>}
                </div>

                <div className="slds-size_2-of-8">
                    {/* Dropdown for selecting duration */}
                    <Dropdown
                        align="left"
                        iconCategory="utility"
                        iconName="filter"
                        iconPosition="right"
                        label={selectedDropDownFilter.label}
                        options={filterDropdownOptions}
                        onSelect={(selectedFilter: any) => {
                            setSelectedDropDownFilter(selectedFilter);
                        }}
                        disabled={disable || isFetchingArtifacts}
                    />
                </div>
            </div>
        </>
    );
};

export default ArtifactSelector;
