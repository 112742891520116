export class ListSCPOperationsResponse {
    operations: SCPOperation[];
    constructor(operations: SCPOperation[] = []) {
        this.operations = operations;
    }

    static fromJSON(data: any): ListSCPOperationsResponse {
        const operations = data.operations.map((op: any) => new SCPOperation(op));
        return new ListSCPOperationsResponse(operations);
    }
}

export class SCPOperation {
    operation_id: string;
    mpa_id: string;
    auto_mode: boolean;
    aws_path: string;
    current_stagger_stage_completed_timestamp: string;
    is_falcon: boolean;
    operation_type: string;
    scp_id: string;
    scp_name: string;
    stagger_level: string;
    stagger_stage: string;
    status: string;
    timestamp_of_operation_creation: string;
    can_expand: boolean;
    can_revert: boolean;

    constructor(data: {
        operation_id: string;
        mpa_id: string;
        auto_mode: boolean;
        aws_path: string;
        current_stagger_stage_completed_timestamp: string;
        is_falcon: string;
        operation_type: string;
        scp_id: string;
        scp_name: string;
        stagger_level: string;
        stagger_stage: string;
        status: string;
        timestamp_of_operation_creation: string;
        can_expand: boolean;
        can_revert: boolean;
    }) {
        this.operation_id = data.operation_id;
        this.mpa_id = data.mpa_id;
        this.auto_mode = data.auto_mode;
        this.aws_path = data.aws_path;
        this.current_stagger_stage_completed_timestamp = data.current_stagger_stage_completed_timestamp;
        this.is_falcon = data.is_falcon === "true";
        this.operation_type = data.operation_type;
        this.scp_id = data.scp_id;
        this.scp_name = data.scp_name;
        this.stagger_level = data.stagger_level;
        this.stagger_stage = data.stagger_stage;
        this.status = data.status;
        this.timestamp_of_operation_creation = data.timestamp_of_operation_creation;
        this.can_expand = data.can_expand ? data.can_expand : false;
        this.can_revert = data.can_revert ? data.can_revert : false
    }

    hasActions(): boolean {
        return this.can_expand || this.can_revert;
    }

    static fromJSON(json: any): SCPOperation {
        return new SCPOperation(json);
    }
}


export class SCPDeployment {
    operation_id: string;
    changes: SCPOperation[];

    constructor(operationID: string, changes: SCPOperation[]) {
        this.operation_id = operationID;
        this.changes = changes;
    }

    static fromOperations(operations: SCPOperation[]): SCPDeployment[] {
        // Ensure operations is an array of SCPOperation
        if (!Array.isArray(operations)) {
            throw new TypeError('operations must be an array of SCPOperation');
        }

        // Create a map to group operations by operation_id
        const operationMap = new Map<string, SCPOperation[]>();

        // Iterate over the operations array to populate the map
        for (const operation of operations) {
            if (!operationMap.has(operation.operation_id)) {
                operationMap.set(operation.operation_id, []);
            }
            operationMap.get(operation.operation_id)!.push(operation);
        }

        // Convert the map entries into SCPDeployment instances using Array.from for compatibility
        return Array.from(operationMap.entries()).map(
            ([operationID, changes]) => new SCPDeployment(operationID, changes)
        );
    }
}
