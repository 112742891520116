import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import './App.css';
import AppHeader from "./components/app/AppHeader";
import PCSKAdmin from "./components/app/PCSKAdmin";
import Login from "./routes/Login";
import Callback from "./routes/callback";
import PrivateRoutes from "./routes/PrivateRoutes";
import NotFound from "./components/app/NotFound";
import { IconSettings } from "@salesforce/design-system-react";
import actionSprite from '@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg';
import customSprite from '@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg';
import utilitySprite from '@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg';
import standardSprite from '@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg';
import doctypeSprite from '@salesforce-ux/design-system/assets/icons/doctype-sprite/svg/symbols.svg';
import '@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.min.css';
import { UserProvider } from "./context/users";

function App() {
    return (
        <IconSettings
            standardSprite={standardSprite}
            utilitySprite={utilitySprite}
            actionSprite={actionSprite}
            doctypeSprite={doctypeSprite}
            customSprite={customSprite}
        >
            <UserProvider>
                <><BrowserRouter>
                    <Routes>
                        <Route element={<PrivateRoutes />}>
                            <Route element={<AppHeader/>} path="/"/>
                            <Route element={<AppHeader requestType='view'/>} path="/access-requests/:accessId"/>
                            <Route element={<AppHeader requestType='approve'/>} path="/slack/approve/:accessId"/>
                            <Route element={<AppHeader requestType='reject'/>} path="/slack/reject/:accessId"/>
                            <Route element={<AppHeader requestType='slack_channel_update'/>} path="/team"/>
                            <Route element={<AppHeader requestType='service_team_update'/>} path="/update-accounts-team"/>
                            <Route element={<AppHeader requestType='account_closure'/>} path="/account-closure"/>
                            <Route element={<AppHeader requestType='stackset_drift'/>} path="/stackset-drift"/>
                            <Route element={<PCSKAdmin />} path="/pcsk-admin">
                                <Route path="accounts">
                                    <Route path="onboarding" />
                                    <Route path="closure-requests" />
                                    <Route path="closure-exceptions" />
                                </Route>
                                <Route path="access-request">
                                    <Route path="reasons" />
                                </Route>
                                <Route path="violations">
                                    <Route path="stacksets-drift" />
                                    <Route path="scp-drift" />
                                    <Route path="ou-violation" />
                                    <Route path="dangling-accounts" />
                                </Route>
                                <Route path="deployments">
                                    <Route path="stacksets" />
                                    <Route path="scp" />
                                    <Route path="sam" />
                                </Route>
                            </Route>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route element={<Login/>} path="/login"/>
                        <Route element={<Callback />} path="/auth/salesforce/callback"/>
                    </Routes>
                </BrowserRouter></>
            </UserProvider>
        </IconSettings>
    );
}

export default App;
