export const CONSTANT_TEAMS = {
    UPDATE_SLACK_CHANNEL: 'Update Slack Channel',
    UPDATE_SERVICE_TEAM: 'Update Service Team',
    SERVICE_TEAM: 'Service Team',
    SERVICE_TEAM_PLACEHOLDER: 'Enter service team',
    SLACK_CHANNEL: 'Slack Channel',
    SLACK_CHANNEL_PLACEHOLDER: 'Enter Slack Channel',
    UPDATE_BUTTON: 'Update',
    ACCOUNT: 'Account',
    ACCOUNT_PLACEHOLDER: 'Select Account',
    CURRENT_SERVICE_TEAM: 'Current Service Team',
    CURRENT_SERVICE_TEAM_PALCEHOLDER: '-',
    NEW_SERVICE_TEAM: 'New Service Team',
    NEW_SERVICE_TEAM_PLACEHOLDER: 'Select New Service Team',
}