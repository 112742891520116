import React, { Component } from "react";
import { getCurrentUnixTimestamp } from "../../../routes/helper";
import { fetchAccountAccessRequests, revokeAccessRequests } from '../../../services/accessRequests';
import { CONSTANT_ACCESS_REQUEST } from "../../../constants/accessRequest";
import { approveAccessRequest, rejectAccessRequest } from "../../../services/requestReviews"
import { ReviewAccessRequest, AccessRequestsToReviewAccessRequest } from "../../../utils/types/accessRequest";
import {
    Button,
    Lookup,
    DataTable,
    DataTableColumn,
    DataTableCell,
    Dropdown,
} from "@salesforce/design-system-react";
import {getLoggedInUserEmail} from "../../../utils/lookup/session";
import {ToastState} from "../../../utils/types/toast";
import {Account, selectedAccountInfo} from "../../../utils/types/account";
import {fetchMyAccounts} from "../../../services/myAccounts";
import EmptyPageSpinner from "../../../utils/components/spinner/page_spinner_overlay";

type CustomStatusCellProps = {
    children?: React.ReactNode;
    item?: ReviewAccessRequest;
};

type SearchAccessRequestProps = {
    selectedAccount: selectedAccountInfo;
    isActive: boolean;
    toast: ToastState;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
};

type SearchAccessRequestState = {
    accounts: Account[];
    selectedDuration: number;
    liveAccessRequests: ReviewAccessRequest[];
    allAccessRequests: ReviewAccessRequest[];
    loading: boolean;
    currentPage: number;
    pageSize: number;
    totalPages: number;
    startIndex: number;
    endIndex: number;
    displayedData: ReviewAccessRequest[];
    selectedAccount: string;
    selectedAccountIndex: number;
    filter: string;
};

const accessRequestsActionMap: Record<string,
    {
        actionFunction: (id: string) => Promise<any>;
        toastType: string;
        toastMessage: string
    }> = {
    Cancel: {
        actionFunction: revokeAccessRequests,
        toastType: 'success',
        toastMessage: 'Cancelled',
    },
    Revoke: {
        actionFunction: revokeAccessRequests,
        toastType: 'success',
        toastMessage: 'Revoked',
    },
    Reject: {
        actionFunction: rejectAccessRequest,
        toastType: 'success',
        toastMessage: 'Rejected',
    },
    Approve: {
        actionFunction: approveAccessRequest,
        toastType: 'success',
        toastMessage: 'Approved',
    },
};


class SearchAccessRequests extends Component<
    SearchAccessRequestProps,
    SearchAccessRequestState
> {
    constructor(props: SearchAccessRequestProps) {
        super(props);
        const initialSelectedAccount = props.selectedAccount && props.selectedAccount.account_id ? props.selectedAccount.account_id : "";
        this.state = {
            selectedAccount: initialSelectedAccount,
            selectedAccountIndex: -1,
            selectedDuration: 24,
            liveAccessRequests: [],
            allAccessRequests: [],
            loading: false,
            currentPage: 1,
            pageSize: 10,
            totalPages: -1,
            startIndex: -1,
            endIndex: -1,
            accounts: [],
            displayedData: [],
            filter: "All",
        };
    }

    componentDidMount() {
        // this.updateAccounts(this.state.selectedAccount);
        if (this.props.isActive) {
            this.clearSelections();
            this.updateAccounts(this.state.selectedAccount);
        }
    }

    componentDidUpdate(prevProps: SearchAccessRequestProps) {
        if (!prevProps.isActive && this.props.isActive) {
            this.clearSelections();
            this.updateAccounts(this.state.selectedAccount);
        }
    }


    private sendAlert = (type: string, message: string) => {
        this.props.setToast({
            isVisible: true,
            message: {
                heading: message,
            },
            variant: type
        });
    };

    private showLoading = () => {
        this.setState({loading: true});
    }

    private hideLoading= () => {
        this.setState({loading: false});
    }

    private upsertReviewAccessRequest(reviewAccessRequest: ReviewAccessRequest, action: string): ReviewAccessRequest {
        if (action === 'Approve') {
            return {
                ...reviewAccessRequest,
                reviewer: getLoggedInUserEmail(),
                approved_at: Math.floor(Date.now() / 1000),
                calculated_status: CONSTANT_ACCESS_REQUEST.CALCULATED_STATUS_ACTIVE,
                actions: [],
            }
        }
        if (['Revoke', 'Cancel'].includes(action)) {
            return {
                ...reviewAccessRequest,
                revoked_at: Math.floor(Date.now() / 1000),
                revoked_by: getLoggedInUserEmail(),
                calculated_status: CONSTANT_ACCESS_REQUEST.CALCULATED_STATUS_EXPIRED,
                actions: [],
            }
        }
        if (action === 'Reject') {
            return {
                ...reviewAccessRequest,
                rejected_at: Math.floor(Date.now() / 1000),
                rejected_by: getLoggedInUserEmail(),
                calculated_status: CONSTANT_ACCESS_REQUEST.CALCULATED_STATUS_EXPIRED,
                actions: [],
            }
        }
        return reviewAccessRequest;
    }

    private performAction = (
        reviewAccessRequest: ReviewAccessRequest,
        action: 'Approve' | 'Reject' | 'Cancel' | 'Revoke'
    ) => {
        this.showLoading();
        const { actionFunction, toastType, toastMessage } = accessRequestsActionMap[action];
        actionFunction(reviewAccessRequest.id).then(apiResponse => {
            const { allAccessRequests, displayedData, liveAccessRequests } = this.state;
            const updatedDisplayedData = [...displayedData];
            const updatedLiveAccessRequests = [...liveAccessRequests];
            const updatedAllAccessRequests = [...allAccessRequests];

            let index = updatedDisplayedData.findIndex(item => item.id === reviewAccessRequest.id);
            if (index !== -1) {
                updatedDisplayedData[index] = this.upsertReviewAccessRequest(updatedLiveAccessRequests[index], action);
            }

            index = updatedLiveAccessRequests.findIndex(item => item.id === reviewAccessRequest.id);
            if (index !== -1) {
                updatedLiveAccessRequests[index] = this.upsertReviewAccessRequest(updatedLiveAccessRequests[index], action);
            }

            index = updatedAllAccessRequests.findIndex(item => item.id === reviewAccessRequest.id);
            if (index !== -1) {
                updatedAllAccessRequests[index] = this.upsertReviewAccessRequest(updatedLiveAccessRequests[index], action);
            }

            this.setState(
                {
                    displayedData: updatedDisplayedData,
                    liveAccessRequests: updatedLiveAccessRequests,
                    allAccessRequests: updatedAllAccessRequests,
                },
                () => this.filterAccessRequests()
            );
            this.sendAlert(toastType, `${reviewAccessRequest.id} access request ${toastMessage}.`);
        })
        .catch(error => {
            this.sendAlert('error', `Unable to ${action} access request! ${error}`);
        })
        .finally(() => {
            this.hideLoading();
        });
    };

    public updateAccounts(selectedAccountID: string) {
        this.showLoading();
        fetchMyAccounts().then((data) => {
            const transformedData = data.map((account: Account) => ({
                id: account.id,
                label: account.id + "(" + account.name + ") ( " + account.email + " )",
                value: account.id,
                account_substrate: account.account_substrate,
                account_status: account.account_status,
                team_name: account.team_name,
            }));
            this.setState({accounts: transformedData}, () => {
                // This callback ensures the state has been updated with accounts list
                if (selectedAccountID) {
                    this.setSelectedAccount(selectedAccountID);
                    this.handleAccountSelect({id: selectedAccountID})
                }
            });
        }).catch((error) => {
            this.sendAlert('error', `Unable to fetch accounts! ${error}`)
            this.hideLoading();
        }).finally( () => {
            this.hideLoading();
        });
    }

    private fetchAccessRequests = () => {
        if (!this.state.selectedAccount) {
            console.log("Invalid account selection !");
            return;
        }
        if (!this.state.selectedDuration) {
            console.log("Invalid duration !");
            return;
        }

        const timestamp = getCurrentUnixTimestamp() - this.state.selectedDuration * 60 * 60
        this.showLoading()
        fetchAccountAccessRequests(this.state.selectedAccount, timestamp).then(apiResponse => {
            this.setState({
                allAccessRequests: AccessRequestsToReviewAccessRequest(apiResponse.accessRequests),
            }, () => this.filterAccessRequests());
        }).catch(error => {
            this.sendAlert('error', `Unable to fetch access requests! ${error}`)
            this.hideLoading();
        }).finally( () => {
            this.hideLoading();
        });
    };

    private setSelectedAccount(accountID: string) {
        const selectedIndex = this.state.accounts.findIndex(
            (opt) => opt.id === accountID
        );
        this.setState({
            selectedAccountIndex: selectedIndex,
        });
    }

    private handleAccountSelect = (item: any) => {
        const selectedIndex = this.state.accounts.findIndex(
            (opt) => opt.id === item.id
        );
        this.setState(
            {
                selectedAccountIndex: selectedIndex,
                selectedAccount: item.id,
            },
            () => this.fetchAccessRequests()
        );
    };

    private handleDurationSelect = (duration: number) => {
        this.setState(
            {
                selectedDuration: duration,
                currentPage: 1,
            },
            () => this.fetchAccessRequests()
        );
    };

    private handleFilterChange = (value: string) => {
        this.setState({
            filter: value,
            currentPage: 1,
        }, () => this.filterAccessRequests());
    };

    private filterAccessRequests() {
        const { allAccessRequests, filter } = this.state;
        let filterRequests = allAccessRequests
        if (filter !== "All") {
            filterRequests = allAccessRequests.filter((item) => item.calculated_status === filter);
        }
        this.setState({ liveAccessRequests: filterRequests }, () => this.setPaginationControls());
    }

    private setPaginationControls = () => {
        const { liveAccessRequests, pageSize, currentPage } = this.state;
        const totalPages = Math.ceil(liveAccessRequests.length / pageSize);
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const displayedData = liveAccessRequests.slice(startIndex, endIndex);

        this.setState({
            totalPages: totalPages,
            startIndex: startIndex,
            endIndex: endIndex,
            displayedData: displayedData,
        });
    }

    private CustomReviewRequestActionsCell: React.FC<CustomStatusCellProps> = ({ children, ...props }) => {
        const { item } = props;
        return (
            <td className='slds-text-align_left'>
                {item && item.actions.includes('approve') && (
                    <Button
                        label={CONSTANT_ACCESS_REQUEST.APPROVE}
                        className='slds-button slds-button_brand slds-m-top--xx-small slds-m-bottom--xx-small slds-m-left--xxx-small'
                        onClick={() => this.performAction(item, 'Approve')}
                    />
                )}
                {item && item.actions.includes('reject') && (
                    <Button
                        label={CONSTANT_ACCESS_REQUEST.REJECT}
                        variant="text-destructive"
                        className='slds-button slds-button_destructive slds-m-top--xx-small slds-m-bottom--xx-small slds-m-left--xxx-small'
                        onClick={() => this.performAction(item, 'Reject')}
                    />
                )}
                {item && item.actions.includes('cancel') && (
                    <Button
                        label={CONSTANT_ACCESS_REQUEST.CANCEL}
                        variant="text-destructive"
                        className='slds-m-top--xx-small slds-m-bottom--xx-small slds-m-left--xxx-small'
                        onClick={() => this.performAction(item, 'Cancel')}
                    />
                )}
                {item && item.actions.includes('revoke') && (
                    <Button
                        label={CONSTANT_ACCESS_REQUEST.REVOKE}
                        variant="text-destructive"
                        className='slds-m-top--xx-small slds-m-bottom--xx-small slds-m-left--xxx-small'
                        onClick={() => this.performAction(item, 'Revoke')}
                    />
                )}
            </td>
        );
    };


    private CustomReviewRequestCell: React.FC<CustomStatusCellProps> = ({ children, ...props }) => {
        const breakTextIntoChunks = (text: string, chunkSize: number) => {
            const regex = new RegExp(`.{1,${chunkSize}}`, "g");
            return text.match(regex);
        };

        const textChunks = breakTextIntoChunks(children as string, 25);

        return (
            <td className="slds-text-align_left">
                <p>
                    {textChunks &&
                        textChunks.map((chunk, index) => (
                            <React.Fragment key={index}>
                                {chunk}
                                {index < textChunks.length - 1 && <br />}
                            </React.Fragment>
                        ))}
                </p>
            </td>
        );
    };

    clearSelections() {
        this.setState({
            selectedAccount: "",
            selectedAccountIndex: -1,
            selectedDuration: 24,
            liveAccessRequests: [],
            allAccessRequests: [],
            loading: false,
            currentPage: 1,
            pageSize: 10,
            totalPages: -1,
            startIndex: -1,
            endIndex: -1,
            accounts: [],
            displayedData: [],
        });
    }

    render() {
        this.CustomReviewRequestActionsCell.displayName = DataTableCell.displayName;
        this.CustomReviewRequestCell.displayName = DataTableCell.displayName;

        return (
            <div style={{minHeight: "80vh"}}>
                <div className="slds-grid slds-gutters slds-wrap">
                <div className="slds-col slds-size--5-of-12 slds-m-bottom_medium">
                    <div className="slds-form-element">
                        <div className="slds-form-element__control">
                            <Lookup
                                emptyMessage={CONSTANT_ACCESS_REQUEST.SEARCH_ACCOUNT_NO_RECORD}
                                hasError={false}
                                label={
                                    <span style={{ fontWeight: 'bold' }}>{CONSTANT_ACCESS_REQUEST.ACCOUNT_ID}</span>
                                }
                                required
                                selectedItem={this.state.selectedAccountIndex}
                                options={this.state.accounts}
                                onSelect={this.handleAccountSelect.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <div className="slds-col slds-size--3-of-12 slds-m-bottom_medium">
                    {this.state.selectedAccount.length > 0 && (
                        <div className="slds-form-element">
                            <div className="slds-form-element__control" style={{ display: 'flex', flexDirection: 'column' }}>
                                <label className="slds-form-element__label" style={{ fontWeight: 'bold' }}>Time Period</label>
                                <div className="slds-button-group" role="group">
                                    <Button
                                        label={CONSTANT_ACCESS_REQUEST.DURATION_24_HRS}
                                        variant={this.state.selectedDuration === 24 ? "brand" : "neutral"}
                                        onClick={() => this.handleDurationSelect(24)}
                                    />
                                    <Button
                                        label={CONSTANT_ACCESS_REQUEST.DURATION_7_DAYS}
                                        variant={this.state.selectedDuration === 7 * 24 ? "brand" : "neutral"}
                                        onClick={() => this.handleDurationSelect(7 * 24)}
                                    />
                                    <Button
                                        label={CONSTANT_ACCESS_REQUEST.DURATION_31_DAYS}
                                        variant={this.state.selectedDuration === 31 * 24 ? "brand" : "neutral"}
                                        onClick={() => this.handleDurationSelect(31 * 24)}
                                    />
                                    <Button
                                        label={CONSTANT_ACCESS_REQUEST.DURATION_90_DAYS}
                                        variant={this.state.selectedDuration === 90 * 24 ? "brand" : "neutral"}
                                        onClick={() => this.handleDurationSelect(90 * 24)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {this.state.selectedAccount.length > 0 && (
                    <div className="slds-form-element">
                        <label className="slds-form-element__label" style={{ fontWeight: 'bold' }}>Status</label>
                        <div className="slds-form-element__control">
                            <Dropdown
                                align="left"
                                iconCategory="utility"
                                iconName="down"
                                iconPosition="right"
                                label={this.state.filter}
                                options={[
                                    { label: CONSTANT_ACCESS_REQUEST.REQUEST_STATUS_ALL, value: CONSTANT_ACCESS_REQUEST.REQUEST_STATUS_ALL },
                                    { label: CONSTANT_ACCESS_REQUEST.REQUEST_STATUS_ACTIVE, value: CONSTANT_ACCESS_REQUEST.REQUEST_STATUS_ACTIVE },
                                    { label: CONSTANT_ACCESS_REQUEST.REQUEST_STATUS_PENDING, value: CONSTANT_ACCESS_REQUEST.REQUEST_STATUS_PENDING },
                                    { label: CONSTANT_ACCESS_REQUEST.REQUEST_STATUS_REJECTED, value: CONSTANT_ACCESS_REQUEST.REQUEST_STATUS_REJECTED },
                                    { label: CONSTANT_ACCESS_REQUEST.REQUEST_STATUS_EXPIRED, value: CONSTANT_ACCESS_REQUEST.REQUEST_STATUS_EXPIRED },
                                ]}
                                value={this.state.filter}
                                onSelect={(value: any) => {
                                    this.handleFilterChange(value.value);
                                }}
                            />
                        </div>
                    </div>
                )}
                {this.state.liveAccessRequests.length > 0 && (
                    <div className="slds-col slds-size_1-of-1 slds-text-align_right slds-m-bottom_medium">
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <div className="slds-align-bottom" style={{display: 'flex'}}>
                                <b>Showing:</b>
                                <p> {`(${this.state.startIndex + 1} - ${Math.min(this.state.endIndex, this.state.liveAccessRequests.length)}) of ${this.state.liveAccessRequests.length}`} </p>
                            </div>
                            <div style={{visibility: this.state.liveAccessRequests.length > this.state.pageSize ? "visible" : "hidden", paddingRight: "1vw"}}>
                            <Button
                                label="<"
                                onClick={() =>
                                    this.setState((prevState) => ({
                                        currentPage: Math.max(prevState.currentPage - 1, 1),
                                    }), () => this.setPaginationControls())
                                }
                                disabled={this.state.currentPage === 1}
                                className={`slds-button ${
                                    this.state.currentPage === 1
                                        ? 'slds-button_neutral'
                                        : 'slds-button_brand'
                                }`}
                            />
                            <span> Page {this.state.currentPage} of {this.state.totalPages} </span>
                            <Button
                                label=">"
                                onClick={() =>
                                    this.setState((prevState) => ({
                                        currentPage: Math.min(
                                            prevState.currentPage + 1,
                                            Math.ceil(this.state.liveAccessRequests.length / this.state.pageSize)
                                        ),
                                    }), () => this.setPaginationControls())
                                }
                                disabled={
                                    this.state.currentPage ===
                                    Math.ceil(this.state.liveAccessRequests.length / this.state.pageSize)
                                }
                                className={`slds-button ${
                                    this.state.currentPage ===
                                    Math.ceil(this.state.liveAccessRequests.length / this.state.pageSize)
                                        ? 'slds-button_neutral'
                                        : 'slds-button_brand'
                                }`}
                            />
                            </div>
                        </div>
                    </div>
                )}
                {this.state.loading ? (
                    <EmptyPageSpinner/>
                ) : this.state.liveAccessRequests.length > 0 ? (
                    <div style={{wordWrap: 'break-word', width: '99%'}}>
                        <DataTable
                            resizeBehavior="auto"
                            striped={true}
                            columnBordered={true}
                            items={this.state.displayedData}
                            fixedHeader={true}
                            id="ReviewAccessRequestsTable"
                        >
                            <DataTableColumn
                                label={CONSTANT_ACCESS_REQUEST.ACCESS_TYPE}
                                property='role_name'>
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCESS_REQUEST.REQUESTER}
                                property='sfdc_email'>
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCESS_REQUEST.REVIEWER}
                                property='reviewer'>
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCESS_REQUEST.CREATION}
                                property='created_at_date'>
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCESS_REQUEST.EXPIRATION}
                                property='expiry_date'>
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCESS_REQUEST.REASON}
                                property='complete_reason'>
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCESS_REQUEST.STATUS}
                                property='calculated_status'>
                                <this.CustomReviewRequestCell/>
                            </DataTableColumn>
                            <DataTableColumn
                                label={CONSTANT_ACCESS_REQUEST.ACTIONS}
                                property="id">
                                <this.CustomReviewRequestActionsCell />
                            </DataTableColumn>
                        </DataTable>
                    </div>
                ) : this.state.selectedAccount.length > 0 && (
                    <div className="slds-col slds-size_1-of-1">
                        <p> {CONSTANT_ACCESS_REQUEST.SEARCH_ACC_REQ_NO_RECORD} </p>
                    </div>
                )}
            </div>
            </div>
        );
    }
}

export default SearchAccessRequests;
