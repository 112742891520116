import React, { useEffect, useState } from "react";
import {
    Card,
    Icon,
    Button,
    Accordion,
    AccordionPanel,
    Input, ButtonGroup, Dropdown
} from "@salesforce/design-system-react";
import { AddToastFunction } from "../../../../utils/types/toast";
import { CustomHeightPageSpinner } from "../../../../utils/components/spinner/page_spinner_overlay";
import { StackSetsDeploymentApiServiceFactory } from "../../../../services/deployment/stacksets/factory";
import { TOAST_MESSAGE } from "../../../../constants/toastMessages";
import {StackSetsDeployment} from "../../../../utils/types/deployments/stacksets/deployment";
import {StackSetOperation} from "../../../../utils/types/deployments/stacksets/operation";
import {StackSetStagger} from "../../../../utils/types/deployments/stacksets/stagger";
import BarChart, {BarChartRow} from "../../../../utils/components/bar_chart/bar_chart";
import {
    CONSTANT_STACKSETS_COMMIT_DEPLOYMENTS,
    CONSTANT_STACKSETS_DEPLOYMENTS,
    CONSTANT_STACKSETS_STAGGERS_DEPLOYMENTS
} from "../../../../constants/accountClosure";
import DeployStackSetCommitModal from "../../../../modals/pcsk_admin/deployments/stacksets/DeployNewCommitModal";
import ExpandStackSetCommitModal from "../../../../modals/pcsk_admin/deployments/stacksets/ExpandStackSetCommitModal";
import RevertStackSetCommitModal from "../../../../modals/pcsk_admin/deployments/stacksets/RevertStackSetCommitModal";
import {GenericDeploymentSubmissionResponse} from "../../../../utils/types/deployments/genericDeploymentResponse";
import QuickLinkList, {QuickLinkItem} from "../../../../utils/components/quick_links/QuickLinks";


const ITEMS_PER_PAGE = 10; // Define how many items to display per page

type StackSetDeploymentsProps = {
    addToast: AddToastFunction;
};

type FilterByStatusOption = {
    label: string;
    value: string;
};
const labelAll : FilterByStatusOption = {label: "All", value: "All"}

const PCSKAdminStackSetTemplateDeploymentsPage: React.FC<StackSetDeploymentsProps> = ({
                                                                                  addToast,
                                                                              }) => {
    const [stackSetsDeployments, setStackSetsDeployments] = useState<StackSetsDeployment[]>([]);
    const [filteredSetsDeployments, setFilteredSetsDeployments] = useState<StackSetsDeployment[]>([]);
    const [isFetchingDeployments, setIsFetchingDeployments] = useState<boolean>(true);
    const [selectedCommit, setSelectedCommit] = useState<StackSetsDeployment | null>(null);
    const [selectedStackSet, setSelectedStackSet] = useState<StackSetOperation | null>(null);
    const [selectedStagger, setSelectedStagger] = useState<StackSetStagger | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filterDropdownOptions, setFilterDropdownOptions] = useState<FilterByStatusOption[]>([labelAll])
    const [selectedDropDownFilter, setSelectedDropDownFilter] = useState<FilterByStatusOption>(labelAll)
    const [commitsBarChartData, setCommitsBarChartData] = useState<BarChartRow[]>([])
    const [stackSetsBarChartData, setStackSetsBarChartData] = useState<BarChartRow[]>([])
    const [staggerBarChartData, setStaggerBarChartData] = useState<BarChartRow[]>([])
    const [isDeployNewCommitModalOpen, setIsDeployNewCommitModalOpen] = useState<boolean>(false);
    const [isExpandCommitModalOpen, setIsExpandCommitModalOpen] = useState<boolean>(false);
    const [isRevertCommitModalOpen, setIsRevertCommitModalOpen] = useState<boolean>(false);

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, filteredSetsDeployments.length);
    const stackSetsApiService = StackSetsDeploymentApiServiceFactory.getService();

    useEffect(() => {
        fetchStackSetsDeployments();
    }, []);

    useEffect(() => {
        setSelectedStackSet(null); // Reset when switching commits
        const statusCounts = new Map<string, number>();

        // Count the number of unique items by deployment_status
        selectedCommit?.changes.forEach(item => {
            const status = item.stackset_status || "Unknown";

            // Update the map with the current count for each status, defaulting to 1 if not already present
            statusCounts.set(status, (statusCounts.get(status) ?? 0) + 1);
        });
        // Calculate the total sum of all counts
        const totalSum = Array.from(statusCounts.values()).reduce((sum, count) => sum + count, 0);

        // Build bar chart data
        const newBarChartData = Array.from(statusCounts.entries()).map(([status, count]) => {
            const percentage = totalSum > 0 ? (count / totalSum) * 100 : 0; // Handle division by zero
            const formattedPercentage = parseFloat(percentage.toFixed(Number.isInteger(percentage) ? 0 : 2));
            return {
                label: status || "None",
                value: count,
                percentage: formattedPercentage,
                class: CONSTANT_STACKSETS_DEPLOYMENTS.GET_STATUS_THEME(status),
            };
        });

        setStackSetsBarChartData(newBarChartData);
    }, [selectedCommit]);

    useEffect(() => {
        setSelectedStagger(null); // Reset when switching stackset
        const statusCounts = new Map<string, number>();

        // Count the number of unique items by deployment_status
        selectedStackSet?.staggers.forEach(item => {
            const status = item.stackset_status || "Unknown";

            // Update the map with the current count for each status, defaulting to 1 if not already present
            statusCounts.set(status, (statusCounts.get(status) ?? 0) + 1);
        });

        // Calculate the total sum of all counts
        const totalSum = Array.from(statusCounts.values()).reduce((sum, count) => sum + count, 0);

        // Build bar chart data
        const newBarChartData = Array.from(statusCounts.entries()).map(([status, count]) => {
            const percentage = totalSum > 0 ? (count / totalSum) * 100 : 0; // Handle division by zero
            const formattedPercentage = parseFloat(percentage.toFixed(Number.isInteger(percentage) ? 0 : 2));
            return {
                label: status || "None",
                value: count,
                percentage: formattedPercentage,
                class: CONSTANT_STACKSETS_STAGGERS_DEPLOYMENTS.GET_STATUS_THEME(status),
            };
        });

        setStaggerBarChartData(newBarChartData);
    }, [selectedStackSet]);

    useEffect(() => {
        filterDeployments();
    }, [searchTerm, currentPage, stackSetsDeployments, selectedDropDownFilter]);

    useEffect(() => {
        // Create a Set to store unique deployment statuses
        const uniqueStatuses = new Set(stackSetsDeployments.map(deployment => deployment.deployment_status));

        // Create the filter options from unique deployment statuses
        const filterByStatusOptions: FilterByStatusOption[] = Array.from(uniqueStatuses).map(status => ({
            label: status,
            value: status
        }));

        // Add "All" as an option
        filterByStatusOptions.push({ label: "All", value: "All" });
        filterByStatusOptions.sort((a, b) => a.value.localeCompare(b.value));

        setFilterDropdownOptions(filterByStatusOptions);
    }, [stackSetsDeployments]);

    useEffect(() => {
        const statusCounts = new Map<string, number>();

        // Count the number of unique items by deployment_status
        filteredSetsDeployments.forEach(item => {
            const status = item.deployment_status || "Unknown";

            // Update the map with the current count for each status, defaulting to 1 if not already present
            statusCounts.set(status, (statusCounts.get(status) ?? 0) + 1);
        });
        // Calculate the total sum of all counts
        const totalSum = Array.from(statusCounts.values()).reduce((sum, count) => sum + count, 0);

        // Build bar chart data
        const newBarChartData = Array.from(statusCounts.entries()).map(([status, count]) => {
            const percentage = totalSum > 0 ? (count / totalSum) * 100 : 0; // Handle division by zero
            const formattedPercentage = parseFloat(percentage.toFixed(Number.isInteger(percentage) ? 0 : 2));
            return {
                label: status || "None",
                value: count,
                percentage: formattedPercentage,
                class: CONSTANT_STACKSETS_COMMIT_DEPLOYMENTS.GET_STATUS_THEME(status),
            };
        });

        setCommitsBarChartData(newBarChartData);
    }, [filteredSetsDeployments]);

    const fetchStackSetsDeployments = async () => {
        setIsFetchingDeployments(true);
        try {
            const response = await stackSetsApiService.getDeployments();
            setStackSetsDeployments(response);
        } catch (error) {
            addToast("", TOAST_MESSAGE.FAILED_LOADING_STACK_SET_DEPLOYMENTS(`${error}`), "error");
        } finally {
            setIsFetchingDeployments(false);
        }
    };

    const handleSelectCommit = (commit: StackSetsDeployment) => {
        setSelectedCommit(selectedCommit === commit ? null : commit);
    };

    const handleSelectStackSet = (operation: StackSetOperation) => {
        setSelectedStackSet(selectedStackSet === operation ? null : operation);
    };

    const handleSelectStagger = (stagger: StackSetStagger) => {
        setSelectedStagger(selectedStagger === stagger ? null : stagger);
    };

    const handleExpand = () => {
        setIsExpandCommitModalOpen(true)
    };

    const handleRevert = () => {
        setIsRevertCommitModalOpen(true)
    };

    // Filter deployments based on search term
    const filterDeployments = () => {
        let data = stackSetsDeployments.filter(deployment => {
            return (
                deployment.commit_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                deployment.commit_title.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        if (selectedDropDownFilter.value != labelAll.value) {
            data = data.filter(deployment => {
                return deployment.deployment_status == selectedDropDownFilter.value;
            });
        }

        setFilteredSetsDeployments(data)
    };

    // Get the current page's deployments
    const getCurrentPageDeployments = () => {
        return filteredSetsDeployments.slice(startIndex, endIndex);
    };

    const resetPage = () => {
        setSelectedCommit(null)
        setSelectedStackSet(null)
        setSelectedStagger(null)
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page when searching
        resetPage()
    };

    const handleNextPage = () => {
        if ((currentPage * ITEMS_PER_PAGE) < filteredSetsDeployments.length) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const getCommitsBarChartSize = () => {
        return `slds-size_1-of-${commitsBarChartData.length <= 0 ? 1 : commitsBarChartData.length}`
    }
    const getStackSetsBarChartSize = () => {
        return `slds-size_1-of-${stackSetsBarChartData.length <= 0 ? 1 : stackSetsBarChartData.length}`
    }
    const getStaggersBarChartSize = () => {
        return `slds-size_1-of-${staggerBarChartData.length <= 0 ? 1 : staggerBarChartData.length}`
    }

    const getDetailsLi = (key: string, value: string) => {
        return <>
            <li>
                <b className="slds-text-color--weak slds-text-body_small">{key}:</b> {value}
            </li>
        </>
    }

    const getDeploymentsCard = () => {
        if (isFetchingDeployments || stackSetsDeployments.length > 0) {
            return <>
                {/* First Box: Commits */}
                <Card
                    heading="PCSK StackSet Template Deployments"
                    icon={<Icon category="standard" name="template" size="small"/>}
                    className={"slds-m-left_small slds-m-right--small slds-m-bottom_small slds-p-right_medium slds-p-left_medium slds-p-bottom_medium"}
                    bodyClassName={"slds-p-around_small"}
                    style={{minHeight: "50vh"}}
                >
                    <div
                        className="slds-grid slds-wrap slds-m-bottom_small slds-m-top--x-small">
                        {/* Search bar */}
                        <div
                            className="slds-col slds-size_2-of-4 slds-p-right_small slds-text-color--weak">
                            <h2 className={"slds-text-title_bold"}> Search Commits</h2>
                            <Input
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search by Commit ID or Title"
                                className="slds-m-bottom_small slds-m-top--x-small"
                                disabled={isFetchingDeployments}
                            />
                        </div>

                        <div className="slds-col slds-size_1-of-4 slds-p-horizontal_small">
                            <label
                                className="slds-form-element__label slds-text-title_bold slds-text-color--weak">Deployment Status</label>
                            <div className="slds-form-element__control">
                                <Dropdown
                                    align="left"
                                    iconCategory="utility"
                                    iconName="filter"
                                    iconPosition="right"
                                    label={selectedDropDownFilter.label}
                                    options={filterDropdownOptions}
                                    onSelect={(selectedFilter: FilterByStatusOption) => {
                                        setSelectedDropDownFilter(selectedFilter)
                                    }}
                                    disabled={isFetchingDeployments}
                                />
                            </div>
                        </div>
                        <div className="slds-col slds-size_1-of-4 slds-p-horizontal_small">
                            <label className="slds-form-element__label"></label>
                            <div className="slds-form-element__control">
                                <Button
                                    label="Deploy New Commit"
                                    variant="brand"
                                    onClick={() => {setIsDeployNewCommitModalOpen(true)}}
                                    disabled={isFetchingDeployments}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Bar Chart */}
                    {isFetchingDeployments ? <CustomHeightPageSpinner size="medium" minHeight="50vh"/> : <>
                    {
                        filteredSetsDeployments.length > 0 && <div className="slds-grid slds-wrap">
                            <div className="slds-col">
                                <BarChart
                                    heading={"Commit Deployment Status Distribution"}
                                    data={commitsBarChartData}
                                    liClass={getCommitsBarChartSize()}
                                    headerClass={"slds-text-title_bold slds-text-color--weak"}
                                    contentText={"Commit"}
                                />
                            </div>
                        </div>
                    }

                    {filteredSetsDeployments.length > 0 && <h2 className={"slds-text-title_bold slds-text-color--weak slds-m-top--x-small"}> Commits </h2>}
                    <Accordion>
                        {getCurrentPageDeployments().map((deployment, index) => (
                            <AccordionPanel
                                key={index}
                                expanded={selectedCommit?.commit_id === deployment.commit_id}
                                onTogglePanel={() => handleSelectCommit(deployment)}
                                summary={
                                    <div className="slds-text-wrap" style={{whiteSpace: 'normal'}}>
                                        {`${(currentPage - 1) * ITEMS_PER_PAGE + index + 1}. ${deployment.commit_title}`}
                                    </div>
                                }
                            >
                                <div className="slds-grid slds-box">
                                    <div
                                        className="slds-col slds-size_2-of-3 slds-m-horizontal--x-small">

                                        <ul className="slds-list_dotted">
                                            {getDetailsLi("Commit ID", deployment.commit_id)}
                                            {getDetailsLi("Deployment Status", deployment.deployment_status)}
                                            {getDetailsLi("Developer", deployment.developer_id)}
                                            {getDetailsLi("Created At", deployment.getCreatedAtDate())}
                                            {getDetailsLi("Updated At", deployment.getUpdatedAtDate())}
                                        </ul>
                                    </div>
                                    {deployment && deployment.hasActions() && <Card
                                        heading="Commit Actions"
                                        icon={<Icon category="standard" name="actions_and_buttons"
                                                    size="small"/>}
                                        bodyClassName={"slds-p-right_medium slds-p-left_medium slds-p-bottom_medium"}
                                    >
                                        <div className="slds-row slds-size_1-of-3 slds-text-align--left">
                                            <div className="slds-button-group slds-vertical">
                                                {deployment.can_expand && <Button
                                                    label="Expand"
                                                    variant="neutral"
                                                    className="slds-m-bottom_small slds-m-around_small"
                                                    onClick={handleExpand}
                                                />}
                                                {deployment.can_revert && <Button
                                                    label="Revert"
                                                    variant="text-destructive"
                                                    className="slds-m-bottom_small slds-m-around_small"
                                                    onClick={handleRevert}
                                                />}
                                            </div>
                                        </div>
                                    </Card>}
                                </div>
                            </AccordionPanel>
                        ))}
                    </Accordion>

                    {/* Pagination display */}
                    {Math.min(endIndex, filteredSetsDeployments.length) > 0 &&
                        <div className="slds-grid slds-m-bottom--x-small slds-m-top_medium">
                            <div
                                className="slds-col slds-size_1-of-2 slds-align-middle slds-m-left_medium slds-text-title_bold slds-text-color_success">
                                        <span>
                                            Showing {startIndex + 1} - {Math.min(endIndex, filteredSetsDeployments.length)} of {filteredSetsDeployments.length}
                                        </span>
                            </div>
                            {/* Pagination buttons */}
                            <div className="slds-col slds-size_1-of-2 slds-text-align--right">
                                <ButtonGroup className="slds-m-right_medium">
                                    <Button
                                        label="Previous"
                                        variant="neutral"
                                        onClick={handlePrevPage}
                                        disabled={currentPage <= 1}
                                    />
                                    <Button
                                        label="Next"
                                        onClick={handleNextPage}
                                        disabled={(currentPage * ITEMS_PER_PAGE) >= filteredSetsDeployments.length}
                                    />
                                </ButtonGroup>
                            </div>
                        </div>
                    }
                    </>}
                </Card>
            </>
        }

        return <>
            <div className="slds-size_1-of-1">
                <Card
                    heading="PCSK StackSet Templates Deployments"
                    icon={<Icon category="standard" name="code_playground" size="small"/>}
                    className="slds-m-horizontal--small slds-m-top--small"
                    bodyClassName={"slds-p-right_medium slds-p-left_medium"}
                >
                    <div className="slds-m-top_medium slds-m-bottom_medium slds-text-align_center">
                        <div
                            className="slds-text-title">
                            <p className="slds-text-heading_medium slds-text-color_default">
                                Found No StackSets Deployments
                            </p>
                            <Button
                                className={"slds-m-top--medium"}
                                label="Deploy New Commit"
                                variant="brand"
                                onClick={() => {
                                    setIsDeployNewCommitModalOpen(true)
                                }}
                                disabled={isFetchingDeployments}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        </>
    }

    const getChangesCard = () => {
        return <>
            {/* Second Box: Stackset Operations with Accordion */}
            {selectedCommit && (
                <Card
                    heading="Changes"
                    icon={<Icon category="standard" name="code_set" size="small"/>}
                    className={"slds-m-right--small slds-m-left_small slds-m-bottom_small"}
                    bodyClassName={"slds-p-left--large slds-p-right--large slds-p-bottom--large slds-p-top--medium"}
                    style={{minHeight: "50vh"}}
                >
                    {
                        selectedCommit.changes.length > 0 ?
                            <div className="slds-grid slds-wrap">
                                <div className="slds-col">
                                    <BarChart
                                        heading={"Change Deployment Status Distribution"}
                                        data={stackSetsBarChartData}
                                        liClass={getStackSetsBarChartSize()}
                                        headerClass={"slds-text-title_bold slds-text-color--weak"}
                                        contentText={"StackSet"}
                                    />
                                </div>
                            </div> :
                            <>
                                <div className="slds-grid slds-wrap">
                                    <div className="slds-col">
                                        <p>No Stacksets were changed in this commit</p>
                                    </div>
                                </div>
                            </>
                    }

                    {selectedCommit && selectedCommit.changes.length > 0 &&
                        <h2 className={"slds-text-title_bold slds-text-color--weak slds-m-top--x-small"}> StackSets</h2>}
                    <Accordion>
                        {selectedCommit.changes.length > 0 ? selectedCommit.changes.map((operation, index) => (
                            <AccordionPanel
                                key={index}
                                expanded={selectedStackSet?.id === operation.id}
                                onTogglePanel={() => handleSelectStackSet(operation)}
                                summary={
                                    <div className="slds-text-wrap" style={{whiteSpace: 'normal'}}>
                                        {`${index + 1}. ${operation.stackset_id}`}
                                    </div>
                                }
                                className="slds-m-bottom_small"
                            >
                                <div className="slds-grid slds-box">
                                    <ul className="slds-list_dotted">
                                        <li>
                                            <b className="slds-text-color--weak slds-text-body_small">Status:</b> {operation.stackset_status}
                                        </li>
                                        {operation.operation_type && <li>
                                            <b className="slds-text-color--weak slds-text-body_small">Operation
                                                Type:</b> {operation.operation_type}
                                        </li>}
                                    </ul>
                                </div>
                            </AccordionPanel>
                        )) : <>
                            <div className="slds-grid slds-wrap">
                                <div className="slds-col">
                                    <p>No Stagger Groups</p>
                                </div>
                            </div>
                        </>}
                    </Accordion>
                </Card>
            )}
        </>
    }


    const getStaggersCard = () => {
        return <>
            {/* Third Box: Stackset Operation Staggers with Accordion */}
            {selectedCommit && selectedStackSet && (
                <Card
                    heading="Stagger Groups"
                    icon={<Icon category="standard" name="data_streams" size="small"/>}
                    className={"slds-m-right--small slds-m-left_small slds-m-bottom_small"}
                    bodyClassName={"slds-p-left--large slds-p-right--large slds-p-bottom--large slds-p-top--medium"}
                    style={{minHeight: "50vh"}}
                >
                    {/* Bar Chart */}
                    {
                        selectedStackSet.staggers.length > 0 ? <div className="slds-grid slds-wrap">
                            <div className="slds-col">
                                <BarChart
                                    heading={"Stagger Deployment Status Distribution"}
                                    data={staggerBarChartData}
                                    liClass={getStaggersBarChartSize()}
                                    headerClass={"slds-text-title_bold slds-text-color--weak"}
                                    contentText={"Stagger"}
                                />
                            </div>
                        </div> : <>
                            <p>Found No Staggers</p>
                        </>
                    }

                    {selectedStackSet && selectedStackSet.staggers.length > 0 &&
                        <h2 className={"slds-text-title_bold slds-text-color--weak slds-m-top--x-small"}> Staggers </h2>}
                    <Accordion>
                        {selectedStackSet.staggers.map((stagger, index) => (
                            <AccordionPanel
                                key={index}
                                expanded={selectedStagger?.stagger_group_name === stagger.stagger_group_name}
                                onTogglePanel={() => handleSelectStagger(stagger)}
                                summary={
                                    <div className="slds-text-wrap" style={{whiteSpace: 'normal'}}>
                                        {`${index + 1}. ${stagger.stagger_group_name}`}
                                    </div>
                                }
                                className="slds-box slds-m-bottom_small"
                            >
                                <div className="slds-grid slds-box">
                                    <ul className="slds-list_dotted">
                                        <li>
                                            <b className="slds-text-color--weak slds-text-body_small">Status:</b> {stagger.stackset_status}
                                        </li>
                                        <li>
                                            <b className="slds-text-color--weak slds-text-body_small">Operation
                                                Type:</b> {stagger.operation_type}
                                        </li>
                                        <li>
                                            <b className="slds-text-color--weak slds-text-body_small">Created
                                                At:</b> {stagger.getCreatedAtDate()}
                                        </li>
                                        <li>
                                            <b className="slds-text-color--weak slds-text-body_small">Updated
                                                At:</b> {stagger.getUpdatedAtDate()}
                                        </li>
                                    </ul>
                                </div>
                            </AccordionPanel>
                        ))}
                    </Accordion>
                </Card>
            )}
        </>
    }

    // Useful links Data
    const usefulPCSKLinks: QuickLinkItem[] = [
        { text: "Admin StackSets Deployment Guide", link: "https://salesforce.quip.com/ntQgAbKVstB9" },
        { text: "StackSets Repo", link: "https://git.soma.salesforce.com/identity-access/pcsk-stacksets" },
        { text: "Deployment Runbook", link: "https://salesforce.quip.com/WBooASITifGJ" },
        { text: "PCSK Troubleshooting Guide", link: "https://salesforce.quip.com/1c4HA0Gl48Vr" },
        { text: "PCSK Availability", link: "https://tabse.internal.salesforce.com/views/UnifiedServiceHealthREADS/SFHealthTrend?Senior%20Engineering%20Leader=Paul%20Constantinides&Service%2FFeature=pcsk-jit&%3Aembed=y&%3Aoriginal_view=y#1" },
    ];

    return <>
        <QuickLinkList usefulPCSKLinks={usefulPCSKLinks} />
        <div className={"slds-grid"}>
            <div className="slds-col slds-size_2-of-4">
                {getDeploymentsCard()}
            </div>
            <div className="slds-col slds-size_1-of-4">
                {getChangesCard()}
            </div>
            <div className="slds-col slds-size_1-of-4">
                {getStaggersCard()}
            </div>
        </div>

        {isDeployNewCommitModalOpen && <DeployStackSetCommitModal
            isOpen={isDeployNewCommitModalOpen}
            onClose={() => setIsDeployNewCommitModalOpen(false)}
            onDeploySuccess={(commitID: string, apiResponse: GenericDeploymentSubmissionResponse) => {
                addToast("Deployment Request Submitted Successfully!", `commit: ${commitID} has been submitted for deployment.`, "success");
                setIsDeployNewCommitModalOpen(false)
            }}
        />}

        {isExpandCommitModalOpen && selectedCommit && <ExpandStackSetCommitModal
            isOpen={isExpandCommitModalOpen}
            onClose={() => setIsExpandCommitModalOpen(false)}
            selectedCommit={selectedCommit}
            onExpandSuccess={(apiResponse: GenericDeploymentSubmissionResponse) => {
                addToast("Expand Request Submitted Successfully!", `commit: ${selectedCommit?.commit_id} has been submitted for Expansion.`, "success");
                setIsExpandCommitModalOpen(false)
            }}
        />}

        {isRevertCommitModalOpen && selectedCommit && <RevertStackSetCommitModal
            isOpen={isRevertCommitModalOpen}
            onClose={() => setIsRevertCommitModalOpen(false)}
            selectedCommit={selectedCommit}
            onRevertSuccess={(apiResponse: GenericDeploymentSubmissionResponse) => {
                addToast("Revert Request Submitted Successfully!", `commit: ${selectedCommit?.commit_id} has been submitted for Revert.`, "success");
                setIsRevertCommitModalOpen(false)
            }}
        />}
    </>
};

export default PCSKAdminStackSetTemplateDeploymentsPage;
