
import {StackSetsDeployment} from "../../../utils/types/deployments/stacksets/deployment";
import {BASE_URL, HandleErrorResponse} from "../../common";
import {StackSetsDeploymentApiService} from "./service";
import {GenericDeploymentSubmissionResponse} from "../../../utils/types/deployments/genericDeploymentResponse";

export class MainStackSetsDeploymentApiService implements StackSetsDeploymentApiService {
    readonly adminBasePath = `${BASE_URL}/pcsk-admin/deployments/stacksets`;
    async getDeployments(): Promise<StackSetsDeployment[]> {
        const endpoint = `${this.adminBasePath}`;

        try {
            const response = await fetch(endpoint, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const resp = await response.json();
            return StackSetsDeployment.fromJSON(resp)
        } catch (error) {
            console.error("Error fetching deployments:", error);
            throw error;
        }
    }

    async deployCommit(changeCase: string, commitID: string, commitTitle: string, force: boolean, auto: boolean, dryRun: boolean): Promise<GenericDeploymentSubmissionResponse> {
        const endpoint = `${this.adminBasePath}/new`;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    commit_id: commitID,
                    auto: auto,
                    force: force,
                    change_case: changeCase,
                    dry_run: dryRun,
                    commit_title: commitTitle,
                })
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const resp = await response.json();
            return new GenericDeploymentSubmissionResponse(resp.message, resp.success)
        } catch (error) {
            console.error("Error starting new deployment:", error);
            throw error;
        }
    }

    async expandCommit(changeCase: string, commitID: string, force: boolean, auto: boolean, dryRun: boolean): Promise<GenericDeploymentSubmissionResponse> {
        const endpoint = `${this.adminBasePath}/expand`;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    commit_id: commitID,
                    auto: auto,
                    force: force,
                    change_case: changeCase,
                    dry_run: dryRun,
                })
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const resp = await response.json();
            return new GenericDeploymentSubmissionResponse(resp.message, resp.success)
        } catch (error) {
            console.error("Error expanding commit:", error);
            throw error;
        }
    }

    async revertCommit(changeCase: string, commitID: string, commitTitle: string, revertCommitID: string, force: boolean, dryRun: boolean): Promise<GenericDeploymentSubmissionResponse> {
        const endpoint = `${this.adminBasePath}/revert`;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    commit_id: commitID,
                    commit_title: commitTitle,
                    revert_commit_id: revertCommitID,
                    force: force,
                    change_case: changeCase,
                    dry_run: dryRun,
                })
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const resp = await response.json();
            return new GenericDeploymentSubmissionResponse(resp.message, resp.success)
        } catch (error) {
            console.error("Error reverting commit:", error);
            throw error;
        }
    }
}

