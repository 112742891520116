import { IsMockEnabled } from "../constants/accessRequest";
import {BASE_URL, HandleErrorResponse} from "./common";

export const approveAccessRequest = async (accessRequestID: string) => {
    if (IsMockEnabled()) {
        console.log('Mock Approval Given !!')
        return;
    }
    const endpoint = `${BASE_URL}/access-requests/${accessRequestID}/approve`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
        });
        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return response;
    } catch (error) {
        console.error("Error approving access request:", error);
        throw error;
    }
};

export const rejectAccessRequest = async (accessRequestID: string) => {
    if (IsMockEnabled()) {
        console.log('Mock Reject Done !!')
        return;
    }
    const endpoint = `${BASE_URL}/access-requests/${accessRequestID}/reject`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
        });
        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return response;
    } catch (error) {
        console.error("Error rejecting access request:", error);
        throw error;
    }
};

