import { IsMockEnabled } from "../constants/accessRequest";
import {mock_aws_accounts_response} from "../utils/mocks/awsAccountsResponse";
import {BASE_URL, HandleErrorResponse} from "./common";

export const fetchAccounts = async () => {
    const endpoint = `${BASE_URL}/aws-accounts`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}

export const fetchAccountDetails = async (accountID: string) => {
    const endpoint = `${BASE_URL}/aws-accounts/${accountID}`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching reason data:", error);
        throw error;
    }
}

export const fetchOwnedAccounts = async () => {
    if (IsMockEnabled()) {
        const waitForTimeout = (ms: number): Promise<void> => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, ms);
            });
        }
        await waitForTimeout(1000);
        return mock_aws_accounts_response;
    }

    const endpoint = `${BASE_URL}/aws-accounts?filter%5Bteam_only%5D=true`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching reason data:", error);
        throw error;
    }
}
export const fetchSlackChannel = async () => {
    const endpoint = `${BASE_URL}/teams`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching reason data:", error);
        throw error;
    }
}