import React from 'react';
import {DataTable, DataTableColumn, Modal} from '@salesforce/design-system-react';
import CustomBreakDataTableCell from '../utils/components/table_cell/custom_break_cell';
import {AccountStackSetDriftInfo} from "../utils/types/stackSetDrift";

interface Props {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    data: AccountStackSetDriftInfo | null;
}

type DriftDetails = {
    LogicalResourceId: string;
    ResourceType: string;
    StackId: string;
    PhysicalResourceId: string;
    StackResourceDriftStatus: string;
    PropertyPath: string;
    ExpectedValue: string;
    ActualValue: string;
    DifferenceType: string;
}

const StackSetDriftInfoModal: React.FC<Props> = ({isModalOpen, setIsModalOpen, data}) => {

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const normalizeDriftStatus = (data: AccountStackSetDriftInfo | null): DriftDetails[] => {
        const driftDetails: DriftDetails[] = [];

        if (!data || !data.drift_details) {
            return driftDetails;
        }

        for (const drift of data.drift_details) {
            for (const propertyDifference of drift.PropertyDifferences) {
                driftDetails.push({
                    LogicalResourceId: drift.LogicalResourceId,
                    ResourceType: drift.ResourceType,
                    StackId: drift.StackId,
                    PhysicalResourceId: drift.PhysicalResourceId,
                    StackResourceDriftStatus: drift.StackResourceDriftStatus,
                    PropertyPath: propertyDifference.PropertyPath,
                    ExpectedValue: propertyDifference.ExpectedValue,
                    ActualValue: propertyDifference.ActualValue,
                    DifferenceType: propertyDifference.DifferenceType
                });
            }
        }

        return driftDetails;
    }

    const getModalBody = () => {
        return (
            <div className="slds-p-around_medium">
                <DataTable
                    items={normalizeDriftStatus(data)}
                    id="StacksetInstanceDriftStatus"
                >
                    <DataTableColumn
                        label="Resource Type"
                        property="ResourceType"
                        truncate
                    ><CustomBreakDataTableCell size={10}/>
                    </DataTableColumn>

                    <DataTableColumn
                        label="Physical Resource Id"
                        property="PhysicalResourceId"
                        truncate
                        primaryColumn
                    ><CustomBreakDataTableCell size={10}/>
                    </DataTableColumn>

                    <DataTableColumn
                        label="Difference Type"
                        property="DifferenceType"
                        truncate
                    ><CustomBreakDataTableCell size={10}/>
                    </DataTableColumn>

                    <DataTableColumn
                        label="Property Path"
                        property="PropertyPath"
                        truncate
                    ><CustomBreakDataTableCell size={10}/>
                    </DataTableColumn>

                    <DataTableColumn
                        label="Expected Value"
                        property="ExpectedValue"
                        truncate
                    ><CustomBreakDataTableCell size={40}/>
                    </DataTableColumn>

                    <DataTableColumn
                        label="Actual Value"
                        property="ActualValue"
                        truncate
                    ><CustomBreakDataTableCell size={40}/>
                    </DataTableColumn>
                </DataTable>
            </div>
        );
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            size="large"
            heading={`Drift in account ${data?.account_id} for stackset ${data?.stackset_name}`}
            disableClose={false}
        >
            {getModalBody()}
        </Modal>
    );
};

export default StackSetDriftInfoModal;
