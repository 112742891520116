import React, {useEffect} from "react";
import {Icon, PageHeader, Tabs, TabsPanel} from "@salesforce/design-system-react";
import MyAccounts from "./my_accounts/MyAccounts";
import {selectedAccountInfo} from "../../utils/types/account";
import {ACCOUNT_MANAGEMENT_TABS_METADATA, CONSTANT_ACCOUNT} from "../../constants/accountManagement";
import {useUser} from "../../context/users";
import AccountVending from "./account_vending/AccountVending";
import StacksSetDrift from "./drift/stackset_drift/StackSetDrift";
import {ToastState} from "../../utils/types/toast";
import UpdateAccountDetails from "./update_account_details/UpdateAccountDetails";
import AccountClosure from "./account_closure/AccountClosure";
import PageSpinnerOverlay from "../../utils/components/spinner/page_spinner_overlay";

type AccountManagementProps = {
    activeTab: string;
    setActiveTab: (tab: string) => void;
    activeSubTab: number;
    setActiveSubTab: (tabID: number) => void;
    selectedAccount: selectedAccountInfo;
    setSelectedAccount: (accountInfo: selectedAccountInfo) => void;
    toast: ToastState;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
    requestedURL: URL
    fetchingUser: boolean
};

const AccountManagementObjectPage: React.FC<AccountManagementProps> = (props) => {
    const { user } = useUser();
    const titleLabel = ACCOUNT_MANAGEMENT_TABS_METADATA[user.allowed_account_creation !== "true" && props.activeSubTab > 0 ? Math.min(props.activeSubTab + 1, Object.keys(ACCOUNT_MANAGEMENT_TABS_METADATA).length - 1): props.activeSubTab];

    const handleTabSelect = (selectedIndex: number, data: any) => {
        props.setActiveSubTab(selectedIndex);
        props.setActiveSubTab(selectedIndex);
        props.setSelectedAccount({
            account_id: "",
        });
    };

    useEffect(() => {
        if (!props.fetchingUser) {
            if(props.requestedURL.pathname.startsWith('/update-accounts-team') || props.requestedURL.pathname.startsWith('/team')) {
                user.allowed_account_creation !== "true" ? props.setActiveSubTab(1) : props.setActiveSubTab(2)
            } else if (props.requestedURL.pathname.startsWith('/account-closure')) {
                user.allowed_account_creation !== "true" ? props.setActiveSubTab(2) : props.setActiveSubTab(3)
            } else if (props.requestedURL.pathname.startsWith('/stackset-drift')) {
                user.allowed_account_creation !== "true" ? props.setActiveSubTab(3) : props.setActiveSubTab(4)
            } else {
                props.setActiveSubTab(0)
            }
        }
    }, [props.fetchingUser])

    if (props.fetchingUser) {
        return (
            <div style={{minHeight: '80vh'}}>
                <PageSpinnerOverlay/>
            </div>
        )
    } else {
        return (
            <div className="slds-m-around--x-small">
                <div className="slds-m-bottom--x-small">
                    <PageHeader
                        icon={
                            <Icon
                                assistiveText={{label: "User"}}
                                category="standard"
                                name="account"
                            />
                        }
                        label={CONSTANT_ACCOUNT.ACCOUNT_MANAGEMENT}
                        title={titleLabel}
                        variant="object-home"
                    />
                </div>
                <Tabs
                    variant="vertical"
                    id="access_requests"
                    selectedIndex={props.activeSubTab}
                    onSelect={handleTabSelect}
                >
                    <TabsPanel label={CONSTANT_ACCOUNT.MY_ACCOUNTS}>
                        <MyAccounts
                            isActive={props.activeSubTab === 0}
                            setActiveTab={props.setActiveTab}
                            selectedAccount={props.selectedAccount}
                            setActiveSubTab={props.setActiveSubTab}
                            setSelectedAccount={props.setSelectedAccount}
                            setToast={props.setToast}
                        />
                    </TabsPanel>
                    {user.allowed_account_creation === "true" && (
                        <TabsPanel label={CONSTANT_ACCOUNT.ACCOUNT_VENDING}>
                            <AccountVending
                                isActive={props.activeSubTab === 1}
                                setActiveTab={props.setActiveTab}
                                selectedAccount={props.selectedAccount}
                                setActiveSubTab={props.setActiveSubTab}
                                setSelectedAccount={props.setSelectedAccount}
                                toast={props.toast}
                                setToast={props.setToast}
                            />
                        </TabsPanel>
                    )}
                    <TabsPanel label={CONSTANT_ACCOUNT.UPDATE_ACCOUNT_DETAILS}>
                        <UpdateAccountDetails
                            isActive={props.activeSubTab === 2 || (user.allowed_account_creation === "false" && props.activeSubTab === 1)}
                            setActiveTab={props.setActiveTab}
                            setActiveSubTab={props.setActiveSubTab}
                            setToast={props.setToast}
                            requestedURL={props.requestedURL}
                        />
                    </TabsPanel>
                    <TabsPanel label={CONSTANT_ACCOUNT.ACCOUNT_CLOSURE}>
                        <AccountClosure
                            isActive={props.activeSubTab === 3 || (user.allowed_account_creation === "false" && props.activeSubTab === 2)}
                            setActiveTab={props.setActiveTab}
                            setActiveSubTab={props.setActiveSubTab}
                            toast={props.toast}
                            setToast={props.setToast}
                            requestedURL={props.requestedURL}
                        />
                    </TabsPanel>
                    {user.allowed_drift_view === "true" && (
                        <TabsPanel label={CONSTANT_ACCOUNT.STACKSET_DRIFT}>
                            <StacksSetDrift
                                isActive={props.activeSubTab === 4 || (user.allowed_account_creation === "false" && props.activeSubTab === 3)}
                                setActiveTab={props.setActiveTab}
                                setActiveSubTab={props.setActiveSubTab}
                                setToast={props.setToast}
                            />
                        </TabsPanel>
                    )}
                </Tabs>
            </div>
        );
    }
};

export default AccountManagementObjectPage;