import React from 'react';
import {BrandBand, Card, Icon} from '@salesforce/design-system-react';
import CustomSpinnerOverlay from "../../utils/components/spinner/custom_spinner_overlay"

const AdminAuthenticatingView: React.FC = () => {
    return (
        <BrandBand
            className="custom-brand-band-class"
            id="brand-band-large"
            size="large"
        >
            <div className="slds-align_absolute-center slds-m-around_xx-large">
                <img
                    src={`${process.env.PUBLIC_URL}/salesforce_logo_large.png`}
                    alt="Big Logo"
                    className="slds-image slds-size_medium"
                />
            </div>

            <div className="slds-p-around--large">
                <CustomSpinnerOverlay size="large"/>
            </div>
            <p className={"slds-align_absolute-center slds-m-top--medium"}>verifying identity...</p>


        </BrandBand>
    );
};

export default AdminAuthenticatingView;
