import React, { useState } from 'react';
import { Avatar, Button, Modal } from '@salesforce/design-system-react';
import {useUser} from "../../../context/users";

const ProfileCircle = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const {user } = useUser();

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    return (<>
        {/* Profile circle on the top right */}
        <Button onClick={toggleModal} className={"slds-align-middle"} variant={"icon"}>
            <strong className={"slds-m-right_xx-small slds-text-color--weak"}> {user.user_id.split('@')[0]} </strong>
            <Avatar alt="User Avatar" size="small"/>
        </Button>

        {/* Modal displaying user details */}
        <Modal
            isOpen={isModalOpen}
            onRequestClose={toggleModal}
            size="x-small"
            heading={<>
                <Avatar alt="User Avatar" size="large"/>
                <p>{`Hi ${user.user_id.split('@')[0]}`}</p>
            </>}
        >
            <div className="slds-modal__content slds-p-around_medium">
                <pre>{JSON.stringify(user, null, 2)}</pre>
            </div>
        </Modal>
        </>
    );
};

export default ProfileCircle;
