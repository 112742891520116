import React from 'react';
import { Modal, Button } from '@salesforce/design-system-react';
import {CONSTANT_ACCESS_REQUEST} from "../constants/accessRequest";

type RefreshConfirmationModalProps = {
    isOpen: boolean;
    toggleModal: (isOpen: boolean) => void;
    onConfirm: () => void;
};

const RefreshConfirmationModal: React.FC<RefreshConfirmationModalProps> = ({
   isOpen,
   toggleModal,
   onConfirm,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            disableClose
            prompt="warning"
            onRequestClose={() => toggleModal(false)}
            heading={CONSTANT_ACCESS_REQUEST.SESSION_EXPIRED}
            footer={[
                <Button label={CONSTANT_ACCESS_REQUEST.LOGIN} variant="brand" onClick={onConfirm} />,
            ]}
        >
            <div className="slds-p-around_large slds-text-align_center">
                Your session has expired. For security reasons, you have been logged out. Please login again.
                <div className="slds-p-top--large slds-has-divider--bottom"></div>
                If this issue persists, please reach out to us at #ask-iam channel
            </div>
        </Modal>
    );
};

export default RefreshConfirmationModal;
