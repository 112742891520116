import React, { useEffect, useState } from "react";
import CustomSpinnerOverlay from "../../../utils/components/spinner/custom_spinner_overlay"
import {GUSApiServiceFactory} from "../../../services/gus/factory";

interface Props {
    isValidChangeCase: boolean;
    disableInput: boolean;
    changeCaseID: string;
    setChangeCaseID: React.Dispatch<React.SetStateAction<string>>;
    setIsValidChangeCase: React.Dispatch<React.SetStateAction<boolean>>;
    searchKey: string;
}

const ChangeCaseInput: React.FC<Props> = ({
        changeCaseID,
        setChangeCaseID,
        disableInput, isValidChangeCase,
        setIsValidChangeCase,
        searchKey,
    }) => {
    const [isCCValidationInProgress, setIsCCValidationInProgress] = useState<boolean>(false);
    const [changeCaseMessage, setChangeCaseMessage] = useState<string>('');
    const [ccMessageColor, setCCMessageColor] = useState<string>('');
    const gusAPIService = GUSApiServiceFactory.getService();


    useEffect(() => {
        setChangeCaseID('')
        setChangeCaseMessage('')
        setIsValidChangeCase(false)
    }, []);

    const errorCCMessage = (message: string) => {
        setCCMessageColor("slds-text-color_destructive")
        setChangeCaseMessage(message)
    }

    const successCCMessage = (message: string) => {
        setCCMessageColor("slds-text-color_success")
        setChangeCaseMessage(message)
    }
    const handleChangeCaseInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Only update state if the value is a number or an empty string
        if (/^\d*$/.test(value)) {
            setChangeCaseID(value);
            setIsValidChangeCase(false)
            setChangeCaseMessage('')
            if (value.length >= 8) {
                setIsCCValidationInProgress(true);
                gusAPIService.fetchChangeCaseMetadata(value, searchKey).then((response) => {
                    if (response.generic_admin_actions_data.allowed) {
                        successCCMessage(response.metadata.subject);
                        setIsValidChangeCase(true);
                    } else {
                        errorCCMessage(response.generic_admin_actions_data.errors.join('\n'));
                        setIsValidChangeCase(false);
                    }
                }).catch((err) => {
                    let mssg = 'Error occurred. Please try again later.'
                    if (err.hasOwnProperty("message")) {
                        mssg = err.message
                    }
                    errorCCMessage(mssg);
                }).finally(() => {
                    setIsCCValidationInProgress(false);
                });
            } else {
                setIsValidChangeCase(false);
            }
        }
    };

    return (
        <>
            <div className="slds-grid slds-grid_vertical-align-center">
                <div>
                    <input
                        type="text"
                        className="slds-input"
                        onChange={(e) => handleChangeCaseInput(e)}
                        disabled={disableInput}
                        placeholder={"Change Case ID"}
                        value={changeCaseID}
                    />
                </div>
                <div className="slds-m-left_large">
                    {isCCValidationInProgress && (
                        <CustomSpinnerOverlay size="small"/>
                    )}
                </div>
            </div>
            {changeCaseMessage.length > 0 &&
            <div className={ccMessageColor}>
                <p>{changeCaseMessage}&nbsp;</p>
            </div>
            }
        </>
    );
}

export default ChangeCaseInput;