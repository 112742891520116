
import {BASE_URL, HandleErrorResponse} from "../common";
import {
    PCSKAccountClosureApprover,
    PCSKAdminClosureSummaryItem,
    PCSKAccountClosureItem, InsertAccountClosureResponse, ServicesComplianceExaminationResponse, ErroredAccount
} from "../../utils/types/accountClosure";
import {AccountClosureApiService} from "./service"
import {GenerateUUID} from "../../utils/uuid/uuid";

export class MainAccountClosureApiService implements AccountClosureApiService {
    readonly basePath = `${BASE_URL}/account-closures`;
    readonly adminBasePath = `${BASE_URL}/pcsk-admin/account-closures`;
    async getClosureApprovers(aws_account_id: string): Promise<PCSKAccountClosureApprover[]> {
        const endpoint = `${this.basePath}/${aws_account_id}/approvers`;

        try {
            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                }
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const jsonArray: any[] = await response.json();
            return jsonArray.map((item, index) => {
                return new PCSKAccountClosureApprover(item.email, item.type);
            });

        } catch (error) {
            console.error("Error fetching closure approvers:", error);
            throw error;
        }
    }

    async approveAccountClosure(aws_account_id: string): Promise<PCSKAccountClosureItem> {
        const endpoint = `${this.basePath}/approve`;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
                body: JSON.stringify({ "aws_account_id": aws_account_id })
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const respJSON = await response.json();
            return asPCSKAccountClosureItem(respJSON, 1);

        } catch (error) {
            console.error("Error approving closure request:", error);
            throw error;
        }
    }

    async getAccountClosures(): Promise<PCSKAccountClosureItem[]> {
        try {
            const response = await fetch(this.basePath, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const jsonArray: any[] = await response.json();
            return jsonArray.map((item, index) => {
                return asPCSKAccountClosureItem(item, index + 1);
            });

        } catch (error) {
            console.error("Error fetching active account closures:", error);
            throw error;
        }
    }
    async getAdminAccountClosures(): Promise<PCSKAccountClosureItem[]> {
        return this.getAccountClosures()
    }

    async getClosureSummary(): Promise<PCSKAdminClosureSummaryItem[]> {
        try {
            const response = await fetch(`${this.adminBasePath}/summary`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const jsonArray: any[] = await response.json();
            return jsonArray.map((item) => {
                return asPCSKAdminClosureSummaryItem(item);
            });

        } catch (error) {
            console.error("Error fetching active account closures:", error);
            throw error;
        }
    }

    async forceApproveAccount(accountGUID: string, changeCaseID: string): Promise<PCSKAccountClosureItem> {
        try {
            const response = await fetch(`${this.adminBasePath}/force-approve/${accountGUID}`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({change_case: changeCaseID})
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const approvalResponse = await response.json();

            if (approvalResponse.hasOwnProperty("error_message") && approvalResponse.error_message.length > 0) {
                throw new Error(approvalResponse.error_message)
            }
            if (approvalResponse.hasOwnProperty("account_closure")) {
                return asPCSKAccountClosureItem(approvalResponse.account_closure);
            }
            throw new Error("invalid response")

        } catch (error) {
            console.error("Error fetching admin account closures:", error);
            throw error;
        }
    }

    async insertAccounts(accountIDs: string[], changeCaseID: string): Promise<InsertAccountClosureResponse> {
        try {
            const response = await fetch(`${this.adminBasePath}/insert`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_request_token: GenerateUUID(),
                    account_ids: accountIDs,
                    change_case: changeCaseID
                })
            });
            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const insertResponse = await response.json();
            const resp = new InsertAccountClosureResponse({
                clientRequestToken: insertResponse.client_request_token ?? '',
                processed: [],
                unProcessed: [],
                errorMessage: insertResponse.error_message ?? '',
            });
            if (insertResponse.processed) {
                for (let i = 0; i < insertResponse.processed.length; i++) {
                    resp.processed.push(asPCSKAccountClosureItem(insertResponse.processed[i]));
                }
            }
            if (insertResponse.unprocessed) {
                for (let i = 0; i < insertResponse.unprocessed.length; i++) {
                    const errorServices: ServicesComplianceExaminationResponse[] = [];
                    if (insertResponse.unprocessed[i].hasOwnProperty('error_services') && insertResponse.unprocessed[i].error_services) {
                        for (let j = 0; j < insertResponse.unprocessed[i].error_services.length; j++) {
                            errorServices.push(new ServicesComplianceExaminationResponse({
                                service: insertResponse.unprocessed[i].error_services[j].service ?? '',
                                error: insertResponse.unprocessed[i].error_services[j].error ?? ''
                            }));
                        }
                    }
                    resp.unProcessed.push(new ErroredAccount({
                        accountId: insertResponse.unprocessed[i].account_id ?? '',
                        error: insertResponse.unprocessed[i].error ?? '',
                        errorServices: errorServices,
                    }));
                }
            }

            return resp;

        } catch (error) {
            console.error("Error inserting account closures:", error);
            throw error;
        }
    }

    async reinsertAccount(accountGUID: string, changeCaseID: string): Promise<PCSKAccountClosureItem> {
        try {
            const response = await fetch(`${this.adminBasePath}/re-insert/${accountGUID}`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({account_guid: accountGUID, change_case: changeCaseID})
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const reinsertResponse = await response.json();
            if (reinsertResponse.hasOwnProperty("error_message") && reinsertResponse.error_message.length > 0) {
                throw new Error(reinsertResponse.error_message)
            }
            if (reinsertResponse.hasOwnProperty("account_closure")) {
                return asPCSKAccountClosureItem(reinsertResponse.account_closure);
            }
            throw new Error("invalid response")

        } catch (error) {
            console.error("Error fetching admin account closures:", error);
            throw error;
        }
    }

    async updateAccountClosure(accountGUID: string, changeCaseID: string, waitingPeriod: number): Promise<PCSKAccountClosureItem> {
        try {
            const response = await fetch(`${this.adminBasePath}/update/${accountGUID}`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({change_case: changeCaseID, waiting_period: waitingPeriod})
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const editResponse = await response.json();
            if (editResponse.hasOwnProperty("error_message") && editResponse.error_message.length > 0) {
                throw new Error(editResponse.error_message)
            }
            if (editResponse.hasOwnProperty("account_closure")) {
                return asPCSKAccountClosureItem(editResponse.account_closure);
            }
            throw new Error("invalid response")

        } catch (error) {
            console.error("Error fetching admin account closures:", error);
            throw error;
        }
    }


}
const asPCSKAdminClosureSummaryItem = (response: any): PCSKAdminClosureSummaryItem => {
    // Ensure response object is valid and contains necessary properties
    const count = response?.count ?? 0;
    const status = response?.status ?? `Unknown`;

    return new PCSKAdminClosureSummaryItem(count, status);
};
const asPCSKAccountClosureItem = (response: any, index = 1): PCSKAccountClosureItem => {
    return new PCSKAccountClosureItem({
        approval_expiry_timestamp: response.approval_expiry_timestamp,
        approval_ids: response.approval_ids,
        approved_by_emails: response.approved_by_emails,
        aws_account_id: response.aws_account_id,
        account_uuid: response.account_uuid,
        can_approve: response.can_approve,
        closure_work_item_id: response.closure_work_item_id,
        closed_at: response.closed_at,
        email: response.email,
        exceptions: response.exceptions,
        fd: response.fd,
        fi: response.fi,
        force_approval_reason: response.force_approval_reason,
        force_approved_by_email: response.force_approved_by_email,
        has_crossed_waiting_period: response.has_crossed_waiting_period,
        index: index,
        is_approved: response.is_approved,
        is_closed: response.is_closed,
        is_expired: response.is_expired,
        is_falcon: response.is_falcon ?? false,
        last_notification_timestamp: response.last_notification_timestamp,
        min_approvers: response.min_approvers,
        name: response.name,
        org_account_id: response.org_account_id,
        requester_arn: response.requester_arn,
        request_timestamp: response.request_timestamp,
        scheduled_closure_message: response.scheduled_closure_message,
        sent_notifications_count: response.sent_notifications_count,
        services_examined: response.services_examined,
        state: response.state,
        status: response.status,
        team: response.team,
        waiting_period: response.waiting_period
    });
};