import React, {useEffect, useState} from "react";
import {
    Button,
    ButtonGroup,
    Card,
    Checkbox,
    DataTable,
    DataTableCell,
    DataTableColumn,
    ExpandableSection,
    Icon,
    Popover
} from "@salesforce/design-system-react";

import {AddToastFunction} from "../../../utils/types/toast";
import {AccountStackSetDriftInfo} from "../../../utils/types/stackSetDrift";
import {DriftDetectionApiServiceFactory} from "../../../services/drift_detection/factory";
import {CustomStatusCellProps} from "../../../utils/components/table_cell/auto_break_cell";
import {CONSTANT_ACCOUNT} from "../../../constants/accountManagement";
import {TOAST_MESSAGE} from "../../../constants/toastMessages";
import {CustomHeightPageSpinner} from "../../../utils/components/spinner/page_spinner_overlay";
import CustomBreakDataTableCell from "../../../utils/components/table_cell/custom_break_cell";
import StackSetDriftInfoModal from "../../../modals/StackSetDriftInfoModal";

type barChartRow = { label: string, value: number, percentage: string, class: string }

type StackSetDriftProps = {
    addToast: AddToastFunction;
    navigationBar: React.ReactNode;
};

const PCSKAdminStackSetDriftPage: React.FC<StackSetDriftProps> = ({navigationBar, addToast}) => {

    // stateful on page
    const [data, setData] = useState<AccountStackSetDriftInfo[]>([]);
    const [filteredData, setFilteredData] = useState<AccountStackSetDriftInfo[]>([]);
    const [selectedRow, setSelectedRow] = useState<AccountStackSetDriftInfo | null>(null);
    const [isAccountListLoading, setIsAccountListLoading] = useState<boolean>(false);
    const [isMoreDetailsModalOpen, setIsMoreDetailsModalOpen] = useState<boolean>(false);
    const [isGlossaryOpen, setIsGlossaryOpen] = useState<boolean>(false);

    // search filters
    const [accountSearchTerm, setAccountSearchTerm] = useState<string>('');
    const [stackSetNameSearchTerm, setStackSetNameSearchTerm] = useState<string>('');
    const [statusFilterValue, setStatusFilterValue] = useState<string>('');
    const [addFalsePositive, setAddFalsePositive] = useState<boolean>(false);
    const [instanceStatusFilterValue, setInstanceStatusFilterValue] = useState<string>('');
    const [stackSetFilterValue, setStackSetFilterValue] = useState<string>('');
    const dataTableCellSize: number = 20;

    // pagination controls
    const [currentPage, setCurrentPage] = useState<number>(1);
    const rowsPerPage: number = 50;
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentPageData = filteredData.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    // charts
    const [barChartData, setBarChartData] = useState<barChartRow[]>([])

    // API repository
    const driftDetectionApiService = DriftDetectionApiServiceFactory.getService();

    // Custom cell that displays the action buttons
    const CustomDriftActionsCell: React.FC<CustomStatusCellProps> = ({children, item, ...props}) => {
        return (
            <td className='slds-text-align_left'>
                {item?.drift_status === "DRIFTED" && (
                    <Button
                        iconCategory="utility"
                        iconName="threedots"
                        title={CONSTANT_ACCOUNT.DRIFT_INFORMATION}
                        iconSize="medium"
                        onClick={() => {
                            setSelectedRow(item ?? null);
                            setIsMoreDetailsModalOpen(!isMoreDetailsModalOpen)
                        }}
                        disabled={isAccountListLoading}
                        className="slds-button_icon slds-button_small"
                    />)}
            </td>
        );
    };
    CustomDriftActionsCell.displayName = DataTableCell.displayName;

    // Handle page re-loads and tab switches
    useEffect(() => {
        fetchStackSetDrift()
        clearFilters()
        setIsMoreDetailsModalOpen(false)
    }, []);

    const clearFilters = () => {
        setAccountSearchTerm('');
        setStackSetNameSearchTerm('');
        setStatusFilterValue('DRIFTED');
        setAddFalsePositive(false);
        setStackSetFilterValue('');
        setInstanceStatusFilterValue('');
        setIsGlossaryOpen(false)
        filterAccounts();
    }

    // Handle Charts re-render
    useEffect(() => {
        const statusCounts: Map<string, number> = new Map();
        filteredData.forEach(item => {
            const status = item.drift_status;
            if (statusCounts.has(status)) {
                statusCounts.set(status, statusCounts.get(status)! + 1);
            } else {
                statusCounts.set(status, 1);
            }
        });

        const result: barChartRow[] = [];
        statusCounts.forEach((count, status) => {
            let colorClass = ""
            if (`${status}`.toLowerCase().includes("drifted")) {
                colorClass = "slds-theme--error"
            }
            if (`${status}`.toLowerCase().includes("unknown")) {
                colorClass = "slds-theme_warning"
            }

            let percentage = filteredData.length > 0 ? (count / filteredData.length) * 100 : 0;
            let formattedPercentage = Number.isInteger(percentage) ? percentage.toFixed(0) : percentage.toFixed(2);
            result.push(
                {
                    label: status.length > 0 ? status : "None",
                    value: count,
                    percentage: formattedPercentage,
                    class: colorClass,
                }
            );
        });
        setBarChartData(result)
    }, [filteredData]);

    // Handle filter changes
    useEffect(() => {
        filterAccounts()
    }, [data, accountSearchTerm, statusFilterValue, instanceStatusFilterValue, stackSetFilterValue, stackSetNameSearchTerm, addFalsePositive]);

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(filteredData.length / rowsPerPage)));
    };

    const fetchStackSetDrift = (isRefresh = false) => {
        setIsAccountListLoading(true)
        let filters = {
            account_id: accountSearchTerm,
            stackset_name: stackSetNameSearchTerm,
            from_time: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            to_time: new Date()
        }

        driftDetectionApiService.getStackSetDrift(filters).then((response: AccountStackSetDriftInfo[]) => {
            for (let i = 0; i < response.length; i++) {
                response[i].index = i + 1
                response[i].drift_check_time = new Date(response[i].drift_check_timestamp * 1000).toLocaleString()
            }
            setData(response);
        }).catch((message) => {
            addToast("", TOAST_MESSAGE.FAILED_LOADING_STACK_SET_DRIFTS(message), "error")
        }).finally(() => {
            setIsAccountListLoading(false)
            setCurrentPage(1)
        });
    }

    const handleRefreshClick = () => {
        fetchStackSetDrift(true)
        setIsMoreDetailsModalOpen(false)
    }

    const onGlossaryClick = () => {
        setIsGlossaryOpen(!isGlossaryOpen)
    }

    const handleStatusDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const filterValue = event.target.value;
        setStatusFilterValue(filterValue);
    };

    const handleInstanceStatusDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const filterValue = event.target.value;
        setInstanceStatusFilterValue(filterValue);
    };

    const handleDownloadClick = () => {
        // Convert JSON to CSV
        const convertToCSV = (jsonArray: AccountStackSetDriftInfo[]): string => {
            if (!jsonArray || jsonArray.length === 0) return '';
            const skipFields = ['index', 'drift_details'];
            const headers = Object.keys(jsonArray[0]).filter(key => !skipFields.includes(key)).join(',');
            const rows = jsonArray.map(item => {
                return Object.keys(item)
                    .filter(key => !skipFields.includes(key))
                    .map(key => {
                        const value = item[key as keyof AccountStackSetDriftInfo];
                        return value !== undefined ? `"${value}"` : '';
                    })
                    .join(',');
            });

            return [headers].concat(rows).join('\n');
        };

        const csvString = convertToCSV(filteredData);
        const blob = new Blob([csvString], {type: 'text/csv'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = "pcsk_drift_detection.csv";
        link.click();
        URL.revokeObjectURL(url);
    };

    const filterAccounts = () => {
        let dataCopy = data
        if (accountSearchTerm !== '') {
            dataCopy = dataCopy.filter(account => account.account_id.toLowerCase().includes(accountSearchTerm.toLowerCase()));
        }

        if (stackSetNameSearchTerm !== '') {
            dataCopy = dataCopy.filter(account => account.stackset_name.toLowerCase().includes(stackSetNameSearchTerm.toLowerCase()));
        }


        // Filter by filter value (if any)
        if (statusFilterValue !== '') {
            dataCopy = dataCopy.filter(account => account.drift_status.toLowerCase() === statusFilterValue.toLowerCase());
        }

        if (instanceStatusFilterValue !== '') {
            dataCopy = dataCopy.filter(account => account.instance_status.toLowerCase() === instanceStatusFilterValue.toLowerCase());
        }


        if (!addFalsePositive) {
            dataCopy = dataCopy.filter(account => !account.false_positive);
        }

        dataCopy.sort((a, b) => (a.drift_check_timestamp > b.drift_check_timestamp ? -1 : 1));

        for (let i = 0; i < dataCopy.length; i++) {
            dataCopy[i].index = i + 1
        }

        setFilteredData(dataCopy)
        setCurrentPage(1)
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        if (event.target.id === "searchAccountInput") {
            setAccountSearchTerm(searchTerm)
        }

        if (event.target.id === "searchStackSetNameInput") {
            setStackSetNameSearchTerm(searchTerm)
        }
    };

    const handleResetFiltersClick = () => {
        clearFilters()
    }

    const handleAddFalsePositiveDataChange = () => {
        setAddFalsePositive(!addFalsePositive)
    };

    // Function to render SLDS styled bar chart
    const renderBarChart = () => (
        <ExpandableSection title={CONSTANT_ACCOUNT.STACKSET_DRIFT_DISTRIBUTION}>
            <ul className="slds-grid slds-wrap slds-grid_pull-padded">
                {barChartData.map((data, index) => (
                        <li key={index} className="slds-p-horizontal_medium slds-size_1-of-2">
                            <div className="slds-box--small">
                                <div className="slds-text-title slds-text-title_bold">
                                    {data.label}
                                    <Popover
                                        className="slds-grid slds-wrap slds-grid_pull-padded"
                                        align="bottom left"
                                        body={
                                            <div>
                                                <p className="slds-p-bottom_x-small">
                                                    Stack set instance is not in sync with stack set template. A stack
                                                    set instance is
                                                    considered to have drifted if one or more of its resource have
                                                    drifted from
                                                    their expected template and parameter configuration.
                                                </p>
                                            </div>
                                        }
                                        footerWalkthroughActions={
                                            <Button
                                                onClick={() => {
                                                    window.open("https://docs.aws.amazon.com/AWSCloudFormation/latest/APIReference/API_StackSetDriftDetectionDetails.html#API_StackSetDriftDetectionDetails_Contents:~:text=Required%3A%20No-,DriftStatus,-Status%20of%20the", "_blank");
                                                }}
                                                variant="neutral"
                                            >
                                                Learn More
                                            </Button>
                                        }
                                        heading={CONSTANT_ACCOUNT.STACK_INSTANCE_RESOURCE_DRIFT}
                                        id="drift-status-popup-feature"
                                        icon={
                                            <Icon category="utility" name="description" size="small" inverse/>
                                        }
                                        variant="feature"
                                    >
                                        <Button
                                            assistiveText={{icon: 'Help'}}
                                            iconCategory="utility"
                                            iconName="question"
                                            iconSize="small"
                                            variant="icon"
                                        />
                                    </Popover>
                                </div>
                                <br/>
                                <div className="slds-progress-bar">
                        <span
                            className={`slds-progress-bar__value ${data.class}`}
                            style={{width: `${data.percentage}%`}}
                        >
                            <span className="slds-assistive-text">{data.value}% Complete</span>
                        </span>
                                </div>
                                <span>{data.value} Stack Set Instance{data.value > 1 && "s"}</span>
                            </div>
                        </li>
                    )
                )}
            </ul>
        </ExpandableSection>
    );

    return <>
        {navigationBar}
        <Card
            heading="PCSK Stacksets Drift"
            icon={<Icon
                category="standard"
                name="recipe"
                size="small"
            />}
            className="slds-m-left_medium slds-m-right_medium slds-m-bottom_medium slds-m-top--small"
            bodyClassName="slds-p-right_medium slds-p-left_medium slds-p-bottom_medium"
            style={{minHeight: '90vh'}}
        >
            <div className="slds-is-relative slds-size_1-of-1" style={{minHeight: "80vh"}}>
        <Card
            heading={
                <div className="slds-grid slds-grid_align-spread slds-grid_vertical-align-center">
                    <span>{CONSTANT_ACCOUNT.SEARCH_AND_FILTER}</span>
                    <ButtonGroup>
                        <Button
                            iconCategory="utility"
                            label={<>&nbsp; Reset</>}
                            iconName="skip_back"
                            title="Reset Filters"
                            iconSize="medium"
                            onClick={handleResetFiltersClick}
                            disabled={isAccountListLoading}
                            variant={"neutral"}
                            className="slds-button_icon slds-button_small slds-text-body_regular"
                        />
                    </ButtonGroup>
                </div>
            }
            className=""
            bodyClassName="slds-p-right_medium slds-p-left_medium slds-p-bottom_xx-small"
        >
            <div
                className="slds-grid slds-gutters slds-m-bottom--x-small slds-m-left--x-small slds-grid_vertical-align-center">
                <div className="slds-col slds-size_1-of-5">
                    <label className="slds-form-element__label" htmlFor="searchInput">StackSet Name:</label>
                    <input
                        id="searchStackSetNameInput"
                        type="text"
                        title="StackSet Name"
                        className="slds-input"
                        placeholder="StackSet Name"
                        value={stackSetNameSearchTerm}
                        onChange={(e) => handleSearchChange(e)}
                        disabled={isAccountListLoading}
                    />
                </div>
                <div className="slds-col slds-large-size_1-of-5">
                    <label className="slds-form-element__label" htmlFor="searchInput">Account ID: </label>
                    <input
                        id="searchAccountInput"
                        type="text"
                        title={CONSTANT_ACCOUNT.ACCOUNT_ID}
                        className="slds-input"
                        placeholder="AWS Account ID"
                        value={accountSearchTerm}
                        onChange={(e) => handleSearchChange(e)}
                        disabled={isAccountListLoading}
                    />
                </div>
                <div className="slds-col slds-large-size_1-of-5">
                    <label className="slds-form-element__label" htmlFor="filterSelect">
                        Drift Status:
                        <Popover
                            className="slds-grid slds-wrap slds-grid_pull-padded"
                            align="bottom left"
                            body={
                                <div>
                                    <p className="slds-p-bottom_x-small">
                                        Stack set instance is not in sync with stack set template. A stack
                                        set instance is
                                        considered to have drifted if one or more of its resource have
                                        drifted from
                                        their expected template and parameter configuration.
                                    </p>
                                </div>
                            }
                            footerWalkthroughActions={
                                <Button
                                    onClick={() => {
                                        window.open("https://docs.aws.amazon.com/AWSCloudFormation/latest/APIReference/API_StackSetDriftDetectionDetails.html#API_StackSetDriftDetectionDetails_Contents:~:text=Required%3A%20No-,DriftStatus,-Status%20of%20the", "_blank");
                                    }}
                                    variant="neutral"
                                >
                                    Learn More
                                </Button>
                            }
                            heading='Drift Status'
                            id="drift-status-popup-feature"
                            icon={
                                <Icon category="utility" name="description" size="small" inverse/>
                            }
                            variant="feature"
                        >
                            <Button
                                assistiveText={{icon: 'Help'}}
                                iconCategory="utility"
                                iconName="question"
                                iconSize="small"
                                variant="icon"
                            />
                        </Popover>
                    </label>
                    <select
                        className="slds-select"
                        value={statusFilterValue}
                        onChange={handleStatusDropdownChange}
                        disabled={isAccountListLoading}
                    >
                        <option value="">All</option>
                        <option value="DRIFTED">DRIFTED</option>
                        <option value="IN_SYNC">IN_SYNC</option>
                        <option value="NOT_CHECKED">NOT_CHECKED</option>
                        <option value="UNKNOWN">UNKNOWN</option>
                    </select>
                </div>
                <div className="slds-col slds-large-size_1-of-5">
                    <label className="slds-form-element__label" htmlFor="filterSelect">
                        Instance Status:
                        <Popover
                            className="slds-grid slds-wrap slds-grid_pull-padded"
                            align="bottom left"
                            body={
                                <div>
                                    <p className="slds-p-bottom_x-small">
                                        AWS CloudFormation StackSets generates status codes for stack instances
                                        after every non-drift operation.
                                    </p>
                                </div>
                            }
                            footerWalkthroughActions={
                                <Button
                                    onClick={() => {
                                        window.open("https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/stacksets-concepts.html#stack-instance-status-codes", "_blank");
                                    }}
                                    variant="neutral"
                                >
                                    Learn More
                                </Button>
                            }
                            heading='Instance Status'
                            id="drift-status-popup-feature"
                            icon={
                                <Icon category="utility" name="description" size="small" inverse/>
                            }
                            variant="feature"
                        >
                            <Button
                                assistiveText={{icon: 'Help'}}
                                iconCategory="utility"
                                iconName="question"
                                iconSize="small"
                                variant="icon"
                            />
                        </Popover>
                    </label>
                    <select
                        className="slds-select"
                        value={instanceStatusFilterValue}
                        onChange={handleInstanceStatusDropdownChange}
                        disabled={isAccountListLoading}
                    >
                        <option value="">All</option>
                        <option value="INOPERABLE">INOPERABLE</option>
                        <option value="OUTDATED">OUTDATED</option>
                        <option value="CURRENT">CURRENT</option>
                        <option value="CANCELLED">CANCELLED</option>
                        <option value="SKIPPED_SUSPENDED_ACCOUNT">SKIPPED_SUSPENDED_ACCOUNT</option>
                        <option value="FAILED">FAILED</option>
                        <option value="FAILED_IMPORT">FAILED_IMPORT</option>
                        <option value="PENDING">PENDING</option>
                        <option value="RUNNING">RUNNING</option>
                    </select>
                </div>
                <div className="slds-col slds-size_1-of-5 slds-m-top--small">
                    <Checkbox
                        labels={{
                            label: 'Include False Positive',
                        }}
                        id="checkbox-toggle-example--checked"
                        variant="toggle"
                        size={"small"}
                        checked={addFalsePositive}
                        onChange={(e: React.ChangeEvent<Event>) => {
                            handleAddFalsePositiveDataChange()
                        }}
                    />
                </div>
            </div>
        </Card>
        <div className="slds-m-top--x-small slds-color__background_gray-3">
            <ExpandableSection
                title='Glossary'
                isOpen={isGlossaryOpen}
                onToggleOpen={onGlossaryClick}
            >
                <ul className="slds-list_dotted">
                    <li>
                        For details on how to resolve stack set drift, you can start with the guidelines
                        <a href="https://salesforce.quip.com/uyg3AJgFatk5" target="_blank"
                           rel="noreferrer"> here.</a>
                    </li>

                    <li>
                        The table below have list of all the stack set instances that are not in sync with stack
                        set template as specified in PCSK Infra account.
                    </li>
                    <li>
                        DRIFTED: Stack set instance is not in sync with stack set template. Click on drift info
                        to know the exact difference
                    </li>
                    <li>
                        NOT_CHECKED: CloudFormation has/could not check if the stack, stack set, or stack
                        instance differs from its expected template configuration.
                        CloudFormation detects drift on those AWS resources that support drift detection.
                        Resources that don't support drift detection are assigned a drift status of NOT_CHECKED.
                        Any stack set instance where underlying account is in suspended state or trust
                        relationship has been broken will have this status as well.
                        Check the status reason for more details.
                    </li>
                    <li>
                        Dashboard show data from last 1 week.
                    </li>
                    <li>
                        Highest Priority Items: Where Drift status is DRIFTED
                    </li>
                    <li>
                        Medium Priority Items: Where Drift status is NOT_CHECKED and Instance Status is INOPERABLE
                    </li>
                    <li>
                        False Positive: Stack set instances that are marked as false positive will not be shown by
                        default. False positive drifts are those drifts that are not actual drifts and are detected
                        by the drift detection mechanism. This is to avoid the noice in the data and allow engineers
                        to focus on fixing genuine drift. For details of scenarios on when a drift is marked as
                        false positive please refer to the documentation <a
                        href="https://salesforce.quip.com/uyg3AJgFatk5#temp:C:KbCf3f3baa584814da08dcda825c">here</a>.
                    </li>
                </ul>
            </ExpandableSection>
        </div>
        {isAccountListLoading ? (
            <CustomHeightPageSpinner size="medium" minHeight="30vh"/>
        ) : filteredData.length > 0 ? (
            <>
                {/* Bar Chart */}
                <div className="slds-grid slds-wrap">
                    <div className="slds-col slds-size_1-of-1 slds-m-right_small">
                        {renderBarChart()}
                    </div>
                </div>

                {/* Pagination Info */}
                <div
                    className="slds-grid slds-grid_vertical-align-center slds-m-right_small slds-box slds-color__background_gray-3">
                    <span
                        className="slds-col slds-text-color_success slds-text-title_bold"> Showing {startIndex + 1} - {Math.min(endIndex, filteredData.length)} of {filteredData.length}
                    </span>
                    <div className="slds-form-element slds-m-right--large">
                        <div className="slds-form-element__control slds-col">
                            <ButtonGroup>
                                <Button
                                    iconCategory="utility"
                                    iconName="refresh"
                                    title={CONSTANT_ACCOUNT.REFRESH}
                                    iconSize="medium"
                                    onClick={handleRefreshClick}
                                    disabled={isAccountListLoading}
                                    variant={"neutral"}
                                    className="slds-button_icon slds-button_small"
                                />
                                <Button
                                    iconCategory="utility"
                                    iconName="download"
                                    title={CONSTANT_ACCOUNT.DOWNLOAD_CSV}
                                    iconSize="medium"
                                    onClick={handleDownloadClick}
                                    disabled={isAccountListLoading}
                                    variant={"neutral"}
                                    className="slds-button_icon slds-button_small"
                                />
                            </ButtonGroup>
                        </div>
                    </div>
                    {!isAccountListLoading && filteredData.length > 0 && (
                        <div className="slds-grid">
                            <div className="slds-col slds-size_1-of-2 slds-align-middle">
                                <span>
                                    Page {Math.min(currentPage, totalPages)} of {totalPages}
                                </span>
                            </div>
                            <div className="slds-col slds-size_1-of-2">
                                <ButtonGroup className="slds-m-left--xxx-small">
                                    <Button label="<" onClick={handlePreviousPage} disabled={currentPage <= 1}/>
                                    <Button label=">" onClick={handleNextPage}
                                            disabled={currentPage === totalPages || totalPages === 0}/>
                                </ButtonGroup>
                            </div>
                        </div>
                    )}
                </div>
                {/* Table */}
                <div className="slds-col slds-size_8-of-8 slds-wrap slds-m-top_small" style={{width: '99%'}}>
                    <DataTable
                        items={currentPageData}
                        id="drift-detection-table"
                        hasCellActions={false}
                        striped={true}
                        //columnBordered={true}
                        resizeBehavior="auto"
                    >
                        <DataTableColumn label="Stackset Name" property='stackset_name'>
                            <CustomBreakDataTableCell size={dataTableCellSize}/>
                        </DataTableColumn>
                        <DataTableColumn label="AWS Account" property='account_id'>
                            <CustomBreakDataTableCell size={dataTableCellSize}/>
                        </DataTableColumn>
                        <DataTableColumn label="Drift Check Time" property='drift_check_time'>
                            <CustomBreakDataTableCell size={dataTableCellSize}/>
                        </DataTableColumn>
                        <DataTableColumn label="Drift Status" property='drift_status'>
                            <CustomBreakDataTableCell size={dataTableCellSize}/>
                        </DataTableColumn>
                        <DataTableColumn label="Instance Status" property='instance_status'>
                            <CustomBreakDataTableCell size={dataTableCellSize}/>
                        </DataTableColumn>
                        <DataTableColumn label="Instance Status Reason" property='status_reason'>
                            <CustomBreakDataTableCell size={dataTableCellSize}/>
                        </DataTableColumn>
                        {/*<DataTableColumn label="False Positive" property='false_positive'>*/}
                        {/*    <CustomBreakDataTableCell  size={dataTableCellSize}/>*/}
                        {/*</DataTableColumn>*/}
                        <DataTableColumn label="Drift Info" property='drift_info'>
                            <CustomDriftActionsCell/>
                        </DataTableColumn>
                    </DataTable>
                </div>
                <div
                    className="slds-grid slds-grid_vertical-align-center slds-m-top_small slds-box slds-color__background_gray-3">
                    <span
                        className="slds-col slds-text-color_success slds-text-title_bold"> Showing {startIndex + 1} - {Math.min(endIndex, filteredData.length)} of {filteredData.length}
                    </span>
                    {!isAccountListLoading && filteredData.length > 0 && (
                        <div className="slds-grid">
                            <div className="slds-col slds-size_1-of-2 slds-align-middle">
                                <span>
                                    Page {Math.min(currentPage, totalPages)} of {totalPages}
                                </span>
                            </div>
                            <div className="slds-col slds-size_1-of-2">
                                <ButtonGroup className="slds-m-left--xxx-small">
                                    <Button label="<" onClick={handlePreviousPage} disabled={currentPage <= 1}/>
                                    <Button label=">" onClick={handleNextPage}
                                            disabled={currentPage === totalPages || totalPages === 0}/>
                                </ButtonGroup>
                            </div>
                        </div>
                    )}
                </div>
            </>

        ) : (
            <>
                <div
                    className="slds-m-top_xx-large slds-size_1-of-1 slds-grid slds-grid_vertical-align-center slds-grid_align-center slds-grid_vertical">
                    <div className="slds-align_absolute-center">
                        <strong>No Drift Detected</strong>
                    </div>
                </div>
            </>
        )}

        {isMoreDetailsModalOpen &&
            <StackSetDriftInfoModal
                isModalOpen={isMoreDetailsModalOpen}
                setIsModalOpen={setIsMoreDetailsModalOpen}
                data={selectedRow}
            />
        }
    </div>
        </Card>
    </>
        ;
};


export default PCSKAdminStackSetDriftPage;