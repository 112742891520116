export function IsAllAPIsMockEnabled() : boolean {
    const isEnabled = process.env.REACT_APP_MOCK_ALL_API || 'false';
    return isEnabled.toLowerCase() === 'true'
}

export function IsAccountClosureAPIMockEnabled(): boolean {
    const isEnabled = process.env.REACT_APP_IS_ACCOUNT_CLOSURE_API_MOCK_ENABLED || 'false';
    return isEnabled.toLowerCase() === 'true' || IsAllAPIsMockEnabled()
}

export function IsGUSAPIMockEnabled(): boolean {
    const isEnabled = process.env.REACT_APP_IS_GUS_API_MOCK_ENABLED || 'false';
    return isEnabled.toLowerCase() === 'true' || IsAllAPIsMockEnabled()
}
export function IsUserAPIMockEnabled(): boolean {
    const isEnabled = process.env.REACT_APP_IS_USER_API_MOCK_ENABLED || 'false';
    return isEnabled.toLowerCase() === 'true' || IsAllAPIsMockEnabled()
}
export function IsStackSetsAPIMockEnabled(): boolean {
    const isEnabled = process.env.REACT_APP_IS_STACKSETS_DEPLOYMENTS_API_MOCK_ENABLED || 'false';
    return isEnabled.toLowerCase() === 'true' || IsAllAPIsMockEnabled()
}
export function IsArtifactsAPIMockEnabled(): boolean {
    const isEnabled = process.env.REACT_APP_IS_ARTIFACTS_API_MOCK_ENABLED || 'false';
    return isEnabled.toLowerCase() === 'true' || IsAllAPIsMockEnabled()
}
export function IsSCPAPIMockEnabled(): boolean {
    const isEnabled = process.env.REACT_APP_IS_SCP_API_MOCK_ENABLED || 'false';
    return isEnabled.toLowerCase() === 'true' || IsAllAPIsMockEnabled()
}