import {BASE_URL, HandleErrorResponse} from "../common";
import {GUSApiService} from "./service"
import {ChangeCaseMetadata} from "../../utils/types/gus";

export class MainGUSApiService implements GUSApiService {
    readonly basePath = `${BASE_URL}/validations`;
    async fetchChangeCaseMetadata(caseID: string, descriptionMustContain: string): Promise<ChangeCaseMetadata> {

        let endpoint = `${this.basePath}/change-case/metadata/${caseID}`;
        if (descriptionMustContain.length > 0) {
            endpoint = `${endpoint}?description_must_include=${descriptionMustContain}`
        }

        try {
            const response = await fetch(endpoint, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                }
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }
            return ChangeCaseMetadata.fromJSON(await response.json());
        } catch (error) {
            console.error("Error fetching change case data:", error);
            throw error;
        }
    }
}

