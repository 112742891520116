import React from 'react';

interface ToggleGuideProps {
    showTitle?: boolean;
    showDryRun?: boolean;
    showAutoExpand?: boolean;
    showForceDeploy?: boolean;
    suffix: string;
}

const ToggleGuide: React.FC<ToggleGuideProps> = ({
                                                     showTitle = true,
                                                     showDryRun = true,
                                                     showAutoExpand = true,
                                                     showForceDeploy = true,
                                                     suffix
                                                 }) => {
    return (
        <div className="slds-m-around_medium slds-p-around_medium">
            {showTitle && (
                <h2 className="slds-text-heading_medium slds-text-align_left slds-m-bottom_medium slds-m-left_small slds-text-color--weak">
                    Toggle Guide:
                </h2>
            )}
            <div className="slds-grid slds-wrap slds-grid_align-spread">
                {/* Dry Run Explanation */}
                {showDryRun && (
                    <div className="slds-form-element slds-size_1-of-1 slds-p-horizontal_small">
                        <p className="slds-text-body_small slds-m-top_small slds-m-left_none">
                            <strong>Dry Run:</strong> Simulates the deployment process without making any actual changes to the system.
                        </p>
                    </div>
                )}

                {/* Auto Expand Explanation */}
                {showAutoExpand && (
                    <div className="slds-form-element slds-size_1-of-1 slds-p-horizontal_small">
                        <p className="slds-text-body_small slds-m-top_small slds-m-left_none">
                            <strong>Auto Expand:</strong> Automatically expands all remaining staggers one after another.
                        </p>
                    </div>
                )}

                {/* Force Deploy Explanation */}
                {showForceDeploy && (
                    <div className="slds-form-element slds-size_1-of-1 slds-p-horizontal_small">
                        <p className="slds-text-body_small slds-m-top_small slds-m-left_none">
                            <strong>Force {suffix}:</strong> Overrides any ongoing deployment.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ToggleGuide;