export const HandleError = (props: any, error: Error, detailedError?: string) => {
    if (error.message === "TokenExpired") {
        localStorage.setItem('authenticated', 'false');
    }
    else {
        props.setToast({
            isVisible: true,
            message: {
                details: detailedError?.length !== 0 ? detailedError + " : " + error.message : error.message,
                heading: "Error",
            },
            variant: "error"
        });
        console.error("Error fetching data:", error.message);
    }
}