import React, {useEffect, useState} from 'react';
import {Modal, Button, Card} from '@salesforce/design-system-react';
import {StackSetsDeploymentApiServiceFactory} from "../../../../services/deployment/stacksets/factory";
import {extractCommitIDFromArtifact} from "../../../../components/pcsk_admin/deployment/stacksets/Utils";
import CustomSpinnerOverlay from "../../../../utils/components/spinner/custom_spinner_overlay";
import ToggleGuide from "../../../../components/pcsk_admin/deployment/stacksets/ToggleGuide";
import ChangeCaseInput from "../../../../utils/components/input/changeCaseInput";
import {GenericDeploymentSubmissionResponse} from "../../../../utils/types/deployments/genericDeploymentResponse";
import ArtifactSelector from "../../../../utils/components/input/artifactSelector";

interface DeployCommitModalProps {
    isOpen: boolean;
    onClose: () => void;
    onDeploySuccess: (commitId: string, apiResponse: GenericDeploymentSubmissionResponse) => void;
}

const DeployStackSetCommitModal: React.FC<DeployCommitModalProps> = ({ isOpen, onClose, onDeploySuccess }) => {
    const [commitTitle, setCommitTitle] = useState<string>('');
    const [changeCaseID, setChangeCaseID] = useState<string>('');
    const [commitTitleInputError, setCommitTitleInputError] = useState<string>('');
    const [isValidChangeCase, setIsValidChangeCase] = useState<boolean>(false);
    const [isDeployInProgress, setIsDeployInProgress] = useState<boolean>(false);
    const [disableDeployButton, setDisableDeployButton] = useState<boolean>(false);
    const [dryRun, setDryRun] = useState<boolean>(false);
    const [forceDeploy, setForceDeploy] = useState<boolean>(false);
    const [autoDeploy, setAutoDeploy] = useState<boolean>(false);
    const [deployAPIResponse, setDeployAPIResponse] = useState<GenericDeploymentSubmissionResponse | undefined>(undefined);

    const [selectedArtifact, setSelectedArtifact] = useState<string>('');

    const stackSetsApiService = StackSetsDeploymentApiServiceFactory.getService();

    useEffect(() => {
        setDisableDeployButton(isDeployInProgress || !isValidChangeCase || !validateCommitID(selectedArtifact) || !validateCommitTitle(commitTitle))
    }, [isValidChangeCase, selectedArtifact, isDeployInProgress, commitTitle]);

    const handleDeploy = async () => {
        try {
            setIsDeployInProgress(true);
            const deploymentResponse = await stackSetsApiService.deployCommit(changeCaseID, extractCommitIDFromArtifact(selectedArtifact), commitTitle, forceDeploy, autoDeploy, dryRun);
            deploymentResponse.success && onDeploySuccess(extractCommitIDFromArtifact(selectedArtifact), deploymentResponse)
            setDeployAPIResponse(deploymentResponse)
        } catch (error) {
            console.log("Error submitting commit for deployment", error);
            setDeployAPIResponse(new GenericDeploymentSubmissionResponse(`${error}`, false))
        } finally {
            setIsDeployInProgress(false);
        }
    };

    const validateCommitID = (commitID: string) => {
        return commitID.length > 0
    }

    const validateCommitTitle = (commitTitle: string) => {
        return commitTitle.length > 0
    }

    const handleCommitTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        setCommitTitle(input);
    };

    const getBottomContent = () => {
        if (isDeployInProgress) {
            return <>
                <Card hasNoHeader={true}>
                    <div style={{minHeight: "10vh"}}>
                        <div className="slds-m-top_xx-large slds-flex slds-flex_column slds-align-items_center">
                            <CustomSpinnerOverlay size={"medium"}/>
                            <p className="slds-m-top_large slds-align_absolute-center slds-text-color--inverse-weak">
                                Submitting commit for deployment
                            </p>
                        </div>
                    </div>
                </Card>
            </>
        }

        if (deployAPIResponse) {
            return <>
                <Card hasNoHeader={true}>
                    {deployAPIResponse.success ?
                        <div className="slds-m-around_medium slds-p-around_medium">
                            <h2 className="slds-text-heading_medium slds-text-align_center slds-m-bottom_medium slds-text-color_success">
                                Deployment Request Submitted Successfully!
                            </h2>
                        </div> :
                        <div className="slds-m-around_medium slds-p-around_medium">
                            <h2 className="slds-text-heading_medium slds-text-align_center slds-m-bottom_medium slds-text-color_destructive">
                                {deployAPIResponse.message}
                            </h2>
                        </div>
                    }
                </Card>
            </>
        }

        return (
            <>
                <Card
                    bodyClassName={"slds-color__background_gray-4"}
                    hasNoHeader={true}
                >
                    <ToggleGuide suffix={"Deploy"}/>
                </Card>
            </>
        );
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            size="medium"
            heading="Deploy New StackSet Template Commit"
            dismissOnClickOutside={false}
            footer={
                <>
                    <Button label="Deploy" onClick={handleDeploy} variant="brand" disabled={disableDeployButton}/>
                </>
            }
        >
            <div className="slds-p-around_medium">
                <div className={"slds-box"}>
                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Change Case:</strong></label>
                        </div>
                        <div className="slds-col">
                            <ChangeCaseInput
                                isValidChangeCase={isValidChangeCase}
                                disableInput={isDeployInProgress}
                                changeCaseID={changeCaseID}
                                setChangeCaseID={setChangeCaseID}
                                setIsValidChangeCase={setIsValidChangeCase}
                                searchKey={""}
                            ></ChangeCaseInput>
                        </div>
                    </div>

                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Artifact:</strong></label>
                        </div>
                        <div className="slds-size_2-of-3">
                            <div className="slds-grid slds-grid_vertical-align-center">
                                <div className="slds-col">
                                    <ArtifactSelector
                                        setArtifact={setSelectedArtifact}
                                        disable={isDeployInProgress}
                                        prefix={"pcsk-stacksets"}
                                    />
                                    {selectedArtifact && <div className="slds-text-color--weak slds-text-body_small">
                                        <p>Commit ID: {extractCommitIDFromArtifact(selectedArtifact)}</p>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Commit Title:</strong></label>
                        </div>
                        <div className="slds-col slds-size_2-of-3">
                            <input
                                type="text"
                                className="slds-input"
                                onChange={(e) => handleCommitTitleInput(e)}
                                disabled={isDeployInProgress}
                                placeholder={"Enter Commit Title"}
                                value={commitTitle}
                            />
                            <div className="slds-text-color_destructive slds-m-top_small">
                            <p>{commitTitleInputError}</p>
                            </div>
                        </div>
                    </div>
                    <div className="slds-grid slds-gutters slds-m-horizontal--medium">
                        <div className="slds-form-element__control slds-m-around--small">
                            <label className="slds-checkbox_toggle slds-grid">
                                <input
                                    type="checkbox"
                                    checked={dryRun}
                                    disabled={isDeployInProgress}
                                    onChange={() => {setDryRun(!dryRun)}}
                                />
                                <span className="slds-checkbox_faux_container" aria-live="assertive">
                                <span className="slds-checkbox_faux"></span>
                                <span className="slds-checkbox_on">Dry Run</span>
                                <span className="slds-checkbox_off">Dry Run </span>
                            </span>
                            </label>
                        </div>
                        <div className="slds-form-element__control slds-m-around--small">
                            <label className="slds-checkbox_toggle slds-grid">
                                <input
                                    type="checkbox"
                                    checked={autoDeploy}
                                    disabled={isDeployInProgress}
                                    onChange={() => {setAutoDeploy(!autoDeploy)}}
                                />
                                <span className="slds-checkbox_faux_container" aria-live="assertive">
                                <span className="slds-checkbox_faux"></span>
                                <span className="slds-checkbox_on">Auto Expand</span>
                                <span className="slds-checkbox_off">Auto Expand </span>
                            </span>
                            </label>
                        </div>
                        <div className="slds-form-element__control slds-m-around--small">
                            <label className="slds-checkbox_toggle slds-grid">
                                <input
                                    type="checkbox"
                                    checked={forceDeploy}
                                    disabled={isDeployInProgress}
                                    onChange={() => {setForceDeploy(!forceDeploy)}}
                                />
                                <span className="slds-checkbox_faux_container" aria-live="assertive">
                                <span className="slds-checkbox_faux"></span>
                                <span className="slds-checkbox_on">Force Deploy</span>
                                <span className="slds-checkbox_off">Force Deploy </span>
                            </span>
                            </label>
                        </div>
                    </div>
                </div>
                {getBottomContent()}
            </div>
        </Modal>
    );
};

export default DeployStackSetCommitModal;
