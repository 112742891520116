export const ROLES = {
    PCSKAdministratorAccessRole: 'PCSKAdministratorAccessRole',
    PCSKLimitedAdministratorAccessRole: 'PCSKLimitedAdministratorAccessRole',
    ROLE_SUBSTRATE_AWS: 'aws',
    ROLE_SUBSTRATE_GCP: 'gcp'
}

type RoleDetails = {
    [key: string]: {
        displayName: string;
        description: string;
    }
};

export const PCSK_ROLES: RoleDetails = {
    'PCSKReadOnlyAccessRole': {
        displayName: 'Read Only',
        description: 'The read only role allows reading almost all resources except secrets and customer data [s3, dynamo, rds].'
    },
    'PCSKSupportAccessRole': {
        displayName: 'Support',
        description: 'The support role is for creating support requests with AWS. It has very limited permissions.'
    },
    'PCSKCSIRTLimitedReadWrite': {
        displayName: 'CSIRT',
        description: `The CSIRT role is used by the CSIRT team for investigating urgent incidents. It is mostly a read only role
                      with limited write operations on ec2 network related resources.`
    },
    'PCSKSTARReadOnly': {
        displayName: 'STAR Read Only',
        description: `The STAR read only role is used by the CSIRT and STAR team to triage alerts. It is mostly a read only role
                     This is a subset of the CSIRT role. The STAR ReadOnly role is intended to only query objects and not make changes to any existing objects.`
    },
    'PCSKTerraformManager': {
        displayName: 'Terraform Manager',
        description: `Terraform manager role is mostly around reading codepipeline, codebuild, codecommit.`
    },
    'PCSKTerraformAdmin': {
        displayName: 'Terraform Admin',
        description: `Terraform admin role is like the terraform manager role with kms encrypt and decrypt.`
    },
    'PCSKDeveloperRole': {
        displayName: 'Developer',
        description: `This role allows read/write on numerous resources. It is the role that developers should be using
                          every day. Let us know if we missed something that you regularly need!`
    },
    'PCSKLimitedAdministratorAccessRole': {
        displayName: 'Limited Admin',
        description: `The limited admin can read/write almost every resource except a few highly sensitive resources.
                              If the developer role does not quite have the juice you need then this one should do it.`
    },
    'PCSKAdministratorAccessRole': {
        displayName: 'Admin',
        description: `The admin role is a full-on admin. Ability to read data, create users/roles, read secrets. Use this
                      role sparingly. Please justify why you need this role instead of the developer role.`
    },
    'PCSKEKSAccessRole': {
        displayName: 'EKS Access Role',
        description: `The role grants access to the EKS Worker Nodes for debugging.`
    },
    'PCSKSecurityAdminRole': {
        displayName: 'Security Admin',
        description: `The role grants Security Administrator privileges. This role is available only in GCP.`
    },
    'PCSKDeployRole': {
        displayName: 'Deploy',
        description: `The role grants permission to NetSec projects. This role is available only in GCP.`
    },
    'PCSKDDosShieldAdvancedResponseRole': {
        displayName: 'DDos Shield Advance Response',
        description: `This role grants read permissions to NetSec DDos team. This is only applicable for Falcon accounts`
    }
};
