import React from 'react';
import {Outlet, Navigate, useLocation} from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const PrivateRoutes: React.FC = () => {
    const location = useLocation();
    const token = localStorage.getItem("access_token");
    const fullAttemptedPath = location.pathname + location.search;

    if (!token) {
        localStorage.setItem('attemptedURL', fullAttemptedPath);
        return <Navigate to="/login" />
    } else {
        interface DecodedToken {
            exp: number
        }

        const decoded: DecodedToken = jwt_decode(token);
        const currentTimestamp = Math.floor(Date.now() / 1000);

        if (currentTimestamp > decoded.exp) {
            localStorage.setItem('attemptedURL', fullAttemptedPath);
            return <Navigate to="/login" />
        }
    }

    if (localStorage.getItem("authenticated") === "true") {
        return <Outlet />
    } else {
        localStorage.setItem('attemptedURL', fullAttemptedPath);
        return <Navigate to="/login" />
    }
}

export default PrivateRoutes;
