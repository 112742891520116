// Assuming that process.env values can be either string or undefined
const API_SCHEME = (process.env.REACT_APP_API_DISABLE_HTTPS === "true" || process.env.REACT_APP_API_DISABLE_HTTPS === "1") ? 'http://' : 'https://';
const API_HOST = process.env.REACT_APP_API_HOST ? `api.${process.env.REACT_APP_API_HOST}` : 'localhost:5001';
const API_URL = `${API_SCHEME}${API_HOST}`;

const OAUTH_IMPLEMENTATION = process.env.REACT_APP_OAUTH_IMPLEMENTATION;
const OAUTH_FLOW = process.env.REACT_APP_OAUTH_FLOW;

let OAUTH_URL: string | undefined, OAUTH_TOKEN_URL: string | undefined, OAUTH_CLIENT_ID: string | undefined, OAUTH_REDIRECT_PATH: string | undefined;
if (OAUTH_IMPLEMENTATION === "quantumk") {
    OAUTH_URL = process.env.REACT_APP_QUANTUMK_OAUTH_AUTH_URI as string;
    OAUTH_TOKEN_URL = process.env.REACT_APP_QUANTUMK_OAUTH_TOKEN_URI as string;
    OAUTH_CLIENT_ID = process.env.REACT_APP_QUANTUMK_OAUTH_CLIENT_ID as string;
    OAUTH_REDIRECT_PATH = process.env.REACT_APP_QUANTUMK_OAUTH_REDIRECT_PATH as string;
    if (OAUTH_FLOW !== 'pkce') {
        //TODO send to rollbar
        throw new Error(`Invalid ${OAUTH_IMPLEMENTATION} OAuth flow "${process.env.REACT_APP_OAUTH_FLOW}" (only PKCE allowed)`);
    }
} else {
    //TODO send to rollbar
    throw new Error(`Invalid OAuth implementation "${OAUTH_IMPLEMENTATION}"`);
}

// Get the backup endpoint's OAuth settings
const enableBackupDomain =
    process.env.REACT_APP_ENABLE_BACKUP_DOMAIN &&
    process.env.REACT_APP_ENABLE_BACKUP_DOMAIN.toLowerCase().trim() === "true";

const ENABLE_BACKUP_DOMAIN = enableBackupDomain === true;
const BACKUP_OAUTH_IMPLEMENTATION = process.env.REACT_APP_BACKUP_OAUTH_IMPLEMENTATION;
const BACKUP_OAUTH_FLOW = process.env.REACT_APP_BACKUP_OAUTH_FLOW as string;
let BACKUP_OAUTH_URL, BACKUP_OAUTH_TOKEN_URL, BACKUP_OAUTH_CLIENT_ID, BACKUP_OAUTH_REDIRECT_PATH;
if (BACKUP_OAUTH_IMPLEMENTATION === "quantumk") {
    BACKUP_OAUTH_URL = process.env.REACT_APP_BACKUP_QUANTUMK_OAUTH_AUTH_URI as string;
    BACKUP_OAUTH_TOKEN_URL = process.env.REACT_APP_BACKUP_QUANTUMK_OAUTH_TOKEN_URI as string;
    BACKUP_OAUTH_CLIENT_ID = process.env.REACT_APP_BACKUP_QUANTUMK_OAUTH_CLIENT_ID as string;
    BACKUP_OAUTH_REDIRECT_PATH = process.env.REACT_APP_BACKUP_QUANTUMK_OAUTH_REDIRECT_PATH as string;
    if (BACKUP_OAUTH_FLOW !== 'pkce') {
        throw new Error(`Invalid ${BACKUP_OAUTH_IMPLEMENTATION} OAuth flow "${process.env.REACT_APP_BACKUP_OAUTH_FLOW}" (only PKCE allowed)`);
    }
} else {
    throw new Error(`Invalid OAuth implementation "${BACKUP_OAUTH_IMPLEMENTATION}"`);
}

type Config = {
    api: {
        url: string;
    };
    oauth: {
        flow: string;
        url: string;
        token_url: string;
        client_id: string;
        redirect_path: string;
        implementation: string;
    };
    backup_domain: {
        enabled: boolean,
        oauth: {
            flow: string;
            url: string;
            token_url: string;
            client_id: string;
            redirect_path: string;
            implementation: string;
        }
    }
};

const config: Config = {
    'api': {
        'url': `${API_URL}`
    },
    'oauth': {
        'flow': OAUTH_FLOW,
        'url': OAUTH_URL,
        'token_url': `${OAUTH_TOKEN_URL}`,
        'client_id': `${OAUTH_CLIENT_ID}`,
        'redirect_path': OAUTH_REDIRECT_PATH,
        'implementation': OAUTH_IMPLEMENTATION
    },
    'backup_domain': {
        'enabled': ENABLE_BACKUP_DOMAIN,
        'oauth': {
            'flow': BACKUP_OAUTH_FLOW,
            'url': BACKUP_OAUTH_URL,
            'token_url': `${BACKUP_OAUTH_TOKEN_URL}`,
            'client_id': `${BACKUP_OAUTH_CLIENT_ID}`,
            'redirect_path': BACKUP_OAUTH_REDIRECT_PATH,
            'implementation': BACKUP_OAUTH_IMPLEMENTATION
        }
    }
};

export default config;
