// Drifted Account Retrieval API request type
export type DriftedAccountRequest = {
    account_id: string;
    stackset_name: string;
    from_time: Date;
    to_time: Date;
}

// Drifted Accounts instance returned from the drifted accounts API
export type AccountStackSetDriftInfo = {
    index: number;
    account_id: string;
    stackset_name: string;
    drift_status: string;
    false_positive: boolean;
    instance_status: string;
    status_reason: string;
    drift_check_timestamp: number;
    drift_check_time?: string;
    drift_details?: DriftDetails[];
};

// List of stack set names in the account
export type StackSetNames = string[];

// Drift Details for the stack set instance
export type DriftDetails = {
    LogicalResourceId: string;
    ResourceType: string;
    StackId: string;
    PhysicalResourceId: string;
    StackResourceDriftStatus: string;
    PropertyDifferences: PropertyDifference[];
}

// Property Difference for the stack set instance
export type PropertyDifference = {
    PropertyPath: string;
    ExpectedValue: string;
    ActualValue: string;
    DifferenceType: string;
}

// Summary of StackSets drift
export class StackSetsDriftSummary {
    status: string;
    count: number;

    constructor(status: string, count: number) {
        this.status = status;
        this.count = count;
    }
}

