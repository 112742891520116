export const CONSTANT_ACCOUNT = {
    ACCOUNT_MANAGEMENT: 'Account Management',
    MY_ACCOUNTS: 'My Accounts',
    ACCOUNT_VENDING: 'Request New Account',
    STACKSET_DRIFT: 'StackSet Drift',
    STACKSET_DRIFT_DISTRIBUTION: 'StackSet Drift Distribution',
    DRIFT_INFORMATION: 'Drift Information',
    STACK_INSTANCE_RESOURCE_DRIFT: 'Stack Instance Resource Drift',
    UPDATE_ACCOUNT_DETAILS: 'Update Account Details',
    REFRESH: 'Refresh',
    DOWNLOAD_CSV: 'Download CSV',
    ACCOUNT_CLOSURE: 'Account Closures',
    REVIEW_ACCESS: 'Review Access',
    REQUEST_ACCESS: 'Request Access',
    ACCOUNT_SEARCH_PLACEHOLDER: 'Search by Account ID or Name...',
    SEARCH_AND_FILTER: 'Search and Filter',
    ACCOUNT_ID: 'Account ID',
    NAME: 'Name',
    EMAIL: 'Email',
    SERVICE_TEAM: 'Service Team',
    SUBSTRATE_TYPE: 'Substrate Type',
    STATUS: 'Status',
    ACTIONS: 'Actions',
    NO_ACCOUNT_MSG: 'No Accounts found',
    NEW_ACCOUNT_REQUEST: 'Request New Account',
    ACCOUNT_NAME: 'Account Name',
    MPA_NAME: 'Master Payer',
    MPA_NAME_PLACEHOLDER: 'Select a Master Payer account',
    ACCOUNT_REQUESTORS: 'Account Requestor(s)',
    ACCOUNT_REQUESTORS_PLACEHOLDER: 'Comma separated list of email address',
    GUS_PRODUCT_TAG: 'GUS Product Tag',
    OU_ARN: 'OU ARN',
    OU_ARN_PLACEHOLDER: 'Enter OU ARN',
    ACCOUNT_STATUS: 'Account Creation Status',
    CRT_ID: 'Client Request Token ID',
    CRT_ID_PLACEHOLDER: 'Enter the client request token here',
    CHECK_STATUS: 'Check Status',
    PROCESSING: 'Processing',
    SUCCESS: 'Success',
    FAILURE: 'Failure',
    NOT_FOUND: 'Not Found',
    ERROR: 'Error',
    CRT_ID_ERROR_MESSAGE: 'Client request token must have a length of 36 characters.'
}

interface TabsMetadata {
    [key: number]: string;
}

export const ACCOUNT_MANAGEMENT_TABS_METADATA: TabsMetadata = {
    0: CONSTANT_ACCOUNT.MY_ACCOUNTS,
    1: CONSTANT_ACCOUNT.ACCOUNT_VENDING,
    2: CONSTANT_ACCOUNT.UPDATE_ACCOUNT_DETAILS,
    3: CONSTANT_ACCOUNT.ACCOUNT_CLOSURE,
    4: CONSTANT_ACCOUNT.STACKSET_DRIFT,
};