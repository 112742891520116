// AccessRequestObjectPage.tsx
import React, { useState, useRef } from "react";
import {Icon, PageHeader, Spinner, Tabs, TabsPanel, Toast, ToastContainer} from "@salesforce/design-system-react";
import MyAccessRequests from "./my_access_requests/MyAccessRequests";
import SearchAccessRequests from "./search_access_requests/SearchAccessRequests";
import CreateAccessRequests from "./create_access_requests/CreateAccessRequests";
import SlackAccessRequest from "../../modals/SlackAccessRequest";
import { CONSTANT_ACCESS_REQUEST, TABS_METADATA } from "../../constants/accessRequest";
import { AccessRequest } from "../../utils/types/accessRequest";
import {selectedAccountInfo} from "../../utils/types/account";
import {ToastState} from "../../utils/types/toast";
import SlackIntegration from "./slack_integration/SlackIntegration";

type AccessRequestObjectProps = {
    accessId?: string;
    requestType?: string;
    activeTab: string;
    setActiveTab: (tab: string) => void;
    activeSubTab: number;
    setActiveSubTab: (tabID: number) => void;
    selectedAccount: selectedAccountInfo;
    setSelectedAccount: (accountInfo: selectedAccountInfo) => void;
    toast: ToastState;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
};

export type SlackIntegrationProps = {
    accessId?: string;
    requestType?: string;
    setActiveTab: (tab: string) => void;
    setActiveSubTab: (tabID: number) => void;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
    setIsAccessRequestModalOpen: (value: boolean) => void;
    setAccessDetails: (details: AccessRequest | null) => void;
    setIsPageLoading: (value: boolean) => void;
}

const AccessRequestObjectPage: React.FC<AccessRequestObjectProps> = (props) => {
    const [accessDetails, setAccessDetails] = useState<AccessRequest | null>(null);
    const [isAccessRequestModalOpen, setIsAccessRequestModalOpen] = useState<boolean>(false);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const searchAccessRequestsRef = useRef<SearchAccessRequests>(null);
    const titleLabel = TABS_METADATA[props.activeSubTab];

    // Use the custom effect
    SlackIntegration({
        accessId: props.accessId,
        requestType: props.requestType,
        setActiveTab: props.setActiveTab,
        setActiveSubTab: props.setActiveSubTab,
        setToast: props.setToast,
        setIsAccessRequestModalOpen,
        setAccessDetails,
        setIsPageLoading
    });

    const handleTabSelect = (selectedIndex: number, data: any) => {
        props.setActiveSubTab(selectedIndex);

        props.setSelectedAccount({
            account_id: ""
        });

        if (selectedIndex === 2 && searchAccessRequestsRef.current) {
            searchAccessRequestsRef.current.updateAccounts("");
        }
    };

    return (
        <div className="slds-m-around--x-small" style={{ minHeight: "80vh" }}>
            {/*This modal is loaded on some other page which might be fully loaded before this API finishes*/}
            {/*To avoid accidental clicks, run a full page spinner*/}
            {isPageLoading && <Spinner size="medium" variant="brand" />}
            {props.toast.isVisible && (
                <ToastContainer>
                    <Toast
                        labels={props.toast.message}
                        variant={props.toast.variant}
                        onRequestClose={() => props.setToast({
                            isVisible: false,
                        })}
                    />
                </ToastContainer>
            )}
            <div className="slds-m-bottom--x-small">
                <PageHeader
                    icon={
                        <Icon
                            assistiveText={{ label: "User" }}
                            category="standard"
                            name="care_request_reviewer"
                        />
                    }
                    label={CONSTANT_ACCESS_REQUEST.ACCESS_REQUESTS}
                    title={titleLabel}
                    variant="object-home"
                />
            </div>
            <Tabs
                id="access_requests_tabs"
                variant="vertical"
                selectedIndex={props.activeSubTab}
                onSelect={handleTabSelect}
            >
                <TabsPanel label={CONSTANT_ACCESS_REQUEST.MY_ACCESS_REQUESTS}>
                    <MyAccessRequests isActive={props.activeSubTab === 0} setActiveTab={props.setActiveTab}
                                      requestType={props.requestType === undefined ? "" : props.requestType}
                                      setActiveSubTab={props.setActiveSubTab}
                                      setSelectedAccount={props.setSelectedAccount}
                                      toast={props.toast} setToast={props.setToast}/>
                </TabsPanel>
                <TabsPanel label={CONSTANT_ACCESS_REQUEST.CREATE_ACCESS_REQUEST}>
                    <CreateAccessRequests
                        isActive={props.activeSubTab === 1}
                        selectedAccount={props.selectedAccount}
                        setActiveSubTab={props.setActiveSubTab}
                        toast={props.toast} setToast={props.setToast}
                    />
                </TabsPanel>
                <TabsPanel label={CONSTANT_ACCESS_REQUEST.SEARCH_OLD_REQUESTS}>
                    <SearchAccessRequests isActive={props.activeSubTab === 2} ref={searchAccessRequestsRef} selectedAccount={props.selectedAccount}
                                          toast={props.toast} setToast={props.setToast}/>
                </TabsPanel>
            </Tabs>
            {isAccessRequestModalOpen && accessDetails && props.requestType === "view" && (
                <SlackAccessRequest requestType="view" accessDetails={accessDetails} toast={props.toast} setToast={props.setToast}/>
            )}
            {isAccessRequestModalOpen && accessDetails && props.requestType === "reject" && (
                <SlackAccessRequest requestType="reject" accessDetails={accessDetails} toast={props.toast} setToast={props.setToast}/>
            )}
        </div>
    );
};

export default AccessRequestObjectPage;
