import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {SlackIntegrationProps} from "../AccessRequestObjectPage";
import {canApproveAccessRequests, getAccessRequestDetails} from "../../../services/accessRequests";
import {TOAST_MESSAGE} from "../../../constants/toastMessages";
import {approveAccessRequest, rejectAccessRequest} from "../../../services/requestReviews";
import {fetchAccountDetails} from "../../../services/accounts";
import {HandleError} from "../../utils/common";
import {getLoggedInUserEmail} from "../../../utils/lookup/session";

const SlackIntegration = (props: SlackIntegrationProps) => {
    const navigate = useNavigate();

    function viewSlackAccessRequest() {
        props.setIsPageLoading(true);
        const user = getLoggedInUserEmail();
        let isApproved = false;
        let isRejected = false;
        let rejectedBy = "";
        let isRevoked = false;
        let isOwner = false;
        let isExpired = false;
        let accessRequestDetails: any;

        getAccessRequestDetails(props.accessId || "").then((data) => {
            accessRequestDetails = data;
            if(accessRequestDetails.expired) {
                isExpired = true;
            }
            if(accessRequestDetails.approved_at !== 0) {
                isApproved = true;
            }
            if(accessRequestDetails.rejected_at !== 0) {
                isRejected = true;
                rejectedBy = accessRequestDetails.rejected_by;
            }
            if(accessRequestDetails.revoked_at !== 0) {
                isRevoked = true;
            }
            if(accessRequestDetails.sfdc_email === user) {
                isOwner = true;
            }

            if (isOwner) {
                if (isRevoked) {
                    props.setToast({
                        isVisible: true,
                        message: {
                            details: TOAST_MESSAGE.ACCESS_REQUEST_ALREADY_REVOKED_ERROR,
                            heading: TOAST_MESSAGE.ERROR,
                        },
                        variant: "error"
                    });
                }
                else if (isRejected) {
                    props.setToast({
                        isVisible: true,
                        message: {
                            details: TOAST_MESSAGE.ACCESS_REQUEST_REJECTED_BY + rejectedBy,
                            heading: TOAST_MESSAGE.ERROR,
                        },
                        variant: "error"
                    });
                }
                else if (isApproved) {
                    if (isExpired) {
                        props.setToast({
                            isVisible: true,
                            message: {
                                details: TOAST_MESSAGE.ACCESS_REQUEST_EXPIRED,
                                heading: TOAST_MESSAGE.ERROR,
                            },
                            variant: "error"
                        });
                    } else {
                        // Own request - Need to show credentials of this request in My Access Request Page
                        props.setActiveTab('AccessRequestSection');
                        props.setActiveSubTab(0);
                    }
                } else {
                    props.setToast({
                        isVisible: true,
                        message: {
                            details: TOAST_MESSAGE.ACCESS_REQUEST_NOT_REVIEWED,
                            heading: TOAST_MESSAGE.ERROR,
                        },
                        variant: "info"
                    });
                    props.setActiveTab('AccessRequestSection');
                    props.setActiveSubTab(0);
                }
                props.setIsPageLoading(false);
                navigate("/");
            }
            //Someone else's Access Request
            else {
                canApproveAccessRequests(props.accessId || "").then((canApprove) => {
                    if (canApprove && !isExpired) {
                        fetchAccountDetails(accessRequestDetails.aws_account).then((data: { name: any; }) => {
                            accessRequestDetails.aws_account_name = data.name;
                            props.setIsAccessRequestModalOpen(true);
                            props.setAccessDetails(accessRequestDetails);
                        }).catch(() => {
                            accessRequestDetails.aws_account_name = "";
                            console.log("Error fetching account details");
                            props.setIsAccessRequestModalOpen(true);
                            props.setAccessDetails(accessRequestDetails);
                        });

                    } else {
                        props.setToast({
                            isVisible: true,
                            message: {
                                details: TOAST_MESSAGE.ACCESS_REQUEST_VIEW_NOT_ALLOWED,
                                heading: TOAST_MESSAGE.ERROR,
                            },
                            variant: "error"
                        });
                        navigate("/");
                    }
                    props.setIsPageLoading(false);
                }).catch((err) => {
                    throw new Error(err);
                });
            }
        }).catch((err) => {
            HandleError(props, err, TOAST_MESSAGE.ACCESS_REQUEST_VIEW_NOT_ALLOWED);
            props.setIsPageLoading(false);
            navigate("/");
        });
    }

    function approveSlackAccessRequest() {
        props.setIsPageLoading(true);
        canApproveAccessRequests(props.accessId || "").then((canApprove) => {
            if (canApprove) {
                approveAccessRequest(props.accessId || "").then((response) => {
                    props.setToast({
                        isVisible: true,
                        message: {
                            details: TOAST_MESSAGE.ACCESS_REQUEST_APPROVAL_DETAIL,
                            heading: TOAST_MESSAGE.ACCESS_REQUEST,
                        },
                        variant: "success"
                    });
                    props.setIsPageLoading(false);
                    navigate("/");
                }).catch((err: any) => {
                    props.setToast({
                        isVisible: true,
                        message: {
                            details: TOAST_MESSAGE.ACCESS_REQUEST_APPROVAL_ERROR,
                            heading: TOAST_MESSAGE.ERROR,
                        },
                        variant: "error"
                    });
                    props.setIsPageLoading(false);
                    navigate("/");
                })
            } else {
                props.setToast({
                    isVisible: true,
                    message: {
                        details: TOAST_MESSAGE.ACCESS_REQUEST_APPROVAL_NOT_ALLOWED,
                        heading: TOAST_MESSAGE.ERROR,
                    },
                    variant: "error"
                });
                props.setIsPageLoading(false);
                navigate("/");
            }
        }).catch((err) => {
            HandleError(props, err, TOAST_MESSAGE.ACCESS_REQUEST_APPROVAL_NOT_ALLOWED);
            props.setIsPageLoading(false);
            navigate("/");
        });
    }

    function rejectSlackAccessRequest() {
        props.setIsPageLoading(true);
        canApproveAccessRequests(props.accessId || "").then((canApprove) => {
            if (canApprove) {
                rejectAccessRequest(props.accessId || "").then((response) => {
                    props.setToast({
                        isVisible: true,
                        message: {
                            details: TOAST_MESSAGE.ACCESS_REQUEST_REJECT_DETAIL,
                            heading: TOAST_MESSAGE.ACCESS_REQUEST,
                        },
                        variant: "success"
                    });
                    props.setIsPageLoading(false);
                    navigate("/");
                }).catch((err) => {
                    props.setToast({
                        isVisible: true,
                        message: {
                            details: TOAST_MESSAGE.ACCESS_REQUEST_REJECT_ERROR,
                            heading: TOAST_MESSAGE.ERROR,
                        },
                        variant: "error"
                    });
                    props.setIsPageLoading(false);
                    navigate("/");
                })
            } else {
                props.setToast({
                    isVisible: true,
                    message: {
                        details: TOAST_MESSAGE.ACCESS_REQUEST_REJECT_NOT_ALLOWED,
                        heading: TOAST_MESSAGE.ERROR,
                    },
                    variant: "error"
                });
                props.setIsPageLoading(false);
                navigate("/");
            }
        }).catch((err) => {
            HandleError(props, err, TOAST_MESSAGE.ACCESS_REQUEST_REJECT_NOT_ALLOWED);
            props.setIsPageLoading(false);
            navigate("/");
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            if (props.requestType === "view") {
                viewSlackAccessRequest();
            }
            if (props.requestType === "approve") {
                approveSlackAccessRequest();
            }
            if (props.requestType === "reject") {
                rejectSlackAccessRequest();
            }
        };
        fetchData();
    }, [props.accessId, props.requestType]);

};

export default SlackIntegration;
