import React, {useEffect, useState} from 'react';
import {Card, Icon,} from '@salesforce/design-system-react';
import {useNavigate} from "react-router-dom";
import {URL_PATHS} from "../../../constants/pcsk_admin/paths";
import {CONSTANT_SCP_DRIFTS} from "../../../constants/accountClosure";
import {DriftDetectionApiServiceFactory} from "../../../services/drift_detection/factory";
import BarChart, {BarChartRow} from "../../../utils/components/bar_chart/bar_chart";
import {SCPDriftSummary} from "../../../utils/types/scpDrift";
import {CustomHeightPageSpinner} from "../../../utils/components/spinner/page_spinner_overlay";
import {StackSetsDriftSummary} from "../../../utils/types/stackSetDrift";

const StackSetDeploymentSummaryCard: React.FC = () => {
    const [isFetchingSummary, setIsFetchingSummary] = useState<boolean>(false);
    const [fetchingSummaryError, setFetchingSummaryError] = useState<string>("");
    const navigate = useNavigate();
    const [scpDriftSummaries, setSCPDriftSummaries] = useState<SCPDriftSummary[]>([]);
    const driftDetectionApiService = DriftDetectionApiServiceFactory.getService();
    const [barChartData, setBarChartData] = useState<BarChartRow[]>([])

    useEffect(() => {
        fetchSCPDriftSummary()
    }, []);

    const fetchSCPDriftSummary = async () => {
        setIsFetchingSummary(true);
        setFetchingSummaryError("")
        try {
            const summaries = await new Promise<any>(resolve => {
                setTimeout(() => {
                    const driftSummary: StackSetsDriftSummary[] = [
                        new SCPDriftSummary("IN_PROGRESS", 1),
                        new SCPDriftSummary("COMPLETE", 10)
                    ]
                    resolve(driftSummary);
                }, 1000);
            });
            console.log("summaries", summaries)
            setSCPDriftSummaries(summaries)
        } catch (error) {
            setFetchingSummaryError(`${error}`)
        } finally {
            setIsFetchingSummary(false);
        }
    };

    useEffect(() => {
        const statusCounts = new Map<string, number>();

        // Count statuses
        scpDriftSummaries.forEach(item => {
            const status = item.status || "Unknown";
            const count = item.count ?? 0; // Default to 0 if undefined
            statusCounts.set(status, (statusCounts.get(status) ?? 0) + count);
        });

        // Calculate the total sum of all counts
        const totalSum = Array.from(statusCounts.values()).reduce((sum, count) => sum + count, 0);

        // Build bar chart data
        const newBarChartData = Array.from(statusCounts.entries()).map(([status, count]) => {
            const percentage = totalSum > 0 ? (count / totalSum) * 100 : 0; // Handle division by zero
            const formattedPercentage = parseFloat(percentage.toFixed(Number.isInteger(percentage) ? 0 : 2));
            return {
                label: status || "None",
                value: count,
                percentage: formattedPercentage,
                class: CONSTANT_SCP_DRIFTS.GET_STATUS_THEME(status),
            };
        });

        setBarChartData(newBarChartData);
    }, [scpDriftSummaries]);

    const getSummaryBody = () => {
        if (fetchingSummaryError !== '') {
            return (
                <div className="slds-grid slds-wrap slds-m-around_medium slds-text-color_destructive">
                    <p>{fetchingSummaryError}&nbsp;</p>
                </div>
            );
        }

        const getBarChartSize = () => {
            return `slds-size_1-of-${barChartData.length <= 0 ? 1 : barChartData.length}`
        }

        return (
            <div className="slds-grid slds-wrap">
                <div className="slds-col slds-size_1-of-1 slds-m-right_medium">
                    <BarChart
                        heading={""}
                        data={barChartData}
                        liClass={getBarChartSize()}
                        headerClass={"slds-text-title_caps slds-m-left_small"}
                    />
                </div>
            </div>
        )
    };

    return (<>
        <Card
            className="slds-card slds-card_boundary"
            heading={
                <div className="slds-grid slds-grid_align-spread">
                    <button
                        className="slds-button slds-button_reset slds-text-heading_small slds-text-link"
                        onClick={() => navigate(URL_PATHS.PCSK_ADMIN_STACKSETS_DEPLOYMENTS_PAGE)}
                        title="Navigate to Stacksets Drift Summary"
                    >
                        StackSets Deployment
                    </button>
                    <button
                        className="slds-button slds-button_icon slds-button_icon-border-filled"
                        title="Refresh"
                        onClick={fetchSCPDriftSummary}
                    >
                        <Icon
                            category="utility"
                            name="refresh"
                            size="x-small"
                        />
                        <span className="slds-assistive-text">Refresh</span>
                    </button>
                </div>
            }
            icon={<Icon
                category="standard"
                name="apex"
                size="small"
            />}
            style={{minHeight: '15vh'}}
        >
            {isFetchingSummary ? <>{<CustomHeightPageSpinner size="small" minHeight="5.2vh"/>}</> :
                <>
                    {getSummaryBody()}
                </>
            }
        </Card>
    </>);
};

export default StackSetDeploymentSummaryCard;
