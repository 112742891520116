import React, { useState, useEffect } from 'react';
import {
    Accordion,
    AccordionPanel, Button,
    DataTable,
    DataTableColumn,
    Icon,
    Modal
} from '@salesforce/design-system-react';
import {PCSKAccountClosureApprover, PCSKAccountClosureItem} from '../utils/types/accountClosure';
import CustomBreakDataTableCell from '../utils/components/table_cell/custom_break_cell';
import { CustomHeightPageSpinner } from "../utils/components/spinner/page_spinner_overlay";
import {AccountClosureApiServiceFactory} from "../services/account_closure/factory";
import {CONSTANT_ACCOUNT_CLOSURE} from "../constants/accountClosure";

interface Props {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    data: PCSKAccountClosureItem | null;
}

const AccountClosureMoreDetailsModal: React.FC<Props> = ({ isModalOpen, setIsModalOpen, data }) => {
    const [approvers, setApprovers] = useState<string[]>([]);
    const [activePanels, setActivePanels] = useState<string[]>([]);
    const [fetchingApprovers, setFetchingApprovers] = useState<boolean>(false);
    const [fetchApproversError, setFetchApproversError] = useState<string>("");

    const closureAPIService = AccountClosureApiServiceFactory.getService();

    useEffect(() => {
        if (activePanels.includes('panel1') && approvers.length === 0 && !fetchingApprovers) {
            fetchApprovers();
        }
    }, [activePanels]);

    const fetchApprovers = () => {
        if (!data) return
        setFetchingApprovers(true);
        closureAPIService.getClosureApprovers(data.aws_account_id).then((response: PCSKAccountClosureApprover[]) => {
            const aEmails: string[] = []
            for (let i = 0 ; i < response.length; i++) {
                aEmails.push(response[i].email)
            }
            aEmails.sort()
            setApprovers(aEmails)
        }).catch((message) => {
            setFetchApproversError(message)
        }).finally(() => {
            setFetchingApprovers(false);
        });
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const processClassData = (data: PCSKAccountClosureItem | null) => {
        if (!data) {
            return [];
        }
        return Object.entries(data)
            .filter(([key]) => !PCSKAccountClosureItem.skipKeys.includes(key))
            .map(([key, value]) => {
                const newKey = key.startsWith('_') ? key.slice(1) : key;
                if (Array.isArray(value)) {
                    value = value.join(', ');
                }
                if (value && typeof value === 'object') {
                    value = JSON.stringify(value, null, 2);
                }
                return { id: newKey, field: newKey, value: String(value) };
            })
            .sort((a, b) => a.id.localeCompare(b.id));
    };

    const handleTogglePanel = (panelId: string) => {
        setActivePanels((prevPanels) => {
            if (prevPanels.includes(panelId)) {
                return prevPanels.filter((id) => id !== panelId);
            } else {
                return [...prevPanels, panelId];
            }
        });
    };

    const renderApproversList = () => {
        if (fetchingApprovers) {
            return(
                <div>
                    <div className={"slds-m-top--xx-large"}></div>
                    <CustomHeightPageSpinner size="small" minHeight="1vh"/>
                </div>
            )
        }

        const breakEmailAfterCharacters = (email: string | null | undefined, maxLength = 35) => {
            if (!email) return '';
            const regex = new RegExp(`.{1,${maxLength}}`, 'g');
            return email.match(regex)?.join('\n') || '';
        };

        if (fetchApproversError.length > 0) {
            return (
                <>
                    <div className={"slds-text-color_error slds-m-left_small"}>
                        <p>{fetchApproversError}</p>
                    </div>
                </>
            )
        }

        if (approvers.length == 0) {
            return (
                <>
                    <div className={"slds-m-left_small"}>
                        <p>None</p>
                    </div>
                </>
            )
        }

        const hasEmailApproved = (email: string) => {
            if (!data || !email || email.length == 0) return false
            return data.approved_by_emails.includes(email)
        }

        const halfLength = Math.ceil(approvers.length / 2);

        const emailLi = (index: number, approver: string) => {
            return <>
                <li key={index} className="slds-p-around_xx-small">
                    <div
                        className={`slds-grid slds-align-items-center ${hasEmailApproved(approver) && "slds-text-title_bold slds-text-color_success"}`}>
                        <p>
                            {breakEmailAfterCharacters(approver)}
                        </p>
                        {hasEmailApproved(approver) &&
                            <Icon
                                category="utility"
                                name="success"
                                size="x-small"
                                className="slds-m-left_xx-small slds-text-color_success"
                            />
                        }
                    </div>
                </li>
            </>
        }

        return (<>
            <div className="slds-grid slds-wrap">
                <div className="slds-col slds-size_1-of-2">
                    <ul className="slds-list_dotted">
                        {approvers.slice(0, halfLength).map((approver, index) => (
                            <>{emailLi(index, approver)}</>
                        ))}
                    </ul>
                </div>
                <div className="slds-col slds-size_1-of-2">
                    <ul className="slds-list_dotted">
                        {approvers.slice(halfLength).map((approver, index) => (
                            <>{emailLi(index, approver)}</>
                        ))}
                    </ul>
                </div>
            </div>
        </>);
    };

    const renderTopMessage = ()=> {
        return (
            <div className="slds-modal__content slds-m-vertical--small slds-m-left_small">
                <li className={`slds-m-bottom--x-small`}>
                    PCSK Account {data?.aws_account_id} closure <b>{data?.getStatusMessage()}</b>
                </li>
                {data?.is_expired &&
                    <li className={`slds-m-bottom--x-small`}>
                        Closure expired on <b>{data?.approval_expiry_date}</b>
                    </li>
                }
                {data?.is_closed && data.closed_at > 0 &&
                    <li className={`slds-m-bottom--x-small`}>
                        It was closed on <b>{PCSKAccountClosureItem.timestampToDate(data?.closed_at)}</b>
                    </li>
                }
            </div>
        )
    }

    const getModalBody = () => {
        const processedData = processClassData(data);

        return (
            <div className="slds-p-around_medium">
                {renderTopMessage()}
                <div
                    className="slds-col slds-size_1-of-1 slds-m-bottom_small slds-color__background_gray-3 slds-text-color_default">
                    <Accordion>
                        <AccordionPanel
                            expanded={activePanels.includes('panel1')}
                            onTogglePanel={() => handleTogglePanel('panel1')}
                            summary="Closure Approvers"
                            className="slds-box"
                        >
                            {renderApproversList()}
                        </AccordionPanel>
                    </Accordion>
                </div>

                <div className="slds-col slds-size_1-of-1">
                    <DataTable items={processedData} id="AccountClosureDetailsTable">
                        <DataTableColumn
                            label={"PCSK Account Closure State"}
                            property="field"
                            primaryColumn
                            truncate
                        >
                            <CustomBreakDataTableCell size={30}/>
                        </DataTableColumn>
                        <DataTableColumn
                            label={
                                <div
                                    className={`slds-col slds-size_1-of-1 slds-p-around_small ${CONSTANT_ACCOUNT_CLOSURE.GET_STATUS_THEME(data?.status || '')}`}>
                                    {data?.isReadyInPCSKClosureQueue() ? data?.state : data?.status}
                                </div>
                            }
                            property="value"
                            truncate
                        >
                            <CustomBreakDataTableCell size={50}/>
                        </DataTableColumn>
                    </DataTable>
                </div>

            </div>
        );
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            size="small"
            heading={
                <div className={"slds-text-heading_medium slds-text-color--weak"}>
                    <strong>{`${data?.name} (${data?.aws_account_id})`}</strong>
                </div>
            }
            disableClose={false}
        >
            {getModalBody()}
        </Modal>
    );
};

export default AccountClosureMoreDetailsModal;
