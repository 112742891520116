import React from "react";
import { selectedAccountInfo } from "../../../utils/types/account";
import AccountVendingStatus from "./status/AccountVendingStatus";
import AccountCreation from "./account_creation/AccountVendingCreation";
import { ToastState } from "../../../utils/types/toast";
import {AccountCreationStatusProvider} from "../../../context/account_vending/AccountCreationStatus";

type AccountVendingProps = {
    action?: (type: string) => void;
    isActive: boolean;
    setActiveTab: (tab: string) => void;
    setActiveSubTab: (tabIndex: number) => void;
    selectedAccount: selectedAccountInfo;
    setSelectedAccount: (accountInfo: selectedAccountInfo) => void;
    toast: ToastState;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
};

const AccountVending: React.FC<AccountVendingProps> = ({ isActive, toast, setToast }) => {
    return (
        <AccountCreationStatusProvider>
            <div style={{ minHeight: "80vh" }}>
                <div className="slds-grid slds-gutters">
                    <div className="slds-col slds-size--5-of-8">
                        <AccountCreation isActive={isActive} toast={toast} setToast={setToast} />
                    </div>
                    <div className="slds-col slds-size--3-of-8">
                        <AccountVendingStatus isActive={isActive}/>
                    </div>
                </div>
            </div>
        </AccountCreationStatusProvider>
    );
};

export default AccountVending;