import {Component} from "react";
import {Button, Input, InputIcon, Modal, Textarea} from "@salesforce/design-system-react";
import React from "react";
import {ToastState} from "../utils/types/toast";
import {CONSTANT_ACCESS_REQUEST} from "../constants/accessRequest";
import {selectedAccountInfo} from "../utils/types/account";

type AccessRequestDetailsProps = {
    isOpen: boolean;
    toggleModal: (item: any) => void;
    requestDetails?: any;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
    setActiveTab: (tab: string) => void;
    setActiveSubTab: (tabIndex: number) => void;
    setSelectedAccount: (accountInfo: selectedAccountInfo) => void;
};

type AccessRequestDetailsState = {
    isModalVisible: boolean;
};
class AccessRequestDetails extends Component<AccessRequestDetailsProps, AccessRequestDetailsState> {
    state: AccessRequestDetailsState = {
        isModalVisible: false
    }

    closeModal = () => {
        this.props.toggleModal({});
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                size="small"
                footer={[
                    <Button key='closeCredentialsModal' label={CONSTANT_ACCESS_REQUEST.CLOSE_CREDENTIALS} onClick={this.closeModal} />,
                ]}
                onRequestClose={this.props.toggleModal}
                heading={CONSTANT_ACCESS_REQUEST.ACCESS_REQUEST_DETAILS}
            >
                <section className="slds-p-around_large">
                    <Input
                        id="request_details_account_id"
                        type="text"
                        label={CONSTANT_ACCESS_REQUEST.ACCOUNT}
                        value={this.props.requestDetails?.aws_account_display}
                        disabled
                        iconRight={
                            <InputIcon
                                assistiveText={{
                                    icon: 'Search',
                                }}
                                name="forward"
                                category="utility"
                                onClick={() => {
                                    this.props.setActiveTab('AccountManagementSection');
                                    this.props.setActiveSubTab(0);
                                    this.props.setSelectedAccount({
                                        account_id: this.props.requestDetails.aws_account
                                    })
                                }}
                            />
                        }
                    />
                    <Textarea
                        id="request_details_reason"
                        type="text"
                        label={CONSTANT_ACCESS_REQUEST.REASON}
                        value={this.props.requestDetails?.reason}
                        disabled
                    />
                    {
                        this.props.requestDetails?.change_case !== "" &&
                        <Input
                            id="change_case_details"
                            label={CONSTANT_ACCESS_REQUEST.CHANGE_CASE}
                            value={this.props.requestDetails?.change_case}
                            disabled
                        />
                    }
                    {
                        this.props.requestDetails?.incident !== "" &&
                        <Input
                            id="incident_details"
                            label={CONSTANT_ACCESS_REQUEST.INCIDENT_ID}
                            value={this.props.requestDetails?.incident}
                            disabled
                        />
                    }
                </section>
            </Modal>
        );
    }
}

export default AccessRequestDetails;