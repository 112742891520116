import React from 'react';
import { GlobalNavigationBarLink, Icon } from '@salesforce/design-system-react';

interface NavBarLinkProps {
    label: string;
    active: () => boolean; // List of paths to determine active state
    onClick: () => void; // Click handler function
    activeIcon?: string;
    nonActiveIcon?: string;
}

const PCSKAdminGlobalNavBarLink: React.FC<NavBarLinkProps> = ({ label, active, onClick, activeIcon, nonActiveIcon }) => {
    const isActive = active();

    const navBarLabelWithIcon = (
        <>{label}

          {activeIcon && nonActiveIcon && <div className={"slds-m-left--xxx-small"}>
            <Icon
                category="standard"
                name={isActive ? activeIcon : nonActiveIcon}
                size="xx-small"
                className=""
            />
          </div>}
        </>
    );

    return (
        <GlobalNavigationBarLink
            label={navBarLabelWithIcon}
            active={isActive}
            onClick={onClick}
        />
    );
};

export default PCSKAdminGlobalNavBarLink;
