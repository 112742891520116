// Summary of StackSets drift
export class SCPDriftSummary {
    status: string;
    count: number;

    constructor(status: string, count: number) {
        this.status = status;
        this.count = count;
    }
}

