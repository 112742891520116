export const GenerateUUID = () => {
    const hexDigits = '0123456789abcdef';
    let uuid = '';
    for (let i = 0; i < 36; i++) {
        if (i === 14) {
            uuid += '4'; // UUID version 4
        } else if (i === 19) {
            uuid += hexDigits.substr(Math.floor(Math.random() * 4) + 8, 1);
        } else {
            uuid += hexDigits.substr(Math.floor(Math.random() * 16), 1);
        }
        if (i === 8 || i === 13 || i === 18 || i === 23) {
            uuid += '-';
        }
    }
    return uuid;
}