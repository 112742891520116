export const TOAST_MESSAGE = {
    ERROR_VALIDATION: 'Error validation',
    ERROR_VALIDATION_DETAIL: 'Please validation the input',
    ACCESS_REQUEST_CREATION: 'Access Request creation',
    ACCESS_REQUEST_CREATION_DETAIL: 'Request creation in progress. Please wait.',
    ACCESS_REQUEST_CREATION_FAILED: 'Error creating access request',
    ERROR: 'Error',
    ACCESS_REQUEST: 'Access Request',
    ACCESS_REQUEST_APPROVAL_DETAIL: 'Access approved successfully!',
    ACCESS_REQUEST_APPROVAL_ERROR: 'Error approving access.',
    ACCESS_REQUEST_APPROVAL_NOT_ALLOWED: 'You are not allowed to approve the request',
    ACCESS_REQUEST_REJECT_NOT_ALLOWED: 'You are not allowed to reject the request',
    ACCESS_REQUEST_VIEW_NOT_ALLOWED: 'You are not allowed to view the request',
    ACCESS_REQUEST_REJECT_DETAIL: 'Access rejected successfully!',
    ACCESS_REQUEST_REJECT_ERROR: 'Error rejecting access.',
    ACCESS_REQUEST_REJECTED_BY: 'Access request rejected by ',
    ACCESS_REQUEST_REVOKE_DETAIL: 'Access revoked successfully!',
    ACCESS_REQUEST_REVOKE_ERROR: 'Error revoking access.',
    ACCESS_REQUEST_ALREADY_REVOKED_ERROR: 'Access request already revoked by you',
    ACCESS_REQUEST_CANCEL_DETAIL: 'Access cancelled successfully!',
    ACCESS_REQUEST_CANCEL_ERROR: 'Error cancelling access.',
    ACCESS_REQUEST_EXPIRED: 'Access request expired',
    ACCESS_REQUEST_NOT_REVIEWED: 'Access request not reviewed.',
    SHARE_ACCESS: 'Successfully copied',
    SHARE_ACCESS_DETAILS: 'Access Request link copied!',
    COPY_CREDENTIALS_ERROR: 'Error copying credentials',
    COPY_CREDENTIALS_DETAILS: 'Credentials copied!',
    ERROR_FETCHING_ACCOUNTS: 'Error fetching account details',
    ERROR_FETCHING_REASONS: 'Error fetching reasons',
    ERROR_FETCHING_SLACK_CHANNEL: 'Error fetching reasons',
    ERROR_FETCHING_APPROVERS: 'Error fetching approvers',
    ERROR_FETCHING_ROLES: 'Error fetching roles',
    ERROR_FETCHING_CREDENTIALS: 'Error fetching console credentials',
    ERROR_SHARING_ACCESS_DETAILS: 'Error copying access request details',
    ERROR_FETCHING_ACCESS_REQUESTS: 'Error fetching access request details',
    ERROR_UPDATING_SLACK_CHANNEL: 'Error updating slack channel',
    ERROR_UPDATING_SERVICE_TEAM: 'Error updating service team',
    ERROR_FETCHING_MY_ACCOUNTS: 'Error fetching My Accounts',
    ERROR_ACCOUNT_VENDING_CREATION: 'Error creating account',
    WARNING_FOR_ACCESSING_NOT_OWNED_ACCOUNT_HEADING: 'Notification for Access Permission',
    WARNING_FOR_ACCESSING_NOT_OWNED_ACCOUNT: 'Warning: You are attempting to access an account where you are not listed as an approver.',
    ACCOUNT_CLOSURE_APPROVAL_SUCCESS: (accountID: string) => {return `Account ${accountID} Closure Approved Successfully`},
    ACCOUNT_CLOSURE_APPROVAL_FAILED: (accountID: string, message : string) => {return `Account ${accountID} Closure Approval Failed. ${message}`},
    FAILED_LOADING_ACCOUNT_CLOSURES: (message: string) => `Failed loading account closures. ${message}`,
    FAILED_LOADING_STACK_SET_DRIFTS: (message: string) => `Failed loading stackset drift. ${message}`,
    FAILED_LOADING_STACK_SET_DEPLOYMENTS: (message: string) => `Failed loading stackset deployments. ${message}`,
}