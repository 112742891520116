import {MockStackSetsDeploymentApiService} from "./mock_api_repository";
import {MainStackSetsDeploymentApiService} from "./api_repository"
import {StackSetsDeploymentApiService} from "./service";
import {IsStackSetsAPIMockEnabled} from "../../environment/environment";
export class StackSetsDeploymentApiServiceFactory {
    static getService(): StackSetsDeploymentApiService {
        if (IsStackSetsAPIMockEnabled()) {
            return new MockStackSetsDeploymentApiService();
        } else {
            return new MainStackSetsDeploymentApiService();
        }
    }
}
