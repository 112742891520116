import React, { useState, useEffect } from "react";
import {Button, Card, Input, InputIcon, ProgressRing, Textarea} from "@salesforce/design-system-react";
import {fetchAccountVendingStatus} from "../../../../services/accountVending";
import {CONSTANT_ACCOUNT} from "../../../../constants/accountManagement";
import {useAccountCreationStatus} from "../../../../context/account_vending/AccountCreationStatus";
import CustomSpinnerOverlay from "../../../../utils/components/spinner/custom_spinner_overlay";

type AccountVendingStatusProps = {
    isActive: boolean;
};

const AccountVendingStatus: React.FC<AccountVendingStatusProps> = ({ isActive }) => {
    const [isSearchCRTButtonDisabled, setIsSearchCRTButtonDisabled] = useState(true);
    const [searchClientErrorMessage, setSearchClientErrorMessage] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);

    const {accountCreationStatus, setAccountCreationStatus} = useAccountCreationStatus()
    const clearSelections = () => {
        setAccountCreationStatus({
            crt_id: "", status: "", account_id: "", error_status: ""
        })
    };

    useEffect(() => {
        if (isActive) {
            clearSelections();
        }
    }, [isActive]);

    useEffect(() => {
        setIsSearchCRTButtonDisabled(!validateCRT());
    }, [accountCreationStatus.crt_id]);

    const validateCRT = () => {
        if (accountCreationStatus.crt_id.length === 0) {
            setSearchClientErrorMessage("");
            return false;
        }
        if (accountCreationStatus.crt_id.length === 36) {
            setSearchClientErrorMessage("");
            return true;
        }
        setSearchClientErrorMessage(CONSTANT_ACCOUNT.CRT_ID_ERROR_MESSAGE);
        return false;
    };

    const handleSearchClientRequestToken = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAccountCreationStatus({
            crt_id: event.target.value, status: "", account_id: "", error_status: ""
        })
    };

    const handleFetchCRTStatus = async () => {
        setShowSpinner(true);
        clearSelections();
        fetchAccountVendingStatus(accountCreationStatus.crt_id).then(data => {
            setAccountCreationStatus({
                crt_id: accountCreationStatus.crt_id, status: data.status, error_status: data.error, account_id: data.aws_account_id
            });
            setShowSpinner(false);
        }).catch(error => {
            setShowSpinner(false);
            setAccountCreationStatus({
                crt_id: accountCreationStatus.crt_id, status: "Not Found", error_status: error, account_id: ""
            });
        })
    };

    return (
        <Card heading={CONSTANT_ACCOUNT.ACCOUNT_STATUS}>
            <div className="slds-p-around_small">
                <div>
                    <Input
                        id="account_vending_crt_id"
                        label={CONSTANT_ACCOUNT.CRT_ID}
                        required
                        value={accountCreationStatus.crt_id}
                        onChange={handleSearchClientRequestToken}
                        errorText={searchClientErrorMessage}
                        placeholder={CONSTANT_ACCOUNT.CRT_ID_PLACEHOLDER}
                        iconLeft={<InputIcon name="search" category="utility" />}
                    />
                </div>
                <div className="slds-p-top_medium slds-grid slds-grid_vertical-align-center">
                    <Button
                        label={CONSTANT_ACCOUNT.CHECK_STATUS}
                        variant="brand"
                        onClick={handleFetchCRTStatus}
                        disabled={isSearchCRTButtonDisabled}
                    />
                    {showSpinner && (
                        <div className="slds-m-left_medium">
                            <CustomSpinnerOverlay size={"small"}/>
                        </div>
                    )}
                </div>

                {accountCreationStatus.status.toLowerCase() === "processing" &&
                    <div className="slds-p-top--medium">
                        <div className="slds-grid">
                            <div className="slds-m-left--x-small slds-m-top--xxx-small">
                                {CONSTANT_ACCOUNT.STATUS}:
                            </div>
                            <div className="slds-m-left--x-small">
                                <ProgressRing value={100} theme="warning"/>
                            </div>
                            <div className="slds-m-left--xx-small slds-m-top--xxx-small">
                                {CONSTANT_ACCOUNT.PROCESSING}
                            </div>
                        </div>
                    </div>
                }
                {accountCreationStatus.status.toLowerCase() === "success" &&
                    <div className="slds-p-top--medium">
                        <div className="slds-grid">
                            <div className="slds-m-left--x-small slds-m-top--xxx-small">
                                {CONSTANT_ACCOUNT.STATUS}:
                            </div>
                            <div className="slds-m-left--x-small">
                                <ProgressRing value={100} theme="base"/>
                            </div>
                            <div className="slds-m-left--xx-small slds-m-top--xxx-small">
                                {CONSTANT_ACCOUNT.SUCCESS}
                            </div>
                        </div>
                        <Input label={CONSTANT_ACCOUNT.ACCOUNT_ID} value={accountCreationStatus.account_id} disabled/>
                    </div>
                }
                {accountCreationStatus.status.toLowerCase() === "failure" &&
                    <div className="slds-p-top--medium">
                        <div className="slds-grid">
                            <div className="slds-m-left--x-small slds-m-top--xxx-small">
                                {CONSTANT_ACCOUNT.STATUS}:
                            </div>
                            <div className="slds-m-left--x-small">
                                <ProgressRing value={100} theme="expired"/>
                            </div>
                            <div className="slds-m-left--xx-small slds-m-top--xxx-small">
                                {CONSTANT_ACCOUNT.FAILURE}
                            </div>
                        </div>
                        {accountCreationStatus.account_id?.length !== 0 && <Input label={CONSTANT_ACCOUNT.ACCOUNT_ID} value={accountCreationStatus.account_id} disabled/>}
                        <Textarea label={CONSTANT_ACCOUNT.ERROR} value={accountCreationStatus.error_status} disabled/>
                    </div>
                }
                {accountCreationStatus.status.toLowerCase() === "not found" &&
                    <div className="slds-p-top--medium">
                        <div className="slds-grid">
                            <div className="slds-m-left--x-small slds-m-top--xxx-small">
                                {CONSTANT_ACCOUNT.STATUS}:
                            </div>
                            <div className="slds-m-left--x-small">
                                <ProgressRing value={100} theme="expired"/>
                            </div>
                            <div className="slds-m-left--xx-small slds-m-top--xxx-small">
                                {CONSTANT_ACCOUNT.NOT_FOUND}
                            </div>
                        </div>
                        {accountCreationStatus.account_id?.length !== 0 && <Input label={CONSTANT_ACCOUNT.ACCOUNT_ID} value={accountCreationStatus.account_id} disabled/>}
                        <Textarea label={CONSTANT_ACCOUNT.ERROR} value={accountCreationStatus.error_status} disabled/>
                    </div>
                }
            </div>
        </Card>
    );
};

export default AccountVendingStatus;