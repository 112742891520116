import {MainGUSApiService} from "./api_repository"
import {GUSApiService} from "./service"
import {MockGUSApiService} from "./mock_api_repository";
import {IsGUSAPIMockEnabled} from "../environment/environment";
export class GUSApiServiceFactory {
    static getService(): GUSApiService {
        if (IsGUSAPIMockEnabled()) {
            return new MockGUSApiService();
        } else {
            return new MainGUSApiService();
        }
    }
}
