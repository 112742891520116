export class GenericDeploymentSubmissionResponse {
    message: string;
    success: boolean;

    constructor(message: string, success: boolean) {
        this.message = message;
        this.success = success;
    }

    static fromJSON(json: any): GenericDeploymentSubmissionResponse {
        // Default values
        const defaultMessage = "";
        const defaultSuccess = false;

        if (typeof json !== 'object' || json === null) {
            return new GenericDeploymentSubmissionResponse(defaultMessage, defaultSuccess);
        }

        const message = typeof json.message === 'string' ? json.message : defaultMessage;
        const success = typeof json.success === 'boolean' ? json.success : defaultSuccess;

        return new GenericDeploymentSubmissionResponse(message, success);
    }
}
