import React from "react";
import PCSKAdminStackSetTemplateDeploymentsPage from "./StackSetTemplateDeployments";
import {AddToastFunction} from "../../../../utils/types/toast";

type StackSetDeploymentsProps = {
    addToast: AddToastFunction;
    navigationBar: React.ReactNode;
};

const PCSKAdminStackSetDeploymentsPage: React.FC<StackSetDeploymentsProps> = ({
                                                                                  navigationBar,
                                                                                  addToast,
                                                                              }) => {
    return (
        <div>
            {/* Render navigation bar */}
            {navigationBar}

            <PCSKAdminStackSetTemplateDeploymentsPage addToast={addToast} />
        </div>
    );
};

export default PCSKAdminStackSetDeploymentsPage;
