import React, { useState } from "react";
import {Button, Modal, Icon} from "@salesforce/design-system-react"; // Assuming Icon is part of this library
import { useNavigate } from 'react-router-dom';
import moment from "moment/moment";
import {approveAccessRequest, rejectAccessRequest} from "../services/requestReviews";
import {AccessRequest} from "../utils/types/accessRequest";
import {ToastState} from "../utils/types/toast";
import {TOAST_MESSAGE} from "../constants/toastMessages";
import {CONSTANT_ACCESS_REQUEST} from "../constants/accessRequest";
import CustomSpinnerOverlay from "../utils/components/spinner/custom_spinner_overlay"


type SlackAccessRequestProps = {
    requestType?: string;
    accessDetails?: AccessRequest | null
    toast: ToastState;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
};

const SlackAccessRequest: React.FC<SlackAccessRequestProps> = ({ requestType, accessDetails, toast, setToast }) => {
    const [isAccessRequestModalOpen, setIsAccessRequestModalOpen] = useState(true);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const closeAccessRequestModal = () => {
        setIsAccessRequestModalOpen(false);
        navigate("/"); // Redirect to home
    };

    const approveRequest = () => {
        setIsLoading(true);
        approveAccessRequest(accessDetails?.id || "").then(() => {
            setToast({
                isVisible: true,
                message: {
                    heading: TOAST_MESSAGE.ACCESS_REQUEST,
                    details: TOAST_MESSAGE.ACCESS_REQUEST_APPROVAL_DETAIL
                },
                variant: "success"
            });
            navigate("/");
        }).catch(() => {
            setToast({
                isVisible: true,
                message: {
                    heading: TOAST_MESSAGE.ACCESS_REQUEST,
                    details: TOAST_MESSAGE.ACCESS_REQUEST_APPROVAL_ERROR
                },
                variant: "error"
            });
        }).finally(() => {
            setIsLoading(false); // Stop loading
        });
    }

    const rejectRequest = () => {
        setIsLoading(true);
        rejectAccessRequest(accessDetails?.id || "").then(() => {
            setToast({
                isVisible: true,
                message: {
                    heading: TOAST_MESSAGE.ACCESS_REQUEST,
                    details: TOAST_MESSAGE.ACCESS_REQUEST_REJECT_DETAIL
                },
                variant: "success"
            });
            navigate("/");
        }).catch(() => {
            setToast({
                isVisible: true,
                message: {
                    heading: TOAST_MESSAGE.ACCESS_REQUEST,
                    details: TOAST_MESSAGE.ACCESS_REQUEST_REJECT_ERROR
                },
                variant: "error"
            });
        }).finally(() => {
            setIsLoading(false); // Stop loading
        });
    }

    const displayHour = (h: number) => {
        const HOURS_PER_DAY = 24;
        return h < HOURS_PER_DAY ? (h + 'h') : (h / HOURS_PER_DAY + 'd')
    }

    return (
        <div>
            {isAccessRequestModalOpen && (
                <div>
                    <Modal
                        isOpen={true}
                        dismissOnClickOutside={false}
                        onRequestClose={closeAccessRequestModal}
                        heading={CONSTANT_ACCESS_REQUEST.REVIEW_ACCESS_REQUEST}
                        footer={[
                            <div className="slds-grid slds-grid_vertical-align-center slds-grid_align-end">
                                {isLoading && (
                                    <div className="slds-m-right_x-large">
                                        <CustomSpinnerOverlay size={"small"}/>
                                    </div>
                                )}
                                <div>
                                    <Button variant="brand" label={CONSTANT_ACCESS_REQUEST.APPROVE}
                                            disabled={isLoading} onClick={approveRequest}></Button>
                                    <Button variant="destructive" label={CONSTANT_ACCESS_REQUEST.REJECT}
                                            disabled={isLoading} onClick={rejectRequest}></Button>
                                </div>
                            </div>

                        ]}
                    >
                        <section className="slds-modal__content slds-p-around--small">
                            <p className='slds-m-around--x-small'><strong>AWS Account:</strong> {accessDetails?.aws_account_name} - {accessDetails?.aws_account}</p>
                            <p className='slds-m-around--x-small'><strong>Duration:</strong> {displayHour(accessDetails?.duration || 0)}</p>
                            <p className='slds-m-around--x-small'><strong>Role:</strong> {accessDetails?.role_name}</p>
                            <hr />
                            <p className='slds-m-around--x-small'><strong>Reason:</strong> {accessDetails?.reason_bucket}</p>
                            <p className='slds-m-around--x-small'><strong>Reason Details:</strong> {accessDetails?.reason}</p>
                            {accessDetails?.change_case !== "" && <p className='slds-m-around--x-small'><strong>Change case:</strong> {accessDetails?.change_case}</p>}
                            {accessDetails?.incident !== "" && <p className='slds-m-around--x-small'><strong>Incident ID:</strong> {accessDetails?.incident}</p>}
                            <hr />
                            <p className='slds-m-around--x-small'><Icon category="standard" name="user" size="small"/> <strong>Requested By:</strong> {accessDetails?.sfdc_email}</p>
                            <p className='slds-m-around--x-small'><Icon category="standard" name="event" size="small"/> <strong>Created at:</strong> {moment(moment.unix(accessDetails?.timestamp || 0)).format('MMM Do YYYY [at] h:mm:ss A')}</p>
                        </section>
                    </Modal>
                </div>
            )}
        </div>
    );
}

export default SlackAccessRequest;