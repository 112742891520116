import {timestampToDate} from "./deployment";

export class StackSetStagger {
    created_at: number;
    operation_type: string;
    stagger_group_name: string;
    stackset_status: string;
    updated_at: number;

    /**
     * Creates a new instance of StackSetStagger.
     * @param {number} created_at - Timestamp of creation.
     * @param {string} operation_type - Type of operation being performed.
     * @param {string} stackset_id - Identifier for the stack set.
     * @param {string} operation_status - Current status of the operation.
     * @param {number} [updated_at] - Timestamp of the last update.
     */
    constructor(
        created_at: number,
        operation_type: string,
        stackset_id: string,
        operation_status: string,
        updated_at: number = Date.now()
    ) {
        this.created_at = created_at;
        this.operation_type = operation_type;
        this.stagger_group_name = stackset_id;
        this.stackset_status = operation_status;
        this.updated_at = updated_at;
    }

    /**
     * Updates the stack set status and updated_at timestamp.
     * @param {string} newStatus - The new stack set status.
     */
    updateStatus(newStatus: string): void {
        if (!newStatus) {
            throw new Error("A new status must be provided.");
        }
        this.stackset_status = newStatus;
        this.updated_at = Date.now();
    }

    /**
     * Converts the created_at timestamp to a human-readable date string.
     * @returns {string} - Human-readable date string for created_at.
     */
    getCreatedAtDate(): string {
        return timestampToDate(this.created_at);
    }

    /**
     * Converts the updated_at timestamp to a human-readable date string.
     * @returns {string} - Human-readable date string for updated_at.
     */
    getUpdatedAtDate(): string {
        return timestampToDate(this.updated_at);
    }

    /**
     * Returns a JSON representation of the stagger object.
     * @returns {object} - JSON object representing the stagger.
     */
    toJSON(): Record<string, unknown> {
        return {
            created_at: this.created_at,
            operation_type: this.operation_type,
            stackset_id: this.stagger_group_name,
            stackset_status: this.stackset_status,
            updated_at: this.updated_at,
        };
    }

    /**
     * Creates an instance of StackSetStagger from a plain object.
     * @param {object} obj - The object containing StackSetStagger data.
     * @returns {StackSetStagger} - A new instance of StackSetStagger.
     */
    static fromObject(obj: Record<string, unknown>): StackSetStagger {
        if (!obj || typeof obj !== 'object') {
            throw new Error("Invalid object provided.");
        }

        const {
            created_at,
            operation_type,
            stackset_id,
            stackset_status,
            updated_at,
        } = obj as {
            created_at: number;
            operation_type: string;
            stackset_id: string;
            stackset_status: string;
            updated_at: number;
        };

        return new StackSetStagger(
            created_at,
            operation_type,
            stackset_id,
            stackset_status,
            updated_at
        );
    }
}