import React, {useEffect, useState} from 'react';
import {Modal, Button, Card} from '@salesforce/design-system-react';
import ChangeCaseInput from "../../../../utils/components/input/changeCaseInput";
import CustomSpinnerOverlay from "../../../../utils/components/spinner/custom_spinner_overlay";
import {SCPDeploymentAPIServiceFactory} from "../../../../services/deployment/scp/factory";
import {GenericDeploymentSubmissionResponse} from "../../../../utils/types/deployments/genericDeploymentResponse";

interface ExpandOperationModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedOperationID: string;
    onExpandSuccess: (apiResponse: GenericDeploymentSubmissionResponse) => void;
}

const SCPExpandOperationModal: React.FC<ExpandOperationModalProps> = ({ isOpen, onClose, onExpandSuccess, selectedOperationID }) => {
    const [changeCaseID, setChangeCaseID] = useState<string>('');
    const [isValidChangeCase, setIsValidChangeCase] = useState<boolean>(false);
    const [isExpandInProgress, setIsExpandInProgress] = useState<boolean>(false);
    const [disableExpandButton, setDisableExpandButton] = useState<boolean>(false);
    const [expandAPIResponse, setExpandAPIResponse] = useState<GenericDeploymentSubmissionResponse | undefined>(undefined);


    const scpAPIService = SCPDeploymentAPIServiceFactory.getService();

    useEffect(() => {
        setDisableExpandButton(isExpandInProgress || !isValidChangeCase)
    }, [isValidChangeCase, isExpandInProgress]);
    const handleExpand = async () => {
        try {
            setIsExpandInProgress(true);
            const expandResponse = await scpAPIService.expandOperation(changeCaseID, selectedOperationID);
            expandResponse.success && onExpandSuccess(expandResponse)
            setExpandAPIResponse(expandResponse)
        } catch (error) {
            console.log("Error submitting scp operation for Expansion", error);
            setExpandAPIResponse(new GenericDeploymentSubmissionResponse(`${error}`, false))
        } finally {
            setIsExpandInProgress(false);
        }
    };

    const getBottomContent = () => {
        if (isExpandInProgress) {
            return <>
                <Card hasNoHeader={true}>
                    <div style={{minHeight: "10vh"}}>
                        <div className="slds-m-top_xx-large slds-flex slds-flex_column slds-align-items_center">
                            <CustomSpinnerOverlay size={"medium"}/>
                            <p className="slds-m-top_large slds-align_absolute-center slds-text-color--inverse-weak">
                                Submitting operation for Expansion
                            </p>
                        </div>
                    </div>
                </Card>
            </>
        }

        if (expandAPIResponse) {
            return <>
                <Card hasNoHeader={true}>
                    {expandAPIResponse.success ?
                        <div className="slds-m-around_medium slds-p-around_medium">
                            <h2 className="slds-text-heading_medium slds-text-align_center slds-m-bottom_medium slds-text-color_success">
                                Expand Request Submitted Successfully!
                            </h2>
                        </div> :
                        <div className="slds-m-around_medium slds-p-around_medium">
                            <h2 className="slds-text-heading_medium slds-text-align_center slds-m-bottom_medium slds-text-color_destructive">
                                {expandAPIResponse.message}
                            </h2>
                        </div>
                    }
                </Card>
            </>
        }

        return <></>;
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            size="small"
            heading={
                <div>
                    <div>
                        <strong>Expand Operation:</strong> {selectedOperationID}
                    </div>
                </div>
            }

            dismissOnClickOutside={false}
            footer={
                <>
                    <Button label="Expand" onClick={handleExpand} variant="brand" disabled={disableExpandButton}/>
                </>
            }
        >
            <div className="slds-p-around_medium">
                <div className={"slds-box"}>
                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Change Case:</strong></label>
                        </div>
                        <div className="slds-col">
                            <ChangeCaseInput
                                isValidChangeCase={isValidChangeCase}
                                disableInput={isExpandInProgress}
                                changeCaseID={changeCaseID}
                                setChangeCaseID={setChangeCaseID}
                                setIsValidChangeCase={setIsValidChangeCase}
                                searchKey={""}
                            ></ChangeCaseInput>
                        </div>
                    </div>
                </div>
                {getBottomContent()}
            </div>
        </Modal>
    );
};

export default SCPExpandOperationModal;
