import React from 'react';
import {generateCodeChallenge, generateRandomString} from "./helper";
import config from "../config/environments";

const Login: React.FC = () => {

    console.log("in Login.js");

    // begin login.js
    const oauthConfig = (config.backup_domain.enabled && window.location.hostname.startsWith("dashboard-bak")) ? config.backup_domain.oauth : config.oauth;
    const oauthUrl = new URL(oauthConfig.url as string);
    const redirectUrl = new URL(window.location.origin);
    redirectUrl.pathname  = config.oauth.redirect_path as string;

    const codeVerifier = generateRandomString(128);
    const codeChallenge = generateCodeChallenge(codeVerifier);
    localStorage.setItem('pcsk-login-oauth-pkce-code-verifier', codeVerifier);

    oauthUrl.searchParams.set("response_type", "code");
    oauthUrl.searchParams.set("code_challenge_method", "S256");
    oauthUrl.searchParams.set("code_challenge", codeChallenge);
    oauthUrl.searchParams.set("client_id", config.oauth.client_id as string);
    oauthUrl.searchParams.set("redirect_uri", redirectUrl.toString());

    const bytes = new Uint8Array(48);
    window.crypto.getRandomValues(bytes);
    let binary = '';
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    const randomBase64 = window.btoa(binary);
    const nonce = randomBase64.substring(0, 32);
    oauthUrl.searchParams.set("nonce", nonce);

    const state = randomBase64.substring(32, 64);
    oauthUrl.searchParams.set("state", state);
    localStorage.setItem('pcsk-login-oauth-state', state);

    window.location.assign(oauthUrl.toString());

    console.log("finished login.js", oauthUrl.toString());

    return null; // Explicitly return null since the component doesn't render anything
}

export default Login;
