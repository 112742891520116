import React, { useEffect, useState } from 'react';
import {BrandBand, Card, Icon} from '@salesforce/design-system-react';
import CustomSpinnerOverlay from "../../utils/components/spinner/custom_spinner_overlay";

const NotAuthenticatedView: React.FC = () => {
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            window.location.replace('/');
        }
    }, [countdown]);

    return (
        <>
            <BrandBand
                className="custom-brand-band-class"
                id="brand-band-large"
                size="large"
            >
                <div className="slds-align_absolute-center slds-m-around_xx-large">
                    <img
                        src={`${process.env.PUBLIC_URL}/salesforce_logo_large.png`}
                        alt="Big Logo"
                        className="slds-image slds-size_medium"
                    />
                </div>

                <div>
                    <Icon
                        assistiveText={{label: 'Warning'}}
                        category="utility"
                        name="warning"
                        size="large"
                        className="slds-icon slds-icon_large slds-icon-text-warning slds-align_absolute-center"
                    />
                    <div className="slds-text-heading_small slds-align_absolute-center">
                        Unauthorized !
                    </div>
                    <div className="slds-align_absolute-center slds-m-top--small">
                        Redirecting to Home Page in <strong>&nbsp;{countdown}</strong>
                    </div>
                </div>
            </BrandBand>
        </>
)
;
};

export default NotAuthenticatedView;
