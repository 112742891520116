import { SHA256, enc } from 'crypto-js';

// Function returns random string of length ( provided in the params)
export function generateRandomString(length: number): string {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

// Function generated code, to be later used for QuantumK token response verification
export function generateCodeChallenge(codeVerifier: string): string {
    return SHA256(codeVerifier).toString(enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

// Function parse the auth data from the URL
export function parseAuthDataFromFragment(): Record<string, string> {
    const raw_fragment = window.location.search;
    if (!raw_fragment) {
        return {};
    }
    let fragment = raw_fragment.substring(1); //trim leading ?
    let auth_data: Record<string, string> = {};
    for (let kv of fragment.split("&")) {
        let split = kv.split("=");
        if (split.length === 2) {
            auth_data[split[0]] = split[1];
        }
    }

    return auth_data;
}

// Function to validate the auth data
export function validateAuthData(authData: Record<string, string>): void {
    if (!authData.state) {
        throw Error("Invalid auth fragment: no state field");
    }

    verifyState(authData.state);

    if (!authData.code) {
        throw Error("Invalid auth fragment: no access code field");
    }
}

// Function to verify the auth data state
export function verifyState(returnedState: string): void {
    const expectedState = localStorage.getItem('pcsk-login-oauth-state');
    const state = decodeURIComponent(returnedState);
    if (state !== expectedState) {
        throw Error(`OAuth state mismatch. CSRF attack? ${state} received but vs ${expectedState} expected.`);
    }
}

// Function returning code verifier token
export function getCodeVerifier(): string | null {
    return localStorage.getItem('pcsk-login-oauth-pkce-code-verifier');
}

// Function returns current unix timestamp
export function getCurrentUnixTimestamp(): number {
    const currentDate = new Date();
    return Math.floor(currentDate.getTime() / 1000);
}
