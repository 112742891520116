import React from 'react';

export type BarChartRow = {
    label: string;
    value: number;
    percentage: number;
    class: string;
};

type BarChartProps = {
    heading: string;
    liClass: string;
    headerClass?: string;
    contentText?: string;
    data: BarChartRow[];
};

const BarChart: React.FC<BarChartProps> = (props: BarChartProps) => {
    const getLiClasses = () => {
        return `slds-p-horizontal_small ${props.liClass}`
    }
    const getHeaderClasses = () => {
        return `${props.headerClass}`
    }

    const sortedData = [...props.data].sort((a, b) => b.value - a.value);

    const contentText = props.contentText || "Account";
    return (
        <>
            <h2 className={getHeaderClasses()}>{props.heading}</h2>
            <ul className="slds-grid slds-wrap slds-grid_pull-padded">
                {sortedData.map((row, index) => (
                    <li key={index} className={getLiClasses()}>
                        <div className="slds-box--small">
                            <div className="slds-text-title">{row.label}</div>
                            <span>{row.percentage}%</span>
                            <div className="slds-progress-bar">
                                <span
                                    className={`slds-progress-bar__value ${row.class}`}
                                    style={{ width: `${row.percentage}%` }}
                                >
                                    <span className="slds-assistive-text">{row.value}% Complete</span>
                                </span>
                            </div>
                            <span>{row.value} {props.contentText}{row.value !== 1 && 's'}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default BarChart;
