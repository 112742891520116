import {ACCOUNT_VENDING_BASE_URL, HandleErrorResponse} from "./common";

export const fetchAccountVendingStatus = async (crtId: string) => {
    const endpoint = `${ACCOUNT_VENDING_BASE_URL}/request-new-accounts/account-status/${crtId}`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return response.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}

export const createNewAccountUsingVending = async (requestData: any) => {
    const endpoint = `${ACCOUNT_VENDING_BASE_URL}/request-new-accounts`;

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            body: JSON.stringify(requestData)
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return response.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}
