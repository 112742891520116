import {ArtifactApiService} from "./service";
import {FetchArtifactsResponse} from "../../utils/types/artifacts/artifacts";
import {GenericDeploymentSubmissionResponse} from "../../utils/types/deployments/genericDeploymentResponse";

// Helper function to generate mock data
// Helper function to generate random commit IDs and mock data
function generateMockData(number: number): { artifact_names: string[] } {
    const generateRandomCommitID = (length: number = 40): string => {
        const characters = '0123456789abcdef';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const commitIDs = Array.from({ length: number }, (_, index) => {
        return `pcsk-stacksets_master_${generateRandomCommitID()}.zip`;
    });

    return {
        artifact_names: commitIDs
    };
}

// Updated MockStackSetsDeploymentApiService class
export class MockArtifactApiService implements ArtifactApiService {
    async getArtifacts(prefix: string, sinceDays: number): Promise<FetchArtifactsResponse> {
        const mockData = generateMockData(100);
        return new Promise<FetchArtifactsResponse>(resolve => {
            setTimeout(() => {
                resolve(FetchArtifactsResponse.fromJSON(mockData));
            }, 2500);
        });
    }

    async deployArtifact(changeCase: string, artifactName: string): Promise<GenericDeploymentSubmissionResponse> {
        const mockData = generateMockData(100);
        return new Promise<GenericDeploymentSubmissionResponse>(resolve => {
            setTimeout(() => {
                resolve(new GenericDeploymentSubmissionResponse("Deployment request submitted successfully", true));
            }, 2500);
        });
    }
}
