import {GUSApiService} from "./service"
import {ChangeCaseMetadata} from "../../utils/types/gus";

export class MockGUSApiService implements GUSApiService {
    async fetchChangeCaseMetadata(caseID: string, accountID: string): Promise<ChangeCaseMetadata> {
        const ccMetadata = new ChangeCaseMetadata()
        ccMetadata.metadata.subject = "Mock change case subject"
        ccMetadata.generic_admin_actions_data.allowed = true

        return new Promise<ChangeCaseMetadata>(resolve => {
            setTimeout(() => {
                resolve(ccMetadata)
            }, 500);
        });
    }
}