import React, {useEffect, useState} from 'react';
import {Modal, Button, Card} from '@salesforce/design-system-react';
import {StackSetsDeployment} from "../../../../utils/types/deployments/stacksets/deployment";
import {StackSetsDeploymentApiServiceFactory} from "../../../../services/deployment/stacksets/factory";
import CustomSpinnerOverlay from "../../../../utils/components/spinner/custom_spinner_overlay";
import ToggleGuide from "../../../../components/pcsk_admin/deployment/stacksets/ToggleGuide";
import ChangeCaseInput from "../../../../utils/components/input/changeCaseInput";
import {GenericDeploymentSubmissionResponse} from "../../../../utils/types/deployments/genericDeploymentResponse";


interface ExpandCommitModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedCommit: StackSetsDeployment;
    onExpandSuccess: (apiResponse: GenericDeploymentSubmissionResponse) => void;
}

const ExpandStackSetCommitModal: React.FC<ExpandCommitModalProps> = ({ isOpen, onClose, onExpandSuccess, selectedCommit }) => {
    const [changeCaseID, setChangeCaseID] = useState<string>('');
    const [isValidChangeCase, setIsValidChangeCase] = useState<boolean>(false);
    const [isExpandInProgress, setIsExpandInProgress] = useState<boolean>(false);
    const [dryRun, setDryRun] = useState<boolean>(false);
    const [disableExpandButton, setDisableExpandButton] = useState<boolean>(false);
    const [forceExpand, setForceExpand] = useState<boolean>(false);
    const [autoExpand, setAutoExpand] = useState<boolean>(false);
    const [expandAPIResponse, setExpandAPIResponse] = useState<GenericDeploymentSubmissionResponse | undefined>(undefined);


    const stackSetsApiService = StackSetsDeploymentApiServiceFactory.getService();

    useEffect(() => {
        setDisableExpandButton(isExpandInProgress || !isValidChangeCase || !validateCommitID(selectedCommit.commit_id))
    }, [isValidChangeCase, isExpandInProgress]);
    const handleExpand = async () => {
        try {
            setIsExpandInProgress(true);
            const expandResponse = await stackSetsApiService.expandCommit(changeCaseID, selectedCommit.commit_id, forceExpand, autoExpand, dryRun);
            expandResponse.success && onExpandSuccess(expandResponse)
            setExpandAPIResponse(expandResponse)
        } catch (error) {
            console.log("Error submitting commit for Expansion", error);
            setExpandAPIResponse(new GenericDeploymentSubmissionResponse(`${error}`, false))
        } finally {
            setIsExpandInProgress(false);
        }
    };

    const validateCommitID = (commitID: string) => {
        return commitID.length > 0
    }
    const getBottomContent = () => {
        if (isExpandInProgress) {
            return <>
                <Card hasNoHeader={true}>
                    <div style={{minHeight: "10vh"}}>
                        <div className="slds-m-top_xx-large slds-flex slds-flex_column slds-align-items_center">
                            <CustomSpinnerOverlay size={"medium"}/>
                            <p className="slds-m-top_large slds-align_absolute-center slds-text-color--inverse-weak">
                                Submitting commit for Expansion
                            </p>
                        </div>
                    </div>
                </Card>
            </>
        }

        if (expandAPIResponse) {
            return <>
                <Card hasNoHeader={true}>
                    {expandAPIResponse.success ?
                        <div className="slds-m-around_medium slds-p-around_medium">
                            <h2 className="slds-text-heading_medium slds-text-align_center slds-m-bottom_medium slds-text-color_success">
                                Deployment Request Submitted Successfully!
                            </h2>
                        </div> :
                        <div className="slds-m-around_medium slds-p-around_medium">
                            <h2 className="slds-text-heading_medium slds-text-align_center slds-m-bottom_medium slds-text-color_destructive">
                                {expandAPIResponse.message}
                            </h2>
                        </div>
                    }
                </Card>
            </>
        }

        return (
            <>
                <Card
                    bodyClassName={"slds-color__background_gray-4"}
                    hasNoHeader={true}
                >
                    <ToggleGuide suffix={"Expand"}/>
                </Card>
            </>
        );
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            size="small"
            heading={
                <div>
                    <div>
                        <strong>Expand Commit:</strong> {selectedCommit.commit_id}
                    </div>
                    <div>
                        <em>{selectedCommit.commit_title}</em>
                    </div>
                </div>
            }

            dismissOnClickOutside={false}
            footer={
                <>
                    <Button label="Expand" onClick={handleExpand} variant="brand" disabled={disableExpandButton}/>
                </>
            }
        >
            <div className="slds-p-around_medium">
                <div className={"slds-box"}>
                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Change Case:</strong></label>
                        </div>
                        <div className="slds-col">
                            <ChangeCaseInput
                                isValidChangeCase={isValidChangeCase}
                                disableInput={isExpandInProgress}
                                changeCaseID={changeCaseID}
                                setChangeCaseID={setChangeCaseID}
                                setIsValidChangeCase={setIsValidChangeCase}
                                searchKey={""}
                            ></ChangeCaseInput>
                        </div>
                    </div>
                    <div className="slds-grid slds-gutters slds-m-horizontal--medium">
                        <div className="slds-form-element__control slds-m-around--small">
                            <label className="slds-checkbox_toggle slds-grid">
                                <input
                                    type="checkbox"
                                    checked={dryRun}
                                    disabled={isExpandInProgress}
                                    onChange={() => {setDryRun(!dryRun)}}
                                />
                                <span className="slds-checkbox_faux_container" aria-live="assertive">
                                <span className="slds-checkbox_faux"></span>
                                <span className="slds-checkbox_on">Dry Run</span>
                                <span className="slds-checkbox_off">Dry Run </span>
                            </span>
                            </label>
                        </div>
                        <div className="slds-form-element__control slds-m-around--small">
                            <label className="slds-checkbox_toggle slds-grid">
                                <input
                                    type="checkbox"
                                    checked={autoExpand}
                                    disabled={isExpandInProgress}
                                    onChange={() => {setAutoExpand(!autoExpand)}}
                                />
                                <span className="slds-checkbox_faux_container" aria-live="assertive">
                                <span className="slds-checkbox_faux"></span>
                                <span className="slds-checkbox_on">Auto Expand</span>
                                <span className="slds-checkbox_off">Auto Expand </span>
                            </span>
                            </label>
                        </div>
                        <div className="slds-form-element__control slds-m-around--small">
                            <label className="slds-checkbox_toggle slds-grid">
                                <input
                                    type="checkbox"
                                    checked={forceExpand}
                                    disabled={isExpandInProgress}
                                    onChange={() => {setForceExpand(!forceExpand)}}
                                />
                                <span className="slds-checkbox_faux_container" aria-live="assertive">
                                <span className="slds-checkbox_faux"></span>
                                <span className="slds-checkbox_on">Force Expand</span>
                                <span className="slds-checkbox_off">Force Expand </span>
                            </span>
                            </label>
                        </div>
                    </div>
                </div>
                {getBottomContent()}
            </div>
        </Modal>
    );
};

export default ExpandStackSetCommitModal;
