import React from 'react';
import { Modal, Button } from '@salesforce/design-system-react';
import {CONSTANT_ACCESS_REQUEST} from "../constants/accessRequest";

type AccessRequestConfirmationModalProps = {
    isOpen: boolean;
    toggleModal: (isOpen: boolean) => void;
    onConfirm: () => void;
    actionType: string;
};

const AccessRequestConfirmationModal: React.FC<AccessRequestConfirmationModalProps> = ({
   isOpen,
   toggleModal,
   onConfirm,
   actionType,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => toggleModal(false)}
            heading={`Confirm ${actionType}`}
            footer={[
                <Button label={CONSTANT_ACCESS_REQUEST.CANCEL} onClick={() => toggleModal(false)}/>,
                <Button label={CONSTANT_ACCESS_REQUEST.CONFIRM} variant="brand" onClick={onConfirm} />,
            ]}
        >
            <p className="slds-p-around_medium">Are you sure you want to {actionType.toLowerCase()} this access request?</p>
        </Modal>
    );
};

export default AccessRequestConfirmationModal;
