import React from "react";
import { Icon } from "@salesforce/design-system-react";

// Define the type for each link item
export interface QuickLinkItem {
    link: string;
    text: string;
}

// Define the props for the LinkList component
interface LinkListProps {
    usefulPCSKLinks: QuickLinkItem[];
}

const QuickLinkList: React.FC<LinkListProps> = ({ usefulPCSKLinks }) => {
    return (
        <div className="slds-grid slds-wrap slds-gutters slds-p-left--small slds-p-bottom--small slds-p-top--xx-small ">
            {usefulPCSKLinks.map((item, index) => (
                <div
                    key={index}
                    className="slds-col slds-size_1-of-fit slds-box slds-box_border slds-theme_default slds-m-horizontal_small"
                >
                    <div className="slds-grid slds-gutters_small slds-align-center slds-text-align_left">
                        <div className="slds-m-right_small">
                            <Icon
                                category="standard"
                                name="link"
                                size="small"
                            />
                        </div>
                        <div className="slds-m-top--xxx-small">
                            <a
                                href={item.link}
                                className="slds-text-link slds-text-color_action slds-m-right--small"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {item.text}
                            </a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default QuickLinkList;
