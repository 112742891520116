import React, {useEffect, useState} from "react";
import { ToastState } from "../../../utils/types/toast";
import {Tabs, TabsPanel} from "@salesforce/design-system-react";
import UpdateSlackChannel from "./update_slack_channel/UpdateSlackChannel";
import UpdateServiceTeam from "./update_service_team/UpdateServiceTeam";
import {CONSTANT_TEAMS} from "../../../constants/teams";
import {useNavigate} from "react-router-dom";

type UpdateAccountDetailsProps = {
    action?: (type: string) => void;
    isActive: boolean;
    setActiveTab: (tab: string) => void;
    setActiveSubTab: (tabIndex: number) => void;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
    requestedURL: URL
};

const UpdateAccountDetails: React.FC<UpdateAccountDetailsProps> = ({isActive,setToast, requestedURL}) => {
    const [activeSection, setActiveSection] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        if (isActive && requestedURL.pathname.startsWith('/team')) {
            setActiveSection(0);
        }
        if (isActive && requestedURL.pathname.startsWith('/update-accounts-team')) {
            setActiveSection(1);
        }
        else {
            setActiveSection(0);
        }
        navigate("/");
    }, [isActive]);

    const handleTabSelect = (selectedIndex: number, data: any) => {
        setActiveSection(selectedIndex);
    };

    return (
        <div style={{ minHeight: "80vh" }}>
            <Tabs
                id="account_details_update"
                selectedIndex={activeSection}
                onSelect={handleTabSelect}
            >
                <TabsPanel label={CONSTANT_TEAMS.UPDATE_SLACK_CHANNEL}>
                    <UpdateSlackChannel isActive={isActive} isActiveSection={activeSection === 0} setToast={setToast}/>
                </TabsPanel>
                <TabsPanel label={CONSTANT_TEAMS.UPDATE_SERVICE_TEAM}>
                    <UpdateServiceTeam isActive={isActive} isActiveSection={activeSection === 1} setToast={setToast}/>
                </TabsPanel>
            </Tabs>
        </div>
    );
};

export default UpdateAccountDetails;