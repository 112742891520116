import React from 'react';
import { Spinner } from '@salesforce/design-system-react';
interface CustomHeightPageSpinnerProps {
    minHeight: string;
    size: "xx-small" | "x-small" | "small" | "medium" | "large";
}
const PageSpinnerOverlay = () => {
    return (
        <div className="slds-align_absolute-center">
            <div role="status" className="slds-spinner">
                <span className="slds-assistive-text">Loading...</span>
                <Spinner size="medium" variant="brand" />
            </div>
        </div>
    );
};


export const EmptyPageSpinner = () => {
    return (
        <div className="slds-is-relative slds-size_1-of-1" style={{minHeight: "80vh"}}>
            <PageSpinnerOverlay/>
        </div>
    )
}

export const CustomHeightPageSpinner: React.FC<CustomHeightPageSpinnerProps> = ({ minHeight, size }) => {
    return (
        <div className="slds-m-bottom_xx-large slds-is-relative slds-size_1-of-1" style={{ minHeight }}>
            <div className="slds-align_absolute-center">
                <div role="status" className="slds-spinner">
                    <span className="slds-assistive-text">Loading...</span>
                    <Spinner size={size} variant="brand"/>
                </div>
            </div>
        </div>
    );
};

export default PageSpinnerOverlay;
