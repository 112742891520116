export class FetchArtifactsResponse {
    /**
     * List of artifact names.
     */
    public artifactNames: string[];

    /**
     * Constructor to initialize the artifact names.
     * @param artifactNames - List of artifact names.
     */
    constructor(artifactNames: string[] = []) {
        this.artifactNames = artifactNames;
    }

    /**
     * Adds an artifact name to the list.
     * @param artifactName - The artifact name to add.
     */
    public addArtifactName(artifactName: string): void {
        if (artifactName && typeof artifactName === "string") {
            this.artifactNames.push(artifactName);
        } else {
            throw new Error("Invalid artifact name. It must be a non-empty string.");
        }
    }

    /**
     * Removes an artifact name from the list.
     * @param artifactName - The artifact name to remove.
     */
    public removeArtifactName(artifactName: string): void {
        const index = this.artifactNames.indexOf(artifactName);
        if (index > -1) {
            this.artifactNames.splice(index, 1);
        } else {
            throw new Error("Artifact name not found in the list.");
        }
    }

    /**
     * Checks if an artifact name exists in the list.
     * @param artifactName - The artifact name to check.
     * @returns True if the artifact name exists, otherwise false.
     */
    public hasArtifactName(artifactName: string): boolean {
        return this.artifactNames.includes(artifactName);
    }

    /**
     * Gets all artifact names.
     * @returns List of artifact names.
     */
    public getArtifactNames(): string[] {
        return [...this.artifactNames];
    }

    /**
     * Static function to create an instance from JSON.
     * @param json - JSON object containing artifact names.
     * @returns Instance of FetchArtifactsResponse.
     */
    public static fromJSON(json: unknown): FetchArtifactsResponse {
        if (
            typeof json !== "object" ||
            json === null ||
            !Array.isArray((json as any).artifact_names) ||
            !(json as any).artifact_names.every((name: unknown) => typeof name === "string")
        ) {
            throw new Error("Invalid JSON format. Expected an object with an 'artifact_names' array of strings.");
        }

        return new FetchArtifactsResponse((json as any).artifact_names);
    }
}
