import React, { useEffect, useState } from "react";
import {
    Card,
    Icon,
    Button,
    Accordion,
    AccordionPanel,
    Input, ButtonGroup, Dropdown, DropdownTrigger, DataTable, DataTableColumn, DataTableCell
} from "@salesforce/design-system-react";
import { AddToastFunction } from "../../../../utils/types/toast";
import { CustomHeightPageSpinner } from "../../../../utils/components/spinner/page_spinner_overlay";
import { TOAST_MESSAGE } from "../../../../constants/toastMessages";
import BarChart, {BarChartRow} from "../../../../utils/components/bar_chart/bar_chart";
import {SCPDeployment, SCPOperation} from "../../../../utils/types/deployments/scp/operations";
import {CONSTANT_SCP_DEPLOYMENTS} from "../../../../constants/accountClosure";
import {SCPDeploymentAPIServiceFactory} from "../../../../services/deployment/scp/factory";
import SCPExpandOperationModal from "./ExpandOperation";
import SCPRollbackOperationModal from "./RollbackOperation";
import QuickLinkList, {QuickLinkItem} from "../../../../utils/components/quick_links/QuickLinks";
import quickLinks from "../../../../utils/components/quick_links/QuickLinks";
import QuickLinks from "../../../../utils/components/quick_links/QuickLinks";
import {timestampToDate} from "../../../../utils/types/deployments/stacksets/deployment";


const ITEMS_PER_PAGE = 10; // Define how many items to display per page

type SCPDeploymentsProps = {
    addToast: AddToastFunction;
    navigationBar: React.ReactNode;
};

type FilterByStatusOption = {
    label: string;
    value: string;
};
const labelAll : FilterByStatusOption = {label: "All", value: "All"}

const PCSKAdminSCPDeploymentsPage: React.FC<SCPDeploymentsProps> = ({
                                                                      addToast,
                                                                        navigationBar
                                                                                      }) => {
    const [scpDeployments, setScpDeployments] = useState<SCPOperation[]>([]);
    const [filteredSCPDeployments, setFilteredSCPDeployments] = useState<SCPOperation[]>([]);
    const [isFetchingDeployments, setIsFetchingDeployments] = useState<boolean>(false);
    const [selectedOperationID, setSelectedOperationID] = useState<string>("");
    const [selectedMPAID, setSelectedMPAID] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filterDropdownOptions, setFilterDropdownOptions] = useState<FilterByStatusOption[]>([labelAll])
    const [selectedDropDownFilter, setSelectedDropDownFilter] = useState<FilterByStatusOption>(labelAll)
    const [operationsBarChartData, setOperationsBarChartData] = useState<BarChartRow[]>([])
    const [isExpandModalOpen, setIsExpandModalOpen] = useState<boolean>(false);
    const [isRevertModalOpen, setIsRevertModalOpen] = useState<boolean>(false);
    const [changessBarChartData, setChangessBarChartData] = useState<BarChartRow[]>([])


    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, filteredSCPDeployments.length);
    const scpApiService = SCPDeploymentAPIServiceFactory.getService();

    useEffect(() => {
        fetchSCPOperations();
    }, []);


    useEffect(() => {
        filterDeployments();
    }, [searchTerm, currentPage, scpDeployments, selectedDropDownFilter]);

    useEffect(() => {
        const statusCounts = new Map<string, number>();

        // Count the number of unique items by status
        findOperation(selectedOperationID).forEach(item => {
            const status = item.status || "Unknown";

            // Update the map with the current count for each status, defaulting to 1 if not already present
            statusCounts.set(status, (statusCounts.get(status) ?? 0) + 1);
        });
        // Calculate the total sum of all counts
        const totalSum = Array.from(statusCounts.values()).reduce((sum, count) => sum + count, 0);

        // Build bar chart data
        const newBarChartData = Array.from(statusCounts.entries()).map(([status, count]) => {
            const percentage = totalSum > 0 ? (count / totalSum) * 100 : 0; // Handle division by zero
            const formattedPercentage = parseFloat(percentage.toFixed(Number.isInteger(percentage) ? 0 : 2));
            return {
                label: status || "None",
                value: count,
                percentage: formattedPercentage,
                class: CONSTANT_SCP_DEPLOYMENTS.GET_STATUS_THEME(status),
            };
        });

        setChangessBarChartData(newBarChartData);
    }, [selectedOperationID])

    useEffect(() => {
        // Create a Set to store unique deployment statuses
        const uniqueStatuses = new Set(scpDeployments.map(deployment => deployment.status));

        // Create the filter options from unique deployment statuses
        const filterByStatusOptions: FilterByStatusOption[] = Array.from(uniqueStatuses).map(status => ({
            label: status,
            value: status
        }));

        // Add "All" as an option
        filterByStatusOptions.push({ label: "All", value: "All" });
        filterByStatusOptions.sort((a, b) => a.value.localeCompare(b.value));

        setFilterDropdownOptions(filterByStatusOptions);
    }, [scpDeployments]);

    useEffect(() => {
        const statusCounts = new Map<string, number>();

        // Count the number of unique items by deployment_status
        filteredSCPDeployments.forEach(item => {
            const status = item.status || "Unknown";

            // Update the map with the current count for each status, defaulting to 1 if not already present
            statusCounts.set(status, (statusCounts.get(status) ?? 0) + 1);
        });
        // Calculate the total sum of all counts
        const totalSum = Array.from(statusCounts.values()).reduce((sum, count) => sum + count, 0);

        // Build bar chart data
        const newBarChartData = Array.from(statusCounts.entries()).map(([status, count]) => {
            const percentage = totalSum > 0 ? (count / totalSum) * 100 : 0; // Handle division by zero
            const formattedPercentage = parseFloat(percentage.toFixed(Number.isInteger(percentage) ? 0 : 2));
            return {
                label: status || "None",
                value: count,
                percentage: formattedPercentage,
                class: CONSTANT_SCP_DEPLOYMENTS.GET_STATUS_THEME(status),
            };
        });

        setOperationsBarChartData(newBarChartData);
    }, [filteredSCPDeployments]);


    const operationIDHAsActions = (operationID: string): boolean => {
        // Filter operations by matching operation_id
        return scpDeployments.some(operation => {
            return operation.operation_id === operationID && operation.hasActions();
        });
    }

    const operationIDCanExpand = (operationID: string): boolean => {
        // Filter operations by matching operation_id
        return scpDeployments.some(operation => {
            return operation.operation_id === operationID && operation.can_expand;
        });
    }

    const operationIDCanRevert = (operationID: string): boolean => {
        // Filter operations by matching operation_id
        return scpDeployments.some(operation => {
            return operation.operation_id === operationID && operation.can_revert;
        });
    }

    const findOperation = (operationID: string): SCPOperation[] => {
        return scpDeployments.filter(operation => operation.operation_id === operationID);
    }

    const getOperationDetails = (operationID: string) => {
        const operations = findOperation(operationID)
        if (operations.length == 0) return <></>

        // Find the minimum timestamp_of_operation_creation
        const minTimestamp = operations.reduce((min, operation) => {
            const operationTimestamp = operation.timestamp_of_operation_creation;
            return operationTimestamp < min ? operationTimestamp : min;
        }, operations[0].timestamp_of_operation_creation);

        // Collect all the MPAs
        const mpaIds = operations.map(operation => operation.mpa_id);

        return <>
            {getDetailsLi("Created At", timestampToDate(Number(minTimestamp)))}
            {getDetailsLi("MPAs", `${mpaIds}`)}
        </>

    }

    const fetchSCPOperations = async () => {
        setIsFetchingDeployments(true);
        try {
            const response = await scpApiService.getOperations();
            setScpDeployments(response.operations);
        } catch (error) {
            addToast("", TOAST_MESSAGE.FAILED_LOADING_STACK_SET_DEPLOYMENTS(`${error}`), "error");
        } finally {
            setIsFetchingDeployments(false);
        }
    };

    const handleSelectOperation = (operation: string) => {
        setSelectedOperationID(selectedOperationID === operation ? "" : operation);
    };

    // Filter deployments based on search term
    const filterDeployments = () => {
        let data = scpDeployments.filter(deployment => {
            return (
                deployment.operation_id.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        if (selectedDropDownFilter.value != labelAll.value) {
            data = data.filter(deployment => {
                return deployment.status == selectedDropDownFilter.value;
            });
        }

        setFilteredSCPDeployments(data)
    };

    // Get the current page's deployments
    const getCurrentPageDeployments = () => {
        const filtered = filteredSCPDeployments.slice(startIndex, endIndex);

        // Extract and return operation IDs
        const uniqueIds: string[] = [];
        for (const operation of filtered) {
            if (!uniqueIds.includes(operation.operation_id)) {
                uniqueIds.push(operation.operation_id);
            }
        }
        return uniqueIds;
    };

    const resetPage = () => {
        setSelectedOperationID("")
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page when searching
        resetPage()
    };

    const handleNextPage = () => {
        if ((currentPage * ITEMS_PER_PAGE) < filteredSCPDeployments.length) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const getOperationsBarChartSize = () => {
        return `slds-size_1-of-${operationsBarChartData.length <= 0 ? 1 : operationsBarChartData.length}`
    }

    const getCombinedOperationsForOperationID = (operationID: string): SCPOperation[] => {
        return scpDeployments.filter(deployment => {
            return deployment.operation_id == operationID;
        });
    }

    const handleExpand = () => {
        setIsExpandModalOpen(true)
    }
    const handleRevert = () => {
        setIsRevertModalOpen(true)
    }

    const getDetailsLi = (key: string, value: string) => {
        return <>
            <li>
                <b className="slds-text-color--weak slds-text-body_small">{key}:</b> {value}
            </li>
        </>
    }

    const getDeploymentsCard = () => {
        const body = <>
            <Card
                heading="PCSK SCP Deployment"
                icon={<Icon category="standard" name="code_playground" size="small"/>}
                className="slds-m-horizontal--small"
                bodyClassName="slds-p-right_medium slds-p-left_medium slds-p-bottom_medium"
            >
            {isFetchingDeployments || scpDeployments.length > 0 ? (
                <>
                    <div className="slds-grid slds-wrap">
                        <div className="slds-col slds-size_1-of-1 slds-m-bottom_medium">
                            <Card
                                icon={<Icon category="standard" name="code_playground" size="small"/>}
                                className=""
                                bodyClassName={"slds-p-around_small"}
                                hasNoHeader={true}
                                style={{minHeight: "50vh"}}
                            >
                                <div
                                    className="slds-grid slds-wrap slds-m-bottom_small">
                                    {/* Search bar */}
                                    <div
                                        className="slds-col slds-size_2-of-4 slds-p-right_small slds-text-color--weak">
                                        <h2 className={"slds-text-title_bold "}> Search Operations</h2>
                                        <Input
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                            placeholder="Search by operation id"
                                            className="slds-m-bottom_small slds-m-top--x-small"
                                            disabled={isFetchingDeployments}
                                        />
                                    </div>

                                    <div className="slds-col slds-size_1-of-4">
                                        <label
                                            className="slds-form-element__label slds-text-title_bold slds-text-color--weak">Operation Status</label>
                                        <div className="slds-form-element__control">
                                            <Dropdown
                                                align="left"
                                                iconCategory="utility"
                                                iconName="filter"
                                                iconPosition="right"
                                                label={selectedDropDownFilter.label}
                                                options={filterDropdownOptions}
                                                value={"haha"}
                                                onSelect={(selectedFilter: FilterByStatusOption) => {
                                                    setSelectedDropDownFilter(selectedFilter)
                                                }}
                                                disabled={isFetchingDeployments}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {isFetchingDeployments ?
                                <CustomHeightPageSpinner size="medium" minHeight="50vh"/> : <>
                                {/* Bar Chart */}
                                {
                                    filteredSCPDeployments.length > 0 && <div className="slds-grid slds-wrap slds-size_1-of-1">
                                        <div className="slds-col">
                                            <BarChart
                                                heading={"Operation Status Distribution"}
                                                data={operationsBarChartData}
                                                liClass={getOperationsBarChartSize()}
                                                headerClass={"slds-text-title_bold slds-text-color--weak"}
                                                contentText={"Commit"}
                                            />
                                        </div>
                                    </div>
                                }

                                {filteredSCPDeployments.length > 0 && <h2 className={"slds-text-title_bold slds-text-color--weak slds-m-top--x-small"}> Operations </h2>}
                                <Accordion>
                                    {getCurrentPageDeployments().map((operationID, index) => (
                                        <AccordionPanel
                                            key={index}
                                            expanded={selectedOperationID === operationID}
                                            onTogglePanel={() => handleSelectOperation(operationID)}
                                            summary={
                                                <div className="slds-text-wrap" style={{whiteSpace: 'normal'}}>
                                                    <h3>{`${(currentPage - 1) * ITEMS_PER_PAGE + index + 1}. ${operationID}`}</h3>
                                                </div>
                                            }
                                        >
                                            <div className="slds-grid slds-box">

                                                <div className="slds-col slds-size_2-of-3 slds-m-horizontal--x-small">
                                                    <ul className="slds-list_dotted">
                                                        {getOperationDetails(selectedOperationID)}
                                                    </ul>
                                                </div>
                                                <div className="slds-grid">
                                                    {operationIDHAsActions(operationID) && <Card
                                                        heading="Operation Actions"
                                                        icon={<Icon category="standard" name="actions_and_buttons"
                                                                    size="small"/>}
                                                        bodyClassName={"slds-p-right_medium slds-p-left_medium slds-p-bottom_medium"}
                                                    >
                                                        <div
                                                            className="slds-row slds-size_1-of-3 slds-text-align--left">
                                                            <div className="slds-button-group slds-vertical">
                                                                {operationIDCanExpand(operationID) && <Button
                                                                    label="Expand"
                                                                    variant="neutral"
                                                                    className="slds-m-bottom_small slds-m-around_small"
                                                                    onClick={handleExpand}
                                                                />}
                                                                {operationIDCanRevert(operationID) && <Button
                                                                    label="Rollback"
                                                                    variant="text-destructive"
                                                                    className="slds-m-bottom_small slds-m-around_small"
                                                                    onClick={handleRevert}
                                                                />}
                                                            </div>
                                                        </div>
                                                    </Card>}
                                                </div>
                                            </div>
                                        </AccordionPanel>
                                        ))}
                                </Accordion>

                                        {/* Pagination display */}
                                        {Math.min(endIndex, filteredSCPDeployments.length) > 0 &&
                                            <div className="slds-grid slds-m-bottom--x-small slds-m-top_medium">
                                                <div
                                                    className="slds-col slds-size_1-of-2 slds-align-middle slds-m-left_medium slds-text-title_bold slds-text-color_success">
                                    <span>
                                        Showing {startIndex + 1} - {Math.min(endIndex, filteredSCPDeployments.length)} of {filteredSCPDeployments.length}
                                    </span>
                                                </div>
                                                {/* Pagination buttons */}
                                                <div className="slds-col slds-size_1-of-2 slds-text-align--right">
                                                    <ButtonGroup className="slds-m-right_medium">
                                                        <Button
                                                            label="Previous"
                                                    variant="neutral"
                                                    onClick={handlePrevPage}
                                                    disabled={currentPage <= 1}
                                                />
                                                <Button
                                                    label="Next"
                                                    onClick={handleNextPage}
                                                    disabled={(currentPage * ITEMS_PER_PAGE) >= filteredSCPDeployments.length}
                                                />
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                }
                                </>}
                            </Card>
                        </div>
                    </div>
                    {isExpandModalOpen && selectedOperationID &&
                        <SCPExpandOperationModal
                            selectedOperationID={selectedOperationID}
                            onExpandSuccess={() => {
                                addToast("Expand Request Submitted Successfully!", `operation: ${selectedOperationID} has been submitted for Expand.`, "success");
                                setIsExpandModalOpen(false)
                            }}
                            onClose={() => {
                                setIsExpandModalOpen(false)
                            }}
                            isOpen={isExpandModalOpen}
                        />
                    }
                    {isRevertModalOpen && selectedOperationID &&
                        <SCPRollbackOperationModal
                            selectedOperationID={selectedOperationID}
                            onExpandSuccess={() => {
                                addToast("Revert Request Submitted Successfully!", `operation: ${selectedOperationID} has been submitted for Revert.`, "success");
                                setIsRevertModalOpen(false)
                            }}
                            onClose={() => {setIsRevertModalOpen(false)}}
                            isOpen={isRevertModalOpen}
                        />
                    }
                </>
                ) :
                <>
                    <div className="slds-size_1-of-1">
                        <div className="slds-m-top_medium slds-m-bottom_medium slds-text-align_center">
                            <div
                                className="slds-text-title">
                                <p className="slds-text-heading_medium slds-text-color_default">
                                    No SCP Operations Found
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            }
            </Card>
        </>

        return <>{body}</>
    }

    const getStackSetsBarChartSize = () => {
        return `slds-size_1-of-${changessBarChartData.length <= 0 ? 1 : changessBarChartData.length}`
    }

    const getChangesCard = () => {
        return <>
            {/* Second Box: Stackset Operations with Accordion */}
            {selectedOperationID && (
                <Card
                    heading="Changes"
                    icon={<Icon category="standard" name="code_set" size="small"/>}
                    className={"slds-m-right--small slds-m-left_small slds-m-bottom_small"}
                    bodyClassName={"slds-p-left--large slds-p-right--large slds-p-bottom--large slds-p-top--medium"}
                    style={{minHeight: "50vh"}}
                >
                    {
                        findOperation(selectedOperationID).length > 0 ? <>
                            <div className="slds-grid slds-wrap">
                                <div className="slds-col">
                                    <BarChart
                                        heading={"Change Deployment Status Distribution"}
                                        data={changessBarChartData}
                                        liClass={getStackSetsBarChartSize()}
                                        headerClass={"slds-text-title_bold slds-text-color--weak"}
                                        contentText={"StackSet"}
                                    />
                                </div>
                            </div>
                            <h2 className={"slds-text-title_bold slds-text-color--weak slds-m-top--x-small"}> MPAs</h2>
                            <Accordion>
                                {findOperation(selectedOperationID).map((operation, index) => (
                                    <AccordionPanel
                                        key={index}
                                        expanded={selectedMPAID === operation.mpa_id}
                                        onTogglePanel={() => {setSelectedMPAID(selectedMPAID === operation.mpa_id ? "" : operation.mpa_id)}}
                                        summary={
                                            <div className="slds-text-wrap" style={{whiteSpace: 'normal'}}>
                                                {`${index + 1}. ${operation.mpa_id}`}
                                            </div>
                                        }
                                        className="slds-m-bottom_small"
                                    >
                                        <div className="slds-grid slds-box">
                                            <div className="slds-col slds-size_3-of-3 slds-m-horizontal--x-small">
                                                <ul className="slds-list_dotted">
                                                    {getDetailsLi("Is Falcon", `${operation.is_falcon}`)}
                                                    {getDetailsLi("AWS Path", operation.aws_path)}
                                                    {getDetailsLi("Operation Type", operation.operation_type)}
                                                    {getDetailsLi("SCP ID", operation.scp_id)}
                                                    {getDetailsLi("SCP Name", operation.scp_name)}
                                                    {getDetailsLi("Status", operation.status)}
                                                    {getDetailsLi("Stagger Level", operation.stagger_level)}
                                                    {getDetailsLi("Stagger Stage", operation.stagger_stage)}
                                                </ul>
                                            </div>
                                        </div>
                                    </AccordionPanel>
                                ))}
                            </Accordion>

                            </> :
                            <>
                                <div className="slds-grid slds-wrap">
                                    <div className="slds-col">
                                        <p>No MPAs were changed in this operation</p>
                                    </div>
                                </div>
                            </>
                    }
                </Card>
            )}
        </>
    }

    // Useful links Data
    const usefulPCSKLinks: QuickLinkItem[] = [
        {text: "Admin SCP Deployment Guide", link: "https://salesforce.quip.com/cve4AxjvoSoU"},
        {text: "SCP Store Repo", link: "https://git.soma.salesforce.com/identity-access/pcsk-scp-store"},
        {text: "SCP API Repo", link: "https://git.soma.salesforce.com/identity-access/pcsk-scp-api"},
        {text: "V2 Deployment Guide", link: "https://salesforce.quip.com/Os8HA4ArgXob"},
        {text: "PCSK Troubleshooting Guide", link: "https://salesforce.quip.com/1c4HA0Gl48Vr"},
        {
            text: "PCSK Availability",
            link: "https://tabse.internal.salesforce.com/views/UnifiedServiceHealthREADS/SFHealthTrend?Senior%20Engineering%20Leader=Paul%20Constantinides&Service%2FFeature=pcsk-jit&%3Aembed=y&%3Aoriginal_view=y#1"
        },
    ];
    return <>
        {/* Render navigation bar */}
        {navigationBar}

        <QuickLinkList usefulPCSKLinks={usefulPCSKLinks}/>


        <div className={"slds-grid"}>
            <div className="slds-col slds-size_3-of-4">
                {getDeploymentsCard()}
            </div>
            <div className="slds-col slds-size_1-of-4">
                {getChangesCard()}
            </div>
        </div>
    </>

};

export default PCSKAdminSCPDeploymentsPage;
