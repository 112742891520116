import React, {Component} from "react";
import {
    Accordion,
    AccordionPanel,
    DataTable,
    DataTableColumn,
} from "@salesforce/design-system-react";
import {CONSTANT_APPROVERS} from "../../../constants/approvers";
import CustomSpinnerOverlay from "../../../utils/components/spinner/custom_spinner_overlay";

type Approver = {
    email: string;
    tags: string[];
}

type ApproversProps = {
    approvers: Approver[]
    slackChannel: string;
    loading: boolean
};

type ApproversState = {
    expandedPanels: { [key: string]: boolean };
};

class Approvers extends Component<ApproversProps, ApproversState> {
    constructor(props: ApproversProps) {
        super(props);
        let expandedPanels = this.initialExpandedPanels();
        this.state = {
            expandedPanels: expandedPanels
        };
    }

    componentDidUpdate(prevProps: ApproversProps) {
        // Check if approvers changed
        if (prevProps.approvers !== this.props.approvers) {
            let expandedPanels = this.initialExpandedPanels();
            this.setState({ expandedPanels });
        }
    }

    initialExpandedPanels() {
        let expandedPanels: { [key: string]: boolean } = {};
        const uniqueTagsList = this.uniqueTags();
        if (uniqueTagsList.length === 1) {
            expandedPanels[uniqueTagsList[0]] = true;
        } else if (uniqueTagsList.includes("OWNER")) {
            expandedPanels["OWNER"] = true;
        }
        return expandedPanels;
    }

    uniqueTags() {
        return Array.from(new Set(this.props.approvers.flatMap(approver => approver.tags.map(tag => tag.toUpperCase()))));
    }

    togglePanel(tag: string) {
        this.setState(prevState => ({
            expandedPanels: {
                ...prevState.expandedPanels,
                [tag]: !prevState.expandedPanels[tag]
            }
        }));
    }

    transformData() {
        let dataMap: { [key: string]: string[] } = {};

        this.props.approvers.forEach(approver => {
            approver.tags.forEach(tag => {
                let uppercaseTag = tag.toUpperCase();
                if (!dataMap[uppercaseTag]) dataMap[uppercaseTag] = [];
                dataMap[uppercaseTag].push(approver.email);
            });
        });

        let transformedData = Object.keys(dataMap).map(tag => ({ tag, emails: dataMap[tag] }));

        // Sorting based on the tag
        transformedData.sort((a, b) => {
            // Ensure "OWNER" is always on top
            if (a.tag === "OWNER") return -1;
            if (b.tag === "OWNER") return 1;

            return a.tag.localeCompare(b.tag);
        });

        return transformedData;
    }

    render() {
        const transformedData = this.transformData();
        return (
            <div>
                <div className='slds-text-heading--medium slds-text-align--center slds-section-title--divider'>Access Request Approver(s)</div>
                {this.props.slackChannel && this.props.slackChannel !== "" ? (
                    <div className='slds-m-top--medium slds-text-align--center'><b>{CONSTANT_APPROVERS.SLACK_CHANNEL_LABEL}</b> {this.props.slackChannel}</div>
                ) : (
                    <div className='slds-m-top--medium slds-text-align--center'>{CONSTANT_APPROVERS.NO_SLACK_CHANNEL_LABEL}</div>
                )}
                {this.props.loading ? (
                    <div className='slds-m-top--large slds-m-bottom--large'>
                        <CustomSpinnerOverlay size={"small"}/>
                    </div>
                ) : transformedData.length === 0 ? (
                    <div className="slds-m-top--medium slds-text-align--center">
                        <div className='slds-m-top--xx-large slds-text-title_bold'>{CONSTANT_APPROVERS.NO_APPROVAL_REQD}</div>
                        <div className='slds-m-top--xx-small'>{CONSTANT_APPROVERS.NO_APPROVAL_REQD_DETAIL} <br/> {CONSTANT_APPROVERS.NO_APPROVAL_REQD_DETAIL_ADDITIONAL}</div>
                    </div>
                ) : (
                    <div className='slds-m-top--medium'>
                        <Accordion>
                            {transformedData.map((data, index) => (
                                <AccordionPanel
                                    key={index}
                                    id={`accordion-panel-${index}`}
                                    expanded={this.state.expandedPanels[data.tag] || false} // Add || false for safety, so undefined becomes false
                                    onTogglePanel={() => this.togglePanel(data.tag)}
                                    summary={data.tag}
                                >
                                    {data.emails.length > 0 ? (
                                        <div style={{ height: '30rem', overflowY: 'auto' }}>
                                            <DataTable items={data.emails.map((email, idx) => ({ id: idx.toString(), email }))} id={`DataTable-${index}`} noRowHover isHeadless>
                                                <DataTableColumn property="email"/>
                                            </DataTable>
                                        </div>
                                    ) : (
                                        <div className='slds-m-top--medium slds-text-align--center'>No approvers</div>
                                    )}
                                    {data.tag === CONSTANT_APPROVERS.SRE && <div className='slds-var-m-around_medium slds-text-color--error'><b>{CONSTANT_APPROVERS.NOTE_LABEL}</b>{CONSTANT_APPROVERS.SRE_NOTE}</div>}
                                </AccordionPanel>
                            ))}
                        </Accordion>
                    </div>
                )}
            </div>
        );
    }


}
export default Approvers;