import {MainSCPDeploymentApiService} from "./api_repository";
import {SCPDeploymentApiService} from "./service";
import {IsSCPAPIMockEnabled} from "../../environment/environment";
import {MockSCPDeploymentApiService} from "./mock_api_repository";
export class SCPDeploymentAPIServiceFactory {
    static getService(): SCPDeploymentApiService {
        if (IsSCPAPIMockEnabled()) {
            return new MockSCPDeploymentApiService();
        } else {
            return new MainSCPDeploymentApiService();
        }
    }
}
