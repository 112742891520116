import React from "react";
import {DataTableCell} from "@salesforce/design-system-react";

export type CustomStatusCellProps = {
    children?: React.ReactNode;
    size: number;
    td_class?: string;
    suffix?: string;
};
const CustomBreakDataTableCell: React.FC<CustomStatusCellProps> = ({ children, td_class = "slds-text-align_left", size = 25, suffix = "", ...props }) => {
    const breakTextIntoChunks = (text: string, chunkSize: number) => {
        const regex = new RegExp(`.{1,${chunkSize}}`, "g");
        return text.match(regex);
    };

    const textChunks = breakTextIntoChunks(`${children}`, size);

    return (
        <td className={`${td_class} slds-p-around_medium`}>
            <p className={"slds-text-color--weak"}>
                {textChunks && textChunks.map((chunk, index) => (
                    <React.Fragment key={index}>
                        {chunk}
                        {index < textChunks.length - 1 && <br/>}
                    </React.Fragment>
                ))}
                &nbsp;{suffix}
            </p>
        </td>
    );
};
CustomBreakDataTableCell.displayName = DataTableCell.displayName;
export default CustomBreakDataTableCell;
