import React, {useEffect, useState} from 'react';
import {Modal, Button, Card} from '@salesforce/design-system-react';
import {StackSetsDeployment} from "../../../../utils/types/deployments/stacksets/deployment";
import {StackSetsDeploymentApiServiceFactory} from "../../../../services/deployment/stacksets/factory";
import CustomSpinnerOverlay from "../../../../utils/components/spinner/custom_spinner_overlay";
import ToggleGuide from "../../../../components/pcsk_admin/deployment/stacksets/ToggleGuide";
import ChangeCaseInput from "../../../../utils/components/input/changeCaseInput";
import {extractCommitIDFromArtifact} from "../../../../components/pcsk_admin/deployment/stacksets/Utils";
import {GenericDeploymentSubmissionResponse} from "../../../../utils/types/deployments/genericDeploymentResponse";
import ArtifactSelector from "../../../../utils/components/input/artifactSelector";


interface RevertCommitModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedCommit: StackSetsDeployment;
    onRevertSuccess: (apiResponse: GenericDeploymentSubmissionResponse) => void;
}

const RevertStackSetCommitModal: React.FC<RevertCommitModalProps> = ({ isOpen, onClose, selectedCommit, onRevertSuccess }) => {
    const [revertArtifact, setRevertArtifact] = useState<string>('');
    const [changeCaseID, setChangeCaseID] = useState<string>('');
    const [isValidChangeCase, setIsValidChangeCase] = useState<boolean>(false);
    const [isRevertInProgress, setIsRevertInProgress] = useState<boolean>(false);
    const [disableRevertButton, setDisableRevertButton] = useState<boolean>(false);
    const [dryRun, setDryRun] = useState<boolean>(false);
    const [forceRevert, setForceRevert] = useState<boolean>(false);
    const [revertAPIResponse, setRevertAPIResponse] = useState<GenericDeploymentSubmissionResponse | undefined>(undefined);

    const stackSetsApiService = StackSetsDeploymentApiServiceFactory.getService();

    useEffect(() => {
        setDisableRevertButton(isRevertInProgress || !isValidChangeCase || !validateCommitID(revertArtifact))
    }, [isValidChangeCase, revertArtifact, isRevertInProgress]);

    const handleRevert = async () => {
        try {
            setIsRevertInProgress(true);
            const deploymentResponse = await stackSetsApiService.revertCommit(changeCaseID, selectedCommit.commit_id, "Revert_" + selectedCommit.commit_title, extractCommitIDFromArtifact(revertArtifact), forceRevert, dryRun);
            deploymentResponse.success && onRevertSuccess(deploymentResponse)
            setRevertAPIResponse(deploymentResponse)
        } catch (error) {
            console.log("Error submitting commit for Revert", error);
            setRevertAPIResponse(new GenericDeploymentSubmissionResponse(`${error}`, false))
        } finally {
            setIsRevertInProgress(false);
        }
    };

    const validateCommitID = (commitID: string) => {
        return commitID.length > 0
    }


    const getBottomContent = () => {
        if (isRevertInProgress) {
            return <>
                <Card hasNoHeader={true}>
                    <div style={{minHeight: "10vh"}}>
                        <div className="slds-m-top_xx-large slds-flex slds-flex_column slds-align-items_center">
                            <CustomSpinnerOverlay size={"medium"}/>
                            <p className="slds-m-top_large slds-align_absolute-center slds-text-color--inverse-weak">
                                Submitting commit for revert
                            </p>
                        </div>
                    </div>
                </Card>
            </>
        }

        if (revertAPIResponse) {
            return <>
                <Card hasNoHeader={true}>
                    {revertAPIResponse.success ?
                        <div className="slds-m-around_medium slds-p-around_medium">
                            <h2 className="slds-text-heading_medium slds-text-align_center slds-m-bottom_medium slds-text-color_success">
                                Deployment Request Submitted Successfully!
                            </h2>
                        </div> :
                        <div className="slds-m-around_medium slds-p-around_medium">
                            <h2 className="slds-text-heading_medium slds-text-align_center slds-m-bottom_medium slds-text-color_destructive">
                                {revertAPIResponse.message}
                            </h2>
                        </div>
                    }
                </Card>
            </>
        }

        return (
            <>
                <Card
                    bodyClassName={"slds-color__background_gray-4"}
                    hasNoHeader={true}
                >
                    <ToggleGuide suffix={"Revert"} showAutoExpand={false}/>
                </Card>
            </>
        );
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            size="medium"
            heading={
                <div>
                    <div>
                        <strong className={"slds-text-color_destructive"}>Revert Commit:</strong> {selectedCommit.commit_id}
                    </div>
                    <div>
                        <em>{selectedCommit.commit_title}</em>
                    </div>
                </div>
            }
            dismissOnClickOutside={false}
            footer={
                <>
                    <Button label="Revert" onClick={handleRevert} variant="brand" disabled={disableRevertButton}/>
                </>
            }
        >
            <div className="slds-p-around_medium">
                <div className={"slds-box"}>
                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Change Case:</strong></label>
                        </div>
                        <div className="slds-col">
                            <ChangeCaseInput
                                isValidChangeCase={isValidChangeCase}
                                disableInput={isRevertInProgress}
                                changeCaseID={changeCaseID}
                                setChangeCaseID={setChangeCaseID}
                                setIsValidChangeCase={setIsValidChangeCase}
                                searchKey={""}
                            ></ChangeCaseInput>
                        </div>
                    </div>

                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Revert Commit ID:</strong></label>
                        </div>
                        <div className="slds-col slds-size_1-of-2">
                            <ArtifactSelector
                                setArtifact={setRevertArtifact}
                                disable={isRevertInProgress}
                                prefix={"pcsk-stacksets"}
                            />
                            {
                                revertArtifact &&
                                <div className="slds-text-color--weak slds-text-body_small">
                                    <p>CommitID: {extractCommitIDFromArtifact(revertArtifact)}</p>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="slds-grid slds-gutters slds-m-horizontal--medium">
                        <div className="slds-form-element__control slds-m-around--small">
                            <label className="slds-checkbox_toggle slds-grid">
                                <input
                                    type="checkbox"
                                    checked={dryRun}
                                    disabled={isRevertInProgress}
                                    onChange={() => {
                                        setDryRun(!dryRun)
                                    }}
                                />
                                <span className="slds-checkbox_faux_container" aria-live="assertive">
                                <span className="slds-checkbox_faux"></span>
                                <span className="slds-checkbox_on">Dry Run</span>
                                <span className="slds-checkbox_off">Dry Run </span>
                            </span>
                            </label>
                        </div>
                        <div className="slds-form-element__control slds-m-around--small">
                            <label className="slds-checkbox_toggle slds-grid">
                                <input
                                    type="checkbox"
                                    checked={forceRevert}
                                    disabled={isRevertInProgress}
                                    onChange={() => {setForceRevert(!forceRevert)}}
                                />
                                <span className="slds-checkbox_faux_container" aria-live="assertive">
                                <span className="slds-checkbox_faux"></span>
                                <span className="slds-checkbox_on">Force Revert</span>
                                <span className="slds-checkbox_off">Force Revert </span>
                            </span>
                            </label>
                        </div>
                    </div>
                </div>
                {getBottomContent()}
            </div>
        </Modal>
    );
};

export default RevertStackSetCommitModal;
