export const mock_access_requests_response = [
    {
        "id": "shrawan.sah@salesforce.com-1001f78d-26e7-441a-a382-4c097773927e",
        "uuid": "1001f78d-26e7-441a-a382-4c097773927e",
        "sfdc_user": "shrawan.sah@salesforce.com",
        "sfdc_email": "shrawan.sah@salesforce.com",
        "role_name": "PCSKDeveloperRole",
        "reason_bucket": "Development",
        "reason_bucket_label": "Development",
        "reason": " pipeline approval pipeline approval pipeline approval pipeline approval pipeline approval pipeline approval pipeline approvalpipeline approval pipeline approval pipeline approval pipeline approval pipeline approval pipeline approval pipeline approval pipeline approval ",
        "detailed_reason": " pipeline approval pipeline approval pipeline approval pipeline approval pipeline approval pipeline approval pipeline approvalpipeline approval pipeline approval pipeline approval pipeline approval pipeline approval pipeline approval pipeline approval pipeline approval ",
        "aws_account": "345328591054",
        "aws_account_name": "Test Stage account",
        "timestamp": 1696572625,
        "duration": 8,
        "approved_at": 1696572627,
        "approved_by": "shrawan.sah@salesforce.com",
        "approved_by_email": "shrawan.sah@salesforce.com",
        "revoked_at": 0,
        "revoked_by": "",
        "rejected_at": 0,
        "rejected_by": "",
        "expired": false,
        "expires_at": 1696601427,
        "status": "pending",
        "name": "test",
        "account_substrate": "aws",
        "change_case": "",
        "incident": ""
    },
    {
        "id": "dnagpal@salesforce.com-8d212e19-ec85-44db-b242-d347f9283078",
        "uuid": "8d212e19-ec85-44db-b242-d347f9283078",
        "sfdc_user": "dnagpal@salesforce.com",
        "sfdc_email": "dnagpal@salesforce.com",
        "role_name": "PCSKReadOnlyAccessRole",
        "reason_bucket": "Development - ",
        "reason_bucket_label": "Development - ",
        "reason": "test",
        "detailed_reason": "test",
        "aws_account": "345328591054",
        "aws_account_name": "Test Stage account",
        "timestamp": 1696542367,
        "duration": 8,
        "approved_at": 1696542370,
        "approved_by": "dnagpal@salesforce.com",
        "approved_by_email": "dnagpal@salesforce.com",
        "revoked_at": 0,
        "revoked_by": "",
        "rejected_at": 0,
        "rejected_by": "shrawan.sah@salesforce.com",
        "expired": true,
        "expires_at": 1696571170,
        "status": "pending",
        "name": "test",
        "account_substrate": "aws",
        "change_case": "",
        "incident": ""
    },
    {
        "id": "dnagpal@salesforce.com-95ae299c-504f-4de9-8793-cc6c74a36acf",
        "uuid": "95ae299c-504f-4de9-8793-cc6c74a36acf",
        "sfdc_user": "dnagpal@salesforce.com",
        "sfdc_email": "dnagpal@salesforce.com",
        "role_name": "PCSKReadOnlyAccessRole",
        "reason_bucket": "Development - ",
        "reason_bucket_label": "Development - ",
        "reason": "test",
        "detailed_reason": "test",
        "aws_account": "345328591054",
        "aws_account_name": "Test Stage account",
        "timestamp": 1696542091,
        "duration": 8,
        "approved_at": 1696542093,
        "approved_by": "dnagpal@salesforce.com",
        "approved_by_email": "dnagpal@salesforce.com",
        "revoked_at": 1696542297,
        "revoked_by": "dnagpal@salesforce.com",
        "rejected_at": 0,
        "rejected_by": "",
        "expired": true,
        "expires_at": 1696570893,
        "status": "pending",
        "name": "test",
        "account_substrate": "aws",
        "change_case": "",
        "incident": ""
    },
    {
        "id": "dnagpal@salesforce.com-11af7b08-a853-4f6d-942b-2a85df4d2ed0",
        "uuid": "11af7b08-a853-4f6d-942b-2a85df4d2ed0",
        "sfdc_user": "dnagpal@salesforce.com",
        "sfdc_email": "dnagpal@salesforce.com",
        "role_name": "PCSKReadOnlyAccessRole",
        "reason_bucket": "Development - ",
        "reason_bucket_label": "Development - ",
        "reason": "test",
        "detailed_reason": "test",
        "aws_account": "345328591054",
        "aws_account_name": "Test Stage account",
        "timestamp": 1696541959,
        "duration": 8,
        "approved_at": 1696541962,
        "approved_by": "dnagpal@salesforce.com",
        "approved_by_email": "dnagpal@salesforce.com",
        "revoked_at": 0,
        "revoked_by": "",
        "rejected_at": 0,
        "rejected_by": "",
        "expired": true,
        "expires_at": 1696570762,
        "status": "pending",
        "name": "test",
        "account_substrate": "aws",
        "change_case": "",
        "incident": ""
    },
    {
        "id": "dnagpal@salesforce.com-32c934e6-09e0-4c26-a0da-749feb0c0a04",
        "uuid": "32c934e6-09e0-4c26-a0da-749feb0c0a04",
        "sfdc_user": "dnagpal@salesforce.com",
        "sfdc_email": "dnagpal@salesforce.com",
        "role_name": "PCSKReadOnlyAccessRole",
        "reason_bucket": "Development - ",
        "reason_bucket_label": "Development - ",
        "reason": "test",
        "detailed_reason": "test",
        "aws_account": "345328591054",
        "aws_account_name": "Test Stage account",
        "timestamp": 1696541792,
        "duration": 8,
        "approved_at": 1696541794,
        "approved_by": "dnagpal@salesforce.com",
        "approved_by_email": "dnagpal@salesforce.com",
        "revoked_at": 1696541967,
        "revoked_by": "dnagpal@salesforce.com",
        "rejected_at": 0,
        "rejected_by": "",
        "expired": true,
        "expires_at": 1696570594,
        "status": "pending",
        "name": "test",
        "account_substrate": "aws",
        "change_case": "",
        "incident": ""
    },
    {
        "id": "dnagpal@salesforce.com-56ac39c2-e3be-456b-915c-0d6c1296a6d5",
        "uuid": "56ac39c2-e3be-456b-915c-0d6c1296a6d5",
        "sfdc_user": "dnagpal@salesforce.com",
        "sfdc_email": "dnagpal@salesforce.com",
        "role_name": "PCSKReadOnlyAccessRole",
        "reason_bucket": "Development - ",
        "reason_bucket_label": "Development - ",
        "reason": "test",
        "detailed_reason": "test",
        "aws_account": "345328591054",
        "aws_account_name": "Test Stage account",
        "timestamp": 1696541547,
        "duration": 8,
        "approved_at": 1696541549,
        "approved_by": "dnagpal@salesforce.com",
        "approved_by_email": "dnagpal@salesforce.com",
        "revoked_at": 1696541749,
        "revoked_by": "dnagpal@salesforce.com",
        "rejected_at": 0,
        "rejected_by": "",
        "expired": true,
        "expires_at": 1696570349,
        "status": "pending",
        "name": "test",
        "account_substrate": "aws",
        "change_case": "",
        "incident": ""
    }
]