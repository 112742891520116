import {Button, Modal } from "@salesforce/design-system-react";
import React from "react";
import CustomSpinnerOverlay from "../utils/components/spinner/custom_spinner_overlay"


interface Props {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isApprovalInProgress: boolean;
    handleConfirmApproval: () => void;
    awsAccountID: string;
    awsAccountName: string;
    awsAccountEmail: string;
}

const ConfirmAccountClosureModal: React.FC<Props> = ({
    isModalOpen,
    setIsModalOpen,
    isApprovalInProgress,
    handleConfirmApproval,
    awsAccountID,
    awsAccountEmail,
    awsAccountName,
    }) => {

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            size="x-small"
            heading="Confirm Account Closure Approval"
            dismissOnClickOutside={false}
            disableClose={isApprovalInProgress}
            footer={
                <div className="slds-grid slds-wrap slds-grid_vertical-align-center slds-grid_align-spread">
                    <div className="slds-align-top">
                        <p>Are you sure you want to approve this account closure?</p>
                    </div>
                    <div className="slds-grid slds-grid_vertical-align-center">
                        {isApprovalInProgress && (
                            <div className="slds-m-right_x-large">
                                <CustomSpinnerOverlay size="small"/>
                            </div>
                        )}
                        <div>
                            <Button
                                label={isApprovalInProgress ? 'Approving...' : 'Confirm Approval'}
                                variant="brand"
                                onClick={handleConfirmApproval}
                                disabled={isApprovalInProgress}
                            />
                        </div>
                    </div>
                </div>
            }
        >
            <div className="slds-m-left_small">
                <p>&nbsp;</p>
                <p><strong>AWS Account ID:</strong> {awsAccountID}</p>
                <p><strong>Name:</strong> {awsAccountName}</p>
                <p><strong>Email:</strong> {awsAccountEmail}</p>
                <p>&nbsp;</p>
            </div>
        </Modal>
    );
};

export default ConfirmAccountClosureModal;
